import { type ReactNode, useEffect, useState } from 'react'
import { getGeoZonesAndAgents, type GeoZone } from '@venturi-io/api/src/config/geoZone'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import MultiSelect from 'src/Input/MultiSelect'

type Value = string[]

export interface Props {
  value?: Value
  onChange: (value: Value) => void
  required?: boolean
  searchable?: boolean
  error?: ReactNode
}

const geoZoneToSelect = ({ geoZoneId, name }: GeoZone) => ({
  value: geoZoneId.toString(),
  label: name
})

export default function GeoZoneMultiSelect ({
  onChange,
  required = false,
  searchable = false,
  error,
  ...props
}: Props) {
  const { token, orgId } = useUser()
  const geoZonesAndAgents = useApi(getGeoZonesAndAgents)
  const [initialLoad, setInitialLoad] = useState(true)
  const [value, setValue] = useState<Value>(props.value ?? [])

  const selectGeoZones = geoZonesAndAgents.data.mapOrDefault(({ geoZones }) => (
    geoZones.map(geoZoneToSelect)
  ), [])

  useEffect(() => {
    const req = {
      orgId
    }

    void geoZonesAndAgents.fetch(req, token)

    if (initialLoad) {
      setInitialLoad(false)
    } else {
      setValue([])
    }
  }, [])

  return (
    <MultiSelect
      required={required}
      searchable={searchable}
      disabled={geoZonesAndAgents.loading}
      label="Geozones"
      placeholder="Choose geozone(s)"
      data={selectGeoZones}
      value={value}
      onChange={val => {
        setValue(val)
        onChange(val)
      }}
      clearSearchOnChange={false}
      clearSearchOnBlur
      error={error}
    />
  )
}
