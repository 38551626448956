import { createStyles, Box, Text } from '@mantine/core'
import { IconWand } from '@tabler/icons-react'
import { getTextColorAsProp, getWhiteBackgroundColor } from 'src/utils/theme'

const useStyles = createStyles((theme) => ({
  container: {
    ...getWhiteBackgroundColor(theme),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 16px',
    borderRadius: '8px 8px 0 0'
  }
}))

export default function Header () {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <IconWand size={20} color="gray" />
      <Text
        ml={8}
        size={16}
        weight={700}
        {...getTextColorAsProp()}
      >
        Geozone Wizard
      </Text>
    </Box>
  )
}
