import React from 'react'
import dayjs from 'dayjs'
import { dateFormat, uiDateFormatUniversal } from 'src/utils/dates'
import { Group, Stack, Title, useMantineTheme } from '@mantine/core'
import StackedLabelValue from 'src/Layout/StackedLabelValue'
import { type VehicleTransaction } from '@venturi-io/api/src/config/fms'
import { IconDropletHalf2Filled } from '@tabler/icons-react'
import Pins, { type Pin } from '.'

export interface TransactionData extends VehicleTransaction {}

interface Props {
  show: boolean
  pins: Pin[]
}

function FillPins ({
  show,
  pins
}: Props) {
  const { colors } = useMantineTheme()

  return show
    ? (
      <Pins
        pins={pins}
        content={(data) => {
          const { started, volume } = data as TransactionData

          return (
            <Stack spacing="xs">
              <Group spacing="xs">
                <IconDropletHalf2Filled size={24} color={colors.violet[5]} />
                <Title order={3}>
                  {`${volume.toFixed(2)} L`}
                </Title>
              </Group>
              <StackedLabelValue color="dimmed" value={dayjs(started, dateFormat).format(uiDateFormatUniversal)} />
            </Stack>
          )
        }}
      />
      )
    : null
}

export default FillPins
