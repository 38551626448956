import { useEffect, useMemo, useState } from 'react'
import { getSensorData } from '@venturi-io/api/src/collector/sensor'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import {
  createStyles,
  Box,
  Group,
  Paper,
  Stack,
  Text,
  useMantineTheme
} from '@mantine/core'
import { type BarCommonProps } from '@nivo/bar'
import { max } from 'd3-array'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import Bar from 'src/Charts/Bar'
import { dateFormat, giveOffset } from 'src/utils/dates'
import dayjs, { type ManipulateType } from 'dayjs'
import SegmentedTimePeriodSelector, {
  type ChosenBucket,
  type ItemKeys
} from 'src/Input/SegmentedTimePeriodSelector'
import { getTextColorAsProp } from 'src/utils/theme'
import NotApplicableOverlay from '../NotApplicableOverlay'
import Loader from './Loader'

const useStyles = createStyles(() => ({
  chartContainer: {
    minWidth: 0,
    position: 'relative'
  },
  tooltip: {
    background: '#212121',
    color: '#FFFFFF'
  }
}))

interface Props {
  title?: string
  sensorInstanceId?: Sensor['sensorInstanceId']
  unit?: string
  height: number
  margin?: BarCommonProps<{}>['margin']
  roundedValues?: boolean
}

export default function SensorHistoryBarChart ({
  title,
  sensorInstanceId,
  unit,
  height,
  margin,
  roundedValues = false
}: Props) {
  const { classes } = useStyles()
  const { token } = useUser()
  const { colors, colorScheme } = useMantineTheme()
  const isLight = colorScheme === 'light'
  const getSensorHistory = useApi(getSensorData)
  const [bucket, setBucket] = useState<ChosenBucket<ItemKeys>>({
    item: '7 days',
    data: {
      name: '7d',
      timeBucket: '1 day',
      noOfRecords: 7
    }
  })
  const axisBottomFormat = bucket.item.includes('day')
    ? 'DD/MM'
    : 'h:mm A'
  const disabled = typeof sensorInstanceId === 'undefined'

  const sensorHistory = useMemo(() => (
    getSensorHistory.data.mapOrDefault(({ result }) => (
      result[0].data
        .map(({ x, y }) => ({
          x,
          y: typeof y !== 'undefined'
            ? roundedValues
              ? Math.round(y)
              : y
            : 0
        }))
        .sort((a, b) => (a.x > b.x ? 1 : -1))
    ), [])
  ), [getSensorHistory.data])

  const maxValue = max(sensorHistory, ({ y }) => y ?? 0)

  useEffect(() => {
    if (sensorInstanceId) {
      const time = bucket.item.split(' ')
      void getSensorHistory.fetch({
        sensorInstanceIds: [sensorInstanceId],
        startTime: dayjs()
          .subtract(Number.parseInt(time[0]), time[1] as ManipulateType)
          .format(dateFormat),
        endTime: dayjs().format(dateFormat),
        timeBucket: bucket.data.timeBucket,
        timeZone: giveOffset() as '+10:00',
        noOfRecords: bucket.data.noOfRecords
      }, token)
    }
  }, [sensorInstanceId, bucket])

  return (
    <Box>
      <Stack spacing={8}>
        <Group position="apart">
          <Text size={14} weight={600}>{title}</Text>
          <SegmentedTimePeriodSelector
            chosenBucket={bucket}
            updateBucket={item => setBucket(item)}
            isLoading={getSensorHistory.loading || disabled}
          />
        </Group>
        <Box
          sx={{
            pointerEvents: getSensorHistory.loading
              ? 'none'
              : 'auto'
          }}
          className={classes.chartContainer}
        >
          {disabled && <NotApplicableOverlay size="lg" />}
          <Loader isLoading={getSensorHistory.loading} />
          <Bar
            data={sensorHistory}
            indexBy="x"
            keys={['y']}
            height={height}
            colors={{
              scheme: 'tableau10'
            }}
            margin={margin}
            enableLabel={false}
            valueScale={{
              type: 'linear',
              max: maxValue && maxValue > 0
                ? 'auto'
                : 100,
              stacked: false,
              reverse: false
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              format: (value) => dayjs(value).format(axisBottomFormat),
              tickSize: 0,
              tickPadding: 12,
              tickRotation: 0,
              legendOffset: 36,
              legendPosition: 'middle',
              truncateTickAt: 0
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 12,
              tickRotation: 0,
              legendOffset: -40,
              legendPosition: 'middle',
              truncateTickAt: 0
            }}
            enableGridX={false}
            legends={[]}
            motionConfig="gentle"
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 10,
                    fontWeight: 400,
                    fill: getTextColorAsProp().color
                  }
                }
              },
              grid: {
                line: {
                  stroke: isLight
                    ? colors.gray[2]
                    : colors.dark[4]
                }
              },
              crosshair: {
                line: {
                  stroke: isLight
                    ? colors.dark[4]
                    : colors.gray[2]
                }
              }
            }}
            isInteractive={sensorHistory.length > 1}
            tooltip={({ indexValue, formattedValue }) => (
              <Paper
                className={classes.tooltip}
                px={12}
                py={8}
                radius="sm"
                shadow="sm"
              >
                <Text size={12} weight={500}>
                  {`${dayjs(indexValue).format('MMM D h:mm A')}`}
                </Text>
                <Text size={16} weight={600}>
                  {`${formattedValue} ${unit ?? ''}`}
                </Text>
              </Paper>
            )}
          />
        </Box>
      </Stack>
    </Box>
  )
}
