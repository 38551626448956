import { useState } from 'react'
import { Group, Text, Tooltip, type TextProps } from '@mantine/core'
import { truncateWithEllipsis } from 'src/utils/strings'
import { type Icon } from '@tabler/icons-react'
import { getTextColorAsProp } from 'src/utils/theme'

interface Props extends TextProps {
  Icon?: Icon
  value: string
  maxLength?: number
}

export default function TextTooltip ({
  Icon,
  value,
  maxLength,
  ...props
}: Props) {
  const [showTooltip, setShowTooltip] = useState(false)
  const isOpenTooltip = typeof maxLength !== 'undefined' &&
    showTooltip &&
    value.length > maxLength

  return (
    <Group>
      {Icon && (
        <Icon
          size={24}
          color={getTextColorAsProp().color}
        />
      )}
      <Tooltip
        label={value}
        opened={isOpenTooltip}
        withArrow
        withinPortal
      >
        <Text
          onMouseOver={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          {...props}
        >
          {maxLength
            ? truncateWithEllipsis(value, maxLength)
            : value}
        </Text>
      </Tooltip>
    </Group>
  )
}
