import { useNavigate } from 'react-router-dom'
import {
  Badge,
  Group,
  Paper,
  Stack,
  Text
} from '@mantine/core'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import ActionButton from 'src/ActionButton'
import { type ReportSchedule } from '@venturi-io/api/src/config/report'
import { dateFormat, uiDateFormatUniversal } from 'src/utils/dates'
import dayjs from 'dayjs'

export default function Card ({
  reportScheduleId,
  reportName,
  reportScheduleType,
  scheduleDay,
  scheduleStartDate,
  lastScheduleRun,
  scheduleEnabled
}: ReportSchedule & ListRow) {
  const navigate = useNavigate()

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={reportScheduleId.toString()} isKey />
          <ActionButton
            label="Edit"
            icon="pencil"
            iconType="fas"
            onClick={() => navigate(`/reports/scheduled/edit/${reportScheduleId}`)}
            actionColor="green"
            actionVariant="filled"
            iconColor="white"
            iconSize="sm"
          />
        </Group>
        <TextGroup label="Name" value={reportName} />
        <TextGroup label="Schedule Type" value={reportScheduleType} />
        <TextGroup label="Schedule Day" value={scheduleDay} />
        <TextGroup
          label="Start Date"
          value={dayjs(scheduleStartDate, dateFormat).format(uiDateFormatUniversal)}
        />
        <TextGroup
          label="Last Schedule Run"
          value={lastScheduleRun
            ? dayjs(lastScheduleRun, dateFormat).format(uiDateFormatUniversal)
            : '-'}
        />
        <Stack spacing="xs" align="flex-start">
          <Text weight={600} size="sm">Enabled</Text>
          <Badge
            color={scheduleEnabled
              ? 'green'
              : 'red'}
            size="sm"
          >
            {scheduleEnabled ? 'ON' : 'OFF'}
          </Badge>
        </Stack>
      </Stack>
    </Paper>
  )
}
