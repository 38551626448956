import { useEffect } from 'react'

interface Props {
  ref: HTMLDivElement | null
  latency?: number
  onSingleClick: () => void
  onDoubleClick: () => void
}

const useDoubleClick = ({
  ref,
  latency = 300,
  onSingleClick = () => null,
  onDoubleClick = () => null
}: Props) => {
  useEffect(() => {
    let clickCount = 0
    const handleClick = () => {
      clickCount += 1

      // introduce a delay timer to determine if clicks are consecutive or single
      setTimeout(() => {
        if (clickCount === 1) onSingleClick()
        else if (clickCount === 2) onDoubleClick()

        clickCount = 0
      }, latency)
    }

    if (ref) {
      // Add event listener for click events
      ref.addEventListener('click', handleClick)

      // Remove event listener
      return () => {
        ref.removeEventListener('click', handleClick)
      }
    }
  })
}

export default useDoubleClick
