import React from 'react'
import { type TripSummary } from '@venturi-io/api/src/collector/trip'
import dayjs from 'dayjs'
import { dateFormat, uiDateOnlyFormatUniversal } from 'src/utils/dates'
import { Badge, Group, Stack } from '@mantine/core'
import { IconArrowRight, IconPlayerPlayFilled } from '@tabler/icons-react'
import { getTextColorAsProp } from 'src/utils/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StackedLabelValue from 'src/Layout/StackedLabelValue'
import Pins, { type Pin } from '.'

export interface TripData extends TripSummary {}

interface Props {
  show: boolean
  pins: Pin[]
}

function TripPins ({
  show,
  pins
}: Props) {
  return show
    ? (
      <Pins
        pins={pins}
        content={(data) => {
          const { startTime, endTime } = data as TripData
          const time = 'h:mm A'
          const date = dayjs(startTime, dateFormat).format(uiDateOnlyFormatUniversal)
          const start = dayjs(startTime, dateFormat).format(time)
          const end = dayjs(endTime, dateFormat).format(time)

          return (
            <Stack spacing="xs">
              <StackedLabelValue
                label="Trip"
                value={date}
              />
              <Group spacing="xs">
                <Badge color="green.7">
                  <Group spacing={4}>
                    <IconPlayerPlayFilled size={12} />
                    {start}
                  </Group>
                </Badge>
                <IconArrowRight size={16} color={getTextColorAsProp().color} />
                <Badge color="red.4">
                  <Group spacing={4}>
                    <FontAwesomeIcon icon={['fas', 'flag-checkered']} size="sm" />
                    {end}
                  </Group>
                </Badge>
              </Group>
            </Stack>
          )
        }}
      />
      )
    : null
}

export default TripPins
