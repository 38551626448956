import { type ChangeEvent, useEffect, useState } from 'react'
import {
  ActionIcon,
  Drawer,
  Group,
  Input,
  Loader,
  Stack,
  Title
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import {
  type GeoZone,
  getGeoZonesAndAgents as getGeoZones
} from '@venturi-io/api/src/config/geoZone'
import { IconX, IconSearch } from '@tabler/icons-react'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import Item from './Item'

interface Props {
  opened: boolean
  setOpened: (status: boolean) => void
  onSelectGeoZone: (geoZone: GeoZone) => void
}

export default function GeoZoneList ({
  opened = false,
  setOpened,
  onSelectGeoZone
}: Props) {
  const { token, orgId } = useUser()
  const allGeoZones = useApi(getGeoZones)
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebouncedValue(search, 500)
  const geoZones = allGeoZones.data.mapOrDefault(({ geoZones }) => geoZones, [])
  const filteredGeoZones = geoZones.filter(({ name }) => (
    name
      .toLowerCase()
      .includes(debouncedSearch.toLowerCase())
  ))

  useEffect(() => {
    if (opened) {
      void allGeoZones.fetch({
        orgId,
        showHiddenGeoZones: true
      }, token)
    } else {
      setSearch('')
    }
  }, [opened])

  return (
    <Drawer.Root
      position="right"
      padding="xl"
      shadow="xs"
      size="sm"
      opened={opened}
      onClose={() => setOpened(false)}
    >
      <Drawer.Overlay />
      <Drawer.Content>
        <Drawer.Header>
          <Drawer.Title
            sx={{
              width: '100%'
            }}
          >
            <Group position="apart" align="center">
              <Group align="center" spacing={8}>
                <Title order={4}>Geozones</Title>
                {allGeoZones.loading && <Loader size="xs" color="primary" />}
              </Group>
              <Drawer.CloseButton />
            </Group>
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <Input
            placeholder="Search geozone"
            icon={<IconSearch size={20} color="gray" />}
            rightSection={search !== '' && (
              <ActionIcon
                title="Clear"
                onClick={() => setSearch('')}
              >
                <IconX
                  size={16}
                  stroke={1.5}
                  color="silver"
                />
              </ActionIcon>
            )}
            radius={4}
            value={search}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          />
          <Stack mt={12} spacing={10}>
            {filteredGeoZones.map(geoZone => (
              <Item
                key={geoZone.geoZoneId}
                geoZone={geoZone}
                onSelectGeoZone={onSelectGeoZone}
              />
            ))}
          </Stack>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  )
}
