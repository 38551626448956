export interface StyleParams {
  headerHeight: string
  sidebarCollapsedWidth: string
  fullView: boolean
  show: boolean
}

export const styleParams = {
  headerHeight: '64px',
  sidebarCollapsedWidth: '64px',
  fullView: false,
  show: false
}
