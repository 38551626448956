import { Paper, Skeleton, Stack } from '@mantine/core'

export default function AssetDetails () {
  return (
    <Paper
      p={16}
      radius={8}
      shadow="xs"
    >
      <Skeleton
        height={20}
        width={120}
        radius="md"
      />
      <Stack mt={24} spacing={16}>
        <Stack spacing={4}>
          <Skeleton
            height={12}
            width={80}
            radius="md"
          />
          <Skeleton
            height={12}
            width={140}
            radius="md"
          />
        </Stack>
        <Stack spacing={4}>
          <Skeleton
            height={12}
            width={80}
            radius="md"
          />
          <Skeleton
            height={12}
            width={200}
            radius="md"
          />
        </Stack>
        <Stack spacing={4}>
          <Skeleton
            height={12}
            width={80}
            radius="md"
          />
          <Skeleton
            height={12}
            width={120}
            radius="md"
          />
        </Stack>
        <Stack spacing={4}>
          <Skeleton
            height={12}
            width={80}
            radius="md"
          />
          <Skeleton
            height={12}
            width={180}
            radius="md"
          />
        </Stack>
        <Stack spacing={4}>
          <Skeleton
            height={12}
            width={80}
            radius="md"
          />
          <Skeleton
            height={12}
            width={150}
            radius="md"
          />
        </Stack>
      </Stack>
    </Paper>
  )
}
