import { type AgentDetails, type LiquidType } from '@venturi-io/api/src/config/agent'
import { createStyles, Box, Paper } from '@mantine/core'
import { getBackgroundColor, getWhiteBackgroundColor } from 'src/utils/theme'
import ConnectivityStatus, {
  type Status as ConnectivityStatusType
} from '../../Components/ConnectivityStatus'
import Pictogram from './Pictogram'
import Runtime from './Runtime'
import FuelType from './FuelType'

const useStyles = createStyles((theme) => ({
  container: {
    overflow: 'clip',
    ...getWhiteBackgroundColor(theme)
  },
  stateContainer: {
    width: '100%',
    padding: '12px 15px',
    display: 'inline-flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.05)',
    position: 'relative',
    overflow: 'hidden',
    zIndex: 2
  },
  withShadow: {
    zIndex: 1,
    '&::after': {
      content: '""',
      width: '80px',
      height: '80px',
      position: 'absolute',
      ...getBackgroundColor(theme, 1),
      left: 'auto',
      top: '0',
      right: '-25px',
      transform: ' rotateY(0deg) rotate(37deg)'
    }
  }
}))

interface Props {
  assetType: AgentDetails['assetType']
  connectivityStatus: ConnectivityStatusType
  connectivityDuration?: string
  lastDataTransfer?: string
  totalAssetRuntime?: string
  fuelType?: LiquidType
}

export default function PictogramAndStatus ({
  assetType,
  connectivityStatus,
  connectivityDuration,
  lastDataTransfer,
  totalAssetRuntime,
  fuelType
}: Props) {
  const { classes } = useStyles()
  return (
    <Paper className={classes.container} radius={8} shadow="xs">
      <Pictogram assetType={assetType} />
      <Box className={classes.stateContainer}>
        <ConnectivityStatus
          status={connectivityStatus}
          duration={connectivityDuration}
          lastDataTransfer={lastDataTransfer}
        />
      </Box>
      {totalAssetRuntime && (
        <Box className={classes.stateContainer}>
          <Runtime duration={totalAssetRuntime} />
        </Box>
      )}
      {fuelType && (
        <Box className={`${classes.stateContainer} ${classes.withShadow}`}>
          <FuelType fuelType={fuelType} />
        </Box>
      )}
    </Paper>
  )
}
