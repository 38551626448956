import { z } from 'zod'
import dayjs from 'dayjs'
import { createStatefulApi, createStatelessApi } from '../configure'
import { user } from '../userManager/user'
import { site } from '../userManager/site'
import { agentGroup } from './agentGroup'
import {
  valueMap,
  geoLocation,
  type AgentIdRequest,
  type PaginatedRequest,
  paginated,
  jsonSchema,
  type Json,
  type AgentGroupIdRequest,
  type OrgUserIdRequest
} from '../shared'

export const gpsSettings = [
  'MANUAL',
  'GPS',
  'CELLULAR'
] as const

export const assetTypes = [
  'GENSET',
  'ATS',
  'UPS',
  'TANK',
  'VEHICLE',
  'DURESS',
  'POWER METER',
  '3P-POWER',
  'OTHERS'
] as const

export const liquidTypes = [
  'DIESEL',
  'GREASE',
  'PALM_OIL',
  'PETROLEUM',
  'UNLEADED',
  'WATER'
] as const

export const alarmStatuses = [
  'NEW',
  'OK',
  'WARNING',
  'ALARM',
  'CRITICAL'
] as const

export const connectionStatuses = [
  'OFFLINE',
  'ONLINE'
] as const

export const agentStatuses = [
  'OFF',
  'IDLE',
  'MOVING',
  'IMMOBILISED'
] as const

export const statiscticsType = [
  'ENGINE_TIME',
  'ODOMETER'
] as const

export type AssetType = typeof assetTypes[number]

export type LiquidType = typeof liquidTypes[number]

export type AlarmStatus = typeof alarmStatuses[number]

export type ConnectionStatus = typeof connectionStatuses[number]

export type AgentStatus = typeof agentStatuses[number]

export type GpsSetting = typeof gpsSettings[number]

export type StatisticType = typeof statiscticsType[number]

const metadataProp = z.object({
  value: z.optional(z.string()).nullable(),
  userEditable: z.optional(z.boolean())
})

export type MetadataProp = z.infer<typeof metadataProp>

export const metadata = z.record(z.string(), metadataProp)

export type Metadata = z.infer<typeof metadata>

const sensor = z.object({
  id: z.number(),
  sensorInstanceId: z.number(),
  name: z.string(),
  type: z.string(),
  unit: z.optional(z.string()),
  pollInterval: z.optional(z.number()),
  primaryFunction: z.boolean(),
  systemOnly: z.boolean(),
  description: z.optional(z.string()),
  minValue: z.optional(z.number()),
  maxValue: z.optional(z.number()),
  upperLimit: z.optional(z.number()),
  lowerLimit: z.optional(z.number()),
  valueMap,
  iconStyle: z.optional(z.string()),
  currentValue: z.optional(z.string()),
  alarmStatus: z.enum(alarmStatuses),
  showGraph: z.boolean(),
  sensorDataTransformerId: z.optional(z.number())
})

export type Sensor = z.infer<typeof sensor>

export const agentUser = user.pick({
  orgUserId: true,
  firstName: true,
  lastName: true,
  email: true
})

export const agentOwner = user.pick({
  orgUserId: true,
  firstName: true,
  lastName: true,
  email: true
})

export type AgentUser = z.infer<typeof agentUser>

const agentDetailsResponse = z.object({
  agentId: z.number(),
  siteId: z.optional(z.number()),
  deviceId: z.optional(z.string()),
  rfidTag: z.optional(z.string()),
  agentName: z.string(),
  agentGroups: z.array(agentGroup),
  assetType: z.optional(z.string()),
  liquidType: z.optional(z.enum(liquidTypes)),
  description: z.optional(z.string()),
  externalId: z.optional(z.string()),
  gpsSetting: z.optional(z.enum(gpsSettings)),
  geoLocation: z.optional(geoLocation),
  transmitInterval: z.optional(z.number()),
  measurementInterval: z.optional(z.number()),
  simulator: z.boolean(),
  sensors: z.array(sensor),
  iconStyle: z.optional(z.string()),
  metadata: z.optional(metadata),
  lastSeenTime: z.optional(z.string()),
  assignedTo: z.optional(z.string()),
  user: z.optional(agentUser),
  owner: z.optional(agentOwner),
  site: z.optional(
    site.pick({
      siteId: true,
      name: true
    })
  ),
  make: z.optional(z.string()),
  model: z.optional(z.string()),
  alarmStatus: z.enum(alarmStatuses),
  agentStatus: z.optional(z.enum(agentStatuses)),
  connectionStatus: z.optional(z.enum(connectionStatuses)),
  hasImmobiliser: z.boolean()
})

export type AgentDetails = z.infer<typeof agentDetailsResponse>

export const getAgentDetails = createStatelessApi<AgentIdRequest, typeof agentDetailsResponse>(
  'config',
  '/agent/:agentId',
  { method: 'GET', type: 'path' },
  agentDetailsResponse
)

export const agent = z.object({
  agentId: z.number(),
  agentName: z.string(),
  assetType: z.optional(z.string()),
  liquidType: z.optional(z.enum(liquidTypes)),
  description: z.optional(z.string()).default(''),
  deviceId: z.optional(z.string()),
  iconStyle: z.optional(z.string()),
  sensors: z.array(sensor),
  lastSeenTime: z.optional(z.string()),
  simulator: z.boolean(),
  assignedTo: z.optional(z.string()),
  metadata: z.optional(metadata),
  user: z.optional(agentUser),
  owner: z.optional(agentOwner),
  site: z.optional(
    site.pick({
      siteId: true,
      name: true
    })
  ),
  alarmStatus: z.enum(alarmStatuses),
  agentStatus: z.optional(z.enum(agentStatuses)),
  connectionStatus: z.optional(z.enum(connectionStatuses)),
  lastLocation: z.optional(z.object({
    updatedAt: z.optional(z.string()),
    fullAddress: z.optional(z.string()),
    addressLine: z.optional(z.string()),
    postcode: z.optional(z.string()),
    locality: z.optional(z.string()),
    place: z.optional(z.string()),
    region: z.optional(z.string()),
    country: z.optional(z.string()),
    geoLocation: z.optional(z.object({
      latitude: z.number(),
      longitude: z.number()
    }))
  })),
  hasImmobiliser: z.boolean()
})

export type Agent = z.infer<typeof agent>

interface CreateAgentRequest extends Record<string, unknown> {
  orgId: number
  agentName: string
  siteId: number
  // TODO: Assign proper type based on assetType enum once backend assetType has been settled.
  assetType?: string
  liquidType?: string | null
  metadata?: Metadata
}

const createdAgent = agent
  .omit({
    alarmStatus: true,
    agentStatus: true,
    connectionStatus: true,
    sensors: true
  })
  .extend({
    sensors: z.array(
      sensor
        .omit({
          type: true,
          alarmStatus: true
        })
        .extend({
          type: z.optional(z.string())
        })
    )
  })

export const createAgent = createStatefulApi<CreateAgentRequest, typeof createdAgent>(
  'config',
  '/agent',
  { method: 'POST' },
  createdAgent
)

interface AgentGroupAssignmentRequest extends AgentIdRequest {
  groupIds: number[]
}

export const assignAgentToGroup = createStatefulApi<AgentGroupAssignmentRequest, z.ZodBoolean>(
  'config',
  '/agent/:agentId/agents-groups',
  { method: 'POST' },
  z.boolean()
)

export const unassignAgentFromGroup = createStatefulApi<AgentGroupAssignmentRequest, z.ZodBoolean>(
  'config',
  '/agent/:agentId/agents-groups',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

export interface UpdateAgentRequest extends AgentIdRequest {
  siteId: number
  agentName?: string
  gpsSetting?: GpsSetting
  geoLocation?: {
    latitude: number
    longitude: number
  }
  deviceId?: string
  iconStyle?: string
  transmitInterval?: number
  simulator?: boolean
  assetType?: string
  liquidType?: string | null
  rfidTag?: string
  user?: OrgUserIdRequest
  owner?: OrgUserIdRequest
  metadata?: Metadata
}

const updateAgentResponse = z.object({})

export const updateAgent = createStatefulApi<UpdateAgentRequest, typeof updateAgentResponse>(
  'config',
  '/agent/:agentId',
  { method: 'PATCH' },
  updateAgentResponse
)

interface SetAgentNameRequest extends AgentIdRequest {
  name: string
}

export const setAgentName = createStatefulApi<SetAgentNameRequest, z.ZodBoolean>(
  'config',
  '/name/agent/:agentId',
  { method: 'PUT' },
  z.boolean()
)

const allAgents = paginated.extend({
  items: z.array(agent)
})

interface AllAgentsRequest extends PaginatedRequest {
  orgId: number
  agentName?: string
  assetType?: string
  alarmStatus?: string
  search?: string
  agentStatus?: string
  connectionStatus?: string
  startLastSeenTime?: string
  endLastSeenTime?: string
}

export const getAgents = createStatelessApi<AllAgentsRequest, typeof allAgents>(
  'config',
  '/org/:orgId/agents',
  { method: 'GET', type: 'path' },
  allAgents
)

interface AgentsForSitesRequest extends PaginatedRequest {
  siteId: number
}

export const getAgentsForSite = createStatelessApi<AgentsForSitesRequest, typeof allAgents>(
  'config',
  '/site/:siteId/agents',
  { method: 'GET', type: 'path' },
  allAgents
)

interface AgentsForAgentGroupRequest extends PaginatedRequest {
  agentGroupId: number
}

export const getAgentsForAgentGroup = createStatelessApi<AgentsForAgentGroupRequest, typeof allAgents>(
  'config',
  '/agent-group/:agentGroupId/agents',
  { method: 'GET', type: 'path' },
  allAgents
)

// Agent Logs
const log = z.object({
  id: z.number(),
  log: z.string(),
  severity: z.number(),
  timestamp: z.string().transform(input => dayjs(input).toDate())
})

const agentLogs = z.object({
  hasMore: z.boolean(),
  items: z.array(log)
})

export type AgentLog = z.infer<typeof log>

export const getAgentLogs = createStatelessApi<AgentIdRequest & PaginatedRequest, typeof agentLogs>(
  'config',
  '/agent/:agentId/logs',
  { method: 'GET', type: 'path' },
  agentLogs
)

export const getAgentConfigs = createStatelessApi<AgentIdRequest, typeof jsonSchema>(
  'config',
  '/agent/:agentId/config',
  { method: 'GET', type: 'path' },
  jsonSchema
)

interface UpdateAgentConfigRequest extends AgentIdRequest {
  configuration: Json
}

export const updateAgentConfig = createStatefulApi<UpdateAgentConfigRequest, typeof jsonSchema>(
  'config',
  '/agent/:agentId/config',
  { method: 'PATCH' },
  jsonSchema
)

export const deleteAgent = createStatefulApi<AgentIdRequest, z.ZodBoolean>(
  'config',
  '/agent/:agentId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

const role = z.object({
  roleId: z.number(),
  name: z.string()
})

export type Role = z.infer<typeof role>

// TODO: Update this into paginated once the API for getRolesForAgentGroup is ready
const roles = z.array(role)

export const getRolesForAgentGroup = createStatelessApi<AgentGroupIdRequest, typeof roles>(
  'config',
  '/agent-group/:agentGroupId/roles',
  { method: 'GET', type: 'path' },
  roles
)

const sensorInstance = z.object({
  sensorInstanceId: z.number(),
  primaryFunction: z.optional(z.boolean()),
  systemOnly: z.optional(z.boolean()),
  sensorDataTransformerId: z.optional(z.number())
})

export type SensorInstance = z.infer<typeof sensorInstance>
interface UpdateAgentSensorInstancesRequest extends AgentIdRequest {
  sensorInstances: SensorInstance[]
}

const sensorInstanceResponse = z.array(sensorInstance)

export const updateAgentSensorInstances = createStatefulApi<UpdateAgentSensorInstancesRequest, typeof sensorInstanceResponse>(
  'config',
  '/agents/:agentId/sensor-instances',
  { method: 'PATCH' },
  sensorInstanceResponse
)

const odometerUpdatedByOrgUser = z.optional(z.object({
  orgUserId: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string()
}))

const statisticsResponse = z.object({
  odometerInMeters: z.optional(z.number()),
  engineTimeInSeconds: z.optional(z.number()),
  odometerUpdateNote: z.optional(z.string()),
  odometerPreviousValue: z.optional(z.number()),
  odometerUpdatedValue: z.optional(z.number()),
  odometerUpdatedAt: z.optional(z.string()),
  odometerUpdatedByOrgUser
})

export type Statistic = z.infer<typeof statisticsResponse>

export interface StatisticRequest extends AgentIdRequest {
  type?: StatisticType
}

export const getAgentStatistics = createStatelessApi<AgentIdRequest, typeof statisticsResponse>(
  'config',
  '/agents/:agentId/statistics',
  { method: 'GET', type: 'path' },
  statisticsResponse
)

const statisticLog = z.object({
  id: z.number(),
  agentId: z.number(),
  type: z.enum(statiscticsType),
  previousValue: z.string(),
  newValue: z.string(),
  notes: z.optional(z.string()),
  timestamp: z.string(),
  orgUser: z.object({
    orgUserId: z.number(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string()
  })
})

export type StatisticLog = z.infer<typeof statisticLog>

const statisticLogs = paginated.extend({
  items: z.array(statisticLog)
})

export const getAgentStatisticsLogs = createStatelessApi<StatisticRequest, typeof statisticLogs>(
  'config',
  '/agents/:agentId/statistics/logs',
  { method: 'GET', type: 'path' },
  statisticLogs
)

export interface UpdateStatisticRequest extends AgentIdRequest {
  odometerInMeters?: number
  engineTimeInSeconds?: number
  notes?: string
}

export const updateAgentStatistics = createStatefulApi<UpdateStatisticRequest, typeof statisticsResponse>(
  'config',
  '/agents/:agentId/statistics',
  { method: 'PATCH' },
  statisticsResponse
)
