import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import { getAllTasks } from '@venturi-io/api/src/config/task'
import {
  createStyles,
  ActionIcon,
  Divider,
  Group,
  Paper,
  Stack,
  Text,
  Tooltip
} from '@mantine/core'
import { IconPlus, IconWindowMaximize } from '@tabler/icons-react'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import CreateTaskForm from 'src/Tasks/CreateForSingleAgent'
import { AssetContext } from 'src/AssetTemplate/Context'
import Item from './Item'

const useStyles = createStyles(() => ({
  container: {
    height: 248,
    width: '100%',
    overflow: 'clip'
  },
  header: {
    height: 48,
    padding: '12px 16px'
  },
  content: {
    height: 200,
    padding: '12px 16px',
    overflow: 'auto',
    scrollbarWidth: 'thin'
  }
}))

interface Props {
  agentId: AgentDetails['agentId']
}

export default function Tasks ({ agentId }: Props) {
  const { setTaskCount } = useContext(AssetContext)
  const { classes } = useStyles()
  const { token } = useUser()
  const tasks = usePaginatedApi(getAllTasks)
  const items = tasks.data.mapOrDefault(({ items }) => items.filter(item => item.status !== 'DONE'), [])
  const [showCreateForm, setShowCreateForm] = useState(false)

  const loadTasks = async (): Promise<void> => {
    void tasks.fetch({
      agentId: agentId.toString(),
      page: 1,
      size: 999999
    }, token)
  }

  useEffect(() => {
    void loadTasks()
  }, [])

  useEffect(() => {
    tasks.data.ifJust(({ totalItems }) => {
      if (setTaskCount) {
        setTaskCount(totalItems)
      }
    })
  }, [tasks.data])

  return (
    <>
      <Paper
        className={classes.container}
        p={0}
        radius={4}
        shadow="xs"
      >
        <Group className={classes.header} position="apart" align="center">
          <Text size={14} weight={600}>{`Tasks • ${items.length}`}</Text>
          <Group position="center" align="center" spacing={16}>
            <Tooltip
              label="Add New Task"
              position="right"
              transitionProps={{
                transition: 'pop',
                duration: 200
              }}
              withArrow
            >
              <ActionIcon
                variant="subtle"
                size="xs"
                color="dark.2"
                onClick={() => setShowCreateForm(true)}
                disabled={showCreateForm}
              >
                <IconPlus size={20} stroke={2} />
              </ActionIcon>
            </Tooltip>
            <Tooltip
              label="View All Tasks"
              position="right"
              transitionProps={{
                transition: 'pop',
                duration: 200
              }}
              withArrow
            >
              <ActionIcon
                component={Link}
                to={`/tasks?assetId=${agentId}`}
                variant="subtle"
                size="xs"
                color="dark.2"
              >
                <IconWindowMaximize size={20} stroke={2} />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Group>
        <Divider color="gray.2" />
        {items.length === 0 && (
          <Text
            mt={80}
            size={12}
            weight={400}
            color="silver"
            align="center"
          >
            No data available
          </Text>
        )}
        <Stack className={classes.content} spacing={14}>
          {items.map(({
            id,
            taskType,
            priority,
            status
          }) => (
            <Item
              key={id}
              id={id}
              taskType={taskType}
              priority={priority}
              status={status}
            />
          ))}
        </Stack>
      </Paper>
      <CreateTaskForm
        agentId={agentId}
        show={showCreateForm}
        close={() => setShowCreateForm(false)}
        onCreate={loadTasks}
      />
    </>
  )
}
