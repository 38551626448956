import {
  Alert,
  Collapse,
  type MantineSize,
  Text,
  createStyles,
  Divider,
  SimpleGrid,
  Button,
  Container,
  useMantineTheme
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { getTextColor } from 'src/utils/theme'

interface Props {
  assetName?: string
  missingSensors?: string[]
}

const useStyles = createStyles(theme => ({
  title: {
    ...getTextColor(theme)
  }
}))

export default function MissingSensorMessage ({ assetName, missingSensors }: Props) {
  const [isOpenDetails, setIsOpenDetails] = useState(false)
  const { classes } = useStyles()
  const { colorScheme } = useMantineTheme()

  const titleProps = {
    className: classes.title,
    size: 'sm' as MantineSize,
    fs: 'italic'
  }

  return (
    <Alert
      title="Missing Sensor"
      icon={<FontAwesomeIcon icon={['far', 'info-circle']} />}
      color="yellow"
      pb="lg"
      mb="sm"
    >
      {`There's a missing sensor for ${assetName ?? 'some of the following assets'}.
        Please ensure that all required/primary sensors were configured completely.`}
      {missingSensors && missingSensors?.length > 0 && (
        <Container sx={{ padding: '15px 0 0 0' }} size="100%">
          <Button
            variant="outline"
            color={colorScheme === 'light'
              ? 'primary'
              : 'gray.2'}
            onClick={() => setIsOpenDetails(!isOpenDetails)}
            size="sm"
            leftIcon={(
              <FontAwesomeIcon
                icon={['fas', 'bell']}
                color={colorScheme === 'light'
                  ? 'primary'
                  : 'gray.2'}
              />
            )}
          >
            {isOpenDetails ? 'Hide' : 'Show'}
            {' '}
            Missing Sensors
          </Button>
          <Collapse in={isOpenDetails}>
            <Divider
              my="lg"
              variant="dotted"
            />
            <SimpleGrid cols={2}>
              {missingSensors?.map((sensor, index) => (
                <Text {...titleProps} key={index}>
                  {sensor}
                </Text>
              ))}
            </SimpleGrid>
          </Collapse>
        </Container>
      )}

    </Alert>
  )
}
