import { Col, Grid, Text } from '@mantine/core'
import { useStyles } from './AlarmRow'

export default function AlarmRowHeader () {
  const { classes, cx } = useStyles({ odd: false })

  return (
    <Grid grow className={cx(classes.row, classes.header)} my={0}>
      <Col span={12} sm={2}>
        <Text size="sm" weight={600}>Name</Text>
      </Col>
      <Col span={12} sm={1}>
        <Text size="sm" weight={600}>Operator</Text>
      </Col>
      <Col span={12} sm={1}>
        <Text size="sm" weight={600}>Set point</Text>
      </Col>
      <Col span={12} sm={1}>
        <Text size="sm" weight={600}>Alarm Type</Text>
      </Col>
      <Col span={12} sm={1}>
        <Text size="sm" weight={600}>Enabled</Text>
      </Col>
      <Col span={12} sm={1}>
        <Text size="sm" weight={600}>Manual Clear</Text>
      </Col>
      <Col span={12} sm={1}>
        <Text size="sm" weight={600}>Quiet Mode</Text>
      </Col>
      <Col span={12} sm={1}>
        <Text size="sm" weight={600}>Task creation</Text>
      </Col>
      <Col span={12} sm={1}>
        <Text size="sm" weight={600}>Actions</Text>
      </Col>
    </Grid>
  )
}
