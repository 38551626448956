import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Group,
  Paper,
  Popover,
  Stack,
  Text,
  useMantineTheme
} from '@mantine/core'
import { useState, type Dispatch, type ReactNode, type SetStateAction } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Report } from '@venturi-io/api/src/config/report'
import { type Range } from '@venturi-io/api'
import dayjs from 'dayjs'
import NeedsRole from 'src/NeedsRole'
import { adminRoles } from 'src/utils/role'
import { downloadCSV } from 'src/utils/files'
import { isEmpty } from 'src/utils/objects'
import TimePeriodButton from 'src/Buttons/TimePeriodButton'
import { uiDateFormatUniversal } from 'src/utils/dates'
import { getWhiteBackgroundColor } from 'src/utils/theme'
import SelectReport from 'src/Input/Select/SelectReport'
import { useNavigate } from 'react-router'
import { IconArrowLeft } from '@tabler/icons-react'
import ExportToPdf from './ExportToPdf'
import { type ParsedTripItem } from './TripReportView'
import { type ParsedSpeedEvent } from './SpeedingReportView'
import { type ParsedDuressEvent } from './DuressReportView'
import { type ParsedIdleEvent } from './IdleReportView'
import { type ParsedGeozoneEvent } from './GeozoneReportView'

interface HeaderBarProps {
  report?: Report
  dateRange: Range | null
  reportData: Record<string, Array<Record<string, string | null>>>
  tripReportData: ParsedTripItem[]
  speedReportData: ParsedSpeedEvent[]
  duressReportData: ParsedDuressEvent[]
  idleReportData: ParsedIdleEvent[]
  geozoneReportData: ParsedGeozoneEvent[]
  fromEmbed?: boolean
  busy: boolean
  extensions: ReactNode
  queryId: number
  setReport: Dispatch<SetStateAction<Report | undefined>>
  onGenerate: (range: Range | null) => void
}

function HeaderBar ({
  report,
  dateRange,
  reportData,
  tripReportData,
  speedReportData,
  duressReportData,
  idleReportData,
  geozoneReportData,
  fromEmbed = false,
  busy = false,
  extensions,
  queryId,
  setReport,
  onGenerate
}: HeaderBarProps) {
  const theme = useMantineTheme()
  const navigate = useNavigate()
  const [upcomingRange, setUpcomingRange] = useState<Range | null>(dateRange)

  const isValidRange = () => {
    if (!report) {
      return true
    }
    const { reportType: { name } } = report
    switch (name) {
      case 'Trip History':
      case 'Duress Event Report':
      case 'Idle Time Report':
      case 'Geozone Event Report':
      case 'Speed Event Report':
        return upcomingRange
          ? dayjs(upcomingRange.from).isBefore(dayjs(upcomingRange.to))
          : true
      default:
        return true
    }
  }

  const handleDownloadReportAsCSV = (name: string) => {
    if (!report) {
      return true
    }
    const { reportType: { name: reportType } } = report
    switch (reportType) {
      case 'Trip History':
        return downloadCSV(tripReportData as unknown as Array<Record<string, unknown>>, name)
      case 'Duress Event Report':
        return downloadCSV(duressReportData as unknown as Array<Record<string, unknown>>, name)
      case 'Idle Time Report':
        return downloadCSV(idleReportData as unknown as Array<Record<string, unknown>>, name)
      case 'Geozone Event Report':
        return downloadCSV(geozoneReportData as unknown as Array<Record<string, unknown>>, name)
      case 'Speed Event Report':
        return downloadCSV(speedReportData as unknown as Array<Record<string, unknown>>, name)
      default:
        return Object
          .keys(reportData)
          .map(queryId => {
            downloadCSV(reportData[queryId], name)
            return queryId
          })
    }
  }

  const handleGenerate = () => {
    onGenerate(upcomingRange)
  }

  const getReportData = () => {
    if (!report) {
      return tripReportData
    }
    const { reportType: { name: reportType } } = report
    switch (reportType) {
      case 'Trip History':
        return tripReportData
      case 'Duress Event Report':
        return duressReportData
      case 'Idle Time Report':
        return idleReportData
      case 'Geozone Event Report':
        return geozoneReportData
      default:
        return speedReportData
    }
  }

  return (
    <Paper my={0} shadow="xs">
      <Flex
        align="center"
        p="md"
        pb={reportData[queryId] ? 0 : 'md'}
      >
        <Group
          position="left"
          spacing="sm"
          align="flex-end"
          sx={{ flexGrow: 1 }}
        >
          {fromEmbed && (
            <Button
              color="gray"
              variant="outline"
              leftIcon={<IconArrowLeft size={16} />}
              onClick={() => navigate('/reports/generated', { replace: true })}
            >
              Back
            </Button>
          )}
          <SelectReport
            disabled={fromEmbed}
            value={report?.reportType?.id}
            onChange={val => setReport(val)}
          />
          {extensions}
          {!fromEmbed && (
            <TimePeriodButton
              dateRange={upcomingRange}
              onChange={(range) => {
                setUpcomingRange(range)
              }}
              onSubmit={handleGenerate}
            />
          )}
          {report && !fromEmbed && (
            <Button
              title="Generate"
              color="primary"
              leftIcon={<FontAwesomeIcon icon={['fas', 'rotate']} />}
              onClick={handleGenerate}
              disabled={!isValidRange()}
              loading={busy}
            >
              Generate
            </Button>
          )}
        </Group>
        {report && (
          <Group
            position="right"
            align="flex-end"
            spacing={3}
            sx={{ flexShrink: 1 }}
            h="100%"
          >
            <NeedsRole role={adminRoles}>
              <Popover
                position="bottom-end"
                withArrow
                arrowOffset={12}
              >
                <Popover.Target>
                  <ActionIcon><FontAwesomeIcon icon={['far', 'ellipsis']} /></ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <Stack spacing="xs">
                    <Text color="dimmed" size="xs">Export data</Text>
                    <Button
                      disabled={isEmpty(reportData)}
                      leftIcon={<FontAwesomeIcon icon={['far', 'file-csv']} />}
                      variant="subtle"
                      color="primary"
                      onClick={() => {
                        handleDownloadReportAsCSV(report.name)
                      }}
                    >
                      Download as CSV
                    </Button>
                    <ExportToPdf
                      report={report}
                      rawData={reportData}
                      data={getReportData()}
                      range={dateRange}
                      queryId={queryId}
                      button={(toggle, disabled) => (
                        <Button
                          disabled={disabled}
                          leftIcon={<FontAwesomeIcon icon={['far', 'file-pdf']} />}
                          variant="subtle"
                          color="primary"
                          onClick={toggle}
                        >
                          Download as PDF
                        </Button>
                      )}
                    />
                  </Stack>
                </Popover.Dropdown>
              </Popover>
            </NeedsRole>
          </Group>
        )}
      </Flex>
      {dateRange && reportData[queryId] && (
        <Box
          p="md"
          sx={{
            borderRadius: 'sm',
            ...getWhiteBackgroundColor(theme),
            position: 'sticky',
            top: 0,
            zIndex: 100
          }}
        >
          <Text size="sm" px="md" color="dimmed">
            Displaying
            {' '}
            <strong>{reportData[queryId].length}</strong>
            {' '}
            events from
            {' '}
            {dayjs(dateRange.from).format(uiDateFormatUniversal)}
            {' '}
            to
            {' '}
            {dayjs(dateRange.to).format(uiDateFormatUniversal)}
          </Text>
        </Box>
      )}
    </Paper>
  )
}

export default HeaderBar
