import { useEffect, useMemo, useState } from 'react'
import { getSensorData } from '@venturi-io/api/src/collector/sensor'
import { type Agent, type Sensor, getAgentDetails } from '@venturi-io/api/src/config/agent'
import {
  createStyles,
  Box,
  Group,
  Stack,
  Text,
  useMantineTheme,
  Switch
} from '@mantine/core'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { dateFormat, giveOffset } from 'src/utils/dates'
import dayjs, { type ManipulateType } from 'dayjs'
import SegmentedTimePeriodSelector, {
  type ChosenBucket,
  type ItemKeys
} from 'src/Input/SegmentedTimePeriodSelector'
import { getNoOfRecordOnMinutesPerBucketKey } from 'src/Input/TimePeriodSelector'
import Loader from 'src/AssetTemplate/Components/Charts/Loader'
import { getTextColorAsProp } from 'src/utils/theme'
import { getAgentTrips } from '@venturi-io/api/src/collector/trip'
import { type CartesianMarkerProps } from '@nivo/core'
import { useDisclosure, useElementSize } from '@mantine/hooks'
import { getVehicleTransactions } from '@venturi-io/api/src/config/fms'
import isBetween from 'dayjs/plugin/isBetween'
import {
  IconDropletHalf2Filled,
  IconPlayerPlayFilled,
  IconAlertTriangleFilled
} from '@tabler/icons-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { computeSvgXCoordinates } from '../shared'
import LineChart from './LineChart'
import TripPins from './Pins/TripPins'
import { type Pin } from './Pins'
import FillPins from './Pins/FillPins'
import TheftPins from './Pins/TheftPins'

dayjs.extend(isBetween)

const THEFT_VALUE = 15
const SAMPLE_RATE = 15
const IGNORE_SIBLING_RATE = 1

const useStyles = createStyles(() => ({
  chartContainer: {
    minWidth: 0,
    position: 'relative',
    height: 300
  }
}))

type SensorWithExtra = Omit<Sensor, 'id'> & {
  id: string
  agentId: Agent['agentId']
  agentName: Agent['agentName']
  agentAlarmStatus: Agent['alarmStatus']
}

interface DataPoint {
  x: Date | null
  y?: number
}

export interface SensorData {
  id: string
  data: DataPoint[]
}

interface Theft {
  x: Date | null
  theft: number
}

interface Props {
  title?: string
  agentId: Agent['agentId']
  sensorInstanceIds: Array<Sensor['sensorInstanceId']>
}

export default function TankHistory ({
  title,
  agentId,
  sensorInstanceIds
}: Props) {
  const { colors } = useMantineTheme()
  const { classes } = useStyles()
  const { token } = useUser()
  const { ref, width } = useElementSize()
  const [showTrips, { toggle: toggleTrips }] = useDisclosure(true)
  const [showTransactions, { toggle: toggleTransactions }] = useDisclosure(true)
  const [showThefts, { toggle: toggleThefts }] = useDisclosure(true)
  const agentDetails = useApi(getAgentDetails)
  const getSensorsHistory = useApi(getSensorData)
  const tripHistory = usePaginatedApi(getAgentTrips)
  const transactions = usePaginatedApi(getVehicleTransactions)
  const [bucket, setBucket] = useState<ChosenBucket<ItemKeys>>({
    item: '12 hours',
    data: {
      name: '12 h',
      timeBucket: '1 minutes',
      noOfRecords: getNoOfRecordOnMinutesPerBucketKey('12 hours')
    }
  })
  const isHours = bucket.item.includes('hour')

  const agent = useMemo(() => (
    agentDetails.data.mapOrDefault(data => data, null)
  ), [agentDetails.data])

  const sensorsWithExtra: SensorWithExtra[] = useMemo(() => {
    if (!agent) return []

    const {
      agentId,
      agentName,
      alarmStatus,
      sensors
    } = agent

    return sensors
      .filter(({ sensorInstanceId }) => sensorInstanceIds.includes(sensorInstanceId))
      .map(sensor => ({
        agentId,
        agentName,
        agentAlarmStatus: alarmStatus,
        ...sensor,
        id: sensor.name
      }))
  }, [agent, sensorInstanceIds])

  const sensorsHistory: SensorData[] = useMemo(() => (
    getSensorsHistory.data.mapOrDefault(({ result }) => (
      result
        .reduce((sensorsData_: SensorData[], { sensorInstanceId, data }) => {
          const sensor: SensorWithExtra | undefined =
            sensorsWithExtra.find(s => s.sensorInstanceId === sensorInstanceId)

          if (sensor) {
            // save the last good value
            // so we can remove negative values and replace it with it
            let lastGoodY = data[0]?.y && data[0].y > 0
              ? data[0]?.y
              : 0
            sensorsData_ = [
              ...sensorsData_,
              {
                id: sensor.id,
                data: data
                  .map(({ x, y }) => {
                    const actualY = typeof y !== 'undefined'
                      ? y
                      : 0
                    if (actualY > 0) {
                      lastGoodY = actualY
                    }
                    return {
                      x: new Date(x),
                      y: actualY > 0
                        ? actualY
                        : lastGoodY
                    }
                  })
                  .toReversed()
              }
            ]
          }

          return sensorsData_
        }, [])
        .filter(sensor => sensor.data.length > 0)
    ), [])
  ), [getSensorsHistory.data, sensorsWithExtra])

  const { chartFrom, chartTo } = useMemo(() => {
    const fuelData = sensorsHistory[0]
      ? sensorsHistory[0].data
      : []

    if (fuelData.length === 0) {
      return {
        chartFrom: undefined,
        chartTo: undefined
      }
    }

    const chartFrom = dayjs(fuelData[0].x, dateFormat)
    const chartTo = dayjs(fuelData[fuelData.length - 1].x, dateFormat)

    return {
      chartFrom,
      chartTo
    }
  }, [sensorsHistory])

  const tripMarkers = useMemo(() => (
    tripHistory.data.mapOrDefault(({ items }) => (
      items.map(({ startTime, endTime }) => {
        const xMarker: Omit<CartesianMarkerProps, 'value' | 'legend'> = {
          axis: 'x',
          legendOrientation: 'vertical',
          lineStyle: {
            strokeWidth: 1.5,
            strokeDasharray: '4, 6'
          },
          textStyle: {
            fill: getTextColorAsProp().color,
            fontSize: 10
          }
        }
        const start = dayjs(startTime, dateFormat)
        const startMarker: CartesianMarkerProps = {
          ...xMarker,
          legendPosition: 'bottom-right',
          value: start.startOf('minute').toDate(),
          lineStyle: {
            ...xMarker.lineStyle,
            stroke: colors.green[5]
          }
        }
        const end = dayjs(endTime, dateFormat)
        const endMarker: CartesianMarkerProps = {
          ...xMarker,
          legendPosition: 'bottom-left',
          value: end.startOf('minute').toDate(),
          lineStyle: {
            ...xMarker.lineStyle,
            stroke: colors.red[5]
          }
        }

        const markers = []
        if (start.isBetween(chartFrom, chartTo)) {
          markers.push(startMarker)
        }
        if (end.isBetween(chartFrom, chartTo)) {
          markers.push(endMarker)
        }

        return markers
      }).flat()
    ), [])
  ), [tripHistory.data, chartFrom, chartTo])

  const transactionData = useMemo(() => (
    transactions.data.mapOrDefault(({ items }) => items
      .filter(({ started }) =>
        dayjs(started, dateFormat).isBetween(chartFrom, chartTo))
    , [])
  ), [transactions, chartFrom, chartTo])

  const transactionMarkers = useMemo(() => (
    transactionData.map(({ started, volume }) => {
      const date = dayjs(started, dateFormat)
      return {
        axis: 'x',
        legendOrientation: isHours
          ? 'horizontal'
          : 'vertical',
        lineStyle: {
          strokeWidth: 2,
          stroke: colors.violet[4]
        },
        textStyle: {
          fill: colors.violet[4],
          fontWeight: 'bold',
          fontSize: 10
        },
        legend: `Fill: ${date.format('DD/MM hh:mm A')} - ${volume} L`,
        value: date.startOf('minute').toDate()
      } as CartesianMarkerProps
    }).flat()
  ), [transactions, bucket])

  const transactionPins = useMemo(() => (
    transactionData.map((data) => {
      // get index from
      const { started } = data
      const x = computeSvgXCoordinates({
        time: started,
        sensorData: sensorsHistory,
        width
      })

      return {
        data,
        icon: <IconDropletHalf2Filled size={16} color={colors.violet[4]} />,
        x
      }
    })
  ), [transactionData, sensorsHistory, width])

  const tripPins = useMemo(() => (
    tripHistory.data.mapOrDefault(({ items }) => (
      items.reduce<Array<Record<string, any>>>((acc, data) => {
        const pins: Pin[] = []
        const { startTime, endTime } = data
        const start = dayjs(startTime)
        if (start.isBetween(chartFrom, chartTo)) {
          const startX = computeSvgXCoordinates({
            time: start.toDate(),
            sensorData: sensorsHistory,
            width
          })
          pins.push({
            data,
            icon: <IconPlayerPlayFilled size={16} color={colors.green[4]} />,
            x: startX
          })
        }

        const end = dayjs(endTime)
        if (end.isBetween(chartFrom, chartTo)) {
          const endX = computeSvgXCoordinates({
            time: end.toDate(),
            sensorData: sensorsHistory,
            width
          })
          pins.push({
            data,
            icon: <FontAwesomeIcon size="sm" icon={['fas', 'flag-checkered']} color={colors.red[4]} />,
            x: endX
          })
        }

        return [
          ...acc,
          ...pins
        ]
      }, []).flat() as never[] as Pin[]
    ), [])
  ), [
    transactionData,
    sensorsHistory,
    width,
    chartFrom,
    chartTo
  ])

  const thefts = useMemo(() => {
    if (bucket.item.includes('day') || sensorsHistory.length === 0) return []
    let theftResult: Theft[] = []
    const fuelData = sensorsHistory[0]
    if (fuelData) {
      const { data } = fuelData
      const theftData = data.toReversed()
      let lastY = theftData[0].y ?? 0
      let lastDetected: Date | null = null
      theftResult = theftData.reduce<Theft[]>((acc, { x, y }, idx) => {
        const actualY = typeof y !== 'undefined'
          ? y
          : 0
        const diff = actualY - lastY
        const isRecent = lastDetected
          ? dayjs(lastDetected).subtract(5, 'minute').isBefore(dayjs(x))
          : false
        if (diff > THEFT_VALUE && !isRecent) {
          const next = theftData.slice(
            idx - SAMPLE_RATE - IGNORE_SIBLING_RATE,
            idx - IGNORE_SIBLING_RATE
          )
          const nextAverage = next.reduce((acc, { y }) => acc + (y ?? 0), 0) / SAMPLE_RATE

          const prev = theftData.slice(
            idx + IGNORE_SIBLING_RATE,
            idx + SAMPLE_RATE + IGNORE_SIBLING_RATE
          )
          const lastAverage = prev.reduce((acc, { y }) => acc + (y ?? 0), 0) / SAMPLE_RATE

          const theft = lastAverage - nextAverage

          if (theft > THEFT_VALUE && next.length === prev.length) {
            lastDetected = x
            return [
              ...acc,
              {
                x,
                theft
              }
            ]
          }
        }
        lastY = actualY
        return acc
      }, [])
    }
    return theftResult
  }, [sensorsHistory, bucket])

  const theftMarkers: CartesianMarkerProps[] = useMemo(() => (
    thefts.map(({ x, theft }) => {
      return {
        axis: 'x',
        legendOrientation: 'vertical',
        legendPosition: 'top-right',
        legend: `Approx. ~${theft.toFixed(0)} L`,
        value: dayjs(x).startOf('minute').toDate(),
        textStyle: {
          fill: colors.red[5],
          fontWeight: 'bold',
          fontSize: 12
        },
        lineStyle: {
          strokeWidth: 3,
          stroke: colors.red[6]
        }
      }
    })
  ), [thefts])

  const theftPins = useMemo(() => (
    thefts.map(({ x, theft }) => {
      const startX = computeSvgXCoordinates({
        time: x,
        sensorData: sensorsHistory,
        width
      })
      return {
        data: { theft, x },
        icon: <IconAlertTriangleFilled size={16} color={colors.red[6]} />,
        x: startX
      }
    })
  ), [sensorsHistory, thefts])

  useEffect(() => {
    const isBelow24Hrs = bucket.item.includes('hour')
    const time = bucket.item.split(' ')
    void getSensorsHistory.fetch({
      sensorInstanceIds,
      startTime: dayjs()
        .subtract(Number.parseInt(time[0]), time[1] as ManipulateType)
        .format(dateFormat),
      endTime: dayjs().format(dateFormat),
      timeBucket: isBelow24Hrs
        ? '1 minutes'
        : bucket.data.timeBucket,
      timeZone: giveOffset() as '+10:00',
      noOfRecords: isBelow24Hrs
        ? getNoOfRecordOnMinutesPerBucketKey(bucket.item)
        : bucket.data.noOfRecords
    }, token)

    // get trips
    const format = `${dateFormat}Z`
    const startTime = dayjs()
      .subtract(Number.parseInt(time[0]), time[1] as ManipulateType)
      .format(format)
    const endTime = dayjs()
      .format(format)
    void tripHistory.fetch({
      agentId,
      startTime,
      endTime,
      page: 1,
      size: 999
    }, token)

    // getTransactions
    void transactions.fetch({
      agentId,
      page: 1,
      size: 999
    }, token)
  }, [sensorInstanceIds, bucket])

  useEffect(() => {
    void agentDetails.fetch({ agentId }, token)
  }, [agentId])

  const markers = useMemo(() => ([
    ...(showTrips
      ? tripMarkers
      : []),
    ...(showTransactions
      ? transactionMarkers
      : []),
    ...(showThefts
      ? theftMarkers
      : [])
  ]), [
    showTrips,
    showThefts,
    showTransactions,
    tripMarkers,
    theftMarkers,
    transactionMarkers
  ])

  const margin = {
    top: 30,
    right: 20,
    bottom: 50,
    left: 40
  }

  return (
    <Box>
      <Stack spacing={8}>
        <Group position="apart">
          <Text size={14} weight={600}>{title}</Text>
          <Group position="right">
            <Switch
              checked={showTrips}
              onChange={toggleTrips}
              size="xs"
              color="primary"
              label="Trips"
            />
            <Switch
              checked={showTransactions}
              onChange={toggleTransactions}
              size="xs"
              color="primary"
              label="Fills"
            />
            <Switch
              checked={showThefts}
              onChange={toggleThefts}
              size="xs"
              color="primary"
              label="Theft"
            />
            <SegmentedTimePeriodSelector
              chosenBucket={bucket}
              updateBucket={item => setBucket(item)}
              isLoading={getSensorsHistory.loading}
            />
          </Group>
        </Group>
        <Box
          sx={{
            pointerEvents: getSensorsHistory.loading
              ? 'none'
              : 'auto'
          }}
          className={classes.chartContainer}
        >
          <Loader isLoading={getSensorsHistory.loading} />
          <LineChart
            sensorsHistory={sensorsHistory}
            bucket={bucket}
            markers={markers}
            margin={margin}
          />
          <Box
            ref={ref}
            sx={{
              position: 'absolute',
              top: 0,
              left: margin?.left ?? 0,
              right: margin?.right ?? 0
            }}
          >
            <TripPins
              show={showTrips}
              pins={tripPins}
            />
            <FillPins
              show={showTransactions}
              pins={transactionPins}
            />
            <TheftPins
              show={showThefts}
              pins={theftPins}
            />
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}
