import { type ChangeEvent, useCallback, useEffect, useState } from 'react'
import { IconPlus, IconX, IconSearch } from '@tabler/icons-react'
import {
  createStyles,
  ActionIcon,
  Box,
  Button,
  Drawer,
  Group,
  Input,
  Loader,
  Stack,
  Text,
  Title
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { type GeoZoneAction, getGeoZoneActions } from '@venturi-io/api/src/config/geoZoneAction'
import InfiniteScroll from 'react-infinite-scroll-component'
import { usePaginatedApi } from 'src/utils/useApi'
import { type FormMode } from 'src/utils'
import { useUser } from 'src/UserContext'
import Item from './Item'
import Form from './Form'

const useStyles = createStyles(() => ({
  listContainer: {
    height: '77vh',
    marginTop: 4,
    width: '100%',
    overflow: 'hidden',
    scrollbarWidth: 'thin',
    '&:hover': {
      overflowY: 'auto'
    }
  },
  drawerTitle: {
    width: '100%'
  }
}))

interface Props {
  opened: boolean
  setOpened: (status: boolean) => void
}

export default function ActionList ({
  opened = false,
  setOpened
}: Props) {
  const { classes } = useStyles()
  const { token, orgId } = useUser()
  const geoZoneActions = usePaginatedApi(getGeoZoneActions)
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebouncedValue(search, 500)
  const [selectedAction, setSelectedAction] = useState<GeoZoneAction>()
  const [formMode, setFormMode] = useState<FormMode>('CREATE')
  const [openedActionForm, setOpenedActionForm] = useState(false)
  const [cachedActions, setCachedActions] = useState<GeoZoneAction[]>([])
  const paginationSize = 15
  const { currentPage, totalPages } = geoZoneActions.data.mapOrDefault(({ currentPage, totalPages }) => ({
    currentPage,
    totalPages
  }), {
    currentPage: 1,
    totalPages: 1
  })

  const loadGeoZoneActions = (page?: number) => {
    void geoZoneActions
      .fetch({
        orgId,
        page: page ?? geoZoneActions.page,
        size: paginationSize,
        search: debouncedSearch
      }, token)
  }

  const handleClickAddNewAction = useCallback(() => {
    setFormMode('CREATE')
    setOpenedActionForm(true)
  }, [])

  const handleClickEditAction = useCallback(() => {
    setFormMode('EDIT')
    setOpenedActionForm(true)
  }, [])

  const handleSuccessCreateAction = useCallback((action: GeoZoneAction) => {
    const updatedActions = [action, ...cachedActions]
    setCachedActions(updatedActions)
  }, [cachedActions])

  const handleSuccessEditAction = useCallback((action: GeoZoneAction) => {
    const updatedActions = cachedActions.map(cachedAction => (
      action.geoZoneActionId === cachedAction.geoZoneActionId
        ? action
        : cachedAction
    ))
    setCachedActions(updatedActions)
  }, [cachedActions])

  const handleSuccessDeleteAction = useCallback((actionId: GeoZoneAction['geoZoneActionId']) => {
    const updatedActions = cachedActions.filter(({ geoZoneActionId }) => actionId !== geoZoneActionId)
    setCachedActions(updatedActions)
  }, [cachedActions])

  useEffect(() => {
    geoZoneActions.data.ifJust(({ items }) => {
      setCachedActions(cachedActions.concat(items))
    })
  }, [geoZoneActions.data])

  useEffect(() => {
    setCachedActions([])
    void loadGeoZoneActions(1)
  }, [debouncedSearch])

  useEffect(() => {
    if (typeof selectedAction !== 'undefined') {
      setFormMode('EDIT')
    } else {
      setFormMode('CREATE')
    }
  }, [selectedAction])

  useEffect(() => {
    if (!opened) {
      setSearch('')
    }
  }, [opened])

  return (
    <>
      <Drawer.Root
        position="right"
        shadow="xs"
        size="sm"
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <Drawer.Overlay />
        <Drawer.Content>
          <Drawer.Header>
            <Drawer.Title className={classes.drawerTitle}>
              <Group position="apart" align="center">
                <Group align="center" spacing={8}>
                  <Title order={4}>Actions</Title>
                  {geoZoneActions.loading && <Loader size="xs" color="primary" />}
                </Group>
                <Group position="center" align="center" spacing={8}>
                  <Button
                    type="button"
                    size="xs"
                    leftIcon={<IconPlus size={16} />}
                    onClick={handleClickAddNewAction}
                  >
                    Add New
                  </Button>
                  <Drawer.CloseButton />
                </Group>
              </Group>
            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body mt={12}>
            <Input
              placeholder="Search action"
              icon={<IconSearch size={20} color="gray" />}
              rightSection={search !== '' && (
                <ActionIcon
                  title="Clear"
                  onClick={() => setSearch('')}
                >
                  <IconX
                    size={16}
                    stroke={1.5}
                    color="silver"
                  />
                </ActionIcon>
              )}
              radius={4}
              value={search}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
              disabled={geoZoneActions.loading}
            />
            {!geoZoneActions.loading && cachedActions.length === 0 && (
              <Text
                mt={16}
                size={12}
                weight={400}
                color="silver"
                align="center"
              >
                No data available
              </Text>
            )}
            {cachedActions.length > 0 && (
              <Box id="scrollable-geozone-actions" className={classes.listContainer}>
                <InfiniteScroll
                  dataLength={cachedActions.length}
                  next={() => loadGeoZoneActions(currentPage + 1)}
                  hasMore={currentPage < totalPages}
                  loader={null}
                  scrollableTarget="scrollable-geozone-actions"
                >
                  <Stack mt={12} p={2} spacing={10}>
                    {cachedActions.map(action => (
                      <Item
                        key={action.geoZoneActionId}
                        action={action}
                        onSelect={setSelectedAction}
                        onClickEdit={handleClickEditAction}
                        onSuccessDelete={handleSuccessDeleteAction}
                      />
                    ))}
                  </Stack>
                </InfiniteScroll>
              </Box>
            )}
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
      <Form
        mode={formMode}
        actionId={selectedAction?.geoZoneActionId}
        opened={openedActionForm}
        setOpened={setOpenedActionForm}
        onSuccessCreate={handleSuccessCreateAction}
        onSuccessEdit={handleSuccessEditAction}
      />
    </>
  )
}
