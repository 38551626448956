import {
  Divider,
  Group,
  Paper,
  Skeleton,
  Stack
} from '@mantine/core'

export default function Pictogram () {
  return (
    <Paper
      h={348}
      radius={12}
      shadow="xs"
      sx={{
        overflow: 'clip'
      }}
    >
      <Skeleton
        radius={0}
        height={224}
        width="100%"
      />
      <Stack
        py={8}
        px={12}
        spacing={4}
      >
        <Group spacing={8}>
          <Skeleton
            height={20}
            width={20}
            circle
          />
          <Skeleton
            height={12}
            width={120}
            radius="md"
          />
        </Group>
        <Group ml={28} position="apart">
          <Skeleton
            height={12}
            width={100}
            radius="md"
          />
          <Skeleton
            height={12}
            width={60}
            radius="md"
          />
        </Group>
      </Stack>
      <Divider my={4} size="xs" />
      <Stack
        py={8}
        px={12}
        spacing={4}
      >
        <Group spacing={8}>
          <Skeleton
            height={20}
            width={20}
            circle
          />
          <Skeleton
            height={12}
            width={120}
            radius="md"
          />
        </Group>
        <Skeleton
          ml={28}
          height={12}
          width={80}
          radius="md"
        />
      </Stack>
    </Paper>
  )
}
