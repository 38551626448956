import { createStyles } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextTooltip from 'src/Layout/TextTooltip'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { getBackgroundColor } from 'src/utils/theme'
import type { SiteWithAlarms } from '@venturi-io/api/src/config/site'

const useStyles = createStyles(theme => ({
  sensor: {
    ...getBackgroundColor(theme, -2),
    padding: '12px',
    borderRadius: '11.35px',
    display: 'flex',
    flexDirection: 'row',
    boxShadow: theme.shadows.sm,
    '&:hover': {
      ...getBackgroundColor(theme)
    },
    transition: 'background .2s ease-in-out'
  },
  error: {
    border: '3px solid #E40000',
    boxShadow: `${theme.shadows.sm}, 0px 0px 10px 0px #E40000 inset`
  },
  col: {
    display: 'flex',
    alignItems: 'center'
  },
  center: {
    justifyContent: 'center'
  },
  icon: {
    flex: 0,
    padding: 5
  },
  title: {
    fontWeight: 600
  },
  content: {
    flex: 4,
    padding: 5
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    width: '100%'
  },
  row: {
    flex: 1,
    textAlign: 'left',
    width: '100%',
    fontWeight: 600
  },
  cursor: {
    cursor: 'pointer'
  },
  gray: {
    color: 'gray'
  },
  red: {
    color: 'red'
  },
  green: {
    color: 'green'
  },
  chevron: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '8px',
    paddingRight: '8px'
  }
}))

export interface Props extends SiteWithAlarms {}

export default function Cell ({ siteId, name, alarmsCount, alarmsOkCount }: Props) {
  const navigate = useNavigate()
  const { classes, cx } = useStyles()
  const hasErrors = alarmsCount > 0

  const sensorClasses = cx({
    [classes.sensor]: true,
    [classes.error]: hasErrors
  })

  const iconClasses = cx([
    classes.col,
    classes.center,
    classes.icon
  ])

  const contentClasses = cx(classes.col, classes.content)
  const alarmsClasses = cx({
    [classes.row]: true,
    [classes.gray]: alarmsCount === 0,
    [classes.red]: alarmsCount > 0
  })

  const alarmsOkClasses = cx({
    [classes.row]: true,
    [classes.gray]: alarmsOkCount === 0,
    [classes.green]: alarmsOkCount > 0
  })
  const sitePageRedirect = useCallback(() => navigate(`/site/${siteId}`), [])

  return (
    <div className={cx(sensorClasses, classes.cursor)} key={siteId} onClick={sitePageRedirect}>
      <div className={iconClasses}>
        {hasErrors
          ? <FontAwesomeIcon icon={['fas', 'times-square']} color="red" size="2x" />
          : <FontAwesomeIcon icon={['fas', 'check-square']} color="green" size="2x" />}
      </div>
      <div className={contentClasses}>
        <TextTooltip
          className={classes.title}
          size="lg"
          value={name}
          maxLength={20}
        />
      </div>
      <div className={cx(contentClasses, classes.right)}>
        <div className={alarmsClasses}>
          {`${alarmsCount} Alarm${alarmsCount > 1 ? 's' : ''}`}
        </div>
        <div className={alarmsOkClasses}>
          {`${alarmsOkCount} OK`}
        </div>
      </div>
    </div>
  )
}
