import { Text } from '@mantine/core'
import { useStyles } from 'src/Layout/ActionList'

const Header = () => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.row, classes.header)}>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Date/Time
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          RFID Tag
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Agent ID
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Agent Name
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Asset Type
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          User
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Volumn(L)
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          LPM
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          In/Out
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Trans ID
        </Text>
      </div>
    </div>
  )
}

export default Header
