import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Text,
  Group,
  Stack,
  ActionIcon,
  Tooltip,
  createStyles,
  Title,
  ScrollArea,
  Indicator,
  Center,
  Button,
  Switch,
  Drawer,
  Avatar,
  useMantineTheme
} from '@mantine/core'
import { useDisclosure, useLocalStorage } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { type Alarm, getAllNotifications } from '@venturi-io/api/src/analytics/notification'
import { useEffect, useState } from 'react'
import { useUser } from 'src/UserContext'
import { usePaginatedApi } from 'src/utils/useApi'
import { mq } from 'src/utils/style'
import { IconBell, IconCirclePlus } from '@tabler/icons-react'
import { getTextColorAsProp } from 'src/utils/theme'
import NotificationItem from './NotificationItem'

const useStyle = createStyles((theme, showIndicator: boolean) => ({
  heading: {
    flexGrow: 1
  },
  notifications: {
    padding: '8px',
    borderRadius: '50%'
  },
  notificationIcon: {
    [mq(theme.breakpoints.sm)]: {
      position: 'initial',
      zIndex: 'initial',
      display: 'none'
    }
  },
  indicator: {
    display: showIndicator ? 'flex' : 'none'
  },
  markAsReadLabel: {
    fontWeight: 'normal'
  },
  divider: {
    '::before': {
      content: '""',
      position: 'absolute',
      top: 63,
      right: 0,
      left: 0,
      height: 1,
      backgroundColor: theme.colors.gray[4]
    }
  }
}))

interface Props {
  showDrawer?: boolean
}

let isFirstLoad = true
const pageSize = 50
function Alarms ({ showDrawer }: Props) {
  const { token, orgName } = useUser()
  const { colors } = useMantineTheme()
  const [isOpened, { close, toggle }] = useDisclosure(false)
  const [showRead, { toggle: toggleRead }] = useDisclosure(false)
  const alarms = usePaginatedApi(getAllNotifications)
  const [alarmMemory, setAlarmMemory] = useLocalStorage({
    key: 'alarmMemory',
    defaultValue: '{}'
  })
  const [lastAlarms, setLastAlarms] = useState<Alarm[]>([])

  const parsedAlarmMemory = JSON.parse(alarmMemory)
  const recentAlarms = lastAlarms.slice(0, pageSize)
  const filtered = showDrawer
    ? recentAlarms.filter(({ id }) => showRead ? true : !parsedAlarmMemory[id])
    : []

  const unreadAlarms = recentAlarms.filter(({ id }) => !parsedAlarmMemory[id])

  const { classes } = useStyle(unreadAlarms.length > 0)

  const showAlarm = (alarm: Alarm) => {
    if (alarm.quietMode) return
    notifications.show({
      color: 'yellow',
      autoClose: 5000,
      message: (
        <NotificationItem
          {...alarm}
        />
      )
    })
  }

  const loadAlarms = () => {
    const req = {
      page: alarms.page ?? 1,
      size: pageSize
    }
    void alarms.fetch(
      req,
      token)
      .finally(() => {
        alarms.stopPolling()
        alarms.startPolling(req, token, 60)
      })
  }

  useEffect(() => {
    loadAlarms()

    return () => {
      alarms.stopPolling()
      alarms.abort()
    }
  }, [])

  const checkAlarm = (newAlarm: Alarm) => {
    const { id: newId } = newAlarm
    if (isFirstLoad) {
      return
    }
    // we don't need to show the alarm popup whenever this is active
    if (showDrawer ?? parsedAlarmMemory[newId]) {
      return
    }
    const alarmExists = lastAlarms.find(({ id: oldId }) => oldId === newId)
    if (!alarmExists) {
      showAlarm(newAlarm)
    }
  }

  const handleMarkAsRead = ({ id }: Alarm) => {
    const memory = JSON.parse(alarmMemory)
    memory[id] = true
    setAlarmMemory(JSON.stringify(memory))
  }

  const handleMarkAllAsRead = () => {
    const memory = JSON.parse(alarmMemory) as Record<string, boolean>
    const newMemory = Object.keys(memory)
      .reduce((acc, memo) => ({
        ...acc,
        [memo]: true
      }), {})
    setAlarmMemory(JSON.stringify(newMemory))
  }

  const handleAddSampleNotification = () => {
    const newId = Math.random() * 100
    const newAlarm = {
      id: 23,
      type: 'TASK',
      data: {
        id: 18,
        agent: {
          agentId: 1,
          agentName: 'Vehicle Test',
          assetType: 'VEHICLE'
        },
        status: 'NEW',
        dueDate: '2024-09-21T14:51:25+08:00',
        priority: 'HIGH',
        taskType: 'INVESTIGATION',
        taskGroup: 'GENERIC'
      },
      timestamp: '2024-09-20T14:51:36.226694+08:00',
      quietMode: false
    }
    setLastAlarms(lasts => ([
      newAlarm,
      ...lasts
    ]))
    if (parsedAlarmMemory[newId]) {
      return
    }
    const alarmExists = lastAlarms.find(({ id: oldId }) => oldId === newId)
    if (!alarmExists) {
      showAlarm(newAlarm)
    }
  }

  useEffect(() => {
    alarms.data.ifJust(({ items }) => {
      const sliced = items.slice(0, 3) // show last 3
      sliced.forEach((newAlarm: Alarm) => {
        checkAlarm(newAlarm)
      })
      setLastAlarms(items)
    })
    if (isFirstLoad) {
      isFirstLoad = false
    }
  }, [alarms.data])

  useEffect(() => {
    if (lastAlarms.length) {
      const lastMemory: Record<string, boolean> = JSON.parse(alarmMemory)
      const memory = lastAlarms.reduce((acc, { id }) => {
        if (lastMemory[id] === undefined) {
          return {
            ...acc,
            [id]: false
          }
        }
        return acc
      }, lastMemory)
      setAlarmMemory(JSON.stringify(memory))
    }
  }, [lastAlarms])

  useEffect(() => {
    if (unreadAlarms) {
      const tabTitle = `${orgName} - Venturi AMP`
      document.title = unreadAlarms.length
        ? `(${unreadAlarms.length}) ${tabTitle}`
        : tabTitle
    }
  }, [unreadAlarms])

  return showDrawer && (
    <>
      {process.env.NODE_ENV === 'development' && (
        <ActionIcon
          onClick={handleAddSampleNotification}
        >
          <IconCirclePlus size={24} color={getTextColorAsProp().color} />
        </ActionIcon>
      )}
      <Tooltip label="Notifications">
        <Indicator
          color="red"
          inline
          size={16}
          label={unreadAlarms.length}
          classNames={{
            indicator: classes.indicator
          }}
        >
          <ActionIcon
            onClick={toggle}
            variant="transparent"
            sx={{
              marginRight: '-8px'
            }}
          >
            <IconBell size={24} color={getTextColorAsProp().color} />
          </ActionIcon>
        </Indicator>
      </Tooltip>
      <Drawer
        opened={isOpened}
        onClose={close}
        position="right"
        withinPortal
        overlayProps={{
          opacity: 0
        }}
        title={(
          <Group h={30.8} position="apart" pr="lg">
            <Group position="left" spacing="xs">
              <Title order={3} sx={{ flexGrow: 1 }}>
                Notifications
                {unreadAlarms.length > 0 && (
                  <>
                    {' '}
                    •
                    {' '}
                    {unreadAlarms.length}
                  </>
                )}
              </Title>
            </Group>
          </Group>
        )}
        closeButtonProps={{
          iconSize: 24,
          style: {
            flexShrink: 1
          }
        }}
        classNames={{
          title: classes.heading
        }}
      >
        <Stack className={classes.divider}>
          <Group
            h={30}
            mt="xs"
            position="apart"
            align="center"
          >
            <Switch
              checked={!showRead}
              color="primary"
              size="xs"
              label="Only show unread"
              labelPosition="left"
              onChange={toggleRead}
            />
            {unreadAlarms.length && (
              <Button
                color="primary"
                leftIcon={<FontAwesomeIcon icon={['far', 'check-double']} />}
                size="xs"
                variant="outline"
                onClick={handleMarkAllAsRead}
                classNames={{
                  label: classes.markAsReadLabel
                }}
              >
                Mark all as read
              </Button>
            )}
          </Group>
          <ScrollArea
            scrollbarSize={4}
            h={'calc(100vh - (3.5rem + 88px))'}
            offsetScrollbars
          >
            {filtered.length === 0 && (
              <Center>
                <Stack py="xl" spacing="lg" align="center">
                  <Avatar size="xl" radius={100}>
                    <FontAwesomeIcon color={colors.gray[5]} size="xl" icon={['fas', 'bullhorn']} />
                  </Avatar>
                  <Stack spacing={-2} align="center">
                    <Text size="xs" color="dimmed">You are all caught up!</Text>
                    <Text size="xs" color="dimmed">No recent alerts to show.</Text>
                  </Stack>
                </Stack>
              </Center>
            )}
            {filtered.map(alarm => (
              <NotificationItem
                key={alarm.id}
                showClear={!!unreadAlarms.find(({ id }) => id === alarm.id)}
                onRead={handleMarkAsRead}
                closeDrawer={close}
                {...alarm}
              />
            ))}
          </ScrollArea>
        </Stack>
      </Drawer>
    </>
  )
}

export default Alarms
