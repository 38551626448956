import { useCallback, useEffect, useState } from 'react'
import { Center, Group, Text, Paper } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { createStyles } from '@mantine/styles'
import { getAgentLogs, type AgentLog } from '@venturi-io/api/src/config/agent'
import { useApi } from 'src/utils/useApi'
import Loader from 'src/Layout/Loader'
import { useUser } from 'src/UserContext'
import { type DateRange, toFixedDateRange } from 'src/utils/dates'
import VirtualTable from './VirtualTable'

const useStyles = createStyles(theme => ({
  container: {
    position: 'relative',
    height: '90%'
  },
  loaderContainer: {
    position: 'absolute',
    bottom: 20,
    left: '50%',
    padding: '5px 15px',
    background: theme.colors.primary[5],
    borderRadius: 5,
    color: 'white'
  },
  noDataContainer: {
    position: 'absolute',
    top: 0
  }
}))

interface Props {
  agentId?: number
  dateRange: DateRange
}

export default function Logs ({ agentId, dateRange }: Props) {
  const { classes } = useStyles()
  const {
    ref: containerRef,
    width,
    height
  } = useElementSize()
  const tableHeight = height - 30
  const pageSize = 50
  const { token } = useUser()
  const agentLogs = useApi(getAgentLogs)
  const { startTime, endTime } = toFixedDateRange(dateRange)
  const [cachedLogs, setCachedLogs] = useState<AgentLog[]>([])
  const [enablePolling, setEnablePolling] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const loadAgentLogs = (page: number, size: number) => {
    if (typeof agentId !== 'undefined' && startTime && endTime) {
      setEnablePolling(false)

      void agentLogs.fetch({
        agentId,
        startTime,
        endTime,
        page,
        size
      }, token)
    }
  }

  const load = () => {
    setCachedLogs([])
    setCurrentPage(1)
    loadAgentLogs(1, pageSize)
  }

  const loadNextPage = useCallback(() => {
    const nextPage = currentPage + 1
    loadAgentLogs(nextPage, pageSize)
    setCurrentPage(nextPage)
  }, [currentPage, loadAgentLogs])

  const loadMoreItems = agentLogs.loading
    ? () => null
    : loadNextPage

  useEffect(() => {
    agentLogs.data.ifJust(({ items, hasMore }) => {
      const allLogs = [
        ...cachedLogs,
        ...items
      ]

      if (enablePolling) {
        // filter unique logs and sort them by log id in DESC order
        const mapLogs = new Map()

        allLogs.forEach(log => mapLogs.set(log.id, log))

        const updatedLogs =
          [...mapLogs.values()]
            .sort((a, b) => (a.id < b.id) ? 1 : -1)
            .slice(0, cachedLogs.length)

        setCachedLogs(updatedLogs)
      } else {
        setCachedLogs(allLogs)
      }

      setHasNextPage(hasMore)
      setEnablePolling(true)
    })
  }, [agentLogs.data])

  useEffect(() => {
    if (agentLogs.loading) {
      agentLogs.abort()
    }

    load()
  }, [agentId, startTime, endTime])

  useEffect(() => {
    if (enablePolling && typeof agentId !== 'undefined' && startTime && endTime) {
      agentLogs.startPolling({
        agentId,
        startTime,
        endTime,
        page: 1,
        size: 100
      }, token, 10)
    }

    return () => {
      agentLogs.stopPolling()
    }
  }, [enablePolling, agentId, startTime, endTime])

  return (
    <Paper
      ref={containerRef}
      p="md"
      shadow="xs"
      className={classes.container}
    >
      <VirtualTable
        width={width}
        height={tableHeight}
        logs={cachedLogs}
        loadMoreItems={loadMoreItems}
        hasNextPage={hasNextPage}
      />
      {!agentLogs.loading &&
        typeof agentId !== 'undefined' &&
        startTime &&
        endTime &&
        cachedLogs.length === 0 &&
        (
          <Center className={classes.noDataContainer} w={width} h="100%">
            <Text color="gray" italic>** No data available **</Text>
          </Center>
        )}
      {agentLogs.loading && (
        <Group
          className={classes.loaderContainer}
          spacing={3}
          mt={3}
          mr="md"
        >
          <Loader
            color="white"
            style={{
              width: '20px',
              height: '20px'
            }}
          />
          <Text>Loading...</Text>
        </Group>
      )}
    </Paper>
  )
}
