import { createStyles, Box, Stack } from '@mantine/core'
import { mq } from 'src/utils/style'
import DiagnosticMetric from './DiagnosticMetric'
import Card from './Card'

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    flex: 1,
    width: '100%',
    [mq(theme.breakpoints.xs)]: {
      width: 1
    }
  },
  diagnosticMetricsContainer: {
    display: 'inline-flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    gap: 8
  }
}))

export default function Content () {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <Stack spacing={16}>
        <Box className={classes.diagnosticMetricsContainer}>
          {Array
            .from({ length: 4 })
            .map((_, index) => (
              <DiagnosticMetric key={index} />
            ))}
        </Box>
        {Array
          .from({ length: 3 })
          .map((_, index) => (
            <Card key={index} />
          ))}
      </Stack>
    </Box>
  )
}
