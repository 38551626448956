import StatusBadge from 'src/AssetTemplate/Components/StatusBadge'
import colors from 'src/AssetTemplate/colors'

interface Props {
  count: number
  showTitle?: boolean
}

export default function Tasks ({ count, showTitle }: Props) {
  return (
    <StatusBadge
      title="Tasks"
      icon={null}
      color={colors.lightGray}
      textColor="black"
      value={count.toString()}
      expanded
      showTitle={showTitle}
    />
  )
}
