import {
  type OverlayProps,
  type TextProps,
  createStyles,
  Overlay,
  Text
} from '@mantine/core'

const useStyles = createStyles(() => ({
  container: {
    cursor: 'default',
    userSelect: 'none'
  }
}))

interface Props extends OverlayProps {
  size?: TextProps['size']
}

export default function NotApplicableOverlay ({
  size = 'md',
  color = '#FAFAFA',
  blur = 0.5,
  zIndex = 1
}: Props) {
  const { classes } = useStyles()

  return (
    <Overlay
      className={classes.container}
      color={color}
      blur={blur}
      zIndex={zIndex}
      center
    >
      <Text size={size} weight={500} color="dimmed">
        N/A
      </Text>
    </Overlay>
  )
}
