import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'
import { type Props } from '../../../Transactions'

export default function DateTime ({ transactions }: Props) {
  return transactions.length > 0 &&
    (
      <>
        {transactions.map(({
          started,
          transactionId
        }) => {
          return (
            <tr key={transactionId}>
              <td
                style={{
                  paddingLeft: 0,
                  border: 'none'
                }}
              >
                {dayjs(started).format(uiDateFormat)}
              </td>
            </tr>
          )
        })}
      </>
    )
}
