import { useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  Image,
  type ImageProps,
  Transition,
  type TransitionProps,
  createStyles
} from '@mantine/core'
import { configure } from '@venturi-io/api/src/configure'
import { getHost } from 'src/utils/host'
import { useNavigate } from 'react-router'
import { type StyleParams, styleParams } from '../shared'

const useStyles = createStyles((theme, params: StyleParams) => ({
  root: {
    background: theme.colors.brand[3],
    display: 'flex',
    alignItems: 'center',
    height: params.headerHeight,
    position: 'relative'
  },
  logo: {
    cursor: 'pointer',
    position: 'absolute',
    top: 10,
    left: 10
  },
  brandLogo: {
    marginLeft: '15px',
    marginBottom: '0'
  }
}))

function DefaultMobileLogo () {
  const { classes } = useStyles(styleParams)
  const navigate = useNavigate()

  return (
    <Image
      key="mobile"
      className={classes.brandLogo}
      src="/logo.svg"
      alt="Mobile Logo"
      width={40}
      onClick={() => {
        navigate('/')
      }}
    />
  )
}

interface DefaultLogoProps {
  minimised: boolean
}

function DefaultLogo ({ minimised }: DefaultLogoProps) {
  const { classes } = useStyles(styleParams)
  const navigate = useNavigate()
  const src = minimised
    ? '/logo.svg'
    : '/venturi-logo.png'

  return (
    <Image
      key="logo"
      className={classes.logo}
      src={src}
      alt="Main Logo"
      height={45}
      width="auto"
      withPlaceholder
      onClick={() => {
        navigate('/')
      }}
    />
  )
}

export interface Props {
  isDesktop: boolean
  minimised?: boolean
  orgId: number
}

export default function Logo ({ isDesktop, minimised = false, orgId }: Props) {
  const navigate = useNavigate()
  const { classes } = useStyles(styleParams)
  const [fullUrl, setFullUrl] = useState<string | null>(null)
  const [minUrl, setMinUrl] = useState<string | null>(null)
  const [hasError, setHasError] = useState(false)
  const imgRef = useRef<HTMLDivElement>(null)

  const redirectToHomePage = useCallback(() => {
    navigate('/')
  }, [])

  const handleImageError = useCallback(() => {
    setHasError(true)
  }, [setHasError])

  useEffect(() => {
    const dateToday = Date.now()

    void configure(getHost())
      .then(({ user }) => {
        setFullUrl(`${user}/api/usermanager/image/org/${orgId}/logo.png?type=topLogo&time=${dateToday}`)
        setMinUrl(`${user}/api/usermanager/image/org/${orgId}/logo.png?type=topMobileLogo&time=${dateToday}`)
      })
  }, [])

  if (!minUrl || !fullUrl) {
    return null
  }

  if (!isDesktop) {
    return !hasError
      ? (
        <Image
          key="mobile"
          className={classes.brandLogo}
          src={minUrl}
          alt="Mobile Logo"
          width={40}
          onClick={redirectToHomePage}
          onError={handleImageError}
        />
        )
      : <DefaultMobileLogo />
  }

  const sharedProps: ImageProps = {
    height: 44,
    className: classes.logo,
    alt: 'Main Logo',
    onClick: redirectToHomePage,
    onError: handleImageError,
    imageProps: {
      style: {
        objectFit: 'contain',
        objectPosition: 'left center'
      }
    }
  }

  const sharedTransitionProps: Omit<TransitionProps, 'mounted' | 'children' | 'transition'> = {
    keepMounted: true,
    duration: 250,
    timingFunction: 'ease-in-out'
  }

  return (
    <Box className={classes.root}>
      {!hasError
        ? (
          <>
            <Transition
              mounted={minimised}
              transition="slide-left"
              {...sharedTransitionProps}
            >
              {(styles) => (
                <Image
                  ref={imgRef}
                  key="minLogo"
                  src={minUrl}
                  width={44}
                  style={styles}
                  {...sharedProps}
                />
              )}
            </Transition>
            <Transition
              mounted={!minimised}
              transition="slide-right"
              {...sharedTransitionProps}
            >
              {(styles) => (
                <Image
                  ref={imgRef}
                  key="fullLogo"
                  src={fullUrl}
                  width={260}
                  style={styles}
                  {...sharedProps}
                />
              )}
            </Transition>
          </>
          )
        : <DefaultLogo minimised={minimised} />}
    </Box>
  )
}
