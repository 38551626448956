import {
  createStyles,
  Box,
  Group,
  Paper,
  Text
} from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import { mq } from 'src/utils/style'
import { hasAlarm } from 'src/AssetTemplate/shared'
import AlarmIndicator from 'src/AssetTemplate/Components/AlarmIndicator'
import { getTextColorAsProp } from 'src/utils/theme'
import PowerState from './PowerState'
import PowerDetails from './PowerDetails/'

const useStyles = createStyles(theme => ({
  content: {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [mq(theme.breakpoints.md)]: {
      alignItems: 'flex-start',
      flexDirection: 'row'
    }
  },
  titleContainer: {
    position: 'relative'
  },
  alarmIndicatorContainer: {
    position: 'absolute',
    top: -8,
    left: -16
  },
  alarmIndicatorLine: {
    height: 32,
    width: 4,
    background: '#FFE066'
  },
  leftSection: {
    flex: 1,
    alignContent: 'center'
  },
  rightSection: {
    flex: 3,
    alignContent: 'center'
  }
}))

export interface MultiSensors {
  a: Sensor | null
  b: Sensor | null
  c: Sensor | null
}

interface Props {
  apparentPower: Sensor | null
  outputPower: Sensor | null
  powerFactor: Sensor | null
  powerFrequency: Sensor | null
  energyProduced: Sensor | null
  voltageLN: MultiSensors
  voltageLL: MultiSensors
  current: MultiSensors
}

export default function PowerStatus ({
  apparentPower,
  outputPower,
  powerFactor,
  powerFrequency,
  energyProduced,
  voltageLN,
  voltageLL,
  current
}: Props) {
  const { classes } = useStyles()
  const withAlarm = hasAlarm([
    apparentPower,
    outputPower,
    powerFactor,
    powerFrequency,
    voltageLN.a,
    voltageLN.b,
    voltageLN.c,
    voltageLL.a,
    voltageLL.b,
    voltageLL.c,
    current.a,
    current.b,
    current.c
  ])

  return (
    <Paper p={16} radius={4} shadow="xs">
      <Box className={classes.titleContainer}>
        <Text
          size={14}
          weight={600}
          {...getTextColorAsProp()}
        >
          Power Status
        </Text>
        {withAlarm && (
          <Group
            className={classes.alarmIndicatorContainer}
            position="center"
            align="center"
            spacing={100}
          >
            <span className={classes.alarmIndicatorLine} />
            <AlarmIndicator size="md" />
          </Group>
        )}
      </Box>
      <Box className={classes.content}>
        <Box className={classes.leftSection}>
          <PowerState apparentPower={apparentPower} />
        </Box>
        <Box className={classes.rightSection}>
          <PowerDetails
            outputPower={outputPower}
            powerFactor={powerFactor}
            powerFrequency={powerFrequency}
            energyProduced={energyProduced}
            voltageLN={voltageLN}
            voltageLL={voltageLL}
            current={current}
          />
        </Box>
      </Box>
    </Paper>
  )
}
