import {
  type ReactNode,
  forwardRef,
  useEffect,
  useState,
  useCallback
} from 'react'
import { type MultiSelectProps, type SelectItem, Group, Text } from '@mantine/core'
import { type AgentGroup, getAgentGroups } from '@venturi-io/api/src/config/ui'
import { IconGrain } from '@tabler/icons-react'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import MultiSelect from '../MultiSelect'

type Value = string[]

export interface Props extends Omit<MultiSelectProps, 'data'> {
  label?: string
  placeholder?: string
  value?: Value
  onChange?: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

const agentGroupToSelect = ({ agentGroupId, name }: AgentGroup): SelectItem => ({
  value: agentGroupId.toString(),
  label: name
})

export default function MultiSelectAgentGroup ({
  label,
  placeholder,
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const { token } = useUser()
  const agentGroups = useApi(getAgentGroups)
  const [value, setValue] = useState<Value>(props.value ?? [])

  const selectAgentGroups = agentGroups.data.mapOrDefault(data => data.map(agentGroupToSelect), [])

  const Item = forwardRef<HTMLDivElement, SelectItem>(({ label, ...others }: SelectItem, ref) => (
    <div ref={ref} {...others}>
      <Group>
        <IconGrain size={12} />
        <Text>{label}</Text>
      </Group>
    </div>
  ))

  const handleChange = useCallback((val: Value) => {
    setValue(val)

    if (onChange) {
      onChange(val)
    }
  }, [onChange])

  useEffect(() => {
    void agentGroups.fetch({}, token)
  }, [])

  return (
    <MultiSelect
      label={label}
      placeholder={placeholder ?? 'Choose asset group(s)'}
      value={value}
      itemComponent={Item}
      data={selectAgentGroups}
      onChange={handleChange}
      required={required}
      searchable={searchable}
      disabled={disabled || agentGroups.loading}
      clearSearchOnChange={false}
      clearSearchOnBlur
      error={error}
      {...props}
    />
  )
}
