import { useCallback, useEffect, useMemo, useState } from 'react'
import { Marker as MapboxMarker } from 'react-map-gl'

import { Box, createStyles, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import useDoubleClick from 'src/utils/useDoubleClick'
import { colors } from 'src/utils/status'
import { getWhiteBackgroundColor } from 'src/utils/theme'
import { type AgentWithColorStatus, sortAgents, useViewTypes } from '../AgentAttendanceContext'
import AgentList from './AgentList'
interface StyleParams {
  expanded: boolean
  isAlarm: boolean
}

const useStyles = createStyles((theme, { expanded, isAlarm }: StyleParams) => {
  const borderRadius = 30
  const smallCircle = 44
  const bigCircle = 60
  const dimensions = expanded
    ? {
        width: 240,
        height: 240
      }
    : {
        width: bigCircle,
        height: bigCircle
      }
  const pinBorders = expanded
    ? {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 22,
        borderBottomRightRadius: 0
      }
    : {
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
        borderBottomRightRadius: borderRadius
      }
  const containerBorders = expanded
    ? {
        borderTopLeftRadius: 22,
        borderTopRightRadius: 22,
        borderBottomRightRadius: 22
      }
    : {
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
        borderBottomRightRadius: borderRadius
      }
  const transition = {
    transition: 'all ease-in-out 0.25s'
  }
  const pinDimensions = expanded
    ? {
        width: smallCircle,
        height: smallCircle
      }
    : {
        width: bigCircle,
        height: bigCircle
      }
  const pinContentDimensions = expanded
    ? {
        width: 28,
        height: 28,
        borderRadius: 16
      }
    : {
        width: smallCircle,
        height: smallCircle,
        borderRadius: 22
      }
  const { colors, colorScheme } = theme
  const isLight = colorScheme === 'light'

  return {
    container: {
      ...dimensions,
      ...transition,
      ...containerBorders,
      ...getWhiteBackgroundColor(theme),
      overflow: 'hidden',
      position: 'relative',
      animation: isAlarm && !expanded
        ? 'pulse-alarm 1s infinite'
        : 'unset'
    },
    pin: {
      ...pinBorders,
      ...transition,
      ...pinDimensions,
      position: 'absolute',
      right: 0,
      top: 0,
      background: isAlarm
        ? colors.red
        : '#274873',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      border: `2px solid ${
        isLight
          ? colors.white[0]
          : colors.dark[6]
      }`,
      '&::before': {
        content: "''",
        position: 'absolute',
        backgroundColor: '#fff',
        top: 6,
        left: 6,
        ...transition,
        ...pinContentDimensions
      }
    },
    label: {
      color: theme.colors.dark[7],
      fontSize: 14,
      fontWeight: 'bold',
      zIndex: 2
    },
    indicator: {
      position: 'absolute',
      top: -20,
      right: -20,
      width: 16,
      height: 16,
      borderRadius: 8
    }
  }
})

export interface AgentCluster {
  clusterId?: number | string
  latitude: number
  longitude: number
}

interface Props extends AgentCluster {
  pointCount: number
  totalPoints?: number
  onAgentSelect?: (agentId: AgentWithColorStatus['agentId']) => void
}

export default function ClusterMarker ({
  clusterId,
  latitude,
  longitude,
  pointCount,
  onAgentSelect
}: Props) {
  const {
    getClusterChildren,
    peekedClusterId,
    setPeekedClusterId,
    handleClusterClick
  } = useViewTypes()
  const [expanded, { open, close }] = useDisclosure()
  const [agents, setAgents] = useState<AgentWithColorStatus[]>([])
  const [markerNode, setMarkerNode] = useState<HTMLDivElement | null>(null)

  const clusterAgents = useMemo(() => {
    return clusterId && typeof clusterId === 'number'
      ? getClusterChildren(clusterId) as AgentWithColorStatus[]
      : []
  }, [clusterId])

  // we might use other status as well in the future, for now just red should do
  const {
    red
  } = useMemo(() => {
    return clusterAgents.reduce((acc, { color }) => {
      switch (color) {
        case `${colors.red}`:
          acc.red += 1
          break
        case `${colors.green}`:
          acc.green += 1
          break
        case `${colors.yellow}`:
          acc.yellow += 1
          break
        case `${colors.orange}`:
          acc.orange += 1
          break
        default:
          acc.gray += 1
          break
      }
      return acc
    }, {
      red: 0,
      green: 0,
      gray: 0,
      yellow: 0,
      orange: 0
    })
  }, [clusterAgents])

  const { classes } = useStyles({ expanded, isAlarm: red > 0 })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClick = useCallback(() => {
    if (clusterId && typeof clusterId === 'number' && !expanded) {
      open()
      setAgents(sortAgents(clusterAgents))
      setPeekedClusterId(clusterId)
    }
  }, [clusterId, expanded])

  useDoubleClick({
    ref: markerNode,
    latency: 250,
    onSingleClick: handleClick,
    onDoubleClick: () => {
      if (!expanded) {
        handleClusterClick({
          clusterId,
          latitude,
          longitude
        })
      }
    }
  })

  useEffect(() => {
    if (peekedClusterId !== clusterId) {
      close()
    }
  }, [peekedClusterId, clusterId])

  return (
    <MapboxMarker
      latitude={latitude}
      longitude={longitude}
      anchor="bottom-left"
      style={{
        zIndex: expanded
          ? 99
          : 0
      }}
    >
      <Box
        ref={setMarkerNode}
        className={classes.container}
      >
        {expanded && (
          <AgentList
            markerNode={markerNode}
            agents={agents}
            onAgentSelect={onAgentSelect}
          />
        )}
        <Box className={classes.pin}>
          <Text className={classes.label}>{pointCount}</Text>
        </Box>
      </Box>
    </MapboxMarker>
  )
}
