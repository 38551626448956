import {
  createStyles,
  Group,
  Paper,
  Stack,
  Text,
  useMantineTheme,
  type MantineStyleSystemProps
} from '@mantine/core'
import { mq } from 'src/utils/style'
import { checkIfAlarm } from 'src/utils/status'
import cx from 'classnames'
import type { Agent } from '@venturi-io/api/src/config/agent'

const useStyles = createStyles(theme => ({
  container: {
    height: '70%'
  },
  sensors: {
    width: '100%',
    height: '100%'
  },
  sensorContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background .2s ease-in-out'
  },
  sensorValue: {
    fontSize: '.9rem',
    [mq(theme.breakpoints.sm)]: {
      fontSize: '1.3rem'
    }
  },
  sensorName: {
    fontSize: '.7rem'
  }
}))

interface Props extends MantineStyleSystemProps {
  agentId: Agent['agentId']
  assetType: Agent['assetType']
  sensors: Agent['sensors']
}

export default function Sensors ({ sensors, ...styleProps }: Props) {
  const { classes } = useStyles()
  const { colorScheme } = useMantineTheme()
  const isLight = colorScheme === 'light'

  return (
    <Group
      className={classes.container}
      position="apart"
      spacing="xs"
      {...styleProps}
    >
      <Group className={classes.sensors} grow>
        {sensors
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ sensorInstanceId, name, currentValue, unit, alarmStatus }) => {
            const parsedValue: string | number = Math.round(parseFloat(currentValue ?? ''))
            const value = isNaN(parsedValue)
              ? currentValue ?? '-'
              : parsedValue
            const isAlarm = checkIfAlarm(alarmStatus)

            return (
              <Paper
                key={sensorInstanceId}
                p={0}
                className={cx(classes.sensorContainer, 'sensors')}
              >
                <Stack align="center" spacing={0}>
                  <Text
                    className={classes.sensorValue}
                    color={isAlarm
                      ? 'red'
                      : isLight
                        ? 'black'
                        : 'gray.2'}
                    weight={600}
                  >
                    {value}
                    {' '}
                    {unit}
                  </Text>
                  <Text
                    className={classes.sensorName}
                    align="center"
                    lineClamp={2}
                    color="dimmed"
                  >
                    {name}
                  </Text>
                </Stack>
              </Paper>
            )
          })}
      </Group>
    </Group>
  )
}
