import {
  Slider,
  createStyles,
  Paper,
  Stack,
  Text,
  ActionIcon,
  Tooltip,
  useMantineTheme,
  Flex,
  RangeSlider
} from '@mantine/core'
import dayjs from 'dayjs'
import { dateFormat } from 'src/utils/dates'
import { useCallback, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDisclosure, useHover } from '@mantine/hooks'
import { getWhiteBackgroundColor } from 'src/utils/theme'
import { AnimationMode, getTripContext } from '../AnimationContext'
import Filters from '../Layer/Filters'
import { convertMillisecondsToHumanReadableTime } from './shared'
import RangeTooltip from './RangeTooltip'

const useStyle = createStyles(theme => ({
  container: {
    position: 'absolute',
    bottom: 15,
    right: 'calc(100vw / 2 - 210px)',
    background: 'transparent',
    zIndex: 99,
    width: 420,
    height: 84,
    opacity: 0.5,
    ...getWhiteBackgroundColor(theme),
    transition: 'opacity ease .5s',
    '&:hover': {
      opacity: 1
    }
  },
  label: {
    top: '-3.5rem',
    fontSize: '0.75rem'
  },
  track: {
    '&::before': {
      backgroundColor: theme.colors.gray[4]
    }
  },
  thumb: {
    border: '0',
    ...getWhiteBackgroundColor(theme)
  },
  trackerThumb: {
    border: `6px solid ${theme.colors.primary[6]}`
  }
}))

const timeFormat = 'HH:mm'

export default function Player () {
  const {
    mode,
    trip,
    currentFrame,
    resumePoints,
    updatePoints,
    updateAnimation,
    pauseAnimation,
    resumeAnimation
  } = useContext(getTripContext())
  const { classes } = useStyle()
  const theme = useMantineTheme()
  const { colors } = theme
  const { ref, hovered } = useHover()
  const [isDragging, { open, close }] = useDisclosure(false)

  const endTime = trip
    ? dayjs(trip.rawPath[trip.rawPath.length - 1].time, dateFormat)
    : undefined

  const reversed = trip
    ? [...trip.rawPath].reverse()
    : undefined

  // completely hides the label
  const getRangeLabel = () => null

  const getLabel = useCallback((value: number) => {
    if (trip && endTime && reversed) {
      const point = trip.rawPath[value]
      const actual = dayjs(point.time, dateFormat)
      const reversedActual = dayjs(reversed[value].time, dateFormat)
      const interval = endTime.diff(reversedActual)
      return (
        <Stack spacing={0} sx={{ bottom: 20 }}>
          <Text size="xs">
            Current:
            {' '}
            {convertMillisecondsToHumanReadableTime(interval)}
          </Text>
          <Text size="xs">
            Timestamp:
            {' '}
            {actual.format('DD/MM/YY HH:mm:ss')}
          </Text>
        </Stack>
      )
    }
    return 'unknown'
  }, [trip, endTime, reversed])

  const totalPoints = trip
    ? trip.rawPath.length
    : 0

  const ten = Math.floor(totalPoints * 0.1)
  const fifty = Math.floor(totalPoints * 0.5)
  const ninety = Math.floor(totalPoints * 0.9)

  const tooltipLabel = mode === AnimationMode.Reset
    ? 'Replay'
    : mode === AnimationMode.Play
      ? 'Pause'
      : 'Play'

  const buttonColor = mode === AnimationMode.Reset
    ? 'green'
    : mode === AnimationMode.Play
      ? 'dark'
      : 'blue'

  return trip && (
    <Paper
      className={classes.container}
      shadow="lg"
    >
      <Stack
        ref={ref}
        m="sm"
        spacing={5}
        pt={4}
      >
        <Flex
          align="center"
          sx={{
            position: 'relative'
          }}
        >
          <Stack
            spacing="xs"
            sx={{
              position: 'absolute',
              top: -7,
              left: 0
            }}
          >
            <Filters />
            <Tooltip
              position="left"
              withArrow
              label={tooltipLabel}
            >
              <ActionIcon
                radius="lg"
                color={buttonColor}
                size="sm"
                variant="filled"
                onClick={() =>
                  mode === AnimationMode.Play
                    ? pauseAnimation()
                    : resumeAnimation(currentFrame)}
              >
                {mode === AnimationMode.Reset
                  ? (
                    <FontAwesomeIcon
                      size="xs"
                      color={colors.white[6]}
                      icon={[
                        'fas',
                        'arrow-rotate-left'
                      ]}
                    />
                    )
                  : (
                    <FontAwesomeIcon
                      size="xs"
                      color={colors.white[6]}
                      icon={[
                        'fas',
                        mode === AnimationMode.Play
                          ? 'pause'
                          : 'play'
                      ]}
                    />
                    )}
              </ActionIcon>
            </Tooltip>
          </Stack>
          <Stack
            sx={{
              position: 'relative',
              marginLeft: '36px',
              flexGrow: 1
            }}
          >
            <RangeTooltip hovered={hovered || isDragging} />
            <RangeSlider
              classNames={{
                label: classes.label,
                track: classes.track,
                thumb: classes.thumb
              }}
              min={0}
              max={totalPoints - 1}
              label={getRangeLabel}
              onMouseDown={open}
              onChange={(value) => updatePoints(value)}
              onChangeEnd={(value) => resumePoints(value)}
              onMouseUp={close}
              ml="xs"
              color="primary"
              thumbSize={16}
              size="xs"
              thumbChildren={[
                <FontAwesomeIcon
                  color="white"
                  size="xs"
                  icon={['fas', 'chevron-right']}
                  key="1"
                />,
                <FontAwesomeIcon
                  color="white"
                  size="xs"
                  icon={['fas', 'chevron-left']}
                  key="2"
                />
              ]}
              styles={() => ({
                track: {
                  backgroundColor: 'red'
                }
              })}
            />
            <Slider
              mt={7}
              ml="xs"
              classNames={{
                label: classes.label,
                track: classes.track,
                thumb: classes.trackerThumb
              }}
              thumbSize={20}
              min={0}
              max={totalPoints - 1}
              label={getLabel}
              value={currentFrame}
              color="primary"
              onChange={(value) => updateAnimation(value)}
              onChangeEnd={(value) => resumeAnimation(value)}
              mb="xl"
              size="xs"
              marks={[
                {
                  value: ten,
                  label: (
                    <Text
                      size={10}
                      sx={{
                        position: 'absolute',
                        left: 0
                      }}
                    >
                      {dayjs(trip.rawPath[ten].time).format(timeFormat)}
                    </Text>
                  )
                },
                ...trip.rawPath.length > 2
                  ? [{
                      value: fifty,
                      label: (
                        <Text size={10}>
                          {dayjs(trip.rawPath[fifty].time).format(timeFormat)}
                        </Text>
                      )
                    }]
                  : [],
                {
                  value: ninety,
                  label: (
                    <Text
                      size={10}
                      sx={{
                        position: 'absolute',
                        right: 0
                      }}
                    >
                      {dayjs(trip.rawPath[ninety].time).format(timeFormat)}
                    </Text>
                  )
                }
              ]}
            />
          </Stack>
        </Flex>
      </Stack>
    </Paper>
  )
}
