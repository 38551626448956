import React from 'react'
import { type DatumValue, ResponsiveLine as Line, type LineSvgProps } from '@nivo/line'
import { createStyles, Text, useMantineTheme } from '@mantine/core'
import dayjs from 'dayjs'
import { type ChosenBucket, type ItemKeys } from 'src/Input/SegmentedTimePeriodSelector'
import Paper from 'src/Layout/Paper'
import { type CartesianMarkerProps } from '@nivo/core'
import { type SensorData } from '.'

interface Props {
  sensorsHistory: SensorData[]
  bucket: ChosenBucket<ItemKeys>
  margin?: LineSvgProps['margin']
  markers: Array<CartesianMarkerProps<DatumValue>>
}

const useStyles = createStyles(() => ({
  tooltip: {
    background: '#212121',
    color: '#FFFFFF'
  }
}))

const units: Record<string, string> = {
  'Fuel Level Liter': 'L',
  'Position Speed': 'km/h'
}

const LineChart = ({
  sensorsHistory,
  margin,
  bucket,
  markers
}: Props) => {
  const { classes } = useStyles()
  const { colors, colorScheme } = useMantineTheme()
  const isLight = colorScheme === 'light'

  const isHours = bucket.item.includes('hour')
  const isLargeDays = bucket.item.includes('30') || bucket.item.includes('90')
  const axisBottomFormat = bucket.item.includes('day')
    ? isLargeDays
      ? 'DD/MM'
      : 'DD/MM h:mm A'
    : 'h:mm A'

  return (
    <Line
      data={sensorsHistory}
      colors={{
        scheme: 'tableau10'
      }}
      margin={margin}
      xScale={{ type: 'time', format: '%Y %I:%M %p' }}
      yScale={{
        type: 'linear',
        stacked: false,
        min: 'auto',
        max: 'auto'
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickValues: isHours
          ? 'every 1 hour'
          : isLargeDays
            ? 'every 2 days'
            : 'every 8 hours',
        format: (value) => dayjs(value).format(axisBottomFormat),
        tickSize: 0,
        tickPadding: 12,
        tickRotation: 0,
        legendOffset: 36,
        legendPosition: 'middle',
        truncateTickAt: 0
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 12,
        tickRotation: 0,
        legendOffset: -40,
        legendPosition: 'middle',
        truncateTickAt: 0
      }}
      enableArea
      enableCrosshair
      useMesh
      enableGridX={false}
      enablePoints={false}
      areaOpacity={0.15}
      crosshairType="x"
      curve="monotoneX"
      theme={{
        axis: {
          ticks: {
            text: {
              fontSize: 10,
              fontWeight: 400,
              fill: '#909296'
            }
          }
        },
        grid: {
          line: {
            stroke: isLight
              ? colors.gray[2]
              : colors.dark[4]
          }
        },
        crosshair: {
          line: {
            stroke: isLight
              ? colors.dark[4]
              : colors.gray[2]
          }
        }
      }}
      markers={markers}
      tooltip={({ point }) => {
        return (
          <Paper
            className={classes.tooltip}
            px={12}
            py={8}
            radius="sm"
            shadow="sm"
          >
            <Text
              size={12}
              weight={500}
              color={point.color}
            >
              {point.serieId}
            </Text>
            <Text size={12} weight={500}>
              {`${dayjs(point.data.x).format('MMM D h:mm A')}`}
            </Text>
            <Text size={16} weight={600}>
              {`${point.data.y ?? 0} ${units[point.serieId.toString()] ?? ''}`}
            </Text>
          </Paper>
        )
      }}
    />
  )
}

export default LineChart
