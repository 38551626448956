import { useCallback, useEffect, useState } from 'react'
import { type GeoZoneRule, deleteGeoZoneRule } from '@venturi-io/api/src/config/geoZoneRule'
import {
  createStyles,
  ActionIcon,
  Group,
  Menu,
  Text
} from '@mantine/core'
import { useDisclosure, useHotkeys } from '@mantine/hooks'
import { IconArrowBigUp, IconDots, IconPencil, IconTrash } from '@tabler/icons-react'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import ConfirmModal from 'src/Layout/ConfirmModal'

const useMenuStyles = createStyles((theme) => ({
  dropdown: {
    padding: 4
  },
  label: {
    fontSize: 10,
    color: theme.colors.gray[6]
  },
  item: {
    padding: '8px 12px',
    fontSize: 12
  },
  divider: {
    margin: '2px 0'
  }
}))

interface Props {
  ruleId: GeoZoneRule['geoZoneRuleId']
  ruleName: GeoZoneRule['name']
  onClickEdit?: (ruleId: GeoZoneRule['geoZoneRuleId']) => void
  onSuccessDelete?: (ruleId: GeoZoneRule['geoZoneRuleId']) => void
}

export default function Actions ({
  ruleId,
  ruleName,
  onClickEdit,
  onSuccessDelete
}: Props) {
  const { classes } = useMenuStyles()
  const { token, orgId } = useUser()
  const removeRule = useApi(deleteGeoZoneRule)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [openedActions, { toggle }] = useDisclosure(false)

  const handleClickEdit = useCallback(() => {
    if (typeof onClickEdit !== 'undefined') {
      onClickEdit(ruleId)
    }
  }, [ruleId, onClickEdit])

  const handleDelete = useCallback(() => {
    void removeRule.fetch({
      orgId,
      geoZoneRuleId: ruleId
    }, token, `Succesfully deleted "${ruleName}"`)
  }, [orgId, ruleId, ruleName])

  useEffect(() => {
    removeRule.data.ifJust(() => {
      setShowDeleteModal(false)

      if (onSuccessDelete) {
        onSuccessDelete(ruleId)
      }
    })
  }, [removeRule.data])

  useHotkeys([
    // Shortcut keys for editing specific rule
    ['shift+E', handleClickEdit]
  ])

  return (
    <>
      <Menu
        classNames={classes}
        opened={openedActions}
        shadow="md"
        width="xl"
        position="bottom-end"
        transitionProps={{
          transition: 'pop-top-right'
        }}
        onClose={toggle}
        closeOnClickOutside
        withArrow
      >
        <Menu.Target>
          <ActionIcon
            color="dark.2"
            variant="subtle"
            onClick={toggle}
          >
            <IconDots
              size={18}
              stroke={1.5}
              color="gray"
            />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Actions</Menu.Label>
          <Menu.Item
            icon={<IconPencil size={12} />}
            rightSection={(
              <Group
                ml={44}
                position="center"
                align="center"
                spacing={2}
              >
                <IconArrowBigUp size={12} color="gray" />
                <Text size="xs" color="gray.6">
                  E
                </Text>
              </Group>
            )}
            onClick={handleClickEdit}
          >
            Edit Rule
          </Menu.Item>
          <Menu.Item
            key="Delete Rule"
            icon={<IconTrash size={12} />}
            color="red"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete Rule
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <ConfirmModal
        type="delete"
        opened={showDeleteModal}
        title={`Deleting "${ruleName}"`}
        loading={removeRule.loading}
        question="Are you sure you want to delete this rule? This cannot be undone."
        onClose={() => setShowDeleteModal(false)}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
      />
    </>
  )
}
