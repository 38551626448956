import { type ReactElement } from 'react'
import {
  createStyles,
  Box,
  Group,
  Paper,
  Text,
  ThemeIcon
} from '@mantine/core'
import { getWhiteBackgroundColor, getTextColor } from 'src/utils/theme'
import TextTooltip from 'src/Layout/TextTooltip'
import SensorHistorySparkLineChart from './Charts/SensorHistorySparkLineChart'

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    flex: '1 1 150px',
    ...getWhiteBackgroundColor(theme),
    height: 200,
    minWidth: 0,
    padding: '4px 0px',
    overflow: 'clip'
  },
  icon: {
    height: 32,
    color: theme.colors.dark[0],
    background: 'transparent'
  },
  name: {
    ...getTextColor(theme),
    lineHeight: 'normal'
  },
  valueContainer: {
    padding: '18px 0px',
    height: 110,
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  footer: {
    height: 55
  }
}))

export interface DiagnosticMetricItem {
  sensorInstanceId: number
  name: string
  icon: ReactElement
  value: number
  displayValue: string
  displayValueUnit: string
  label?: string
  lowerLabel?: string
  disableChart?: boolean
  disabled?: boolean
}

export default function DiagnosticMetric ({
  sensorInstanceId,
  name,
  icon,
  displayValue,
  displayValueUnit,
  label,
  lowerLabel,
  disableChart = false,
  disabled = false
}: DiagnosticMetricItem) {
  const { classes } = useStyles()

  return (
    <Paper
      className={classes.container}
      radius={8}
      shadow="xs"
    >
      <Group
        pl={12}
        pr={4}
        position="apart"
        align="center"
      >
        <TextTooltip
          className={classes.name}
          size={12}
          weight={500}
          value={name}
          maxLength={15}
        />
        <ThemeIcon className={classes.icon}>
          {icon}
        </ThemeIcon>
      </Group>
      <Box className={classes.valueContainer}>
        {!disabled && (
          <Group spacing={2} align="baseline">
            <Text
              size={32}
              weight={700}
              align="center"
            >
              {displayValue}
            </Text>
            <Text size={16} weight={600}>
              {displayValueUnit}
            </Text>
          </Group>
        )}
        {disabled && (
          <Text
            size={32}
            weight={700}
            align="center"
            color="dimmed"
          >
            N/A
          </Text>
        )}
        {!disabled && label && (
          <Text
            size={14}
            weight={500}
            color="dark.3"
            align="center"
          >
            {label}
          </Text>
        )}
      </Box>
      <Box className={classes.footer}>
        {!disableChart && sensorInstanceId !== -1 && (
          <SensorHistorySparkLineChart
            sensorInstanceId={sensorInstanceId}
            height={57}
            minValue={0}
          />
        )}
        {lowerLabel && (
          <Text
            mt={12}
            size={12}
            weight={500}
            color="dark.2"
            align="center"
          >
            {lowerLabel}
          </Text>
        )}
      </Box>
    </Paper>
  )
}
