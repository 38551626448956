import { createStyles, Box } from '@mantine/core'
import { mq } from 'src/utils/style'
import Header from './Header'
import Details from './Details'
import Content from './Content'

const useStyles = createStyles((theme) => ({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    [mq(theme.breakpoints.sm)]: {
      width: '80vw',
      maxWidth: 960
    },
    [mq('1440px')]: {
      width: '80vw',
      maxWidth: 'none'
    }
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: 12,
    padding: '16px',
    [mq(theme.breakpoints.xs)]: {
      flexDirection: 'row'
    }
  }
}))

export default function Loader () {
  const { classes } = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Header />
        <Box className={classes.body}>
          <Details />
          <Content />
        </Box>
      </Box>
    </Box>
  )
}
