import { type CSSProperties } from 'react'
import { useMantineTheme } from '@mantine/core'
import Pie from '@visx/shape/lib/shapes/Pie'
import { Group } from '@visx/group'
import { Text } from '@visx/text'
import { truncateWithEllipsis } from 'src/utils/strings'

interface PieData {
  id: number
  value: number
  color: CSSProperties['color']
}

export interface Props {
  title: string
  subtitle?: string
  value: number
  width?: number
  height?: number
  maxValue?: number
  innerData: PieData
  outerData: PieData[]
}

export default function RadialGauge ({
  title,
  subtitle,
  value,
  width = 300,
  height = 300,
  maxValue = 100,
  innerData,
  outerData
}: Props) {
  const { colors, colorScheme } = useMantineTheme()
  const isLight = colorScheme === 'light'
  const containerSize = (width + height) / 6.25
  const halfHeight = (height + 20) / 2
  const halfWidth = width / 2
  const angle = Math.PI / 1.2
  const data = [
    innerData,
    {
      id: 2,
      value: maxValue - innerData.value,
      color: colors.gray[2]
    }
  ]

  const fill = isLight
    ? colors.dark[4]
    : colors.gray[2]

  return (
    <svg width={width} height={height}>
      <Group top={halfHeight} left={halfWidth}>
        {/* Outer Pie */}
        <Pie
          data={outerData}
          pieValue={data => data.value}
          pieSortValues={null}
          innerRadius={containerSize - 10}
          outerRadius={containerSize - 2}
          startAngle={-angle}
          endAngle={angle}
          cornerRadius={3}
          padAngle={0.02}
        >
          {pie => {
            return pie.arcs.map((arc) => (
              <g key={arc.data.id}>
                <path d={pie.path(arc) ?? ''} fill={arc.data.color}></path>
              </g>
            ))
          }}
        </Pie>

        {/* Inner Pie */}
        <Pie
          data={data}
          pieValue={data => data.value}
          pieSortValues={null}
          innerRadius={containerSize - 48}
          outerRadius={containerSize - 15}
          startAngle={-angle}
          endAngle={angle}
          cornerRadius={3}
          padAngle={0.01}
        >
          {pie => {
            return pie.arcs.map((arc) => (
              <g key={arc.data.id}>
                <path d={pie.path(arc) ?? ''} fill={arc.data.color}></path>
              </g>
            ))
          }}
        </Pie>

        {/* Value */}
        <Text
          textAnchor="middle"
          fontSize={`${containerSize / 5}px`}
          fontWeight="bold"
          y={5}
          fill={fill}
        >
          {value}
        </Text>

        {/* Title */}
        <g>
          {title.length > 15 && <title>{title}</title>}
          <Text
            textAnchor="middle"
            fontSize={`${containerSize / 10}px`}
            y={containerSize / 5}
            fill={fill}
          >
            {truncateWithEllipsis(title, 15)}
          </Text>
        </g>
        {/* Subtitle */}
        {subtitle && (
          <g>
            {subtitle.length > 15 && <title>{subtitle}</title>}
            <Text
              textAnchor="middle"
              fontSize={`${containerSize / 10}px`}
              y={containerSize / 3}
              fill={fill}
            >
              {truncateWithEllipsis(subtitle, 15)}
            </Text>
          </g>
        )}
      </Group>
    </svg>
  )
}
