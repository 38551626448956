import { createStyles, Stack } from '@mantine/core'
import { IconListDetails, IconScale, IconWand } from '@tabler/icons-react'
import Toggle from './Toggle'

const useStyles = createStyles(() => ({
  container: {
    position: 'absolute',
    top: 100,
    right: 0
  }
}))

interface Props {
  openedGeoZoneList: boolean
  openedRuleList: boolean
  openedActionList: boolean
  setOpenedGeoZoneList: (status: boolean) => void
  setOpenedRuleList: (status: boolean) => void
  setOpenedActionList: (status: boolean) => void
}

export default function ListControls ({
  openedGeoZoneList,
  openedRuleList,
  openedActionList,
  setOpenedGeoZoneList,
  setOpenedRuleList,
  setOpenedActionList
}: Props) {
  const { classes } = useStyles()

  return (
    <Stack className={classes.container} spacing={4}>
      <Toggle
        title="Geozones"
        icon={(
          <IconListDetails
            size={24}
            stroke={1.5}
            color="gray"
          />
        )}
        opened={openedGeoZoneList}
        setOpened={setOpenedGeoZoneList}
      />
      <Toggle
        title="Rules"
        icon={(
          <IconScale
            size={24}
            stroke={1.5}
            color="gray"
          />
        )}
        opened={openedRuleList}
        setOpened={setOpenedRuleList}
      />
      <Toggle
        title="Actions"
        icon={(
          <IconWand
            size={24}
            stroke={1.5}
            color="gray"
          />
        )}
        opened={openedActionList}
        setOpened={setOpenedActionList}
      />
    </Stack>
  )
}
