import { Flex, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { type TaskDataItem } from '@venturi-io/api/src/analytics/notification'
import { LabelValue, NotificationBody, NotificationHeading } from './shared'
import { type NotificationItemProps } from '.'

function Task ({
  onRead,
  showClear,
  closeDrawer,
  expanded = false,
  ...incident
}: NotificationItemProps) {
  const {
    timestamp,
    data
  } = incident
  const {
    id,
    agent,
    taskType,
    priority
  } = data as TaskDataItem

  const [show, { toggle }] = useDisclosure(expanded)

  const handleClear = () => {
    if (onRead) {
      onRead(incident)
    }
  }

  return (
    <Flex
      direction="column"
      justify="center"
    >
      <NotificationHeading
        forceReload
        show={show}
        link={`/tasks/details/${id}`}
        showClear={showClear}
        toggle={toggle}
        onClear={handleClear}
        closeDrawer={closeDrawer}
        heading={(
          <Text
            size="xs"
            sx={{
              width: '240px',
              overflow: 'hidden'
            }}
          >
            <strong>
              {taskType}
              {' '}
              for
              {' '}
              {agent
                ? agent.agentName
                : 'Unknown agent'}
            </strong>
            {' '}
            was created
          </Text>
        )}
      />
      <NotificationBody
        show={show}
        timestamp={timestamp}
      >
        <>
          <LabelValue
            forceReload
            size="xs"
            label="Priority"
            value={priority}
            closeDrawer={closeDrawer}
            link={`/tasks/details/${id}`}
          />
          {agent && (
            <LabelValue
              size="xs"
              label="Agent"
              value={agent.agentName}
              closeDrawer={closeDrawer}
              link={`/?agentId=${agent.agentId}`}
            />
          )}
        </>
      </NotificationBody>
    </Flex>
  )
}

export default Task
