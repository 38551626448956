import { type CSSProperties } from 'react'
import {
  Box,
  Group,
  Paper,
  Text,
  createStyles,
  useMantineTheme
} from '@mantine/core'
import { type Alarm } from '@venturi-io/api/src/analytics/alarm'
import { type LiquidType } from '@venturi-io/api/src/config/agent'
import Bar from 'src/Charts/Bar'
import colors from 'src/AssetTemplate/colors'
import PercentAlarmIndicators from './TankLabels/PercentAlarmIndicators'
import LiterAlarmIndicators from './TankLabels/LiterAlarmIndicators'
import MarkerAnnotationCurrentLevel from './TankLabels/MarkerAnnotationCurrentLevel'
import MarkerAnnotationSafeFillLevel from './TankLabels/MarkerAnnotationSafeFillLevel'
import PercentMarkerAnnotations from './TankLabels/PercentMarkerAnnotations'
import LiterMarkerAnnotations from './TankLabels/LiterMarkerAnnotations'

const useStyles = createStyles(() => ({
  container: {
    margin: '0 auto',
    paddingTop: 16,
    paddingBottom: 16
  },
  barContainer: {
    width: 105,
    position: 'relative',
    svg: {
      overflow: 'visible',
      text: {
        textAnchor: 'start',
        transform: 'translate(94px, -1px)',
        fontWeight: 500,
        fontSize: 14,
        color: '#212121'
      },
      line: {
        transform: 'translateX(10px)'
      },
      'g > g:last-child text': {
        fill: '#666'
      },
      'g > g:last-child line': {
        stroke: '#666 !important'
      }
    },
    rect: {
      width: 64
    }
  },
  labels: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1
  }
}))

interface Props {
  fuelLevelPercent: number
  fuelRemainingPercent: number
  fuelCapacityLiter: number
  currentFuelLevelLiter: number
  safeFillLevelPercent: number | null
  safeFillLevelLiter: number | null
  fuelLevelPercentAlarms: Alarm[]
  fuelLevelLiterAlarms: Alarm[]
  fuelType?: LiquidType
  withWarning?: boolean
  withAlarm?: boolean
  isCriticalAlarm?: boolean
  statusColor?: CSSProperties['color']
}

export default function FuelTank ({
  fuelLevelPercent,
  fuelRemainingPercent,
  fuelCapacityLiter,
  safeFillLevelPercent,
  safeFillLevelLiter,
  fuelLevelPercentAlarms,
  fuelLevelLiterAlarms,
  withWarning,
  withAlarm,
  isCriticalAlarm,
  currentFuelLevelLiter,
  statusColor = colors.blue
}: Props) {
  const { classes } = useStyles()
  const { colorScheme } = useMantineTheme()
  const isLight = colorScheme === 'light'
  const stroke = isLight
    ? 'black'
    : 'gray'

  const mapMarkers = [
    {
      axis: 'y',
      value: fuelLevelPercent,
      lineStyle: {
        stroke,
        strokeWidth: 2
      },
      legend: '◄',
      legendOrientation: 'horizontal',
      legendOffsetX: 0,
      legendOffsetY: 0
    }
  ]

  return (
    <Box className={classes.container} w="100%">
      <Group
        h={360}
        position="center"
        align="flex-end"
        spacing={16}
        noWrap
      >
        <Box h="100%" className={classes.labels}>
          <PercentAlarmIndicators
            fuelCapacityLiter={fuelCapacityLiter}
            fuelLevelPercentAlarms={fuelLevelPercentAlarms}
            fuelLevelPercent={fuelLevelPercent}
            withWarning={withWarning}
            withAlarm={withAlarm}
            isCriticalAlarm={isCriticalAlarm}
            statusColor={statusColor}
          />
          <LiterAlarmIndicators
            fuelCapacityLiter={fuelCapacityLiter}
            fuelLevelLiterAlarms={fuelLevelLiterAlarms}
            fuelLevelPercent={fuelLevelPercent}
            withWarning={withWarning}
            withAlarm={withAlarm}
            isCriticalAlarm={isCriticalAlarm}
            statusColor={statusColor}
          />
        </Box>
        <Box className={classes.barContainer}>
          <Bar
            height={360}
            data={[
              {
                sensor: 'Fuel Tank',
                value: fuelLevelPercent,
                remainingValue: fuelRemainingPercent
              }
            ]}
            keys={['value', 'remainingValue']}
            colors={[statusColor, colors.lightGray]}
            indexBy="sensor"
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }}
            padding={0.1}
            minValue={0}
            maxValue={100}
            layout="vertical"
            valueScale={{ type: 'linear' }}
            indexScale={{
              type: 'band',
              round: true
            }}
            borderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            animate={true}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableLabel={false}
            totalsOffset={0}
            labelSkipWidth={15}
            labelSkipHeight={16}
            labelTextColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            legends={[]}
            tooltip={({ id, formattedValue }) => (id === 'value' &&
              <Paper px={12} py={8} radius="sm" shadow="sm">
                <Text size={12}>{`${formattedValue}%`}</Text>
              </Paper>
            )}
            enableGridY={false}
            enableGridX={false}
            markers={mapMarkers as []}
          />
          <PercentMarkerAnnotations
            fuelCapacityLiter={fuelCapacityLiter}
            fuelLevelPercentAlarms={fuelLevelPercentAlarms}
          />
          <LiterMarkerAnnotations
            fuelCapacityLiter={fuelCapacityLiter}
            fuelLevelLiterAlarms={fuelLevelLiterAlarms}
          />
          <MarkerAnnotationSafeFillLevel
            safeFillLevelLiter={safeFillLevelLiter}
            safeFillLevelPercent={safeFillLevelPercent}
          />
        </Box>
        <MarkerAnnotationCurrentLevel
          fuelCapacityLiter={fuelCapacityLiter}
          fuelLevelPercent={fuelLevelPercent}
          currentFuelLevelLiter={currentFuelLevelLiter}
        />
      </Group>
    </Box>
  )
}
