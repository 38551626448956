import {
  createStyles,
  Box,
  Group,
  Paper,
  Skeleton,
  Stack
} from '@mantine/core'
import { mq } from 'src/utils/style'

const useStyles = createStyles(theme => ({
  content: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [mq(theme.breakpoints.md)]: {
      flexDirection: 'row'
    }
  },
  leftSection: {
    flex: 1,
    alignContent: 'center'
  },
  rightSection: {
    minWidth: 0,
    flex: 2,
    alignContent: 'center'
  }
}))

export default function Card () {
  const { classes } = useStyles()

  return (
    <Paper
      p={16}
      radius={4}
      shadow="xs"
    >
      <Skeleton
        height={20}
        width={100}
        radius="md"
      />
      <Box
        mt={32}
        mb={20}
        px={12}
        className={classes.content}
      >
        <Box className={classes.leftSection}>
          <Skeleton
            height={160}
            width={160}
            circle
          />
        </Box>
        <Box className={classes.rightSection}>
          <Stack spacing={24}>
            <Stack spacing={8}>
              <Group position="apart">
                <Skeleton
                  height={16}
                  width={150}
                  radius="md"
                />
                <Skeleton
                  height={16}
                  width={80}
                  radius="md"
                />
              </Group>
              <Skeleton
                height={20}
                width="100%"
                radius="sm"
              />
            </Stack>
            <Stack spacing={8}>
              <Group position="apart">
                <Skeleton
                  height={16}
                  width={200}
                  radius="md"
                />
                <Skeleton
                  height={16}
                  width={60}
                  radius="md"
                />
              </Group>
              <Skeleton
                height={20}
                width="100%"
                radius="sm"
              />
            </Stack>
            <Stack spacing={8}>
              <Group position="apart">
                <Skeleton
                  height={16}
                  width={140}
                  radius="md"
                />
                <Skeleton
                  height={16}
                  width={80}
                  radius="md"
                />
              </Group>
              <Skeleton
                height={20}
                width="100%"
                radius="sm"
              />
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Paper>
  )
}
