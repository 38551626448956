import {
  createStyles,
  useMantineTheme,
  Box,
  Group,
  Paper,
  Skeleton,
  Stack
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import zIndex from 'src/utils/zIndex'

const useStyles = createStyles((theme) => ({
  container: {
    height: 128,
    position: 'sticky',
    top: 0,
    margin: 0,
    padding: 16,
    transition: 'all .3s ease',
    zIndex: zIndex.assetTemplateHeader,
    [mq(theme.breakpoints.xs)]: {
      margin: '0 16px',
      padding: 24
    }
  },
  assetContainer: {
    display: 'flex',
    flexDirection: 'column',
    [mq(theme.breakpoints.sm)]: {
      gap: 8,
      flexDirection: 'row'
    },
    [mq(theme.breakpoints.md)]: {
      gap: 32
    }
  }
}))

export default function Header () {
  const { classes } = useStyles()
  const { breakpoints: { sm } } = useMantineTheme()
  const isSmallScreen = useMediaQuery(mq(sm, false))

  return (
    <Paper
      className={classes.container}
      radius="xs"
      shadow="xs"
    >
      <Skeleton
        height={20}
        width={150}
        radius="xl"
      />
      <Box mt={8} className={classes.assetContainer}>
        <Skeleton
          height={32}
          width={isSmallScreen
            ? '40%'
            : '70%'}
          radius="md"
        />
        <Group
          position="apart"
          align="center"
          sx={{
            flex: 1
          }}
        >
          <Group align="center" spacing={12}>
            <Stack spacing={4}>
              {isSmallScreen && (
                <Skeleton
                  height={4}
                  width={60}
                  radius="md"
                />
              )}
              <Skeleton
                mt={isSmallScreen
                  ? 0
                  : 8}
                height={24}
                width={100}
                radius="sm"
              />
            </Stack>
            <Stack spacing={4}>
              {isSmallScreen && (
                <Skeleton
                  height={4}
                  width={60}
                  radius="md"
                />
              )}
              <Skeleton
                mt={isSmallScreen
                  ? 0
                  : 8}
                height={24}
                width={100}
                radius="sm"
              />
            </Stack>
          </Group>
          <Skeleton
            height={24}
            width={24}
            radius="sm"
          />
        </Group>
      </Box>

    </Paper>
  )
}
