import { Paper, Stack, Text, type MantineColor } from '@mantine/core'
import { getTextColorAsProp } from 'src/utils/theme'

interface Props {
  label: string
  value: string
  valueSize?: React.CSSProperties['fontSize']
  labelSize?: React.CSSProperties['fontSize']
  color?: MantineColor
  width?: React.CSSProperties['width']
  height?: React.CSSProperties['height']
  onClick?: () => void
}

export default function GroupBoxValue ({
  label,
  value,
  valueSize = '20px',
  labelSize = '15px',
  color,
  width = '100%',
  height = '100%',
  onClick
}: Props) {
  return (
    <Paper
      p="xs"
      shadow="xs"
      style={{
        width,
        height
      }}
    >
      <Stack
        align="center"
        justify="center"
        spacing={-5}
        style={{
          width,
          height
        }}
      >
        <Text
          style={{
            fontSize: valueSize
          }}
          size="md"
          color={color === 'gray'
            ? getTextColorAsProp().color
            : color}
          weight={700}
          onClick={onClick}
        >
          {value}
        </Text>
        <Text
          style={{
            fontSize: labelSize
          }}
          size="xs"
        >
          {label}
        </Text>
      </Stack>
    </Paper>
  )
}
