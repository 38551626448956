import { createStyles, Box, Stack, Text } from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import { getSensorValue } from 'src/AssetTemplate/shared'
import { getTextColor } from 'src/utils/theme'

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    margin: '0 auto',
    ...getTextColor(theme),
    padding: '32px 16px'
  }
}))

interface Props {
  apparentPower: Sensor | null
}

export default function PowerState ({ apparentPower }: Props) {
  const { classes } = useStyles()
  const power = getSensorValue(apparentPower, true, true)
  const disabled = apparentPower === null

  return (
    <Box className={classes.container}>
      <Stack spacing={4} align="center">
        <Text size={12} weight={500}>
          Apparent Power
        </Text>
        <Text
          size={20}
          weight={600}
          color={disabled
            ? 'dimmed'
            : undefined}
        >
          {!disabled
            ? power
            : 'N/A'}
        </Text>
      </Stack>
    </Box>
  )
}
