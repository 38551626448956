import { useCallback, useEffect, useState } from 'react'
import {
  createStyles,
  ActionIcon,
  Box,
  Button,
  Divider,
  Group,
  Paper,
  Stack,
  Text
} from '@mantine/core'
import {
  type GeoZoneRuleWithActions,
  getGeoZoneRulesAndActions
} from '@venturi-io/api/src/config/geoZone'
import { deleteGeoZoneRuleAction } from '@venturi-io/api/src/config/geoZoneRuleAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconInfoCircle, IconSquareRoundedMinus, IconTrash, IconWand } from '@tabler/icons-react'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { useNotifications } from 'src/utils/notifications'
import ConfirmModal from 'src/Layout/ConfirmModal'
import TextTooltip from 'src/Layout/TextTooltip'
import { getTextColorAsProp } from 'src/utils/theme'
import { MODES } from '../../constants'
import { type GeoZoneData } from '..'
import { type MapMode } from '../../shared'
import FormAddRule from './FormAddRule'

const useStyles = createStyles((theme) => ({
  id: {
    backgroundColor: theme.colors.teal[6],
    padding: '4px 10px',
    borderRadius: '100%',
    textAlign: 'center'
  },
  description: {
    display: 'inline',
    textAlign: 'justify'
  }
}))

interface Props {
  mode: MapMode
  selectedGeoZone: GeoZoneData
  setHasExistingRule: (status: boolean) => void
}

export default function Rules ({
  mode,
  selectedGeoZone,
  setHasExistingRule
}: Props) {
  const { classes } = useStyles()
  const { token } = useUser()
  const findGeoZoneRulesAndActions = useApi(getGeoZoneRulesAndActions)
  const { showSuccess, showError } = useNotifications()
  const [isAddingRule, setIsAddingRule] = useState<boolean>(false)
  const [toDeleteIds, setToDeleteIds] = useState<number[]>([])
  const [deleteSubject, setDeleteSubject] = useState<string>('')
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const inEditMode = mode?.id === MODES.EDITING

  const geoZoneRulesAndActions: GeoZoneRuleWithActions[] = findGeoZoneRulesAndActions.data.mapOrDefault(
    ({ geoZoneRules }) => geoZoneRules ?? []
    , [])

  const loadRulesAndActions = useCallback(() => {
    void findGeoZoneRulesAndActions.fetch({ geoZoneId: selectedGeoZone.geoZoneId }, token)
  }, [selectedGeoZone])

  const handleDelete = useCallback(() => {
    setIsDeleting(true)

    const queries = toDeleteIds.map(async geoZoneRuleActionId => {
      const query = new Promise<string>((resolve, reject) => {
        void deleteGeoZoneRuleAction({ geoZoneRuleActionId }, token)
          .caseOf({
            Left: err => {
              reject(err)
            },
            Right: () => resolve(`Successfully deleted ${deleteSubject}`)
          })
      })
      return await query
    })

    void Promise.all(queries)
      .then(() => {
        showSuccess(`Successfully deleted ${deleteSubject}`)
        loadRulesAndActions()
      })
      .catch(() => {
        showError(new Error(`Failed to delete ${deleteSubject}`))
      })
      .finally(() => {
        setIsDeleting(false)
        setShowDelete(false)
      })
  }, [toDeleteIds])

  useEffect(() => {
    // Check if the selected geozone has existing rule(s)
    setHasExistingRule(geoZoneRulesAndActions.length > 0)
  }, [geoZoneRulesAndActions])

  useEffect(() => {
    void loadRulesAndActions()
  }, [selectedGeoZone])

  return (
    <Box p={16}>
      <Text
        size={16}
        weight={500}
      >
        Rules & Actions
      </Text>
      <Stack mt={12} spacing={8}>
        {geoZoneRulesAndActions.length === 0 && !isAddingRule && (
          <Text
            my={24}
            align="center"
            italic
          >
            ** No rules assigned **
          </Text>
        )}
        <Stack spacing={24}>
          {geoZoneRulesAndActions.map(({
            geoZoneRuleId,
            name,
            description,
            geoZoneActions
          }, i
          ) => (
            <Box key={geoZoneRuleId}>
              <Group position="apart">
                <Group spacing={8}>
                  <Text
                    className={classes.id}
                    size="xs"
                    weight={500}
                    {...getTextColorAsProp()}
                  >
                    {i + 1}
                  </Text>
                  <TextTooltip
                    size={12}
                    weight={500}
                    value={name}
                    maxLength={25}
                  />
                </Group>
                {inEditMode && (
                  <ActionIcon
                    title="Delete Rule"
                    variant="transparent"
                    onClick={() => {
                      setToDeleteIds(geoZoneActions?.map(({ geoZoneRuleActionId }) => geoZoneRuleActionId) ?? [])
                      setDeleteSubject('Rule')
                      setShowDelete(true)
                    }}
                    disabled={!inEditMode}
                  >
                    <IconTrash size={16} color="silver" />
                  </ActionIcon>
                )}
              </Group>
              {description && (
                <Group
                  mt={8}
                  px={4}
                  spacing={8}
                  position="left"
                  align="flex-start"
                >
                  <IconInfoCircle
                    size={16}
                    {...getTextColorAsProp()}
                  />
                  <Text
                    className={classes.description}
                    {...getTextColorAsProp()}
                    weight={400}
                    italic
                  >
                    {description}
                  </Text>
                </Group>
              )}
              {geoZoneActions &&
                geoZoneActions.length > 0 &&
                typeof geoZoneActions[0]?.geoZoneActionId !== 'undefined' && (
                  <Stack mt={12} mx={6} spacing={8}>
                    <Divider label="Actions" />
                    <Stack spacing={8}>
                      {geoZoneActions.map(({
                        geoZoneRuleActionId,
                        geoZoneActionId,
                        name,
                        description
                      }) => (
                        <Paper key={geoZoneActionId} p="md" withBorder>
                          <Group position="apart" h={26}>
                            <Group spacing={5}>
                              <IconWand
                                size={12}
                                {...getTextColorAsProp()}
                              />
                              <TextTooltip
                                weight={500}
                                value={name ?? '-'}
                                maxLength={20}
                              />
                            </Group>
                            {inEditMode && (
                              <ActionIcon
                                title="Delete Action"
                                variant="transparent"
                                onClick={() => {
                                  setToDeleteIds([geoZoneRuleActionId])
                                  setDeleteSubject('Action')
                                  setShowDelete(true)
                                }}
                                disabled={!inEditMode}
                              >
                                <IconSquareRoundedMinus size={14} color="silver" />
                              </ActionIcon>
                            )}
                          </Group>
                          <Text
                            mt={0}
                            ml={2}
                            {...getTextColorAsProp()}
                            weight={400}
                            italic
                          >
                            {description}
                          </Text>
                        </Paper>
                      ))}
                    </Stack>
                  </Stack>
              )}
            </Box>
          ))}
          {isAddingRule && (
            <FormAddRule
              geoZoneId={selectedGeoZone.geoZoneId}
              existingRules={geoZoneRulesAndActions.map(({ geoZoneRuleId }) => geoZoneRuleId)}
              onCreate={() => {
                loadRulesAndActions()
                setIsAddingRule(false)
              }}
            />
          )}
        </Stack>

        {inEditMode && !isAddingRule && (
          <Button
            mt={8}
            leftIcon={<FontAwesomeIcon icon={['far', 'plus']} color="white" />}
            onClick={() => setIsAddingRule(true)}
            disabled={!inEditMode}
          >
            Add Rule
          </Button>
        )}

        {inEditMode && isAddingRule && (
          <Button
            color="gray"
            variant="default"
            onClick={() => setIsAddingRule(false)}
            disabled={!inEditMode}
          >
            Cancel
          </Button>
        )}
      </Stack>
      <ConfirmModal
        type="delete"
        opened={showDelete}
        title={`Deleting ${deleteSubject}`}
        loading={isDeleting}
        question={`Are you sure you want to delete this ${deleteSubject.toLowerCase()}? This cannot be undone.`}
        onClose={() => setShowDelete(false)}
        onCancel={() => setShowDelete(false)}
        onConfirm={handleDelete}
        centered
      />
    </Box>
  )
}
