/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ActionIcon,
  Button,
  Collapse,
  Group,
  Table as MantineTable,
  Paper,
  Popover,
  Stack,
  Text,
  useMantineTheme
} from '@mantine/core'
import { type ReportData } from '@venturi-io/api/src/config/report'
import { useUser } from 'src/UserContext'
import { getAgentTrips } from '@venturi-io/api/src/collector/trip'
import { usePaginatedApi } from 'src/utils/useApi'
import { toUtc, uiDateFormat } from 'src/utils/dates'
import { useNotifications } from 'src/utils/notifications'
import { secondsToDhms } from 'src/Assets/shared'
import { round } from 'src/utils/math'
import { downloadCSV } from 'src/utils/files'
import { useDisclosure } from '@mantine/hooks'
import Trips from 'src/Assets/VehicleDashboard/Trips'
import { reverseGeocode } from 'src/Maps/GeoZoneMap/mapbox'
import NeedsRole from 'src/NeedsRole'
import { adminRoles } from 'src/utils/role'
import dayjs from 'dayjs'
import { type Range } from '@venturi-io/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconPrinter } from '@tabler/icons-react'
import { getLinkTextColor, getToggleableColorWBG, getWhiteBackgroundColor } from 'src/utils/theme'
import { type ReportFileType } from '..'
import ExportToPdf from './ExportToPdf'

interface Props {
  data: ReportData
  queryId: number
  range: Range | null
  maxWidth: number
  setTripReportData: (data: ParsedTripItem[]) => void
  selectedAgentIds: string[]
  selectedAgentGroupIds: string[]
}

export interface TripItem {
  agent_id: string
  agent_name: string
  alarm_status: string
  agent_groups_ids: string
  total_distance_in_metres: string
  total_duration_in_seconds: string
  total_trips: string
}

export interface ParsedTripItem extends Omit<TripItem,
'agent_groups_ids' |
'total_distance_in_metres' |
'total_duration_in_seconds'
> {
  total_distance_in_kilometres: string
  total_duration: string
}

export interface AgentTripItem {
  trip_id: number
  start_time: string
  end_time: string
  asset: string
  driver: string
  start_location: string
  end_location: string
  driving_duration: string
  idling_duration: string
  distance_travelled: string
  average_speed: string
  max_speed: string
  average_x_acceleration: string
  max_x_acceleration: string
  average_y_acceleration: string
  max_y_acceleration: string
  average_z_acceleration: string
  max_z_acceleration: string
}

interface RowsProps extends Omit<Props, 'data'> {
  data: ParsedTripItem[]
  onDownloadFile?: (type: ReportFileType, agentId: number, dateRange: Range | null) => void
}

interface RowProps extends Pick<Props, 'range' | 'maxWidth'> {
  trip: ParsedTripItem
  onDownloadFile?: (type: ReportFileType, agentId: number, dateRange: Range | null) => void
}

const Row = ({ trip, range, onDownloadFile }: RowProps) => {
  const {
    agent_id,
    agent_name,
    alarm_status,
    total_trips,
    total_duration,
    total_distance_in_kilometres
  } = trip
  const theme = useMantineTheme()
  const [tableHeight, setTableHeight] = useState(200)
  const [open, { toggle }] = useDisclosure(false)

  const onClickDownloadFile = useCallback((type: ReportFileType) => {
    if (onDownloadFile) {
      onDownloadFile(type, Number(agent_id), range)
    }
  }, [onDownloadFile, agent_id, range])

  return (
    <>
      <tr
        onClick={toggle}
        style={{
          cursor: 'pointer',
          backgroundColor: getToggleableColorWBG(open),
          borderBottom: 1,
          position: open
            ? 'sticky'
            : 'initial',
          top: 34,
          zIndex: open
            ? 100
            : 'unset'
        }}
      >
        <td>
          <Text
            {...getLinkTextColor(theme)}
            weight={open
              ? 'bold'
              : 'normal'}
          >
            {agent_name}
          </Text>
        </td>
        <td>{alarm_status}</td>
        <td>{total_trips}</td>
        <td>{total_duration}</td>
        <td>{total_distance_in_kilometres}</td>
        <td align="right" onClick={(e) => e.stopPropagation()}>
          <Group
            spacing="lg"
            position="center"
            align="center"
          >
            <NeedsRole role={adminRoles}>
              <Popover
                position="bottom-end"
                withArrow
                arrowOffset={12}
              >
                <Popover.Target>
                  <ActionIcon size="xs"><IconPrinter size={16} /></ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <Stack align="flex-start" spacing="xs">
                    <Text color="dimmed" size="xs">Export data</Text>
                    <Button
                      leftIcon={<FontAwesomeIcon icon={['far', 'file-csv']} />}
                      variant="subtle"
                      color="primary"
                      onClick={() => onClickDownloadFile('CSV')}
                    >
                      Download as CSV
                    </Button>
                    <Button
                      leftIcon={<FontAwesomeIcon icon={['far', 'file-pdf']} />}
                      variant="subtle"
                      color="primary"
                      onClick={() => onClickDownloadFile('PDF')}
                    >
                      Download as PDF
                    </Button>
                  </Stack>
                </Popover.Dropdown>
              </Popover>
            </NeedsRole>
          </Group>
        </td>
      </tr>
      <tr>
        <td
          colSpan={6}
          style={{
            padding: 0,
            borderTop: 0,
            borderBottom: open
              ? 1
              : 0
          }}
        >
          <Collapse in={open}>
            {open && (
              <Trips
                showTitle={false}
                agentId={parseInt(agent_id)}
                startTime={range?.from}
                endTime={range?.to}
                hideRfid
                style={{
                  // computation for sidebar: 60px + container and paper padding (40px & 36px)
                  width: 'calc(100vw - 136px)',
                  overflowY: 'hidden',
                  overflowX: 'auto',
                  scrollbarWidth: 'thin',
                  height: tableHeight,
                  margin: 0
                }}
                didChangeHeight={(height) => {
                  setTableHeight(height)
                }}
              />
            )}
          </Collapse>
        </td>
      </tr>
    </>
  )
}

const Rows = ({ data, range, maxWidth, onDownloadFile }: RowsProps) => {
  return data
    ? data.map(trip => (
      <Row
        key={trip.agent_id}
        trip={trip}
        range={range}
        maxWidth={maxWidth}
        onDownloadFile={onDownloadFile}
      />
    ))
    : null
}

export default function TripReportView (props: Props) {
  const {
    data,
    queryId,
    setTripReportData,
    selectedAgentIds,
    selectedAgentGroupIds
  } = props
  const theme = useMantineTheme()
  const { showError } = useNotifications()
  const { token } = useUser()
  const agentTripsReport = usePaginatedApi(getAgentTrips)
  const [reportType, setReportType] = useState<ReportFileType | null>(null)
  const [showPdfViewer, { toggle }] = useDisclosure(false)
  const [selectedAgentId, setSelectedAgentId] = useState<string | null>()
  const [agentTrips, setAgentTrips] = useState<AgentTripItem[]>([])
  const tripData = data[`${queryId}`] as unknown as TripItem[] ?? []
  const [selectedTripItem, setSelectedTripItem] = useState<ParsedTripItem | null>(null)

  // Convert agent group ids into array of string
  const updatedTripData = tripData.map(({ agent_groups_ids, ...props }) => ({
    ...props,
    agent_groups_ids: agent_groups_ids ? agent_groups_ids.split(',') : []
  }))

  // Convert selected agent group ids into string
  const agentGroupIds = selectedAgentGroupIds.join(',') ?? ''

  // Filtered updatedTripData by selected agent group id/ids
  const filteredDataByGroups = useMemo(() =>
    agentGroupIds.length > 0
      ? updatedTripData.filter(({ agent_groups_ids }) => agent_groups_ids.some(id => agentGroupIds.includes(id)))
      : updatedTripData,
  [updatedTripData, agentGroupIds])

  // Filtered updatedTripData/filteredDataByGroups by selected agent id/ids
  const filteredDataById = useMemo(() =>
    selectedAgentIds.length > 0
      ? filteredDataByGroups.filter(({ agent_id }) => selectedAgentIds.includes(agent_id))
      : filteredDataByGroups,
  [filteredDataByGroups, selectedAgentIds])

  const parsedTripData: ParsedTripItem[] = filteredDataById.map(({
    agent_id,
    agent_name,
    alarm_status,
    total_trips,
    total_duration_in_seconds,
    total_distance_in_metres
  }) => {
    return {
      agent_id,
      agent_name,
      alarm_status,
      total_trips,
      total_distance_in_kilometres: round(parseInt(total_distance_in_metres) / 1000).toString(),
      total_duration: secondsToDhms(parseInt(total_duration_in_seconds)).toString()
    }
  })

  const handleDownloadFile = useCallback((
    type: ReportFileType,
    agentId: number,
    dateRange: Range | null
  ) => {
    setSelectedAgentId(agentId.toString())
    setReportType(type)

    void agentTripsReport.fetch({
      agentId,
      page: 1,
      size: 999,
      startTime: toUtc(dateRange?.from ?? dayjs().subtract(24, 'hour')) + 'Z',
      endTime: toUtc(dateRange?.to ?? dayjs()) + 'Z'
    }, token)
  }, [])

  useEffect(() => {
    setSelectedTripItem(parsedTripData.find(({ agent_id }) => agent_id === selectedAgentId) ?? null)
  }, [selectedAgentId])

  useEffect(() => {
    const agentName = selectedTripItem?.agent_name ?? '-'

    // generate and download agent's trips report
    agentTripsReport.data.ifJust(({ items }) => {
      Promise.all(items.map(async ({
        tripId,
        startTime,
        endTime,
        distance,
        startPoint,
        endPoint,
        rfidTag,
        user,
        speedMax,
        speedAvg,
        drivingDuration,
        idlingDuration,
        xAccelerationMax,
        xAccelerationAvg,
        yAccelerationMax,
        yAccelerationAvg,
        zAccelerationMax,
        zAccelerationAvg
      }) => {
        try {
          const start_location = await reverseGeocode([startPoint.longitude, startPoint.latitude])
          const end_location = await reverseGeocode([endPoint.longitude, endPoint.latitude])

          return {
            trip_id: tripId,
            start_time: dayjs(startTime).format(uiDateFormat),
            end_time: dayjs(endTime).format(uiDateFormat),
            asset: agentName,
            rfid: rfidTag ?? '-',
            driver: user
              ? `${user.firstName} ${user.lastName}`
              : '-',
            start_location,
            end_location,
            driving_duration: secondsToDhms(drivingDuration ?? 0).toString(),
            idling_duration: secondsToDhms(idlingDuration ?? 0).toString(),
            distance_travelled: `${round(distance / 1000)} kms`,
            average_speed: speedAvg
              ? `${round(speedAvg)} Kmh`
              : '-',
            max_speed: speedMax
              ? `${round(speedMax)} Kmh`
              : '-',
            average_x_acceleration: xAccelerationAvg
              ? round(xAccelerationAvg).toString()
              : '-',
            max_x_acceleration: xAccelerationMax
              ? round(xAccelerationMax).toString()
              : '-',
            average_y_acceleration: yAccelerationAvg
              ? round(yAccelerationAvg).toString()
              : '-',
            max_y_acceleration: yAccelerationMax
              ? round(yAccelerationMax).toString()
              : '-',
            average_z_acceleration: zAccelerationAvg
              ? round(zAccelerationAvg).toString()
              : '-',
            max_z_acceleration: zAccelerationMax
              ? round(zAccelerationMax).toString()
              : '-'
          }
        } catch (err: any) {
          throw Error('Failed to generate trips report')
        }
      }))
        .then((data: AgentTripItem[]) => {
          if (reportType === 'CSV') {
            downloadCSV(data as unknown as Array<Record<string, unknown>>, `${agentName} - Trip History`)
          }

          if (reportType === 'PDF') {
            setAgentTrips(data)
            toggle()
          }
        })
        .catch((error) => {
          showError(error)
        })
    })
  }, [agentTripsReport.data])

  useEffect(() => {
    setTripReportData(parsedTripData)
  }, [queryId, props.range, selectedAgentIds, selectedAgentGroupIds])

  return (
    <>
      <Paper
        p="sm"
        shadow="xs"
        sx={{
          width: '100%',
          scrollbarWidth: 'thin'
        }}
      >
        <MantineTable
          sx={{
            ...getWhiteBackgroundColor(theme),
            position: 'relative'
          }}
        >
          <thead
            style={{
              ...getWhiteBackgroundColor(theme),
              position: 'sticky',
              top: 0,
              zIndex: 100
            }}
          >
            <tr style={{ borderBottom: 1 }}>
              <th >Assets</th>
              <th style={{ width: 150 }}>Alarm Status</th>
              <th style={{ width: 150 }}># Trips</th>
              <th style={{ width: 150 }}>Total Duration</th>
              <th style={{ width: 150 }}>Total Distance KMs</th>
              <th style={{ width: 80 }} />
            </tr>
          </thead>
          <tbody>
            <Rows
              {...props}
              data={parsedTripData}
              onDownloadFile={handleDownloadFile}
            />
          </tbody>
        </MantineTable>
      </Paper>
      {selectedTripItem && (
        <ExportToPdf
          agentName={selectedTripItem.agent_name}
          data={agentTrips}
          dateRange={props.range}
          totalTrips={selectedTripItem.total_trips}
          totalDuration={selectedTripItem.total_duration}
          totalDistance={selectedTripItem.total_distance_in_kilometres}
          showPdfViewer={showPdfViewer}
          toggleShowPdfViewer={toggle}
        />
      )}
    </>
  )
}
