import { type ChangeEvent, useCallback, useEffect, useState } from 'react'
import { IconPlus, IconX, IconSearch } from '@tabler/icons-react'
import {
  createStyles,
  ActionIcon,
  Box,
  Button,
  Drawer,
  Group,
  Input,
  Loader,
  Stack,
  Text,
  Title
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { type GeoZoneRule, getGeoZoneRules } from '@venturi-io/api/src/config/geoZoneRule'
import InfiniteScroll from 'react-infinite-scroll-component'
import { usePaginatedApi } from 'src/utils/useApi'
import { type FormMode } from 'src/utils'
import { useUser } from 'src/UserContext'
import Item from './Item'
import Form from './Form'

const useStyles = createStyles(() => ({
  listContainer: {
    height: '77vh',
    marginTop: 4,
    width: '100%',
    overflow: 'hidden',
    scrollbarWidth: 'thin',
    '&:hover': {
      overflowY: 'auto'
    }
  },
  drawerTitle: {
    width: '100%'
  }
}))

interface Props {
  opened: boolean
  setOpened: (status: boolean) => void
}

export default function RuleList ({
  opened = false,
  setOpened
}: Props) {
  const { classes } = useStyles()
  const { token, orgId } = useUser()
  const geoZoneRules = usePaginatedApi(getGeoZoneRules)
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebouncedValue(search, 500)
  const [selectedRule, setSelectedRule] = useState<GeoZoneRule>()
  const [formMode, setFormMode] = useState<FormMode>('CREATE')
  const [openedRuleForm, setOpenedRuleForm] = useState(false)
  const [cachedRules, setCachedRules] = useState<GeoZoneRule[]>([])
  const paginationSize = 15
  const { currentPage, totalPages } = geoZoneRules.data.mapOrDefault(({ currentPage, totalPages }) => ({
    currentPage,
    totalPages
  }), {
    currentPage: 1,
    totalPages: 1
  })

  const loadGeoZoneRules = (page?: number) => {
    void geoZoneRules
      .fetch({
        orgId,
        page: page ?? geoZoneRules.page,
        size: paginationSize,
        search: debouncedSearch
      }, token)
  }

  const handleClickAddNewRule = useCallback(() => {
    setFormMode('CREATE')
    setOpenedRuleForm(true)
  }, [])

  const handleClickEditRule = useCallback(() => {
    setFormMode('EDIT')
    setOpenedRuleForm(true)
  }, [])

  const handleSuccessCreateRule = useCallback((rule: GeoZoneRule) => {
    const updatedRules = [rule, ...cachedRules]
    setCachedRules(updatedRules)
  }, [cachedRules])

  const handleSuccessEditRule = useCallback((rule: GeoZoneRule) => {
    const updatedRules = cachedRules.map(cachedRule => (
      rule.geoZoneRuleId === cachedRule.geoZoneRuleId
        ? rule
        : cachedRule
    ))
    setCachedRules(updatedRules)
  }, [cachedRules])

  const handleSuccessDeleteRule = useCallback((ruleId: GeoZoneRule['geoZoneRuleId']) => {
    const updatedRules = cachedRules.filter(({ geoZoneRuleId }) => ruleId !== geoZoneRuleId)
    setCachedRules(updatedRules)
  }, [cachedRules])

  useEffect(() => {
    geoZoneRules.data.ifJust(({ items }) => {
      setCachedRules(cachedRules.concat(items))
    })
  }, [geoZoneRules.data])

  useEffect(() => {
    setCachedRules([])
    void loadGeoZoneRules(1)
  }, [debouncedSearch])

  useEffect(() => {
    if (typeof selectedRule !== 'undefined') {
      setFormMode('EDIT')
    } else {
      setFormMode('CREATE')
    }
  }, [selectedRule])

  useEffect(() => {
    if (!opened) {
      setSearch('')
    }
  }, [opened])

  return (
    <>
      <Drawer.Root
        position="right"
        shadow="xs"
        size="sm"
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <Drawer.Overlay />
        <Drawer.Content>
          <Drawer.Header>
            <Drawer.Title className={classes.drawerTitle}>
              <Group position="apart" align="center">
                <Group align="center" spacing={8}>
                  <Title order={4}>Rules</Title>
                  {geoZoneRules.loading && <Loader size="xs" color="primary" />}
                </Group>
                <Group
                  position="center"
                  align="center"
                  spacing={8}
                >
                  <Button
                    type="button"
                    size="xs"
                    leftIcon={<IconPlus size={16} />}
                    onClick={handleClickAddNewRule}
                  >
                    Add New
                  </Button>
                  <Drawer.CloseButton />
                </Group>
              </Group>
            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body mt={12}>
            <Input
              placeholder="Search rule"
              icon={<IconSearch size={20} color="gray" />}
              rightSection={search !== '' && (
                <ActionIcon
                  title="Clear"
                  onClick={() => setSearch('')}
                >
                  <IconX
                    size={16}
                    stroke={1.5}
                    color="silver"
                  />
                </ActionIcon>
              )}
              radius={4}
              value={search}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
              disabled={geoZoneRules.loading}
            />
            {!geoZoneRules.loading && cachedRules.length === 0 && (
              <Text
                mt={16}
                size={12}
                weight={400}
                color="silver"
                align="center"
              >
                No data available
              </Text>
            )}
            {cachedRules.length > 0 && (
              <Box id="scrollable-geozone-rules" className={classes.listContainer}>
                <InfiniteScroll
                  dataLength={cachedRules.length}
                  next={() => loadGeoZoneRules(currentPage + 1)}
                  hasMore={currentPage < totalPages}
                  loader={null}
                  scrollableTarget="scrollable-geozone-rules"
                >
                  <Stack mt={12} p={2} spacing={10}>
                    {cachedRules.map(rule => (
                      <Item
                        key={rule.geoZoneRuleId}
                        rule={rule}
                        onSelect={setSelectedRule}
                        onClickEdit={handleClickEditRule}
                        onSuccessDelete={handleSuccessDeleteRule}
                      />
                    ))}
                  </Stack>
                </InfiniteScroll>
              </Box>
            )}
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
      <Form
        mode={formMode}
        ruleId={selectedRule?.geoZoneRuleId}
        opened={openedRuleForm}
        setOpened={setOpenedRuleForm}
        onSuccessCreate={handleSuccessCreateRule}
        onSuccessEdit={handleSuccessEditRule}
      />
    </>
  )
}
