import {
  createStyles,
  useMantineTheme,
  Box,
  Group,
  Paper,
  Stack,
  Text
} from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import { type BarDatum } from '@nivo/bar'
import Bar from 'src/Charts/Bar'
import AlarmIndicator from 'src/AssetTemplate/Components/AlarmIndicator'
import { getSensorValue } from 'src/AssetTemplate/shared'
import { getTextColorAsProp } from 'src/utils/theme'
import colors from 'src/AssetTemplate/colors'

const useStyles = createStyles(() => ({
  container: {
    position: 'relative',
    minWidth: 0
  },
  barContainer: {
    flex: 1,
    position: 'relative',
    minWidth: 0
  },
  label: {
    whiteSpace: 'nowrap'
  },
  alarmIndicatorContainer: {
    position: 'absolute',
    left: -28,
    bottom: -10
  },
  tooltip: {
    background: '#212121',
    color: '#FFFFFF',
    width: 150
  }
}))

type VoltageType = 'LN' | 'LL'

interface VoltageLimitData extends BarDatum {
  name: string
  label: string
  min: number
  max: number
  color: string
}

const voltageLnLimits: VoltageLimitData[] = [
  {
    name: 'Critical (low)',
    label: 'Under Voltage Shutdown',
    min: 0,
    max: 216,
    color: colors.red
  },
  {
    name: 'Warning (low)',
    label: 'Under Voltage Warning',
    min: 216,
    max: 228,
    color: colors.yellow
  },
  {
    name: 'Good',
    label: 'Nominal Voltage',
    min: 228,
    max: 252,
    color: colors.green
  },
  {
    name: 'Warning (high)',
    label: 'Over Voltage Warning',
    min: 252,
    max: 264,
    color: colors.yellow
  },
  {
    name: 'Critical (high)',
    label: 'Over Voltage Shutdown',
    min: 264,
    max: 480,
    color: colors.red
  }
]

const voltageLlLimits: VoltageLimitData[] = [
  {
    name: 'Critical (low)',
    label: 'Under Voltage Shutdown',
    min: 0,
    max: 373,
    color: colors.red
  },
  {
    name: 'Warning (low)',
    label: 'Under Voltage Warning',
    min: 373,
    max: 395,
    color: colors.yellow
  },
  {
    name: 'Good',
    label: 'Nominal Voltage',
    min: 395,
    max: 435,
    color: colors.green
  },
  {
    name: 'Warning (high)',
    label: 'Over Voltage Warning',
    min: 435,
    max: 457,
    color: colors.yellow
  },
  {
    name: 'Critical (high)',
    label: 'Over Voltage Shutdown',
    min: 457,
    max: 830,
    color: colors.red
  }
]

interface Props {
  label: string
  sensor: Sensor | null
  type: VoltageType
}

export default function VoltageBar ({
  label,
  sensor,
  type
}: Props) {
  const { classes } = useStyles()
  const { colorScheme } = useMantineTheme()
  const isLight = colorScheme === 'light'
  const limits = type === 'LN'
    ? voltageLnLimits
    : type === 'LL'
      ? voltageLlLimits
      : []
  const data: BarDatum = limits.reduce((
    result,
    {
      name,
      min,
      max
    }
  ) => (
    {
      ...result,
      [`${name}`]: max - min
    }
  ), {})
  const maxValue = limits[4].max ?? 'auto'
  const markerStroke = isLight
    ? 'gray'
    : 'white'
  const markers = [
    {
      axis: 'x',
      value: sensor?.currentValue,
      lineStyle: {
        stroke: markerStroke,
        strokeWidth: 3
      },
      legend: '',
      legendOrientation: 'vertical',
      legendOffsetX: 0,
      legendOffsetY: 0
    }
  ]
  const withAlarm = sensor?.alarmStatus === 'ALARM'
  const disabled = sensor === null

  return (
    <Stack className={classes.container} spacing={0}>

      {withAlarm && (
        <Box className={classes.alarmIndicatorContainer}>
          <AlarmIndicator size="sm" />
        </Box>
      )}

      <Group position="apart" align="flex-start" spacing={8}>
        <Text
          className={classes.label}
          size={12}
          weight={500}
          w={10}
          {...getTextColorAsProp()}
        >
          {label}
        </Text>
        <Box className={classes.barContainer}>
          <Bar
            height={16}
            data={[
              {
                key: 'Voltage Sensors',
                ...data
              }
            ]}
            keys={limits.map(({ name }) => name)}
            colors={limits.map(({ color }) => color)}
            indexBy="key"
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }}
            padding={0.2}
            minValue={0}
            maxValue={maxValue}
            layout="horizontal"
            valueScale={{ type: 'linear' }}
            indexScale={{
              type: 'band',
              round: true
            }}
            borderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableLabel={false}
            totalsOffset={0}
            labelSkipWidth={15}
            labelSkipHeight={16}
            labelTextColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            legends={[]}
            tooltip={({ id }) => {
              const limit = limits.find(({ name }) => name === id)

              if (typeof limit === 'undefined') {
                return null
              }

              const {
                label,
                min,
                max,
                color
              } = limit

              return (
                <Paper
                  className={classes.tooltip}
                  px={12}
                  py={8}
                  radius="sm"
                  shadow="sm"
                >
                  <Text
                    size={16}
                    weight={500}
                    color={color}
                  >
                    {label}
                  </Text>
                  <Stack spacing={0} mt={4}>
                    <Text size={12} weight={500}>
                      Minimum Value
                    </Text>
                    <Text size={14} weight={600}>
                      {min}
                    </Text>
                  </Stack>
                  <Stack spacing={0} mt={4}>
                    <Text size={12} weight={500}>
                      Maximum Value
                    </Text>
                    <Text size={14} weight={600}>
                      {max}
                    </Text>
                  </Stack>
                </Paper>
              )
            }}
            markers={markers as []}
          />
        </Box>
        <Text
          className={classes.label}
          size={12}
          weight={500}
          w={20}
          color={disabled
            ? 'dimmed'
            : undefined}
        >
          {!disabled
            ? getSensorValue(sensor, true, true)
            : 'N/A'}
        </Text>
      </Group>
    </Stack>
  )
}
