import { Text } from '@mantine/core'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import TextTooltip from 'src/Layout/TextTooltip'
import { type TankTransaction } from '@venturi-io/api/src/config/fms'
import { uiDateFormat } from 'src/utils/dates'
import dayjs from 'dayjs'
import Link from 'src/Layout/Link'
import { getAgentDashboardPath } from 'src/AssetTemplate/shared'
import { getTextMaxLengthPerBreakpoint, truncateWithEllipsis } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'

export default function Row ({ ...transactions }: TankTransaction & ListRow) {
  const { classes } = useStyles()
  const {
    started,
    rfidTag,
    person,
    volume,
    lpm,
    type,
    transactionId,
    agent
  } = transactions
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint, true)
  const assetDashboardPath = typeof agent !== 'undefined'
    ? getAgentDashboardPath(agent.agentId, agent.assetType)
    : null

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={dayjs(started).format(uiDateFormat).toString()}
          maxLength={25}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(rfidTag)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(agent?.agentId)}
          maxLength={25}
        />
      </div>
      <div className={classes.col}>
        {assetDashboardPath !== null
          ? (
            <Link to={assetDashboardPath}>
              {truncateWithEllipsis(
                agent?.agentName ?? '',
                textMaxLength
              )}
            </Link>
            )
          : '-'}
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(agent?.assetType)}
          maxLength={25}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(person)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {volume.toFixed(2)}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {lpm.toFixed(2)}
        </Text>
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(type)}
          maxLength={25}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(transactionId)}
          maxLength={25}
        />
      </div>
    </div>
  )
}
