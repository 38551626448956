import { createStyles, Box, Stack } from '@mantine/core'
import { mq } from 'src/utils/style'
import Pictogram from './Pictogram'
import AssetDetails from './AssetDetails'
import Location from './Location'
import Tasks from './Tasks'

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    [mq(theme.breakpoints.xs)]: {
      width: 240
    }
  }
}))

export default function Details () {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <Stack spacing={16}>
        <Pictogram />
        <AssetDetails />
        <Location />
        <Tasks />
      </Stack>
    </Box>
  )
}
