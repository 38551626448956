import { useCallback, useEffect, useState } from 'react'
import {
  createStyles,
  ActionIcon,
  Box,
  Button,
  Group,
  Stack,
  Switch,
  Text,
  Tooltip
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { type GeoZone } from '@venturi-io/api/src/config/geoZone'
import { IconDeviceFloppy, IconTrash } from '@tabler/icons-react'
import TextInput from 'src/Input/TextInput'
import MultiSelectAgentGroup from 'src/Input/MultiSelect/MultiSelectAgentGroup'
import { parseEmpty } from 'src/utils/parseEmpty'
import { getTextColorAsProp } from 'src/utils/theme'
import { featureToGeoZone, type Feature, type MapMode } from '../shared'
import { MODES } from '../constants'
import { type GeoZoneData } from '.'

const useStyles = createStyles(() => ({
  container: {
    padding: '12px 20px',
    maxHeight: 254,
    overflow: 'auto',
    scrollbarWidth: 'thin'
  },
  controlsContainer: {
    position: 'absolute',
    bottom: 0,
    padding: '16px 20px',
    width: '100%',
    height: 67,
    borderTop: '.5px solid #E2E1E1'
  }
}))

interface Props {
  mode: MapMode
  orgId: number
  activeFeature: Feature
  selectedGeoZone: GeoZoneData | null
  hasExistingRule: boolean
  onUpdate: (
    geoZone: GeoZone,
    newAgentGroupIds: number[],
    removedAgentGroupIds: number[]
  ) => void
  onDelete: () => void
  onCancel: () => void
  setShowWizard: (value: boolean) => void
}

export default function Properties ({
  mode,
  orgId,
  activeFeature: selectedFeature,
  selectedGeoZone,
  hasExistingRule = false,
  onUpdate,
  onDelete,
  onCancel,
  setShowWizard
}: Props) {
  const { classes } = useStyles()
  const [isHoverDeleteButton, setIsHoverDeleteButton] = useState(false)
  const hasExistingAgentGroup = !!selectedGeoZone?.agentGroups && selectedGeoZone.agentGroups.length > 0
  const disabledDelete = hasExistingAgentGroup || hasExistingRule
  const initialValues = selectedGeoZone ?? {
    geoZoneId: -1,
    orgId,
    name: '',
    description: '',
    agentGroups: [],
    hiddenOnMap: false
  }

  const form = useForm<GeoZoneData>({
    initialValues,
    validate: {
      name: value => !value || value.length < 1
        ? 'Please specify Name'
        : null
    }
  })

  const newAgentGroupIds: number[] = form.values.agentGroups
    ?.filter(({ agentGroupId }) => (
      !selectedGeoZone?.agentGroups?.find((group) => group.agentGroupId === agentGroupId)
    ))
    .map(({ agentGroupId }) => agentGroupId) ??
  []

  const removedAgentGroupIds: number[] = selectedGeoZone?.agentGroups
    ?.filter(({ agentGroupId }) => (
      !form.values.agentGroups?.find((group) => group.agentGroupId === agentGroupId)
    ))
    .map(({ agentGroupId }) => agentGroupId) ??
  []

  const handleSubmit = useCallback((values: typeof form.values) => {
    selectedFeature.properties.data = { ...values }

    const geoZone: GeoZone = featureToGeoZone(selectedFeature)
    const { geoZoneId } = geoZone

    if (geoZoneId && geoZoneId !== -1) {
      onUpdate(geoZone, newAgentGroupIds, removedAgentGroupIds)
    }

    setShowWizard(false)
  }, [newAgentGroupIds, removedAgentGroupIds])

  useEffect(() => {
    if (selectedGeoZone) {
      form.setValues(selectedGeoZone)
    } else {
      form.setValues(initialValues)
    }
  }, [selectedGeoZone])

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Box className={classes.container}>
        {mode?.id === MODES.EDITING && (
          <Stack my={4} spacing={12}>
            <TextInput
              withAsterisk
              label="Name"
              size="xs"
              {...form.getInputProps('name')}
            />
            <TextInput
              label="Description"
              size="xs"
              {...form.getInputProps('description')}
            />
            <MultiSelectAgentGroup
              label="Asset Groups"
              value={form.values?.agentGroups?.map(({ agentGroupId }) => agentGroupId.toString())}
              onChange={ids => (
                form.setFieldValue('agentGroups', ids.map(id => ({ agentGroupId: Number(id) })))
              )}
              searchable
            />
            <div>
              <Text
                size="xs"
                weight={400}
                mb={4}
              >
                Settings
              </Text>
              <Switch
                label="Hide Geozone on Map"
                color="primary"
                size="sm"
                checked={form.values.hiddenOnMap}
                onChange={event => {
                  form.setFieldValue('hiddenOnMap', event.currentTarget.checked)
                }}
              />
            </div>
          </Stack>
        )}
        {mode?.id === MODES.VIEWING && (
          <Box>
            <Stack spacing={12}>
              <div>
                <Text size="xs" weight={500}>Name</Text>
                <Text size="xs">{parseEmpty(selectedGeoZone?.name)}</Text>
              </div>
              <div>
                <Text size="xs" weight={500}>Description</Text>
                <Text size="xs">{parseEmpty(selectedGeoZone?.description)}</Text>
              </div>
              <div>
                <Text
                  size="xs"
                  weight={500}
                  mb={4}
                >
                  Asset Groups
                </Text>
                <MultiSelectAgentGroup
                  value={selectedGeoZone?.agentGroups?.map(({ agentGroupId }) => agentGroupId.toString())}
                  disabled
                />
              </div>
              <div>
                <Text
                  size="xs"
                  weight={500}
                  mb={4}
                >
                  Settings
                </Text>
                <Switch
                  label="Hide Geozone on Map"
                  color="primary"
                  size="sm"
                  checked={form.values.hiddenOnMap}
                />
              </div>
            </Stack>
          </Box>
        )}
      </Box>
      <Box className={classes.controlsContainer}>
        <Group position="apart" align="flex-start">
          <Tooltip
            opened={isHoverDeleteButton && disabledDelete}
            position="left"
            color="red"
            label="Unable to delete a geozone with asset group(s) or rule(s)"
            withArrow
          >
            <Box
              onMouseEnter={() => setIsHoverDeleteButton(true)}
              onMouseLeave={() => setIsHoverDeleteButton(false)}
            >
              <ActionIcon
                variant="subtle"
                {...getTextColorAsProp()}
                onClick={onDelete}
                disabled={disabledDelete}
              >
                <IconTrash size={24} />
              </ActionIcon>
            </Box>
          </Tooltip>
          {mode?.id === MODES.EDITING && (
            <Group position="center" align="center" spacing={16}>
              <Button
                variant="default"
                onClick={() => {
                  onCancel()
                  setShowWizard(false)
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                leftIcon={<IconDeviceFloppy size={20} />}
              >
                Save
              </Button>
            </Group>
          )}
        </Group>
      </Box>
    </form>
  )
}
