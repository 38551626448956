import {
  createStyles,
  Box,
  Group,
  Paper,
  Stack,
  Text
} from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import Bar from 'src/Charts/Bar'
import {
  computeRelativePercentage,
  convertStrToFloat,
  getSensorValue
} from 'src/AssetTemplate/shared'
import AlarmIndicator from 'src/AssetTemplate/Components/AlarmIndicator'
import { getTextColorAsProp } from 'src/utils/theme'

const useStyles = createStyles(() => ({
  container: {
    position: 'relative'
  },
  barContainer: {
    minWidth: 0
  },
  alarmIndicatorContainer: {
    position: 'absolute',
    left: -28,
    bottom: -6
  }
}))

interface Props {
  label?: string
  sensor: Sensor | null
}

export default function SensorBar ({ label, sensor }: Props) {
  const { classes } = useStyles()
  const name = label ?? sensor?.name ?? '-'
  const value = sensor
    ? Math.round(computeRelativePercentage(
      convertStrToFloat(sensor.currentValue),
      sensor.minValue,
      sensor.maxValue
    ))
    : 0
  const displayValue = `${getSensorValue(sensor, true, true)}`
  const withAlarm = sensor?.alarmStatus === 'ALARM'
  const disabled = sensor === null

  return (
    <Box className={classes.container}>
      {withAlarm && (
        <Box className={classes.alarmIndicatorContainer}>
          <AlarmIndicator size="sm" />
        </Box>
      )}

      <Stack className={classes.barContainer} spacing={0}>
        <Group position="apart">
          <Text
            size={12}
            weight={500}
            {...getTextColorAsProp()}
          >
            {name}
          </Text>
          <Text
            size={16}
            weight={500}
            color={disabled
              ? 'dimmed'
              : undefined}
          >
            {!disabled
              ? displayValue
              : 'N/A'}
          </Text>
        </Group>
        <Bar
          height={25}
          data={[
            {
              sensor: name,
              value
            }
          ]}
          keys={['value']}
          colors={['#5C5F66']}
          indexBy="sensor"
          margin={{
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }}
          padding={0.2}
          minValue={0}
          maxValue={100}
          layout="horizontal"
          valueScale={{ type: 'linear' }}
          indexScale={{
            type: 'band',
            round: true
          }}
          borderColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                1.6
              ]
            ]
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={null}
          enableLabel={false}
          totalsOffset={0}
          labelSkipWidth={15}
          labelSkipHeight={16}
          labelTextColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                1.6
              ]
            ]
          }}
          legends={[]}
          tooltip={({ id, formattedValue }) => (id === 'value' &&
            <Paper px={12} py={8} radius="sm" shadow="sm">
              <Text size={12}>{`${formattedValue ?? 0}%`}</Text>
            </Paper>
          )}
          theme={{
            grid: {
              line: {
                stroke: '#D9D9D9',
                strokeWidth: '8px'
              }
            }
          }}
        />
      </Stack>
    </Box>
  )
}
