import {
  type RefObject,
  useCallback,
  useState,
  useEffect,
  useMemo
} from 'react'
import {
  useMantineTheme,
  createStyles,
  ActionIcon,
  Box,
  Tabs,
  Stack
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Agent } from '@venturi-io/api/src/config/geoZone'
import { mq } from 'src/utils/style'
import { useMediaQuery } from '@mantine/hooks'
import { type MapRef } from 'react-map-gl'
import { useSearchParams } from 'react-router-dom'
import * as _ from 'lodash'
import { getBackgroundColor, getTextColor } from 'src/utils/theme'
import { sortAgents, useViewTypes } from '../AgentAttendanceContext'
import AgentStack from './AgentStack'
import AssetsFilter from './AssetsFilter'
import Drivers from './Drivers'
import AgentGroups from './AgentGroups'
import SearchBar from './SearchBar'

const useStyles = createStyles((theme, opened: boolean) => ({
  tabsLists: {
    flexWrap: 'nowrap'
  },
  panel: {
    padding: '0 16px'
  },
  container: {
    position: 'absolute',
    left: opened
      ? 0
      : '-312px',
    width: '312px',
    height: '100%',
    boxShadow: '10px 0 10px -8px black',
    overflow: 'auto',
    transition: 'ease-in-out .4s',
    zIndex: 99,
    display: 'flex',
    flexDirection: 'column',
    scrollbarWidth: 'thin',
    ...getBackgroundColor(theme, -2),
    [mq(theme.breakpoints.md)]: {
      left: opened
        ? 0
        : '-325px',
      width: '312px'
    }
  },
  toggle: {
    position: 'absolute',
    top: 10,
    left: opened
      ? 318
      : 10,
    ...getTextColor(theme),
    ...getBackgroundColor(theme, 1),
    transition: 'left ease-in-out .4s',
    zIndex: 5,
    '&:hover': {
      ...getBackgroundColor(theme, 2)
    },
    [mq(theme.breakpoints.md)]: {
      left: opened
        ? 318
        : 10
    }
  }
}))

interface Props {
  mapRef: RefObject<MapRef>
  loading?: boolean
  onRefreshList: () => void
  onClick?: (agentId: Agent['agentId']) => void
  isOpen: boolean
  setIsOpen?: (status: boolean) => void
}

const ItemsList = ({
  loading,
  onRefreshList,
  onClick,
  isOpen = false,
  setIsOpen
}: Props) => {
  const { setTab, filteredByQuery: agents, agentsOnViewport } = useViewTypes()
  const { breakpoints, colorScheme } = useMantineTheme()
  const isLight = colorScheme === 'light'
  const isDesktop = useMediaQuery(mq(breakpoints.sm, false))

  const [params] = useSearchParams()
  // For PDD handler
  const listParam = params.get('list') ?? 'true'
  const [isListShown] = useState(
    listParam !== 'false'
  )

  const [opened, setOpened] = useState(isListShown)
  const [activeTab, setActiveTab] = useState<string | null>('assets')
  const { classes } = useStyles(opened)
  const filteredAgentsByView = agents.filter(({ agentId }) => (
    !agentsOnViewport.some(agent => agent.agentId === agentId
    )))

  const agentStackData = useMemo(() => {
    const sortedAgents = sortAgents(filteredAgentsByView)
    const sortedViewAgents = sortAgents(agentsOnViewport)

    return [
      ...sortedViewAgents,
      ...sortedAgents
    ]
  }, [filteredAgentsByView, agentsOnViewport])

  useEffect(() => {
    if (activeTab) {
      // Update agent attendance context
      setTab(activeTab)
    }
  }, [activeTab])

  const handleToggle = useCallback(() => {
    setOpened(!opened)

    if (setIsOpen) {
      setIsOpen(!opened)
    }
  }, [opened])

  const handleClickAgent = (agentId: Agent['agentId']) => {
    if (!isDesktop) {
      setOpened(false)

      if (setIsOpen) {
        setIsOpen(false)
      }
    }
    if (onClick) {
      onClick(agentId)
    }
  }

  useEffect(() => {
    setOpened(isOpen)
  }, [isOpen])

  return (
    <>
      <ActionIcon
        className={classes.toggle}
        onClick={handleToggle}
      >
        <FontAwesomeIcon
          size="lg"
          icon={[
            'fas',
            opened
              ? 'chevron-left'
              : 'chevron-right'
          ]}
        />
      </ActionIcon>
      <Box id="tour-map__asset-list" className={classes.container}>
        <Stack spacing={0}>
          <SearchBar />
          <Tabs
            classNames={{
              tabsList: classes.tabsLists,
              panel: classes.panel
            }}
            value={activeTab}
            onTabChange={setActiveTab}
            variant={isLight
              ? 'default'
              : 'outline'}
          >
            <Tabs.List position="apart" grow>
              <Tabs.Tab
                value="assets"
                icon={<FontAwesomeIcon icon={['fas', 'flux-capacitor']} size="xs" />}
              >
                Assets
              </Tabs.Tab>
              <Tabs.Tab
                value="drivers"
                icon={<FontAwesomeIcon icon={['fas', 'people-group']} size="xs" />}
              >
                People
              </Tabs.Tab>
              <Tabs.Tab
                value="groups"
                icon={<FontAwesomeIcon icon={['fas', 'layer-group']} size="xs" />}
              >
                Groups
              </Tabs.Tab>
            </Tabs.List>
            <AssetsFilter />
            {activeTab === 'assets' && opened && (
              <Tabs.Panel value="assets" sx={{ height: 'calc(100vh - 230px)' }}>
                <AgentStack
                  loading={loading}
                  agents={agentStackData}
                  isAgentGroup={false}
                  onClick={handleClickAgent}
                  onRefresh={onRefreshList}
                />
              </Tabs.Panel>
            )}
            {activeTab === 'drivers' && opened && (
              <Tabs.Panel value="drivers">
                <Drivers
                  onClick={handleClickAgent}
                />
              </Tabs.Panel>
            )}
            {activeTab === 'groups' && opened && (
              <Tabs.Panel value="groups">
                <AgentGroups
                  loading={loading}
                  onClick={handleClickAgent}
                />
              </Tabs.Panel>
            )}
          </Tabs>
        </Stack>
      </Box>
    </>
  )
}

export default ItemsList
