import React, { type CSSProperties } from 'react'
import {
  Box,
  Divider,
  Flex,
  Group,
  Stack,
  Text,
  useMantineTheme
} from '@mantine/core'
import { IconPointFilled } from '@tabler/icons-react'
import { type AgentLocation } from '@venturi-io/api/src/collector/agent'
import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'

interface Props {
  data: AgentLocation
  style: CSSProperties
}

interface LabelValueProps {
  label: string
  value?: string | number
}

const LabelValue = ({ label, value }: LabelValueProps) => (
  <Stack spacing={0}>
    <Text
      size="xs"
      color="dimmed"
      weight={500}
    >
      {label}
    </Text>
    <Text size="sm">{value}</Text>
  </Stack>
)

function Row ({
  style,
  data: {
    timestamp,
    geoLocation: {
      longitude,
      latitude
    }
  }
}: Props) {
  const { colors } = useMantineTheme()

  return (
    <Stack spacing={4} style={style}>
      <Text size="xs" color="dimmed">{dayjs(timestamp).format(uiDateFormat)}</Text>
      <Flex my="sm" direction="row" align="center">
        <Box w={34}>
          <IconPointFilled size={24} color={colors.primary[6]} />
        </Box>
        <Group
          sx={{
            flexGrow: 1
          }}
        >
          <LabelValue
            label="Longitude"
            value={longitude}
          />
          <LabelValue
            label="Latitude"
            value={latitude}
          />
        </Group>
      </Flex>
      <Divider />
    </Stack>
  )
}

export default Row
