import { useEffect, useState } from 'react'
import { Box, Center, type SelectItem, MultiSelect } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { executeReport, type QueryParams, type Report } from '@venturi-io/api/src/config/report'
import dayjs from 'dayjs'
import { dateFormat, toUtc } from 'src/utils/dates'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import { type Range } from '@venturi-io/api'
import Loader from 'src/Layout/Loader'
import Nothing from 'src/Nothing'
import MultiSelectAgentGroup from 'src/Input/MultiSelect/MultiSelectAgentGroup'
import uniqBy from 'lodash/uniqBy'
import ReportView from './ReportView'
import TripReportView, { type TripItem, type ParsedTripItem } from './TripReportView'
import SpeedingReportView, { type ParsedSpeedEvent } from './SpeedingReportView'
import HeaderBar from './HeaderBar'
import DuressReportView, { type ParsedDuressEvent } from './DuressReportView'
import IdleReportView, { type ParsedIdleEvent } from './IdleReportView'
import GeozoneReportView, { type ParsedGeozoneEvent } from './GeozoneReportView'

type RawData = Record<string, Array<Record<string, string | null>>>
export interface ReportRawData {
  data: RawData
  fromTime?: string
  toTime?: string
}

export type ReportFileType = 'CSV' | 'PDF'

const totalHorizontalPadding = 24

interface Props {
  embeddedReportData?: ReportRawData
  embeddedReport?: Report
}

export default function Viewer ({ embeddedReportData, embeddedReport }: Props) {
  const { ref, width: maxWidth } = useElementSize()
  const generatedReport = useApi(executeReport)
  const { token } = useUser()
  const [report, setReport] = useState<Report>()
  const reportData = embeddedReportData ?? generatedReport.data.mapOrDefault(data => data, { data: {} })
  const [tripReportData, setTripReportData] = useState<ParsedTripItem[]>([])
  const [duressReportData, setDuressReportData] = useState<ParsedDuressEvent[]>([])
  const [speedReportData, setSpeedReportData] = useState<ParsedSpeedEvent[]>([])
  const [idleReportData, setIdleReportData] = useState<ParsedIdleEvent[]>([])
  const [geozoneReportData, setGeozoneReportData] = useState<ParsedGeozoneEvent[]>([])
  const [selectedAgentIds, setSelectedAgentIds] = useState<string[]>([])
  const [selectedAgentGroupIds, setSelectedAgentGroupIds] = useState<string[]>([])

  const queryId = embeddedReport?.reportType?.reportQueryIds[0] ??
    report?.reportType?.reportQueryIds[0] ??
    -1

  const [dateRange, setDateRange] = useState<Range | null>(
    embeddedReportData?.fromTime && embeddedReportData?.toTime
      ? {
          from: embeddedReportData.fromTime,
          to: embeddedReportData.toTime
        }
      : {
          from: dayjs()
            .subtract(24, 'hour')
            .format(`${dateFormat}Z`),
          to: dayjs()
            .format(`${dateFormat}Z`)
        }
  )

  const reportParams = (range: Range | null): QueryParams => {
    if (!report) {
      return {}
    }
    const { reportType: { name } } = report
    switch (name) {
      case 'Trip History':
      case 'Duress Event Report':
      case 'Speed Event Report':
      case 'Geozone Event Report':
      case 'Idle Time Report':
        return {
          [queryId]: {
            fromTime: toUtc(range?.from ?? dayjs().subtract(24, 'hour')) + 'Z',
            toTime: toUtc(range?.to ?? dayjs()) + 'Z'
          }
        }
      default:
        return {}
    }
  }

  const handleExecute = (range: Range | null) => {
    if (report) {
      const params = reportParams(range)
      setDateRange(range)
      void generatedReport.fetch({
        reportId: report.id,
        queryParams: params
      }, token)
    }
  }

  const renderReportViewFromType = ({ data: rawData }: ReportRawData) => {
    if (!report) return null
    const { reportType: { name } } = report
    switch (name) {
      case 'Trip History':
        return (
          <TripReportView
            data={rawData}
            queryId={queryId}
            range={dateRange}
            maxWidth={maxWidth - totalHorizontalPadding}
            setTripReportData={setTripReportData}
            selectedAgentIds={selectedAgentIds}
            selectedAgentGroupIds={selectedAgentGroupIds}
          />
        )
      case 'Duress Event Report':
        return (
          <DuressReportView
            data={rawData}
            queryId={queryId}
            range={dateRange}
            maxWidth={maxWidth - totalHorizontalPadding}
            setDuressData={setDuressReportData}
            selectedAgentIds={selectedAgentIds}
            selectedAgentGroupIds={selectedAgentGroupIds}
          />
        )
      case 'Idle Time Report':
        return (
          <IdleReportView
            data={rawData}
            queryId={queryId}
            range={dateRange}
            maxWidth={maxWidth - totalHorizontalPadding}
            setIdleData={setIdleReportData}
            selectedAgentIds={selectedAgentIds}
            selectedAgentGroupIds={selectedAgentGroupIds}
          />
        )
      case 'Speed Event Report':
        return (
          <SpeedingReportView
            data={rawData}
            queryId={queryId}
            range={dateRange}
            maxWidth={maxWidth - totalHorizontalPadding}
            setSpeedAlertsData={setSpeedReportData}
            selectedAgentIds={selectedAgentIds}
            selectedAgentGroupIds={selectedAgentGroupIds}
          />
        )
      case 'Geozone Event Report':
        return (
          <GeozoneReportView
            data={rawData}
            queryId={queryId}
            range={dateRange}
            maxWidth={maxWidth - totalHorizontalPadding}
            setGeozoneReportData={setGeozoneReportData}
            selectedAgentIds={selectedAgentIds}
            selectedAgentGroupIds={selectedAgentGroupIds}
          />
        )
      default:
        return <ReportView data={rawData} />
    }
  }

  useEffect(() => {
    // reset selected agent Ids
    setSelectedAgentIds([])
    setSelectedAgentGroupIds([])
  }, [report?.reportType.id])

  useEffect(() => {
    setReport(embeddedReport)
  }, [embeddedReport])

  // TODO: Revisit once all report templates are in place
  const rowData = reportData.data[`${queryId}`] as unknown as TripItem[] ?? []

  const agents: SelectItem[] = rowData.map((agent: TripItem) => ({
    label: agent.agent_name,
    value: agent.agent_id
  }))
  return (
    <>
      <HeaderBar
        report={report}
        dateRange={dateRange}
        reportData={reportData.data}
        tripReportData={tripReportData}
        duressReportData={duressReportData}
        speedReportData={speedReportData}
        idleReportData={idleReportData}
        geozoneReportData={geozoneReportData}
        fromEmbed={embeddedReport !== undefined}
        busy={generatedReport.loading}
        queryId={queryId}
        setReport={(newReport) => {
          // reset once the report was changed
          void generatedReport.clearAll()
          setReport(newReport)
        }}
        onGenerate={handleExecute}
        extensions={(
          report?.reportType.name === 'Trip History' ||
          report?.reportType.name === 'Duress Event Report' ||
          report?.reportType.name === 'Idle Time Report' ||
          report?.reportType.name === 'Geozone Event Report' ||
          report?.reportType.name === 'Speed Event Report') && (
            <>
              <MultiSelect
                height={40}
                searchable
                value={selectedAgentIds}
                data={uniqBy(agents, 'value')}
                placeholder="Select assets"
                onChange={e => setSelectedAgentIds(e)}
              />
              <MultiSelectAgentGroup
                value={selectedAgentGroupIds}
                placeholder="Select asset groups"
                onChange={e => setSelectedAgentGroupIds(e)}
                searchable
              />
            </>
        )}
      />
      {report && (
        <Box ref={ref} mt="sm">
          {generatedReport.loading && (
            <Center>
              <Loader variant="dots" />
            </Center>
          )}
          {generatedReport.data.caseOf({
            Nothing: () => (
              <Nothing
                isLoading={generatedReport.loading}
                nothing={generatedReport.data.isNothing()}
                placeholder="No executed report."
              />
            ),
            Just: (rawData) => generatedReport.loading
              ? null
              : renderReportViewFromType(rawData)
          })}
        </Box>
      )}
      {/* Load from generated report */}
      {embeddedReportData && (
        <Box ref={ref} mt="sm">
          {renderReportViewFromType(embeddedReportData)}
        </Box>
      )}
    </>
  )
}
