/* eslint-disable no-eval */
import { type CSSProperties } from 'react'
import { Group } from '@mantine/core'
import { type Alarm } from '@venturi-io/api/src/analytics/alarm'
import colors from 'src/AssetTemplate/colors'
import AlarmIndicator from '../AlarmIndicator'

interface Props {
  fuelLevelPercentAlarms: Alarm[]
  fuelCapacityLiter: number
  fuelLevelPercent: number
  withWarning?: boolean
  withAlarm?: boolean
  isCriticalAlarm?: boolean
  statusColor?: CSSProperties['color']
}

export default function PercentAlarmIndicators ({
  fuelLevelPercentAlarms,
  fuelLevelPercent,
  withWarning,
  withAlarm,
  statusColor = colors.blue
}: Props) {
  const getClosestPercentAlarm = (fuelLevelPercentAlarms: Alarm[], target: number) => {
    const setPoints = fuelLevelPercentAlarms.map(({ setPoint }) => setPoint)

    return setPoints.reduce((prev: number, curr: number) =>
      Math.abs(curr - target) <
      Math.abs(prev - target)
        ? curr
        : prev)
  }

  return (
    <>
      {fuelLevelPercentAlarms.map(({
        alarmId,
        operator,
        setPoint: alarmPosition
      }) => {
        const operator_ = operator === '='
          ? '=='
          : operator
        // This will check if the current value has been met based on the operator and setPoint
        const condition = `${fuelLevelPercent} ${operator_} ${alarmPosition}`
        const isAlarm = eval(condition)

        return isAlarm &&
          (withAlarm === true || withWarning === true) &&
          alarmPosition === getClosestPercentAlarm(fuelLevelPercentAlarms, fuelLevelPercent) && (
            <Group
              key={alarmId}
              position="center"
              align="flex-start"
              spacing={5}
              style={{
                position: 'absolute',
                bottom: `${alarmPosition - 4}%`,
                right: '-1rem'
              }}
            >
              <span
                style={{
                  background: statusColor
                }}
              />
              <AlarmIndicator
                size={24}
                alarmColor={statusColor}
              />
            </Group>
        )
      })}
    </>
  )
}
