import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Col,
  type ColProps,
  Grid,
  Group
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { randomId, useLocalStorage } from '@mantine/hooks'
import TextInput from 'src/Input/TextInput'
import ConfirmModal from 'src/Layout/ConfirmModal'
import {
  createOrg,
  deleteOrg,
  updateOrg,
  type Organisation
} from '@venturi-io/api/src/userManager/org'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { type FormMode } from 'src/utils'
import { useForm } from '@mantine/form'
import { type Agent, getGeoZonesAndAgents, type GeoZone } from '@venturi-io/api/src/config/geoZone'
import { type ApiData } from 'src/utils/useApi'
import { type Place } from 'src/Maps/GeoZoneMap/mapbox'
import { getCentroid } from 'src/utils/map'
import SearchBar from 'src/Maps/GeoZoneMap/SearchBar'
import SelectTimezone from 'src/Input/Select/SelectTimezone'

interface Props {
  mode: FormMode
  initialValues?: Organisation
  onCreate?: () => Promise<void>
  onClose?: () => void
}

interface DataProps {
  geoZoneIds: number[]
  agentIds: number[]
}

interface UrlProps {
  url: string
  id: string
}
interface FormProps extends Omit<Organisation, 'orgId' | 'urls'> {
  data: DataProps
  urls: UrlProps[]
}

export default function Form ({ mode, initialValues, onCreate, onClose }: Props) {
  const { token, orgId } = useUser()
  const navigate = useNavigate()
  const newOrg = useApi(createOrg)
  const editOrg = useApi(updateOrg)
  const removeOrg = useApi(deleteOrg)
  const [showDelete, setShowDelete] = useState(false)
  const geoZonesAndAgents = useApi(getGeoZonesAndAgents)
  const inCreateMode = mode === 'CREATE'
  const inEditMode = mode === 'EDIT'
  const inputColProps: ColProps = {
    span: 12,
    sm: inCreateMode
      ? 12
      : 6
  }

  const [environment] = useLocalStorage({
    key: 'environment',
    defaultValue: ''
  })

  const form = useForm<FormProps>({
    initialValues: {
      name: '',
      description: '',
      urls: [{
        url: '',
        id: randomId()
      }],
      defaultGeoLocation: {},
      data: {
        geoZoneIds: [] as number[],
        agentIds: [] as number[]
      },
      defaultTimezone: ''
    },
    validate: {
      name: value => (
        value.trim().length === 0
          ? 'Please specify Organisation Name'
          : null
      ),
      defaultGeoLocation: value => (
        !value?.latitude
          ? 'Please select Agent, Geozone or Place'
          : null
      )
    }
  })

  const onSearchAgent = useCallback(({
    agentName,
    agentId,
    lastLocation: {
      geoLocation: {
        latitude,
        longitude
      }
    }
  }: Agent) => {
    form.setFieldValue('defaultGeoLocation', {
      latitude,
      longitude,
      metadata: {
        name: agentName,
        id: agentId.toString(),
        type: 'agent'
      }
    })
  }, [])

  const onSearchGeoZone = useCallback(({ name, boundary, geoZoneId }: GeoZone) => {
    const { latitude, longitude } = getCentroid(boundary)
    form.setFieldValue('defaultGeoLocation', {
      latitude,
      longitude,
      metadata: {
        name,
        id: geoZoneId.toString(),
        type: 'geozone'
      }
    })
  }, [])

  const onSearchPlace = useCallback(({
    geometry: {
      coordinates: [longitude, latitude]
    },
    properties: {
      name,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      mapbox_id
    }
  }: Place) => {
    form.setFieldValue('defaultGeoLocation', {
      latitude,
      longitude,
      metadata: {
        name,
        id: mapbox_id,
        type: 'place'
      }
    })
  }, [])

  const { geoZones, agents }: ApiData<typeof getGeoZonesAndAgents> =
    geoZonesAndAgents.data.mapOrDefault(({ geoZones, agents }) => ({
      geoZones: typeof form.values.data?.geoZoneIds && form.values.data?.geoZoneIds.length > 0
        ? geoZones.filter(({ geoZoneId }) => form.values.data?.geoZoneIds?.includes(geoZoneId))
        : geoZones,
      agents: typeof form.values.data?.agentIds && form.values.data?.agentIds.length > 0
        ? agents.filter(({ agentId }) => form.values.data?.agentIds?.includes(agentId))
        : agents
    }),
    {
      geoZones: [],
      agents: []
    })

  type FormValues = typeof form.values

  const redirectToListPage = useCallback(() => navigate('/my-admin/organisations'), [])

  const handleCreate = useCallback(({ defaultGeoLocation, urls, ...values }: FormValues) => {
    void newOrg
      .fetch({
        ...values,
        environment,
        urls: urls.map(({ url }) => url),
        defaultGeoLocation
      }, token, 'Successfully created new organisation')
      .finally(() => {
        form.reset()

        if (onClose) onClose()

        if (onCreate) void onCreate()
      })
  }, [form.values, environment])

  const handleUpdate = useCallback((values: FormValues) => {
    const orgId = initialValues?.orgId

    if (!orgId) throw new Error('Unable to perform the action, no organisation provided')

    void editOrg
      .fetch({
        ...values,
        orgId,
        urls: values.urls.map(({ url }) => url)
      }, token, 'Successfully updated organisation')
      .finally(redirectToListPage)
  }, [form.values])

  const handleDelete = useCallback(() => {
    const orgId = initialValues?.orgId

    if (!orgId) throw new Error('Unable to perform the action, no organisation provided')

    void removeOrg
      .fetch({ orgId }, token, 'Succesfully deleted organisation')
      .finally(redirectToListPage)
  }, [form.values])

  const handleSubmit = useCallback((values: FormValues) => {
    if (inCreateMode) handleCreate(values)
    if (inEditMode) handleUpdate(values)
  }, [inCreateMode, inEditMode, form.values])

  const handleClose = useCallback(() => {
    form.reset()

    if (onClose) onClose()
  }, [])

  const resetSearchBar = useCallback(() => {
    form.setFieldValue('defaultGeoLocation', {})
  }, [])

  useEffect(() => {
    if (initialValues) {
      const {
        name,
        description,
        defaultGeoLocation,
        defaultTimezone
      } = initialValues

      form.setValues({
        name,
        description: description ?? '',
        urls: [],
        defaultGeoLocation,
        defaultTimezone
      })
    }
  }, [initialValues])

  useEffect(() => {
    const req = {
      orgId,
      pageName: 'map' as const
    }
    void geoZonesAndAgents.fetch(req, token)
  }, [])
  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col {...inputColProps}>
            <TextInput withAsterisk label="Name" {...form.getInputProps('name')} />
          </Col>
          <Col {...inputColProps}>
            <SearchBar
              agents={agents}
              geoZones={geoZones}
              onSearchAgent={onSearchAgent}
              onSearchGeoZone={onSearchGeoZone}
              onSearchPlace={onSearchPlace}
              applyStyle={false}
              metaName={form.values.defaultGeoLocation?.metadata?.name}
              label="Default Geolocation"
              resetMeta={() => resetSearchBar()}
              error={form.errors.defaultGeoLocation}
            />
          </Col>
          <Col {...inputColProps}>
            <SelectTimezone
              searchable
              label="Time zone"
              {...form.getInputProps('defaultTimezone')}
              isOrgTimezone={true}
              showInfo={false}
            />
          </Col>

          {inEditMode && (
            <Col span={6}>
              <Group position="left" mt="lg" mb="sm">
                <Button
                  color="red"
                  leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} color="white" />}
                  onClick={() => setShowDelete(true)}
                >
                  Delete
                </Button>
              </Group>
            </Col>
          )}
          <Col span={inCreateMode ? 12 : 6}>
            <Group position="right" mt="lg" mb="sm">
              <Button
                color="gray"
                leftIcon={<FontAwesomeIcon icon={['fas', 'ban']} color="white" />}
                onClick={inCreateMode ? handleClose : redirectToListPage}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={(
                  <FontAwesomeIcon
                    icon={['fas', inCreateMode ? 'circle-plus' : 'floppy-disk']}
                    color="white"
                  />
                )}
                disabled={inCreateMode ? newOrg.loading : editOrg.loading}
              >
                {inCreateMode ? 'Create' : 'Save'}
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
      {inEditMode && (
        <ConfirmModal
          type="delete"
          opened={showDelete}
          title={`Deleting "${form.values.name}"`}
          loading={removeOrg.loading}
          question="Are you sure you want to delete this organisation? This cannot be undone."
          onClose={() => setShowDelete(false)}
          onCancel={() => setShowDelete(false)}
          onConfirm={handleDelete}
        />
      )}
    </>
  )
}
