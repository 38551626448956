import mapboxgl, { type LngLatLike } from 'mapbox-gl'
import { type AllGeoJSON } from '@turf/helpers'
import centroid from '@turf/centroid'
import { type GeoLocation } from '@venturi-io/api/src/shared'

// Used to get the centre point for a geoJson polygon
export const getCentroid = (geojson: AllGeoJSON): GeoLocation => {
  const {
    geometry: {
      coordinates
    }
  } = centroid(geojson)

  return {
    longitude: coordinates[0],
    latitude: coordinates[1]
  }
}

export const getBoundary = (coordinates: LngLatLike[]) => {
  const bounds = new mapboxgl.LngLatBounds(
    coordinates[0],
    coordinates[0]
  )

  // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
  for (const coord of coordinates) {
    bounds.extend(coord)
  }

  return bounds
}
