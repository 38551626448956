import { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Col,
  Drawer,
  Grid,
  Group,
  Switch,
  Title
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { TimeInput } from '@mantine/dates'
import { IconDeviceFloppy } from '@tabler/icons-react'
import {
  type GeoZoneRule,
  createGeoZoneRule,
  geoZoneRuleTypes,
  getGeoZoneRule,
  updateGeoZoneRule
} from '@venturi-io/api/src/config/geoZoneRule'
import TextInput from 'src/Input/TextInput'
import NumberInput from 'src/Input/NumberInput'
import SelectTimezone from 'src/Input/Select/SelectTimezone'
import Select from 'src/Input/Select'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { useSelectStyles } from 'src/Input/styles'
import { type FormMode } from 'src/utils'
import { capitalise } from 'src/utils/strings'

interface Props {
  mode: FormMode
  ruleId?: GeoZoneRule['geoZoneRuleId']
  opened: boolean
  setOpened: (status: boolean) => void
  onSuccessCreate?: (rule: GeoZoneRule) => void
  onSuccessEdit?: (rule: GeoZoneRule) => void
  onClose?: () => void
}

const days = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY'
]

const typesWithTimeFrame = [
  'ENTER_ZONE_TIMEFRAME',
  'LEAVE_ZONE_TIMEFRAME'
]

const hasTimeFrame = (type: string) => typesWithTimeFrame.includes(type)

export default function Form ({
  mode,
  ruleId,
  opened,
  setOpened,
  onSuccessCreate,
  onSuccessEdit,
  onClose
}: Props) {
  const { classes } = useSelectStyles()
  const { token, orgId } = useUser()
  const rule = useApi(getGeoZoneRule)
  const createRule = useApi(createGeoZoneRule)
  const editRule = useApi(updateGeoZoneRule)
  const [isTypeSpeed, setIsTypeSpeed] = useState(false)
  const [isTypeTimeFrame, setIsTypeTimeFrame] = useState(false)
  const inCreateMode = mode === 'CREATE'

  const form = useForm<Omit<GeoZoneRule, 'geoZoneRuleId'>>({
    initialValues: {
      name: '',
      type: '',
      editable: true,
      quietMode: false,
      numericValue: undefined,
      startDay: '',
      startTime: '',
      endDay: '',
      endTime: '',
      timezone: ''
    },
    validate: {
      name: value => value.length < 1
        ? 'Please specify Name'
        : null,
      type: value => value.length < 1
        ? 'Please specify Type'
        : null,
      numericValue: value => !value && isTypeSpeed
        ? 'Please specify Speed Limit'
        : null,
      startDay: value => !value && isTypeTimeFrame
        ? 'Please specify Start Day'
        : null,
      startTime: value => !value && isTypeTimeFrame
        ? 'Please specify Start Time'
        : null,
      endDay: value => !value && isTypeTimeFrame
        ? 'Please specify End Day'
        : null,
      endTime: value => !value && isTypeTimeFrame
        ? 'Please specify End Time'
        : null
    }
  })

  type FormValues = typeof form.values

  const handleCreate = useCallback(({
    type,
    startTime,
    endTime,
    startDay,
    endDay,
    timezone,
    ...values
  }: FormValues) => {
    const req = {
      orgId,
      type,
      startDay: hasTimeFrame(type)
        ? startDay?.toUpperCase()
        : undefined,
      startTime: hasTimeFrame(type)
        ? startTime
        : undefined,
      endDay: hasTimeFrame(type)
        ? endDay?.toUpperCase()
        : undefined,
      endTime: hasTimeFrame(type)
        ? endTime
        : undefined,
      timezone: hasTimeFrame(type)
        ? timezone
        : undefined,
      ...values
    }

    void createRule.fetch(req, token, 'Successfully created new rule')
  }, [token, orgId, form.values])

  const handleUpdate = useCallback(({
    type,
    startDay,
    startTime,
    endDay,
    endTime,
    timezone,
    ...values
  }: FormValues) => {
    if (typeof ruleId !== 'undefined') {
      const req = {
        geoZoneRuleId: ruleId,
        orgId,
        type,
        startTime: hasTimeFrame(type)
          ? startTime
          : undefined,
        startDay: hasTimeFrame(type)
          ? startDay?.toUpperCase()
          : undefined,
        endTime: hasTimeFrame(type)
          ? endTime
          : undefined,
        endDay: hasTimeFrame(type)
          ? endDay?.toUpperCase()
          : undefined,
        timezone: hasTimeFrame(type)
          ? timezone
          : undefined,
        ...values
      }

      void editRule.fetch(req, token, 'Successfully updated rule')
    }
  }, [token, orgId, ruleId, form.values])

  const handleSubmit = useCallback((values: FormValues) => {
    switch (mode) {
      case 'CREATE':
        handleCreate(values)
        break

      case 'EDIT':
        handleUpdate(values)
        break
    }
  }, [mode])

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }

    setOpened(false)
  }, [onClose])

  useEffect(() => {
    createRule.data.ifJust(data => {
      if (onSuccessCreate) {
        onSuccessCreate(data)
      }

      form.reset()

      handleClose()
    })
  }, [createRule.data])

  useEffect(() => {
    editRule.data.ifJust(data => {
      if (onSuccessEdit) {
        onSuccessEdit(data)
      }

      handleClose()
    })
  }, [editRule.data])

  useEffect(() => {
    const { type } = form.values
    const isTypeSpeed_ = type === 'SPEED_EXCEEDS'
    const isTypeTimeFrame_ = hasTimeFrame(type)

    if (!isTypeSpeed_) {
      form.setFieldValue('numericValue', undefined)
    }

    if (!isTypeTimeFrame_) {
      form.setFieldValue('startDay', undefined)
      form.setFieldValue('startTime', undefined)
      form.setFieldValue('endDay', undefined)
      form.setFieldValue('endTime', undefined)
      form.setFieldValue('timezone', undefined)
    }

    setIsTypeSpeed(isTypeSpeed_)
    setIsTypeTimeFrame(isTypeTimeFrame_)
  }, [form.values.type])

  useEffect(() => {
    if (mode === 'CREATE') {
      form.reset()
    }

    if (mode === 'EDIT' && typeof ruleId !== 'undefined') {
      void rule.fetch({
        orgId,
        geoZoneRuleId: ruleId
      }, token)
    }
  }, [mode, ruleId])

  useEffect(() => {
    rule.data.ifJust(data => {
      form.setValues(data)
    })
  }, [rule.data])

  return (
    <Drawer
      title={(
        <Title order={3}>
          {`${capitalise(mode)} Rule`}
        </Title>
      )}
      size="md"
      padding="xl"
      position="right"
      opened={opened}
      onClose={handleClose}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col span={12}>
            <TextInput
              label="Name"
              {...form.getInputProps('name')}
              withAsterisk
            />
          </Col>
          <Col span={12}>
            <TextInput
              label="Description"
              {...form.getInputProps('description')}
            />
          </Col>
          <Col span={12}>
            <Select
              label="Type"
              data={geoZoneRuleTypes}
              {...form.getInputProps('type')}
              withAsterisk
            />
          </Col>
          {isTypeSpeed && (
            <Col span={12}>
              <NumberInput
                label="Speed Limit"
                {...form.getInputProps('numericValue')}
                withAsterisk
              />
            </Col>
          )}
          {isTypeTimeFrame && (
            <>
              <Col span={12}>
                <SelectTimezone
                  searchable
                  label="Time Zone"
                  {...form.getInputProps('timezone')}
                  showInfo={false}
                />
              </Col>
              <Col span={6}>
                <Select
                  label="Start Day"
                  data={days}
                  {...form.getInputProps('startDay')}
                  withAsterisk
                />
              </Col>
              <Col span={6}>
                <TimeInput
                  classNames={classes}
                  label="Start Time"
                  {...form.getInputProps('startTime')}
                  withAsterisk
                />
              </Col>
              <Col span={6}>
                <Select
                  label="End Day"
                  data={days}
                  {...form.getInputProps('endDay')}
                  withAsterisk
                />
              </Col>
              <Col span={6}>
                <TimeInput
                  classNames={classes}
                  label="End Time"
                  {...form.getInputProps('endTime')}
                  withAsterisk
                />
              </Col>
            </>
          )}
          <Col mt={8} span={12}>
            <Switch
              color="primary"
              label="Quiet mode"
              description="Hides the notification popup when enabled"
              checked={form.values.quietMode}
              {...form.getInputProps('quietMode')}
            />
          </Col>
        </Grid>
        <Group mt={36} position="right" >
          <Button
            variant="default"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            leftIcon={<IconDeviceFloppy size={20} />}
            disabled={inCreateMode
              ? createRule.loading
              : rule.loading || editRule.loading}
          >
            Save
          </Button>
        </Group>
      </form>
    </Drawer>
  )
}
