import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  type ColProps,
  Button,
  Col,
  Grid,
  Group,
  Stack,
  SegmentedControl,
  Text,
  Switch
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from '@mantine/form'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { type FormMode } from 'src/utils'
import {
  scheduleDay,
  type ScheduleType,
  type ReportSchedule,
  type ScheduleDay
} from '@venturi-io/api/src/config/report'
import SelectScheduleType from 'src/Input/Select/SelectScheduleType'
import dayjs from 'dayjs'
import { dateFormat, toUtc } from 'src/utils/dates'
import MultiSelectUser from 'src/Input/MultiSelect/MultiSelectUser'
import SelectReport from 'src/Input/Select/SelectReport'
import { DatePickerInput } from '@mantine/dates'

interface FormProps {
  reportId?: number
  reportScheduleType?: ScheduleType
  scheduleDay?: ScheduleDay
  scheduleStartDate?: string
  scheduleEnabled: boolean
  orgUsers: number[]
}

interface Props {
  mode: FormMode
  initialValues?: ReportSchedule
  isLoading?: boolean
  onCreate?: (values: Partial<ReportSchedule>) => void
  onUpdate?: (values: Omit<ReportSchedule, 'reportScheduleId' | 'reportName'>) => void
  onDelete?: () => void
  onClose?: () => void
}

export default function Form ({
  mode,
  initialValues,
  isLoading,
  onCreate,
  onUpdate,
  onDelete,
  onClose
}: Props) {
  const navigate = useNavigate()
  const [showDelete, setShowDelete] = useState(false)

  const inCreateMode = mode === 'CREATE'
  const inEditMode = mode === 'EDIT'
  const inputColProps: ColProps = {
    span: 12,
    sm: inCreateMode
      ? 12
      : 6
  }

  const today = dayjs().startOf('D')

  const form = useForm<FormProps>({
    initialValues: {
      reportId: undefined,
      reportScheduleType: undefined,
      scheduleDay: undefined,
      scheduleStartDate: today.format(dateFormat),
      scheduleEnabled: true,
      orgUsers: []
    },
    validate: {
      reportId: value => !value
        ? 'Please specify a report type'
        : null,
      reportScheduleType: value => !value
        ? 'Please specify a schedule type'
        : null,
      orgUsers: value => value.length < 1
        ? 'Please provide at least 1 recepient'
        : null
    }
  })

  type FormValues = typeof form.values

  const redirectToListPage = () => navigate('/reports/scheduled')

  const getBody = useCallback((values: FormValues) => {
    const {
      scheduleStartDate,
      orgUsers
    } = values
    return {
      ...values,
      // converts to utc
      scheduleStartDate: toUtc(scheduleStartDate) + 'Z',
      orgUsers: orgUsers.map(id => ({ orgUserId: id }))
    } as ReportSchedule
  }, [])

  const handleCreate = (values: FormValues) => {
    const req = getBody(values)
    if (onCreate) {
      onCreate(req)
    }
  }

  const handleUpdate = (values: FormValues) => {
    const req = getBody(values)
    if (onUpdate) {
      onUpdate(req)
    }
  }

  const handleDelete = () => {
    if (onDelete) {
      void onDelete()
    }
  }

  const handleClose = () => {
    form.reset()

    if (onClose) onClose()
  }

  useEffect(() => {
    if (initialValues?.orgUsers) {
      const { orgUsers } = initialValues
      form.setValues({
        ...initialValues,
        orgUsers: orgUsers.map(({ orgUserId }) => orgUserId)
      } as never as FormProps)
    }
  }, [initialValues])

  return (
    <>
      <form onSubmit={form.onSubmit(mode === 'CREATE'
        ? handleCreate
        : handleUpdate)}
      >
        <Grid grow>
          <Col {...inputColProps}>
            <Stack spacing="md">
              <SelectReport
                required
                {...form.getInputProps('reportId')}
                isMain
                value={form.values.reportId}
                onChange={(type) => form.setFieldValue('reportId', type?.id)}
              />
              <SelectScheduleType
                withAsterisk
                {...form.getInputProps('reportScheduleType')}
                label="Schedule type"
                placeholder="Select schedule type"
                value={form.values.reportScheduleType}
                onChange={(type) => {
                  if (type !== 'SPECIFIC_DAY_OF_WEEK') {
                    form.setFieldValue('scheduleDay', undefined)
                  }
                  form.setFieldValue('reportScheduleType', type ?? undefined)
                }}
              />
              {form.values.reportScheduleType === 'SPECIFIC_DAY_OF_WEEK' && (
                <Stack spacing="xs">
                  <Text size="xs">Schedule day</Text>
                  <SegmentedControl
                    radius="xl"
                    onChange={(day: ScheduleDay) => form.setFieldValue('scheduleDay', day)}
                    data={scheduleDay.map(day => ({
                      label: day[0],
                      value: day
                    }))}
                  />
                </Stack>
              )}
              <MultiSelectUser
                withAsterisk
                {...form.getInputProps('orgUsers')}
                searchable
                label="Recepient"
                placeholder="Select users"
                value={form.values.orgUsers
                  ? form.values.orgUsers.map(userId => userId.toString())
                  : []}
                onChange={(value) => form.setFieldValue('orgUsers', value
                  ? value.map((id) => Number(id))
                  : [])}
              />
              <DatePickerInput
                required
                label="Start date"
                minDate={today.toDate()}
                labelProps={{
                  size: 'xs'
                }}
                value={form.values.scheduleStartDate
                  ? dayjs(form.values.scheduleStartDate).toDate()
                  : undefined}
                onChange={date => form.setFieldValue('scheduleStartDate', date
                  ? dayjs(date).format(dateFormat)
                  : undefined)}
              />
              <Switch
                label="Enabled"
                color="primary"
                checked={form.values.scheduleEnabled}
                onChange={(e) => form.setFieldValue('scheduleEnabled', e.currentTarget.checked)}
              />
            </Stack>
          </Col>
          <Col {...inputColProps} />
          {inEditMode && (
            <Col span={6}>
              <Group>
                <Button
                  mt="sm"
                  color="red"
                  leftIcon={<FontAwesomeIcon icon={['fas', 'trash']} color="white" />}
                  onClick={() => setShowDelete(true)}
                >
                  Delete
                </Button>
              </Group>
            </Col>
          )}
          <Col
            span={inCreateMode
              ? 12
              : 6}
          >
            <Group position="right" mt="sm">
              <Button
                variant="subtle"
                color="dark"
                disabled={isLoading}
                onClick={inCreateMode
                  ? handleClose
                  : redirectToListPage}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="green"
                leftIcon={(
                  <FontAwesomeIcon
                    icon={[
                      'fas',
                      inCreateMode
                        ? 'plus'
                        : 'floppy-disk'
                    ]}
                    color="white"
                  />
                )}
                loading={isLoading}
              >
                {inCreateMode
                  ? 'Create'
                  : 'Save'}
              </Button>
            </Group>
          </Col>
        </Grid>
      </form>
      {inEditMode && (
        <ConfirmModal
          type="delete"
          opened={showDelete}
          title={`
            Deleting "${initialValues?.reportScheduleType} - ${initialValues?.reportName}" automated report schedule
          `}
          loading={isLoading}
          question="Are you sure you want to permanently delete this role?"
          onClose={() => setShowDelete(false)}
          onCancel={() => setShowDelete(false)}
          onConfirm={handleDelete}
        />
      )}
    </>
  )
}
