import { type ReactNode, useEffect, useState, useCallback } from 'react'
import { type SelectProps } from '@mantine/core'
import { liquidTypes } from '@venturi-io/api/src/config/agent'
import Select from '../Select'

type Value = string | null

interface Props extends Omit<SelectProps, 'data'> {
  label?: string
  placeholder?: string
  defaultValue?: Value
  value?: Value
  onChange?: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

export default function SelectLiquidType ({
  defaultValue,
  value,
  label,
  placeholder,
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const [liquidType, setLiquidType] = useState<Value>(null)

  const handleChange = useCallback((val: Value) => {
    setLiquidType(val)

    if (onChange) {
      onChange(val)
    }
  }, [onChange])

  useEffect(() => {
    if (defaultValue) {
      setLiquidType(defaultValue)
    }
  }, [defaultValue])

  return (
    <Select
      label={label}
      placeholder={placeholder ?? 'Choose a liquid type'}
      data={liquidTypes}
      defaultValue={defaultValue}
      value={value ?? liquidType}
      onChange={handleChange}
      required={required}
      searchable={searchable}
      disabled={disabled}
      error={error}
      {...props}
    />
  )
}
