import { type CSSProperties } from 'react'
import { type ThemeIconProps, ThemeIcon } from '@mantine/core'
import { IconAlertTriangle } from '@tabler/icons-react'
import colors from 'src/AssetTemplate/colors'

interface Props {
  size: ThemeIconProps['size']
  alarmColor: CSSProperties['color']
}

export default function AlarmIndicator ({ size, alarmColor }: Props) {
  return (
    <ThemeIcon
      size={size}
      p={4}
      color={alarmColor}
      radius={2}
    >
      <IconAlertTriangle
        size={20}
        color={alarmColor === colors.red
          ? 'white'
          : 'black'}
      />
    </ThemeIcon>
  )
}
