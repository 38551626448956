import {
  createStyles,
  useMantineTheme,
  Box,
  Group,
  Paper,
  Stack,
  Text
} from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import Bar from 'src/Charts/Bar'
import AlarmIndicator from 'src/AssetTemplate/Components/AlarmIndicator'
import { convertStrToFloat, getSensorValue } from 'src/AssetTemplate/shared'
import { getTextColorAsProp } from 'src/utils/theme'
import colors from 'src/AssetTemplate/colors'

const useStyles = createStyles(() => ({
  container: {
    position: 'relative',
    minWidth: 0
  },
  barContainer: {
    flex: 1,
    position: 'relative',
    minWidth: 0
  },
  label: {
    whiteSpace: 'nowrap'
  },
  alarmIndicatorContainer: {
    position: 'absolute',
    left: -28,
    bottom: -10
  },
  tooltip: {
    background: '#212121',
    color: '#FFFFFF'
  }
}))

interface Props {
  label: string
  sensor: Sensor | null
}

export default function CurrentBar ({ label, sensor }: Props) {
  const { classes } = useStyles()
  const { colorScheme } = useMantineTheme()
  const isLight = colorScheme === 'light'
  const value = convertStrToFloat(sensor?.currentValue)
  const maxValue = 250 // TODO: Set max vale based on metadata once ready in BE
  const remainingValue = maxValue - value
  const markerStroke = isLight
    ? 'gray'
    : 'white'
  const markers = [
    {
      axis: 'x',
      value,
      lineStyle: {
        stroke: markerStroke,
        strokeWidth: 3
      },
      legend: '',
      legendOrientation: 'vertical',
      legendOffsetX: 0,
      legendOffsetY: 0
    }
  ]
  const withAlarm = sensor?.alarmStatus === 'ALARM'
  const barColor = withAlarm
    ? colors.red
    : colors.green
  const disabled = sensor === null

  return (
    <Stack className={classes.container} spacing={0}>

      {withAlarm && (
        <Box className={classes.alarmIndicatorContainer}>
          <AlarmIndicator size="sm" />
        </Box>
      )}

      <Group position="apart" align="flex-start" spacing={8}>
        <Text
          className={classes.label}
          size={12}
          weight={500}
          w={10}
          {...getTextColorAsProp()}
        >
          {label}
        </Text>
        <Box className={classes.barContainer}>
          <Bar
            height={16}
            data={[
              {
                sensor: sensor?.name ?? '-',
                value,
                remainingValue
              }
            ]}
            keys={['value', 'remainingValue']}
            colors={[
              barColor,
              '#D9D9D9'
            ]}
            indexBy="sensor"
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }}
            padding={0.2}
            minValue={0}
            maxValue={maxValue}
            layout="horizontal"
            valueScale={{ type: 'linear' }}
            indexScale={{
              type: 'band',
              round: true
            }}
            borderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableLabel={false}
            totalsOffset={0}
            labelSkipWidth={15}
            labelSkipHeight={16}
            labelTextColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            legends={[]}
            tooltip={({ id, formattedValue }) => (id === 'value' &&
              <Paper
                px={12}
                py={8}
                radius="sm"
                shadow="sm"
                className={classes.tooltip}
              >
                <Text size={12}>
                  {formattedValue}
                </Text>
              </Paper>
            )}
            markers={markers as []}
          />
        </Box>
        <Text
          className={classes.label}
          size={12}
          weight={500}
          ml={4}
          w={20}
          color={disabled
            ? 'dimmed'
            : undefined}
        >
          {!disabled
            ? getSensorValue(sensor, true, true)
            : 'N/A'}
        </Text>
      </Group>
    </Stack>
  )
}
