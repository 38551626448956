import { createStyles, Group, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import zIndex from 'src/utils/zIndex'
import { useMeta } from 'src/MetaContext'
import TextTooltip from 'src/Layout/TextTooltip'
import { getTextColor, getWhiteBackgroundColor } from 'src/utils/theme'
import Logo from './Menu/Logo'
import { type StyleParams, styleParams } from './shared'
import ProfileMenu from './ProfileMenu'
import { CloseMenu } from './Menu/CloseMenu'

const useStyles = createStyles((theme, params: StyleParams) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'fixed',
    height: params.headerHeight,
    top: 0,
    left: 0,
    right: 0,
    zIndex: zIndex.header,
    boxShadow: '0 2px 4px 0 #00000030',
    ...getTextColor(theme),
    ...getWhiteBackgroundColor(theme)
  },
  titleContainer: {
    flex: 1,
    [mq(theme.breakpoints.sm)]: {
      paddingLeft: '82px'
    }
  },
  title: {
    fontSize: '1.4em',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    [mq(theme.breakpoints.sm)]: {
      textAlign: 'left'
    },
    ...getTextColor(theme)
  },
  mobileTitle: {
    fontSize: '1.1em',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    [mq(theme.breakpoints.sm)]: {
      textAlign: 'left'
    },
    ...getTextColor(theme)
  }
}))

export interface Props {
  orgId: number
  show: boolean
  signout: () => void
  setShow: (input: boolean) => void
}

export default function Header (props: Props) {
  const { classes } = useStyles({ ...styleParams })
  const { meta } = useMeta()
  const theme = useMantineTheme()
  const isDesktop = useMediaQuery(mq(theme.breakpoints.sm, false))
  const isTablet = useMediaQuery(mq(theme.breakpoints.md, false))

  return (
    <header className={classes.header}>
      <div className={classes.titleContainer}>
        <Group position={isDesktop ? 'apart' : 'left'} spacing="sm" grow={isDesktop}>
          {!isDesktop && <Logo orgId={props.orgId} minimised isDesktop={isDesktop} />}
          {!isTablet
            ? <TextTooltip
                className={classes.mobileTitle}
                value={meta.pageTitle}
                Icon={meta.pageIcon}
                maxLength={20}
              />
            : <TextTooltip
                className={classes.title}
                value={meta.pageTitle}
                Icon={meta.pageIcon}
                maxLength={35}
              />}
          {isDesktop
            ? <ProfileMenu signout={props.signout} />
            : <CloseMenu {...props} showLabel={isDesktop} />}
        </Group>
      </div>
    </header>
  )
}
