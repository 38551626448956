import { Box, Text, createStyles } from '@mantine/core'

interface Props {
  fuelCapacityLiter: number
  fuelLevelPercent: number
  currentFuelLevelLiter: number
}

const useStyles = createStyles(() => ({
  container: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    pointerEvents: 'none'
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '17px',
    position: 'absolute'
  }
}))

export default function MarkerAnnotationCurrentLevel ({
  fuelLevelPercent,
  currentFuelLevelLiter
}: Props) {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <Text
        className={classes.label}
        style={{
          bottom: `${fuelLevelPercent - 2.5}%`
        }}
      >
        {`${fuelLevelPercent}% • ${currentFuelLevelLiter} L`}
      </Text>
    </Box>
  )
}
