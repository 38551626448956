import { Text } from '@mantine/core'
import { useStyles } from 'src/Layout/ActionList'

const Header = () => {
  const { classes, cx } = useStyles()

  return (
    <th className={cx(classes.row, classes.header)}>
      <td className={classes.col}>
        <Text weight={600} size="sm">
          Report Name
        </Text>
      </td>
      <td className={classes.col}>
        <Text weight={600} size="sm">
          Schedule Type
        </Text>
      </td>
      <td className={classes.col}>
        <Text weight={600} size="sm">
          Schedule Day
        </Text>
      </td>
      <td className={classes.col}>
        <Text weight={600} size="sm">
          Start Date
        </Text>
      </td>
      <td className={classes.col}>
        <Text weight={600} size="sm">
          Last Schedule Run
        </Text>
      </td>
      <td className={classes.col}>
        <Text weight={600} size="sm">
          Enabled
        </Text>
      </td>
      <td className={cx(classes.col, classes.actionsCol)}>
        <Text weight={600} size="sm">
          Action
        </Text>
      </td>
    </th>
  )
}

export default Header
