import { Select } from '@mantine/core'
import { useSelectStyles } from './styles'

const items = {
  '1 hour': {
    timeBucket: '5 minutes',
    noOfRecords: 12
  },
  '4 hours': {
    timeBucket: '15 minutes',
    noOfRecords: 16
  },
  '8 hours': {
    timeBucket: '15 minutes',
    noOfRecords: 32
  },
  '12 hours': {
    timeBucket: '1 hour',
    noOfRecords: 12
  },
  '24 hours': {
    timeBucket: '1 hour',
    noOfRecords: 24
  },
  '3 days': {
    timeBucket: '2 hours',
    noOfRecords: 36
  },
  '7 days': {
    timeBucket: '4 hours',
    noOfRecords: 42
  },
  '30 days': {
    timeBucket: '12 hours',
    noOfRecords: 60
  },
  '90 days': {
    timeBucket: '1 day',
    noOfRecords: 90
  }
}

type Items = typeof items

export type ItemKeys = keyof Items

export const getNoOfRecordOnMinutesPerBucketKey = (key: ItemKeys) => {
  const minutesPerHour = 60
  const minutesPerDay = minutesPerHour * 24
  let value = 0
  switch (key) {
    case '4 hours':
      value = minutesPerHour * 4
      break
    case '12 hours':
      value = minutesPerHour * 12
      break
    case '24 hours':
      value = minutesPerDay
      break
    case '3 days':
      value = minutesPerDay * 3
      break
    case '7 days':
      value = minutesPerDay * 7
      break
    case '30 days':
      value = minutesPerDay * 30
      break
    case '90 days':
      value = minutesPerDay * 90
      break
    default:
      value = minutesPerHour
      break
  }
  return value
}

export interface ChosenBucket<K extends ItemKeys> {
  item: K
  data: Items[K]
}

export interface Props {
  label?: string
  required?: boolean
  chosenBucket: ChosenBucket<ItemKeys>
  updateBucket: (input: ChosenBucket<ItemKeys>) => void
}

export default function TimePeriodSelector ({
  label,
  required = false,
  chosenBucket,
  updateBucket
}: Props) {
  const { classes } = useSelectStyles()

  return (
    <Select
      withinPortal
      required={required}
      classNames={classes}
      label={label}
      placeholder="Choose one"
      value={chosenBucket.item}
      data={Object.keys(items).map(key => ({
        value: key,
        label: `Last ${key}`
      }))}
      onChange={(key: ItemKeys) => updateBucket({
        item: key,
        data: items[key]
      })}
    />
  )
}
