import { type SetStateAction, useCallback, useEffect, useState } from 'react'
import { createStyles, Box, Stack } from '@mantine/core'
import Header from './Header'
import Procedure, { type FormProps } from './Procedure'
import Controls from './Controls'

const useStyles = createStyles(() => ({
  container: {
    position: 'absolute',
    right: 0,
    top: 44,
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 17.22447395324707px 0px'
  }
}))

interface Props {
  formData: FormProps
  setFormData: (value: SetStateAction<FormProps>) => void
  onCreate: () => void
  onDelete: () => void
}

export default function NewGeoZoneWizard ({ setFormData, onCreate, onDelete }: Props) {
  const { classes } = useStyles()
  const steps = 4
  const [step, setStep] = useState(0)
  const [hasError, setHasError] = useState(false)

  const nextStep = useCallback(() => {
    if (!hasError) {
      setStep(current => (
        current < steps
          ? current + 1
          : current
      ))

      // this will trigger API calls for creating geozone, groups, rule, and actions
      if (step === steps) {
        onCreate()
      }
    }
  }, [steps, step, setStep, hasError])

  const prevStep = useCallback(() => {
    if (!hasError) {
      setStep(current => (
        current > 0
          ? current - 1
          : current
      ))
    }
  }, [setStep, hasError])

  useEffect(() => {
    // focus to current step if there is validation error
    if (hasError) {
      setStep(current => current - 1)
    }
  }, [hasError])

  return (
    <Box className={classes.container}>
      <Stack align="flex-start" spacing={0}>
        <Header />
        <Procedure
          step={step}
          setFormData={setFormData}
          setHasError={setHasError}
        />
        <Controls
          currentStep={step}
          lastStep={steps}
          prevStep={prevStep}
          nextStep={nextStep}
          onDelete={onDelete}
        />
      </Stack>
    </Box>
  )
}
