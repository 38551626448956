import { useEffect, useRef, useState } from 'react'
import { JsonEditor as Editor } from 'jsoneditor-react'
import { Stack } from '@mantine/core'
import { type Json } from '@venturi-io/api'
import styled from '@emotion/styled'

const StyledContainer = styled.div`
  & .jsoneditor {
      border-color: ${({ theme }) => theme.colors.primary[6]};
  }
  & div.jsoneditor-menu {
      background-color: ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.primary[6]
        : theme.colors.dark[6]
      };
      border-bottom: 1px solid ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.gray[6]
        : theme.colors.dark[2]
      };
  }
  & .jsoneditor-navigation-bar {
      background-color: ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.gray[1]
        : theme.colors.dark[3]
      };
      color: ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.gray[6]
        : theme.colors.gray[2]
      };
      border-bottom: 1px solid ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.gray[6]
        : theme.colors.dark[2]
      };
  }
  & .jsoneditor-frame,
    textarea.jsoneditor-text,
    .jsoneditor-frame > input {
      background-color: ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.white[0]
        : theme.colors.dark[8]
      };
      color: ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.dark[6]
        : theme.colors.gray[2]
      };
  }
  & .jsoneditor-text {
      color: ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.dark[8]
        : theme.colors.gray[0]
      };
  }
  & .jsoneditor-tree {
      background-color: ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.white[0]
        : theme.colors.dark[5]
      };
      color: ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.dark[8]
        : theme.colors.gray[0]
      };
  }
  & .jsoneditor-tree-inner {
      color: ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.gray[6]
        : theme.colors.gray[0]
      };
  }
  & .jsoneditor-field, .jsoneditor-value {
      color: ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.gray[9]
        : theme.colors.white[0]
      };
  }
  & .jsoneditor-string {
      color: ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.green[7]
        : theme.colors.green[5]
      } !important;
  }
  & .jsoneditor-number {
      color: ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.red[7]
        : theme.colors.red[3]
      } !important;
  }
  & .jsoneditor-value[contenteditable=true]:hover,
    .jsoneditor-value[contenteditable=true]:focus,
    .jsoneditor-field[contenteditable=true]:hover,
    .jsoneditor-field[contenteditable=true]:focus {
      background-color: ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.yellow[0]
        : theme.colors.gray[8]
      };
  }
  & .jsoneditor-field.jsoneditor-highlight-active,
    .jsoneditor-value.jsoneditor-highlight-active {
      background-color: ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.yellow[0]
        : theme.colors.yellow[8]
      };
  }
  & .jsoneditor-statusbar {
      background-color: ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.gray[0]
        : theme.colors.dark[6]
      };
      border-top: 1px solid ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.gray[6]
        : theme.colors.dark[2]
      };
  }
  & .jsoneditor-modal {
      background-color: ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.white[0]
        : theme.colors.dark[5]
      };
      color: ${({ theme }) => theme.colorScheme === 'light'
        ? theme.colors.dark[8]
        : theme.colors.gray[0]
      };
  }
  & .jsoneditor-highlight {
    background-color: ${({ theme }) => theme.colorScheme === 'light'
      ? theme.colors.gray[2]
      : theme.colors.dark[8]
    };
  }
  & .jsoneditor-highlight,
    .jsoneditor-highlight .jsoneditor-tree,
    .jsoneditor-menu button:hover,
    .jsoneditor-menu button:focus,
    .jsoneditor-menu li ul li button:hover,
    .jsoneditor-menu li ul li button:focus {
    background-color: ${({ theme }) => theme.colorScheme === 'light'
      ? theme.colors.gray[2]
      : theme.colors.dark[7]
    };
  }
  & .jsoneditor-menu button:hover .jsoneditor-text {
    color: ${({ theme }) => theme.colorScheme === 'light'
      ? theme.colors.gray[8]
      : theme.colors.dark[0]
    };
  }
  & ul.jsoneditor-menu {
    background-color: ${({ theme }) => theme.colorScheme === 'light'
      ? theme.colors.gray[2]
      : theme.colors.dark[8]
    };
  }
`

interface Props {
  json: Json
  isRaw: boolean
  onChangeJson: (json: Json) => void
}

export default function JsonEditor ({ json, isRaw, onChangeJson }: Props) {
  const editorRef = useRef<Editor>(null)
  const [initial, setInitial] = useState(true)

  useEffect(() => {
    if (json && initial) {
      setTimeout(() => {
        if (editorRef.current && !isRaw) {
          editorRef.current.expandAll()
        }
      }, 500)
      setInitial(false)
    }
  }, [json, editorRef.current, isRaw, initial])

  return (
    <Stack>
      <StyledContainer>
        <Editor
          ref={editorRef}
          mode={isRaw ? 'code' : 'tree'}
          value={json}
          onChange={onChangeJson}
          htmlElementProps={{ style: { height: 'calc(100vh - 250px)' } }}
        />
      </StyledContainer>
    </Stack>
  )
}
