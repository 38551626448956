import { Accordion, createStyles, Group, Text } from '@mantine/core'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { wrapWithNeedsRole } from 'src/NeedsRole'
import {
  getNonThemePrimaryBg,
  getPrimaryTextColor,
  getTextColor,
  getWhiteBackgroundColor
} from 'src/utils/theme'
import MenuItem, { type MenuItemProps } from './MenuItem'
import TooltipWrapper from './TooltipWrapper'

interface StyleParams {
  isSamePath: boolean
}

const useDropdownStyles = createStyles((theme, { isSamePath }: StyleParams) => ({
  dropDownMenuContainer: {
    cursor: 'pointer',
    ...getWhiteBackgroundColor(theme),
    '&:hover': {
      ...getWhiteBackgroundColor(theme)
    }
  },
  dropDownMenuItemContainer: {
    border: '0px !important',
    '&:hover': {
      ...getWhiteBackgroundColor(theme)
    }
  },
  dropDownMenuItemTitle: {
    fontWeight: 400,
    fontSize: '0.9rem',
    paddingLeft: 15
  },
  hidden: {
    display: 'none'
  },
  control: {
    paddingLeft: 18,
    ...(isSamePath
      ? getNonThemePrimaryBg(theme)
      : getWhiteBackgroundColor(theme)),
    ...(isSamePath
      ? getPrimaryTextColor(theme)
      : getTextColor(theme)),
    '&:hover': {
      backgroundColor: theme.colors.primary[7],
      ...getPrimaryTextColor(theme)
    }
  }
}))

export default function DropdownMenu ({
  item: {
    title,
    path,
    Icon,
    selector,
    children
  },
  open,
  isSidebarOpen,
  setSidebarOpen,
  closeMenu
}: MenuItemProps) {
  const [isOpen, setIsOpen] = useState(open ?? false)
  const location = useLocation()
  const isSamePath = location.pathname.includes(path)
  const { classes } = useDropdownStyles({ isSamePath })

  useEffect(() => {
    if (isOpen && setSidebarOpen) {
      setSidebarOpen(true)
    }
  }, [isOpen])

  useEffect(() => {
    if (!isSidebarOpen) {
      setIsOpen(false)
    }
  }, [isSidebarOpen])

  if (children.length === 0) return null

  const label = (
    <Group spacing="xs" position="left">
      <Icon id={selector} size={24} />
      <Text className={classes.dropDownMenuItemTitle}>
        {title}
      </Text>
    </Group>
  )

  return (
    <TooltipWrapper label={title} isSidebarOpen={isSidebarOpen}>
      <div
        id={`btn-${selector}`}
        className={classes.dropDownMenuContainer}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Accordion
          key={title}
          value={isOpen ? title.toLowerCase() : ''}
          classNames={{
            control: classes.control
          }}
        >
          <Accordion.Item
            value={title.toLowerCase()}
            className={classes.dropDownMenuItemContainer}
          >
            <Accordion.Control>{label}</Accordion.Control>
            <Accordion.Panel>
              {children.map(child => wrapWithNeedsRole(child.role,
                <MenuItem
                  key={`${title}-${child.title}`}
                  item={child}
                  closeMenu={closeMenu}
                  isSidebarOpen={isSidebarOpen}
                />
              ))}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </div>
    </TooltipWrapper>
  )
}
