import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Stack,
  Group,
  Text,
  createStyles,
  Tooltip
} from '@mantine/core'
import dayjs from 'dayjs'
import { type ReactNode, type Dispatch, type SetStateAction } from 'react'
import { Popup as MapPopup } from 'react-map-gl'
import { dateFormat, uiDateFormat } from 'src/utils/dates'
import { type AgentData } from '@venturi-io/api/src/collector/agent'
import { getWhiteBackgroundColor } from 'src/utils/theme'
import { type RawPointSourceProps } from '../../mapbox'
import type mapboxgl from 'mapbox-gl'

const useStyles = createStyles((theme) => ({
  pin: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    border: '1px solid #fff',
    borderRadius: '100%',
    width: 12,
    height: 12,
    color: 'red',
    fontWeight: 500,
    cursor: 'pointer',
    '&::before': {
      content: "''",
      position: 'absolute',
      left: -11,
      top: -11,
      width: 34,
      height: 34
    }
  },
  special: {
    width: '30px !important',
    height: '30px !important',
    color: 'black',
    backgroundColor: '#fff !important',
    boxShadow: `0 6px 7px ${theme.colors.gray[6]}`,
    '&::before': {
      content: "''",
      position: 'absolute',
      left: -15,
      top: -15,
      width: 34,
      height: 34
    }
  },
  hasDirection: {
    width: 23,
    height: 23,
    '&::before': {
      content: "''",
      position: 'absolute',
      left: -6,
      top: -6,
      width: 34,
      height: 34
    }
  },
  popup: {
    zIndex: 2,
    '& .mapboxgl-popup-content': {
      ...getWhiteBackgroundColor(theme)
    },
    '& .mapboxgl-popup-tip': {
      borderTopColor: `${theme.colorScheme === 'light'
        ? theme.colors.white[0]
        : theme.colors.dark[7]} !important`
    }
  },
  direction: {
    backgroundColor: theme.colors.gray[8],
    borderRadius: '10px',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export interface DataProps {
  coordinates: number[]
  timestamp: string
  position: number
  agentId: number
  pathId: string
  color: string
}

export interface PositionDetails {
  details?: AgentData
  coordinates?: number[]
}

interface PopupProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>
  properties: RawPointSourceProps | null
  isOpen: boolean
  map: mapboxgl.Map | null
}

interface LabelValueProps {
  label: string
  value: string | number
  icon?: ReactNode
}

function LabelValue ({ label, value, icon }: LabelValueProps) {
  return (
    <Group position="apart">
      <Text>
        {label}
        :
      </Text>
      <Group spacing={4}>
        <Text weight="bold">{value}</Text>
        {icon && icon}
      </Group>
    </Group>
  )
}

function Popup ({
  isOpen,
  setIsOpen,
  properties
}: PopupProps) {
  const { classes } = useStyles()
  if (!properties) return null

  const {
    time,
    longitude,
    latitude,
    color,
    position,
    altitude,
    direction,
    satellites,
    xAcceleration,
    zAcceleration,
    yAcceleration,
    speed,
    speedLimit,
    overSpeeding,
    idlingDuration,
    engineIgnitionStatus
  } = properties
  return isOpen
    ? (
      <MapPopup
        key={time}
        closeButton
        closeOnClick={false}
        latitude={latitude ?? 0}
        longitude={longitude ?? 0}
        anchor="bottom"
        className={classes.popup}
        onClose={() => setIsOpen(false)}
        style={{ zIndex: 95 }}
      >
        <Stack spacing={4} pt="md" pb="xs" px="xs">
          <Group position="apart">
            {position === 0 && (
              <FontAwesomeIcon
                size="lg"
                icon={['fas', 'circle-play']}
                color={color}
              />
            )}
            {position === length - 1 && (
              <FontAwesomeIcon
                size="lg"
                icon={['fas', 'flag-checkered']}
                color={color}
              />
            )}
            <Text weight="bold">
              {dayjs(time, dateFormat).format(uiDateFormat)}
            </Text>
          </Group>
          {altitude && (
            <LabelValue
              label="Altitude"
              value={altitude}
            />
          )}
          {direction && (
            <LabelValue
              label="Direction"
              value={direction}
            />
          )}
          {satellites && (
            <LabelValue
              label="Satellites"
              value={satellites}
            />
          )}
          {xAcceleration && (
            <LabelValue
              label="X Acceleration"
              value={xAcceleration}
            />
          )}
          {yAcceleration && (
            <LabelValue
              label="Y Acceleration"
              value={yAcceleration}
            />
          )}
          {zAcceleration && (
            <LabelValue
              label="Z Acceleration"
              value={zAcceleration}
            />
          )}
          {idlingDuration && (
            <LabelValue
              label="Idling"
              value={`${Math.round(idlingDuration / 60)} min`}
            />
          )}
          {speed !== undefined && (
            <LabelValue
              label="Speed"
              value={`${speed} kph`}
              icon={overSpeeding
                ? (
                  <Tooltip
                    withinPortal
                    label={`Exceeded speed limit: ${speedLimit} kph`}
                    position="right"
                    withArrow
                  >
                    <span className="fa-layers fa-fw fa-lg">
                      <FontAwesomeIcon color="red" icon={['fas', 'triangle']} />
                      <FontAwesomeIcon color="yellow" icon={['fas', 'exclamation-triangle']} />
                    </span>
                  </Tooltip>
                  )
                : null}
            />
          )}
          {engineIgnitionStatus !== undefined && (
            <LabelValue
              label="Engine Ignition Status"
              value={engineIgnitionStatus ? 'ON' : 'OFF'}
            />
          )}
        </Stack>
      </MapPopup>
      )
    : null
}

export default Popup
