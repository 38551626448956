/* eslint-disable import/order */
import { useEffect } from 'react'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import ActionList from 'src/Layout/ActionList'
import { Header, Row, Card } from './List'
import { getGeneratedReports } from '@venturi-io/api/src/config/report'

export default function Generated () {
  const { token } = useUser()
  const generated = usePaginatedApi(getGeneratedReports)

  const loadSchedules = async (page?: number, size?: number): Promise<void> => {
    void generated
      .fetch({
        page: page ?? generated.page,
        size: size ?? generated.size
      }, token)
  }

  useEffect(() => {
    void loadSchedules(1)
  }, [])

  useEffect(() => {
    void loadSchedules()
  }, [generated.page])

  return (
    <ActionList
      isLoading={generated.loading}
      data={generated.data.mapOrDefault(({ items }) => items, [])}
      getId={({ generatedReportId }) => generatedReportId}
      header={() => <Header />}
      row={Row}
      card={Card}
      totalPages={generated.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
      page={generated.page}
      onPageChange={generated.setPage}
    />
  )
}
