import { type ReactNode } from 'react'
import { Collapse } from '@mantine/core'

interface Props {
  children?: ReactNode
  active?: boolean
}

export default function ItemContentWrapper ({ children, active = false }: Props) {
  return (
    <Collapse
      mt={20}
      mb={28}
      in={active}
    >
      {children}
    </Collapse>
  )
}
