import { useCallback, useState } from 'react'
import {
  createStyles,
  Collapse,
  Divider,
  Group,
  Paper,
  Stack
} from '@mantine/core'
import { IconBellOff, IconScale } from '@tabler/icons-react'
import { type GeoZoneRule } from '@venturi-io/api/src/config/geoZoneRule'
import TextTooltip from 'src/Layout/TextTooltip'
import dayjs from 'dayjs'
import { getBackgroundColor } from 'src/utils/theme'
import Detail from '../Detail'
import Actions from './Actions'

const useStyles = createStyles(theme => ({
  container: {
    cursor: 'pointer',
    ...getBackgroundColor(theme, -2),
    '&:hover': {
      ...getBackgroundColor(theme, -1)
    },
    overflowX: 'hidden',
    transition: 'all .3s ease'
  }
}))

interface Props {
  rule: GeoZoneRule
  onSelect: (rule: GeoZoneRule) => void
  onClickEdit?: (ruleId: GeoZoneRule['geoZoneRuleId']) => void
  onSuccessDelete?: (ruleId: GeoZoneRule['geoZoneRuleId']) => void
}

export default function Item ({
  rule,
  onSelect,
  onClickEdit,
  onSuccessDelete
}: Props) {
  const { classes } = useStyles()
  const [showDetails, setShowDetails] = useState(false)
  const {
    geoZoneRuleId,
    name,
    description,
    type,
    numericValue,
    timezone,
    startDay,
    startTime,
    endDay,
    endTime,
    quietMode
  } = rule

  const handleClick = useCallback(() => {
    onSelect(rule)
    setShowDetails(!showDetails)
  }, [rule, showDetails])

  return (
    <Paper
      key={geoZoneRuleId}
      className={classes.container}
      p={16}
      shadow="xs"
      radius={8}
      onClick={handleClick}
    >
      <Group position="apart" align="center">
        <Group spacing={8}>
          <IconScale
            size={20}
            stroke={1.5}
            color="#2998F0"
          />
          <TextTooltip
            value={name}
            size={12}
            maxLength={25}
          />
          {quietMode && (
            <IconBellOff
              size={16}
              stroke={1.5}
              color="silver"
            />
          )}
        </Group>
        <Actions
          ruleId={geoZoneRuleId}
          ruleName={name}
          onClickEdit={onClickEdit}
          onSuccessDelete={onSuccessDelete}
        />
      </Group>
      <Collapse in={showDetails}>
        <Divider
          mt={16}
          mb={8}
          variant="dotted"
        />
        <Stack p={4} spacing={8}>
          {description && (
            <Detail label="Description" value={description} />
          )}
          <Detail label="Type" value={type} />
          {numericValue && (
            <Detail label="Numeric Value" value={numericValue} />
          )}
          {timezone && (
            <Detail label="Time Zone" value={timezone} />
          )}
          {startDay && startTime && (
            <Detail
              label="Starts at"
              value={`${startDay} • ${dayjs(`1/1/2001 ${startTime}`).format('h:mm A')}`}
            />
          )}
          {endDay && endTime && (
            <Detail
              label="Ends at"
              value={`${endDay} • ${dayjs(`1/1/2001 ${endTime}`).format('h:mm A')}`}
            />
          )}
        </Stack>
      </Collapse>
    </Paper>
  )
}
