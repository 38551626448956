import { type Alarm } from '@venturi-io/api/src/analytics/alarm'
import { createStyles, Box } from '@mantine/core'
import MarkerAnnotationDetails from './MarkerAnnotationDetails'

interface Props {
  fuelLevelPercentAlarms: Alarm[]
  fuelCapacityLiter: number
}

const useStyles = createStyles(() => ({
  container: {
    position: 'absolute',
    top: 0,
    left: '8px',
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1
  }
}))

export default function PercentMarkerAnnotations ({
  fuelLevelPercentAlarms,
  fuelCapacityLiter
}: Props) {
  const { classes } = useStyles()
  const alarmsPositions = fuelLevelPercentAlarms.map(({ setPoint }) => setPoint)

  return (
    <Box h="100%" className={classes.container}>
      {fuelLevelPercentAlarms
        .map(({
          alarmId,
          name,
          setPoint: alarmPosition
        }) => {
          const isCriticalAlarm = Math.min(...alarmsPositions) === alarmPosition
          const literValue = Math.round((alarmPosition / 100) * fuelCapacityLiter)

          return (
            <MarkerAnnotationDetails
              key={alarmId}
              alarmId={alarmId}
              alarmName={name}
              percentValue={alarmPosition}
              literValue={literValue}
              isCriticalAlarm={isCriticalAlarm}
            />
          )
        })
        .sort((a, b) => Number(b.key) - Number(a.key))}
    </Box>
  )
}
