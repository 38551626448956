import { Box, Text } from '@mantine/core'
import { getTextColorAsProp } from 'src/utils/theme'

interface Props {
  label: string
  value: string | number
}

export default function Detail ({ label, value }: Props) {
  return (
    <Box>
      <Text size={8} weight={400} color="dimmed">
        {label}
      </Text>
      <Text
        size={12}
        {...getTextColorAsProp()}
      >
        {value}
      </Text>
    </Box>
  )
}
