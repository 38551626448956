import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Group,
  type MantineSize,
  Text,
  Stack,
  createStyles,
  useMantineTheme,
  ActionIcon,
  Tooltip,
  Collapse,
  SimpleGrid,
  getStylesRef
} from '@mantine/core'
import dayjs from 'dayjs'
import { type ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { uiDateFormatUniversal } from 'src/utils/dates'
import { truncateWithEllipsis } from 'src/utils/strings'
import { getToggleableColorGBW } from 'src/utils/theme'

const useStyle = createStyles(() => ({
  stack: {
    cursor: 'pointer',
    transition: 'all ease .2s',
    '&:hover': {
      transform: 'translate(0,-2px)'
    },
    '&:active': {
      transform: 'translate(0,-1px)'
    }
  },
  group: {
    cursor: 'pointer',
    // reference button with nested selector
    [`&:hover .${getStylesRef('textRef')}`]: {
      transform: 'translate(0,-2px)'
    },
    [`&:active .${getStylesRef('textRef')}`]: {
      transform: 'translate(0,-1px)'
    }
  },
  text: {
    // assign reference to selector
    transition: 'all ease .2s',
    ref: getStylesRef('textRef')
  }
}))

interface LabelValueProps {
  label: string
  value?: string | number
  link?: string
  size?: MantineSize
  push?: boolean
  forceReload?: boolean
  closeDrawer?: () => void
}

export function LabelValue ({
  label,
  value,
  link,
  size = 'sm',
  push = false,
  forceReload,
  closeDrawer
}: LabelValueProps) {
  const navigate = useNavigate()
  const { classes } = useStyle()

  const handleClick = () => {
    if (link) {
      navigate(link, { replace: !push })
      if (!forceReload && closeDrawer) {
        closeDrawer()
      } else {
        window.location.reload()
      }
    }
  }

  return (
    <Stack
      spacing={0}
      className={classes.stack}
      onClick={handleClick}
    >
      <Text weight={500} color="dimmed" size={size}>
        {label}
        :
      </Text>
      <Group spacing={4}>
        <Text size={size}>{value ?? '--'}</Text>
      </Group>
    </Stack>
  )
}

interface HeadingProps {
  heading: string | ReactNode
  show: boolean
  link?: string
  showClear?: boolean
  forceReload?: boolean
  closeDrawer?: () => void
  onClear?: () => void
  toggle: () => void
}

export function NotificationHeading ({
  heading,
  show,
  showClear,
  onClear,
  toggle
}: HeadingProps) {
  const { classes } = useStyle()
  const { colors } = useMantineTheme()
  const truncated = typeof heading === 'string'
    ? truncateWithEllipsis(heading, 45)
    : heading

  return (
    <Group
      className={classes.group}
      onClick={toggle}
      p="xs"
      position="apart"
      bg={getToggleableColorGBW(show)}
    >
      <Group
        spacing={8}
        className={classes.text}
      >
        <FontAwesomeIcon
          size="sm"
          color={showClear
            ? '#FAB005'
            : colors.primary[4]}
          icon={['fas', 'exclamation-triangle']}
        />
        {typeof truncated === 'string'
          ? (
            <Text size="xs">
              {truncated}
            </Text>
            )
          : truncated}
      </Group>
      <Group spacing="xs">
        {showClear && (
          <Tooltip
            label="Mark as read"
            withinPortal
            position="left"
          >
            <ActionIcon
              onClick={(e) => {
                e.stopPropagation()
                if (onClear) {
                  onClear()
                }
              }}
            >
              <FontAwesomeIcon
                color={colors.primary[4]}
                icon={[
                  'far',
                  'check-double'
                ]}
              />
            </ActionIcon>
          </Tooltip>
        )}
        <ActionIcon
          onClick={(e) => {
            e.stopPropagation()
            if (toggle) {
              toggle()
            }
          }}
        >
          <FontAwesomeIcon
            color={colors.primary[4]}
            icon={[
              'far',
              show
                ? 'chevron-up'
                : 'chevron-down'
            ]}
          />
        </ActionIcon>
      </Group>
    </Group>
  )
}

interface BodyProps {
  show: boolean
  timestamp: string
  children: ReactNode
}

export function NotificationBody ({
  show,
  timestamp,
  children
}: BodyProps) {
  return (
    <Collapse in={show}>
      <Stack
        p="xs"
        pt={0}
        pb="0.5rem"
      >
        <Text
          size="xs"
          color="dimmed"
        >
          {dayjs(timestamp).format(uiDateFormatUniversal)}
        </Text>
        <SimpleGrid
          cols={2}
          spacing={8}
          sx={{
            flexGrow: 1
          }}
          pb="1rem"
        >
          {children}
        </SimpleGrid>
      </Stack>
    </Collapse>
  )
}
