import { useCallback, useState } from 'react'
import { createStyles, Box } from '@mantine/core'
import CoreMap from 'src/Maps/CoreMap'
import StylePicker, { MapType } from 'src/Maps/StylePicker'

const useStyles = createStyles(() => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%'
  }
}))

export default function GeoZones () {
  const { classes } = useStyles()
  const [mapStyle, setMapStyle] = useState<MapType>(MapType.monochrome)

  const onChangeMapMode = useCallback((mapType: MapType) => {
    setMapStyle(mapType)
  }, [])

  return (
    <Box className={classes.container}>
      <CoreMap
        mapStyle={mapStyle}
        longitude={133.8807}
        latitude={23.6980}
        zoom={1}
        canViewList
        canDraw
        canPan
        canZoom
      />
      <StylePicker
        type={mapStyle}
        offsetBottom={5}
        offsetRight={5}
        onChangeStyle={onChangeMapMode}
      />
    </Box>
  )
}
