import {
  createContext,
  type RefObject,
  useContext,
  useEffect,
  useMemo,
  useState,
  type Dispatch,
  type SetStateAction,
  useCallback,
  type CSSProperties
} from 'react'
import { type Agent } from '@venturi-io/api/src/config/geoZone'
import useSupercluster from 'use-supercluster'
import { type PointFeature } from 'supercluster'
import { type MapRef } from 'react-map-gl'
import { type BBox } from '@turf/turf'
import { type AgentUser } from '@venturi-io/api/src/config/agent'
import { type AgentGroup } from '@venturi-io/api/src/config/agentGroup'
import { useDebouncedState } from '@mantine/hooks'
import { colors, getAgentStatusColor } from 'src/utils/status'
import { orderBy } from 'lodash'
import { type AgentCluster } from './Markers/ClusterMarker'
import { type AgentType } from '.'
import type Supercluster from 'supercluster'

const defaultZoom = 5
const focusZoom = 20

export interface AgentPointProps {
  cluster: boolean
  agent: Agent
  point_count?: number
}

export type Cluster = PointFeature<Supercluster.AnyProps> |
PointFeature<Supercluster.ClusterProperties & Supercluster.AnyProps>
export type AgentPoint = PointFeature<AgentPointProps>

type Reference = Record<number, boolean>

export interface AgentWithColorStatus extends Agent {
  color: CSSProperties['color']
}

// We are relying on the colors object to declare the order of status
export const statusOrder: Record<string, number> =
  Object.values(colors)
    .reduce((acc, color, idx) => ({
      ...acc,
      [color ?? 'unknown']: idx + 1
    }
    ), {})

export function sortAgents (items: AgentWithColorStatus[]) {
  return orderBy(items, ['agentName', 'asc'])
    .sort((a, b) => {
      const { color: aColor } = a
      const { color: bColor } = b
      if (aColor && bColor) {
        return statusOrder[aColor] - statusOrder[bColor]
      }
      return -1
    })
}

interface Context {
  tab: string
  query: string
  peekedClusterId?: number
  allAgents: Agent[]
  allPeople: AgentUser[]
  allGroups: AgentGroup[]
  agentClusters: Cluster[]
  agentPoints: AgentPoint[]
  agentsOnViewport: AgentWithColorStatus[]
  filteredByQuery: AgentWithColorStatus[]
  filteredAgentsByAssetType: AgentWithColorStatus[]
  viewAgentsByTypes: AgentType[]
  viewAgentsByStatuses: string[]
  selectionAgents: Agent[]
  agentCheckReference: Reference
  peopleCheckReference: Reference
  groupCheckReference: Reference
  lastClusterZoomLevel: number
  setTab: Dispatch<SetStateAction<string>>
  setQuery: (newValue: string) => void
  setViewAgentsByTypes: Dispatch<SetStateAction<AgentType[]>>
  setViewAgentsByStatuses: Dispatch<SetStateAction<string[]>>
  setPeekedClusterId: Dispatch<SetStateAction<number | undefined>>
  setCheckedAgentIds: Dispatch<SetStateAction<number[]>>
  setCheckedPeopleIds: Dispatch<SetStateAction<number[]>>
  setCheckedGroupIds: Dispatch<SetStateAction<number[]>>
  handleClusterClick: (cluster: AgentCluster) => void
  getClusterChildren: (clusterId: number) => Agent[]
}

const TypeContext = createContext<Context>({
  tab: 'assets',
  query: '',
  peekedClusterId: undefined,
  allAgents: [],
  allPeople: [],
  allGroups: [],
  agentClusters: [],
  agentPoints: [],
  agentsOnViewport: [],
  filteredByQuery: [],
  filteredAgentsByAssetType: [],
  viewAgentsByTypes: [],
  viewAgentsByStatuses: [],
  agentCheckReference: {},
  peopleCheckReference: {},
  groupCheckReference: {},
  lastClusterZoomLevel: focusZoom,
  selectionAgents: [],
  setTab: () => {},
  setQuery: () => {},
  setViewAgentsByTypes: () => [],
  setViewAgentsByStatuses: () => [],
  setPeekedClusterId: () => {},
  setCheckedPeopleIds: () => [],
  setCheckedAgentIds: () => [],
  setCheckedGroupIds: () => [],
  handleClusterClick: () => {},
  getClusterChildren: () => []
})

interface Props {
  agents: Agent[]
  groups: AgentGroup[]
  children: React.ReactNode
  mapRef: RefObject<MapRef>
  bounds: BBox
  zoom: number
  peekedClusterId?: number
  fullView?: boolean
  setPeekedClusterId: Dispatch<SetStateAction<number | undefined>>
}

const defaultTab = 'assets'
export function AgentAttendanceProvider ({
  children,
  agents,
  groups,
  mapRef,
  bounds,
  zoom,
  peekedClusterId,
  fullView,
  setPeekedClusterId
}: Props) {
  const [query, setQuery] = useDebouncedState<string>('', 350)
  const [tab, setTab] = useState<string>(defaultTab)
  const [lastTab, setLastTab] = useState<string>()
  const [viewAgentsByTypes, setViewAgentsByTypes] = useState<AgentType[]>([])
  const [viewAgentsByStatuses, setViewAgentsByStatuses] = useState<string[]>([])
  const [showAgentIds, setCheckedAgentIds] = useState<number[]>([])
  const [showPeopleIds, setCheckedPeopleIds] = useState<number[]>([])
  const [showGroupIds, setCheckedGroupIds] = useState<number[]>([])
  const [lastClusterZoomLevel, setLastClusterZoomLevel] = useState<number>(defaultZoom)
  const [agentsOnViewport, setAgentsOnViewPort] = useState<AgentWithColorStatus[]>([])

  // add sort props on agents
  const coloredAgents = useMemo(() => (
    agents.map((agent) => {
      const { alarmStatus, agentStatus, connectionStatus, lastSeenTime } = agent
      return {
        ...agent,
        color: getAgentStatusColor(
          alarmStatus,
          agentStatus,
          connectionStatus,
          lastSeenTime
        )
      }
    })
  ), [agents])

  const filteredAgentsByAssetType = useMemo(() => fullView
    ? coloredAgents
    : coloredAgents.filter(({ assetType, color }) =>
      viewAgentsByTypes.includes(assetType) &&
        viewAgentsByStatuses.includes(color ?? 'n/a')
    ), [
    fullView,
    viewAgentsByTypes,
    viewAgentsByStatuses,
    query,
    coloredAgents
  ])

  const filteredByQuery = useMemo(() => (
    filteredAgentsByAssetType.filter(({ agentName }) => agentName.toLowerCase().includes(query.toLowerCase()))
  ), [filteredAgentsByAssetType, query])

  const people: AgentUser[] = useMemo(() => (
    filteredAgentsByAssetType
      .filter(({ user }) => typeof user !== 'undefined')
      .reduce((uniqueDrivers: AgentUser[], agent) => {
        const foundDriver = uniqueDrivers.find(({ orgUserId }) => orgUserId === agent?.user?.orgUserId)

        if (typeof agent?.user === 'undefined' || foundDriver) {
          return uniqueDrivers
        }

        return [
          ...uniqueDrivers,
          agent.user
        ]
      }, [])
  ), [filteredAgentsByAssetType])

  // For assets tab
  const agentCheckReference: Reference = useMemo(() => (
    agents.reduce((acc, { agentId }) => (
      {
        ...acc,
        [agentId]: showAgentIds.includes(agentId)
      }
    ), {})
  ), [agents, showAgentIds])

  // For drivers tab
  const peopleCheckReference: Reference = useMemo(() => (
    people.reduce((acc, { orgUserId }) => ({
      ...acc,
      [orgUserId]: showPeopleIds.includes(orgUserId)
    }), {})
  ), [people, showPeopleIds])

  // For peoples tab
  const groupCheckReference: Reference = useMemo(() => (
    groups.reduce((acc, { agentGroupId }) => ({
      ...acc,
      [agentGroupId]: showGroupIds.includes(agentGroupId)
    }), {})
  ), [groups, showGroupIds])

  const selectionAgents = useMemo(() => (
    filteredAgentsByAssetType.filter(({ agentId }) => agentCheckReference[agentId])
  ), [filteredAgentsByAssetType, agentCheckReference])

  const overrideAgentSelection = useCallback((selection: Agent[]): AgentPoint[] => (
    selection.map(agent => ({
      type: 'Feature',
      properties: {
        cluster: false,
        agent
      },
      geometry: {
        type: 'Point',
        coordinates: [
          agent.lastLocation.geoLocation.longitude,
          agent.lastLocation.geoLocation.latitude
        ]
      }
    }))
  ), [])

  const agentPoints: AgentPoint[] = useMemo(() => (
    overrideAgentSelection(filteredAgentsByAssetType)
  ), [filteredAgentsByAssetType])

  const agentMarkers: AgentPoint[] = useMemo(() => (
    overrideAgentSelection(selectionAgents)
  ), [selectionAgents])

  const { clusters: agentClusters, supercluster } = useSupercluster({
    points: agentMarkers,
    bounds,
    zoom,
    options: {
      radius: 100,
      minPoints: 2,
      maxZoom: 20
    }
  })

  useEffect(() => {
    if (tab !== lastTab && agents.length > 0) {
      setLastTab(tab)
      // Setup that all Ids are selected
      setCheckedAgentIds(agents.map(({ agentId }) => agentId))

      // Initialize people Ids
      setCheckedPeopleIds(people.map(({ orgUserId }) => orgUserId))

      // Initialize people Ids
      setCheckedGroupIds(groups.map(({ agentGroupId }) => agentGroupId))
    }
  }, [tab, agents])

  useEffect(() => {
    // get markers from updated bounds
    if (mapRef.current) {
      const map = mapRef.current.getMap()
      const insideViewport = filteredByQuery.filter(({ geoLocation }) => geoLocation
        ? (
            map.getBounds().contains({
              lng: geoLocation.longitude,
              lat: geoLocation.latitude
            })
          )
        : false)
      setAgentsOnViewPort(insideViewport)
    }
  }, [bounds, filteredByQuery])

  const handleClusterClick = useCallback(({ clusterId, longitude, latitude }: AgentCluster) => {
    if (supercluster) {
      const zoomInCluster = Math.min(
        supercluster.getClusterExpansionZoom(Number(clusterId)),
        focusZoom
      )
      setLastClusterZoomLevel(zoomInCluster)
      if (mapRef.current) {
        mapRef.current.flyTo({
          duration: 450,
          center: [longitude, latitude],
          zoom: zoomInCluster
        })
      }
    }
  }, [supercluster])

  const getClusterChildren = useCallback((clusterId: number) => {
    if (supercluster) {
      const children = supercluster
        .getLeaves(clusterId, Infinity)

      const agents = children
        .filter(({ properties: { cluster } }) => !cluster)
        .map(({ properties: { agent } }) => agent)
      return agents
    }
    return []
  }, [supercluster])

  return (
    <TypeContext.Provider
      value={{
        tab,
        query,
        peekedClusterId,
        allAgents: coloredAgents,
        allPeople: people,
        allGroups: groups,
        agentClusters,
        agentPoints,
        agentsOnViewport,
        filteredByQuery,
        filteredAgentsByAssetType,
        agentCheckReference,
        peopleCheckReference,
        groupCheckReference,
        selectionAgents,
        lastClusterZoomLevel,
        viewAgentsByTypes,
        viewAgentsByStatuses,
        setQuery,
        setTab,
        setViewAgentsByTypes,
        setViewAgentsByStatuses,
        setPeekedClusterId,
        setCheckedAgentIds,
        setCheckedPeopleIds,
        setCheckedGroupIds,
        handleClusterClick,
        getClusterChildren
      }}
    >
      {children}
    </TypeContext.Provider>
  )
}

export function useViewTypes () {
  const state = useContext(TypeContext)

  return state
}
