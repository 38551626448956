import {
  ActionIcon,
  Group,
  Image,
  Stack,
  Text
} from '@mantine/core'
import { IconDroplet } from '@tabler/icons-react'
import { type LiquidType } from '@venturi-io/api/src/config/agent'
import { toProperCase } from 'src/utils/strings'

const fuelTypePhotos: Record<LiquidType, string> = {
  DIESEL: '/assets/fuelTypes/Hazardous.svg',
  GREASE: '/assets/fuelTypes/Hazardous.svg',
  PALM_OIL: '',
  PETROLEUM: '/assets/fuelTypes/Hazardous.svg',
  UNLEADED: '/assets/fuelTypes/Hazardous.svg',
  WATER: ''
}

interface Props {
  fuelType: LiquidType
}

export default function FuelType ({ fuelType }: Props) {
  const photo = fuelTypePhotos[fuelType]

  return (
    <Stack
      spacing={4}
      align="flex-start"
      sx={{
        width: '100%',
        zIndex: 1
      }}
    >
      <Group
        spacing={4}
        position="apart"
        w="100%"
      >
        <Group spacing={4} position="center">
          <ActionIcon
            variant="transparent"
            size="xs"
            color="dark.2"
            sx={{
              pointerEvents: 'none'
            }}
          >
            <IconDroplet size={16} />
          </ActionIcon>
          <Text size={12} weight={500} color="dimmed">
            {toProperCase(fuelType)}
          </Text>
        </Group>
        {typeof photo !== 'undefined' &&
          photo.length > 0 && (
            <Image
              src={photo}
              alt="Fuel Type Logo"
              width={24}
              height={21}
              fit="contain"
            />
        )}
      </Group>
    </Stack>
  )
}
