import { type Alarm } from '@venturi-io/api/src/analytics/alarm'
import { createStyles, Group, Popover, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconAlertCircle, IconUrgent } from '@tabler/icons-react'

const useStyles = createStyles((theme) => ({
  line: {
    height: 2,
    background: '#000',
    transform: ' translate(10px, -8px)',
    display: 'block'
  },
  lineText: {
    cursor: 'pointer',
    color: '#000',
    fontSize: 14,
    position: 'absolute',
    lineHeight: '14px',
    opacity: '.25'
  },
  popoverContainer: {
    color: '#fff',
    background: theme.colors.dark[5]
  }
}))

interface Props {
  alarmId: Alarm['alarmId']
  alarmName: Alarm['name']
  percentValue: number
  literValue: number
  isCriticalAlarm: boolean
}

export default function MarkerAnnotationDetails ({
  alarmId,
  alarmName,
  percentValue,
  literValue,
  isCriticalAlarm
}: Props) {
  const { classes } = useStyles()
  const [opened, { close, open }] = useDisclosure(false)

  return (
    <Popover
      key={alarmId}
      opened={opened}
      position="left"
      shadow="lg"
      transitionProps={{
        transition: 'skew-down',
        duration: 150
      }}
      withArrow
      withinPortal
    >
      <Popover.Target>
        <Text
          size={12}
          weight={400}
          className={classes.lineText}
          style={{
            bottom: `${percentValue - 3}%`
          }}
          onMouseEnter={open}
          onMouseLeave={close}
        >
          ►
          {' '}
          <span
            className={classes.line}
            style={{
              width: isCriticalAlarm
                ? 55
                : 24
            }}
          />
        </Text>
      </Popover.Target>
      <Popover.Dropdown className={classes.popoverContainer}>
        <Group
          position="left"
          align="center"
          spacing={6}
        >
          {isCriticalAlarm
            ? <IconUrgent size={24} stroke={2} />
            : <IconAlertCircle size={24} stroke={2} />}
          <Text weight={600}>
            {alarmName}
          </Text>
        </Group>
        <Group
          mt={4}
          ml={2}
          position="left"
          align="center"
          spacing={10}
        >
          <Text>►</Text>
          <Text>
            {`${percentValue}% • ${literValue} L`}
          </Text>
        </Group>
      </Popover.Dropdown>
    </Popover>
  )
}
