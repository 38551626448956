/* eslint-disable @typescript-eslint/naming-convention */
import { PDFViewer } from '@react-pdf/renderer'
import { Modal } from '@mantine/core'
import { type IdlingItem } from '..'
import AgentIdleHistory, { type ParsedIdle } from './AgentIdleHistory'

export interface Props extends IdlingItem {
  data: ParsedIdle[]
  mapUri: string | null
  agentName: string
  showPdfViewer: boolean
  toggleShowPdfViewer: () => void
}

export default function ExportToPdf ({
  data,
  showPdfViewer,
  mapUri,
  toggleShowPdfViewer,
  agentName,
  org_user_first_name,
  org_user_last_name,
  user_groups_names,
  trip_start_time,
  total_idling_events
}: Props) {
  return (
    <Modal
      title="Exporting to pdf..."
      size="auto"
      opened={showPdfViewer}
      onClose={toggleShowPdfViewer}
    >
      <PDFViewer width="1000" height="650">
        <AgentIdleHistory
          data={data}
          mapUri={mapUri}
          agentName={agentName}
          driver={org_user_first_name && org_user_last_name
            ? `${org_user_first_name} ${org_user_last_name}`
            : 'N/A'}
          group={user_groups_names}
          date={trip_start_time}
          count={total_idling_events}
        />
      </PDFViewer>
    </Modal>
  )
}
