import { Box, Group, Text, createStyles } from '@mantine/core'
import { IconDropletCheck } from '@tabler/icons-react'
import { mq } from 'src/utils/style'

interface Props {
  safeFillLevelPercent: number | null
  safeFillLevelLiter: number | null
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    pointerEvents: 'none'
  },
  label: {
    position: 'absolute',
    left: -84,
    lineHeight: '17px',
    color: theme.colors.gray[6]
  },
  line: {
    position: 'absolute',
    left: 10,
    height: 2,
    width: 95,
    background: theme.colors.gray[6]
  },
  value: {
    position: 'absolute',
    left: 120,
    width: 75,
    color: theme.colors.gray[6],
    [mq('370px')]: {
      width: 112
    },
    [mq('585px')]: {
      width: 75
    },
    [mq('645px')]: {
      width: 120
    }
  }
}))

export default function MarkerAnnotationSafeFillLevel ({
  safeFillLevelPercent,
  safeFillLevelLiter
}: Props) {
  const { classes } = useStyles()

  if (safeFillLevelPercent === null || safeFillLevelLiter === null) {
    return
  }

  return (
    <Box className={classes.container}>
      <Group
        className={classes.label}
        style={{
          bottom: `${safeFillLevelPercent - 3}%`
        }}
        spacing={6}
        position="center"
        align="center"
      >
        <IconDropletCheck size={18} stroke={1.8} />
        <Text size={14} weight={400}>
          Safe Fill
        </Text>
      </Group>
      <span
        className={classes.line}
        style={{
          bottom: `${safeFillLevelPercent - 0.5}%`
        }}
      />
      <Box
        className={classes.value}
        style={{
          bottom: `${safeFillLevelPercent - 3}%`
        }}
      >
        <Text size={14} weight={400}>
          {`${safeFillLevelPercent}% • ${safeFillLevelLiter} L`}
        </Text>
      </Box>
    </Box>
  )
}
