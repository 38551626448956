import {
  type ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  getGeoZone,
  getGeoZonesAndAgents,
  type Agent,
  type GeoZone
} from '@venturi-io/api/src/config/geoZone'
import { type AgentLocation } from '@venturi-io/api/src/collector/agent'
import {
  type AgentTripsRequest,
  getAgentTrips,
  type TripSummary,
  type TripDetails
} from '@venturi-io/api/src/collector/trip'
import ReactMapGL, {
  type MapRef,
  Layer,
  Source,
  type SourceProps,
  type LayerProps
} from 'react-map-gl'
import { useUser } from 'src/UserContext'
import { useApi, type ApiData, usePaginatedApi } from 'src/utils/useApi'
import dayjs from 'dayjs'
import { dateFormat } from 'src/utils/dates'
import { type BBox } from 'geojson'
import { getAgentGroups } from '@venturi-io/api/src/config/agentGroup'
import { type AssetType, getAgentDetails } from '@venturi-io/api/src/config/agent'
import { type Range } from '@venturi-io/api/src/shared'
import {
  ActionIcon,
  Box,
  Center,
  Group,
  Indicator,
  Paper,
  SegmentedControl,
  Text,
  Tabs,
  Tooltip,
  createStyles,
  useMantineColorScheme,
  Stack
} from '@mantine/core'
import { mq } from 'src/utils/style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAgentStatusColor } from 'src/utils/status'
import { type IconProp } from '@fortawesome/fontawesome-svg-core'
import parseIconStyle from 'src/utils/parseIconStyle'
import TextTooltip from 'src/Layout/TextTooltip'
import ImmobiliseButton from 'src/Buttons/ImmobiliseButton'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ActionButton from 'src/ActionButton'
import Settings from 'src/Settings/Agents/List/Settings'
import { getOrg } from '@venturi-io/api/src/userManager/org'
import Loader from 'src/Layout/Loader'
import { type LngLatLike } from 'mapbox-gl'
import { getBoundary, getCentroid } from 'src/utils/map'
import { FEATURE_FILL_COLOR } from 'src/Maps/CoreMap/style'
import { getBackgroundColor, getTextColor } from 'src/utils/theme'
import { IconRoute, IconMapPins } from '@tabler/icons-react'
import { getAgentDashboardPath } from 'src/AssetTemplate/shared'
import StylePicker, { MapType } from '../StylePicker'
import { AgentAttendanceProvider } from './AgentAttendanceContext'
import ItemsList from './ItemsList'
import AgentDetails from './ItemDetails'
import SearchBar from './SearchBar'
import { generateTrips, type PlaceGeometry, retrievePlace, type Place } from './mapbox'
import TripLayer from './Trips/Layer'
import Trips from './Trips'
import Player from './Trips/Player'
import AnimationContext from './Trips/AnimationContext'
import Markers from './Markers'
import URLTripLoader from './Trips/URLTripLoader'
import Devtools from './Trips/Layer/Devtools'
import FocusManager, { type FocusManagerRef } from './FocusManager'
import MarkerLayer from './Markers/MarkerLayer'
import URLGeozoneLoader from './URLGeozoneLoader'
import Locations from './Locations'
import LocationLayer from './Locations/Layer'

export type AgentType = AssetType | string | undefined

const useStyles = createStyles((theme, opened: boolean) => ({
  container: {
    scrollbarGutter: 'stable',
    position: 'absolute',
    right: opened ? 0 : '-100%',
    width: '100%',
    height: '100%',
    padding: '40px 20px',
    boxShadow: '-2px 0 4px 0 #000000c4',
    overflow: 'auto',
    transition: 'ease-in-out .4s',
    zIndex: 101,
    [mq(theme.breakpoints.md)]: {
      right: opened ? 0 : '-346px',
      width: '346px'
    },
    [mq(theme.breakpoints.xs)]: {
      width: '346px'
    },
    ...getBackgroundColor(theme),
    ...getTextColor(theme),
    display: 'flex',
    flexDirection: 'column'
  },
  close: {
    position: 'absolute',
    top: 8,
    right: 8
  },
  open: {
    position: 'absolute',
    bottom: 128,
    right: 27,
    padding: 10,
    zIndex: 1
  },
  indicator: {
    position: 'absolute',
    top: -1,
    right: 0
  },
  tabs: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}))

enum Tab {
  details = 'details',
  trips = 'trips'
}
export interface Filters {
  agentIds?: Array<Agent['agentId']>
  geoZoneIds?: Array<GeoZone['geoZoneId']>
}

export interface Centroid {
  longitude: number
  latitude: number
}

export interface Options {
  embedded?: boolean
  centroid?: Centroid
  metadata?: {
    name: string
    id: string
    type: string
  }
}

export interface Props {
  options?: Options
  filters?: Filters
  selectedAgentId?: Agent['agentId']
  expandBtn?: ReactElement
  fullView?: boolean
  centroid?: Centroid
}

export interface PathProps {
  id: string
  color: string
  show: boolean
  showRaw: boolean
  rawSource: SourceProps
  rawLayer: LayerProps
  source: SourceProps
  layer: LayerProps
  rawPoints: AgentLocation[]
}

export interface TripProps extends TripSummary {
  color: string
  data?: TripDetails
}

// TODO: update this to set proper defaultGeoLocation
export const fixedGeoLocation = {
  longitude: 153.106064,
  latitude: -27.451855
}

const defaultZoom = 5
const placeZoom = 13
const focusZoom = 20

// ids came from Layer components inside Trips and Locations.
const interactiveLayerIds = [
  'pointer',
  'arrow-points',
  'speeding-arrow-points',
  'main-line',
  'speeding-points',
  'raw-pointer',
  'idlingFence',
  'baseLine',
  'basePoint',
  'baseIdling',
  'baseSpeeding',
  'basePointer'
]

export default function GeoZoneMap ({
  options = {
    embedded: false
  },
  filters,
  selectedAgentId,
  expandBtn,
  fullView
}: Props) {
  const { colorScheme } = useMantineColorScheme()
  const isLight = colorScheme === 'light'
  const mapRef = useRef<MapRef>(null)
  const focusRef = useRef<FocusManagerRef>(null)
  const parentRef = useRef<HTMLDivElement>(null)
  const tripHistory = usePaginatedApi(getAgentTrips)
  const geoZonesAndAgents = useApi(getGeoZonesAndAgents)
  const [peekedClusterId, setPeekedClusterId] = useState<number>()
  const { token, orgId } = useUser()

  const [viewport, setViewport] = useState({
    zoom: defaultZoom,
    ...fixedGeoLocation
  })

  const [timeRange, setTimeRange] = useState<Range | null>({
    from: dayjs()
      .subtract(24, 'hour')
      .format(dateFormat),
    to: dayjs()
      .format(dateFormat)
  })
  const [params] = useSearchParams()
  const [urlAgentId, setUrlAgentId] = useState(Number(params.get('agentId')))

  const navigate = useNavigate()
  const [mapStyle, setMapStyle] = useState<MapType>(MapType.monochrome)
  const [selectedAgent, setSelectedAgent] = useState<Agent>()
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [showAgentDetails, setShowAgentDetails] = useState(false)
  const [showItemsList, setShowItemsList] = useState(false)
  const [trips, setTrips] = useState<TripProps[]>([])
  const agentGroups = usePaginatedApi(getAgentGroups)
  const getAgent = useApi(getAgentDetails)
  const org = useApi(getOrg)
  const [activeTab, setActiveTab] = useState<string | null>(Tab.details)
  const { classes } = useStyles(showAgentDetails)
  const [isLoadingItemsList, setIsLoadingItemsList] = useState(false)
  const agentStatusColor = getAgentStatusColor(
    selectedAgent?.alarmStatus ?? 'NEW',
    selectedAgent?.agentStatus,
    selectedAgent?.connectionStatus,
    selectedAgent?.lastSeenTime
  )
  const isAlarm = agentStatusColor === 'red'
  const map = mapRef.current?.getMap()
  const [bounds, setBounds] = useState<BBox>(
    map?.getBounds()
      .toArray()
      .flat() as never as BBox
  )

  const [zoom, setZoom] = useState<number>(defaultZoom)
  const [historyMode, setHistoryMode] = useState<string>('trips')
  const [locations, setLocations] = useState<AgentLocation[]>([])

  const agentIcon: IconProp = selectedAgent?.iconStyle
    ? parseIconStyle(selectedAgent.iconStyle)
    : ['fas', 'info']

  const { geoZones, agents }: ApiData<typeof getGeoZonesAndAgents> =
    geoZonesAndAgents.data.mapOrDefault(({ geoZones, agents }) => ({
      geoZones: typeof filters?.geoZoneIds !== 'undefined' && filters.geoZoneIds.length > 0
        ? geoZones.filter(({ geoZoneId }) => filters.geoZoneIds?.includes(geoZoneId))
        : geoZones,
      agents: typeof filters?.agentIds !== 'undefined' && filters.agentIds.length > 0
        ? agents.filter(({ agentId }) => filters.agentIds?.includes(agentId))
        : agents
    }),
    {
      geoZones: [],
      agents: []
    })

  const loadAgentGroups = async (page?: number, size?: number): Promise<void> => {
    void agentGroups
      .fetch({
        page: page ?? 1,
        size: size ?? 500
      }, token)
  }

  const [isOpenSettingsMenu, setIsOpenSettingsMenu] = useState(false)

  const loadOrganisation = () => {
    void org.fetch({ orgId }, token)
  }

  useEffect(() => {
    void loadOrganisation()
    void loadAgentGroups()
  }, [])

  const getDetails = (agentId: Agent['agentId']) => {
    void getAgent.fetch({ agentId }, token)
  }

  const handleAgentClick = useCallback((agentId: Agent['agentId']) => {
    if (options && !options.embedded) {
      navigate(`?agentId=${agentId}`, { replace: true })
    }
  }, [mapRef.current])

  const handleAgentDoubleClick = useCallback((
    agentId: Agent['agentId'],
    assetType: Agent['assetType']
  ) => {
    if (options && !options.embedded) {
      const dashboardPath = getAgentDashboardPath(agentId, assetType)

      navigate(dashboardPath)
    }
  }, [options])

  useEffect(() => {
    if (selectedAgentId) {
      getDetails(selectedAgentId)
    }
  }, [selectedAgentId])

  useEffect(() => {
    if (urlAgentId && !options.embedded) {
      getDetails(urlAgentId)
    }
  }, [urlAgentId])

  useEffect(() => {
    const newUrlAgentId = Number(params.get('agentId'))
    if (newUrlAgentId !== urlAgentId && !options.embedded) {
      setUrlAgentId(newUrlAgentId)
    }
  }, [params])

  useEffect(() => {
    getAgent.data.ifJust(agent => {
      const newAgent = {
        ...agent,
        lastLocation: {
          geoLocation: agent.geoLocation ?? fixedGeoLocation
        }
      }
      setSelectedAgent(newAgent)
      setShowAgentDetails(true)
      if (newAgent.agentId !== selectedAgent?.agentId) {
        setTrips([])
        setLocations([])
        setHistoryMode('trips')
        setSelectedAgent(newAgent)
        setActiveTab(Tab.details)
      }
    })
  }, [getAgent.data])

  useEffect(() => {
    if (historyMode === 'trips') {
      setLocations([])
    }
  }, [historyMode])

  const handleOpen = useCallback(() => {
    setShowAgentDetails(true)
  }, [showAgentDetails])

  const handleClose = useCallback(() => {
    setShowAgentDetails(false)
  }, [showAgentDetails])

  const onSearchAgent = useCallback((agent: Agent) => {
    setSelectedAgent(agent)
    setActiveTab(Tab.details)
    setTrips([])
    setLocations([])
    setHistoryMode('trips')
    navigate(`?agentId=${agent.agentId}`, { replace: true })
  }, [])

  const onSearchGeoZone = useCallback((geoZone: GeoZone) => {
    if (mapRef.current) {
      const { longitude, latitude } = getCentroid(geoZone.boundary)
      mapRef.current.flyTo({
        center: [longitude, latitude],
        zoom: 5
      })
    }
  }, [])

  const onSearchPlace = useCallback((place: Place) => {
    if (mapRef.current) {
      const { coordinates: [longitude, latitude] } = place.geometry
      mapRef.current.flyTo({
        center: [longitude, latitude],
        zoom: 5
      })
    }
  }, [])

  const onChangeMapMode = useCallback((mapType: MapType) => {
    setMapStyle(mapType)
  }, [])

  const loadGeoZonesAndAssets = (showLoader?: boolean) => {
    const req = {
      orgId
    }

    if (showLoader) {
      setIsLoadingItemsList(true)
    }

    void geoZonesAndAgents
      .fetch(req, token)
      .finally(() => {
        geoZonesAndAgents.startPolling(req, token, 10)
        if (showLoader) {
          setIsLoadingItemsList(false)
        }
      })

    return () => {
      geoZonesAndAgents.stopPolling()
      geoZonesAndAgents.abort()
    }
  }

  const getAndFocusToPlace = async (id: string) => {
    const { geometry: { coordinates } } = await retrievePlace(id) as never as PlaceGeometry
    setViewport({
      zoom: placeZoom,
      longitude: coordinates[0],
      latitude: coordinates[1]
    })
  }

  const getAndFocusToGeozone = async (id: string) => {
    void getGeoZone({ geoZoneId: Number(id) }, token).caseOf({
      Left: err => console.error('Failed to get geozone', err),
      Right: (data) => {
        const { boundary: { coordinates } } = data
        const bounds = getBoundary(coordinates.flat() as never as LngLatLike[])
        mapRef.current?.fitBounds(bounds, {
          padding: 40,
          maxZoom: 16
        })
      }
    })
  }

  const isInteractive = typeof peekedClusterId === 'undefined'

  useEffect(() => {
    if (
      timeRange &&
      selectedAgent &&
      activeTab === 'trips' &&
      historyMode === 'trips'
    ) {
      const { from, to } = timeRange
      const { agentId } = selectedAgent
      const format = `${dateFormat}Z`
      const startTime = dayjs(from, dateFormat).utc().format(format)
      const endTime = dayjs(to, dateFormat).utc().format(format)
      const req: AgentTripsRequest = {
        agentId,
        startTime,
        endTime,
        page: 1,
        size: 100
      }
      void tripHistory.fetch(req, token)
    }
  }, [
    selectedAgent,
    activeTab,
    timeRange,
    historyMode
  ])

  useEffect(() => {
    void loadGeoZonesAndAssets()
  }, [])

  useEffect(() => {
    tripHistory.data.ifJust(({ items }) => {
      setTrips(generateTrips(items, trips))
    })
  }, [tripHistory.data])

  const removeSelected = useCallback(() => {
    navigate('', { replace: true })
    setSelectedAgent(undefined)
    handleClose()
  }, [])

  const onKeyDown = useCallback((e: { key: string }) => {
    if (e.key === 'Escape') {
      removeSelected()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  useEffect(() => {
    if (parentRef?.current) {
      const resizeObserver = new ResizeObserver(() => {
        mapRef.current?.getMap().resize()
      })
      resizeObserver.observe(parentRef.current)
      return () => resizeObserver.disconnect()
    }
  }, [])

  useEffect(() => {
    org.data.ifJust((data) => {
      const { defaultGeoLocation } = data
      if (defaultGeoLocation) {
        let { longitude, latitude } = fixedGeoLocation
        const { longitude: lng, latitude: lat, metadata } = defaultGeoLocation
        if (lng && lat) {
          longitude = lng
          latitude = lat
        }
        setViewport({
          zoom,
          longitude,
          latitude
        })
        // check metadata and set selected agent for initial focus
        // considering also the value of some parameters in the url
        const hasParams = params.get('agentId') ?? params.get('geoZoneId') ?? params.get('tripId')
        if (metadata &&
          !hasParams &&
          !options.embedded &&
          isFirstLoad &&
          !filters
        ) {
          setIsFirstLoad(false)
          const { id, type } = metadata
          if (id && type === 'agent') {
            getDetails(Number(id))
          }
          if (id && type === 'geozone') {
            void getAndFocusToGeozone(id)
          }
          if (id && type === 'place') {
            // get place using mapbox API
            void getAndFocusToPlace(id)
          }
        }
      }
    })
  }, [org.data])

  useEffect(() => {
    if (selectedAgent && agents && !options.embedded) {
      const { agentId: refocusId } = selectedAgent
      const refocusAgent = agents.find(({ agentId }) => refocusId === agentId)
      if (refocusAgent) {
        setSelectedAgent(refocusAgent)
      }
    }
  }, [selectedAgent, agents])

  useEffect(() => {
    if (fullView) {
      setShowItemsList(false)
      setShowAgentDetails(false)
    }
  }, [fullView])

  useEffect(() => {
    if (map) {
      map.flyTo({
        zoom,
        center: [viewport.longitude, viewport.latitude],
        animate: false
      })
    }
  }, [viewport, map])

  return (
    <AgentAttendanceProvider
      fullView={fullView}
      agents={agents}
      groups={agentGroups.data.mapOrDefault(({ items }) => items, [])}
      mapRef={mapRef}
      bounds={bounds}
      zoom={zoom}
      peekedClusterId={peekedClusterId}
      setPeekedClusterId={setPeekedClusterId}
    >
      <AnimationContext activeAgent={selectedAgent} mapRef={mapRef}>
        <div
          ref={parentRef}
          style={{
            // Make sure to position parent to relative to fill it's parent
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            overflow: 'hidden'
          }}
        >
          {!options?.embedded && (
            <ItemsList
              mapRef={mapRef}
              loading={isLoadingItemsList || agentGroups.loading}
              onClick={handleAgentClick}
              onRefreshList={loadGeoZonesAndAssets}
              isOpen={showItemsList}
              setIsOpen={setShowItemsList}
            />
          )}

          {selectedAgent && (
            <Paper
              shadow="lg"
              radius={0}
              className={classes.container}
            >
              <ActionIcon className={classes.close} onClick={handleClose}>
                <FontAwesomeIcon title="Close" icon={['far', 'close']} color="dark" size="1x" />
              </ActionIcon>
              <Group mt="sm" position="apart">
                <Indicator
                  inline
                  offset={-14}
                  size={14}
                  color={agentStatusColor}
                  position="middle-end"
                  processing={isAlarm}
                  withBorder
                >
                  <TextTooltip
                    weight="bold"
                    value={selectedAgent.agentName ?? ''}
                    maxLength={20}
                  />
                </Indicator>
                <Group spacing={3}>
                  <Settings
                    agentId={selectedAgent.agentId}
                    isOpen={isOpenSettingsMenu}
                    setIsOpen={setIsOpenSettingsMenu}
                    disableDelete={true}
                  />
                  {selectedAgent.assetType && (
                    <ActionButton
                      label="View Asset"
                      icon="memo-circle-info"
                      iconType="far"
                      actionSize="xs"
                      actionRadius="lg"
                      iconSize="1x"
                      onClick={() => {
                        // eslint-disable-next-line max-len
                        navigate(`/assets/${selectedAgent.assetType?.toLowerCase().replaceAll(' ', '-')}/${selectedAgent.agentId}`)
                      }}
                    />)}
                  {selectedAgent.assetType?.toLowerCase() === 'vehicle' && selectedAgent.hasImmobiliser && (
                    <ImmobiliseButton
                      agentId={selectedAgent.agentId}
                      onSuccess={() => {
                        window.location.reload()
                      }}
                    />
                  )}
                </Group>
              </Group>
              <Tabs
                variant={isLight
                  ? 'default'
                  : 'outline'}
                value={activeTab}
                onTabChange={setActiveTab}
                className={classes.tabs}
              >
                <Tabs.List grow mt="xl" mb="md">
                  <Tabs.Tab
                    value="details"
                    icon={<FontAwesomeIcon icon={['fas', 'memo-circle-info']} size="xs" />}
                  >
                    Details
                  </Tabs.Tab>
                  {selectedAgent.assetType?.toLowerCase() === 'vehicle' &&
                    <Tabs.Tab
                      value="trips"
                      icon={<FontAwesomeIcon icon={['fas', 'flag-checkered']} size="xs" />}
                    >
                      History
                    </Tabs.Tab>}
                </Tabs.List>
                <Tabs.Panel value="details">
                  <AgentDetails
                    agentId={selectedAgent.agentId}
                    opened={showAgentDetails}
                  />
                </Tabs.Panel>
                {selectedAgent.assetType?.toLowerCase() === 'vehicle' && (
                  <Tabs.Panel value="trips" sx={{ flexGrow: 1 }}>
                    <Stack spacing="xs" h="100%">
                      <SegmentedControl
                        value={historyMode}
                        color="primary"
                        radius="xl"
                        onChange={setHistoryMode}
                        data={[
                          {
                            value: 'trips',
                            label: (
                              <Center>
                                <IconRoute size="1rem" />
                                <Text size="xs" ml="xs">Trips</Text>
                              </Center>
                            )
                          },
                          {
                            value: 'locations',
                            label: (
                              <Center>
                                <IconMapPins size="1rem" />
                                <Text size="xs" ml="xs">Location</Text>
                              </Center>
                            )
                          }
                        ]}
                      />
                      {historyMode === 'trips' && (
                        <Trips
                          focusRef={focusRef}
                          loading={tripHistory.loading}
                          timeRange={timeRange}
                          trips={trips}
                          setTimeRange={range => setTimeRange(range)}
                        />
                      )}
                      {historyMode === 'locations' && (
                        <Locations
                          agentId={selectedAgent.agentId}
                          onFetch={setLocations}
                        />
                      )}
                    </Stack>
                  </Tabs.Panel>
                )}
              </Tabs>
            </Paper>
          )}

          {!options.embedded && (
            <SearchBar
              agents={agents}
              geoZones={geoZones}
              onSearchAgent={onSearchAgent}
              onSearchGeoZone={onSearchGeoZone}
              onSearchPlace={onSearchPlace}
              moveSearchbar={showAgentDetails}
            />
          )}

          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              right: showAgentDetails ? 346 : 0,
              transition: 'right ease-in-out .4s',
              zIndex: 100
            }}
          >
            <FocusManager
              ref={focusRef}
              options={options}
              filters={filters}
              agent={selectedAgent}
              agents={agents}
              geozones={geoZones}
              mapRef={mapRef}
            />
            {!options.embedded && selectedAgent && (
              <Tooltip
                label={selectedAgent.agentName}
                position="left"
                withArrow
              >
                <ActionIcon
                  className={classes.open}
                  color="white"
                  variant="filled"
                  onClick={showAgentDetails
                    ? handleClose
                    : handleOpen}
                >
                  <Indicator
                    className={classes.indicator}
                    size={10}
                    color={agentStatusColor}
                    processing={isAlarm}
                  >
                    <span />
                  </Indicator>
                  <FontAwesomeIcon
                    icon={agentIcon}
                    color={agentStatusColor}
                    size="1x"
                  />
                </ActionIcon>
              </Tooltip>
            )}
            {expandBtn}
            <StylePicker
              type={mapStyle}
              offsetBottom={15}
              offsetRight={15}
              onChangeStyle={onChangeMapMode}
              enabledTripContext
            />
          </Box>

          {org.loading
            ? <Loader />
            : (
              <>
                <ReactMapGL
                  ref={mapRef}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                  }}
                  interactive={isInteractive}
                  dragPan={isInteractive}
                  scrollZoom={isInteractive}
                  keyboard={isInteractive}
                  doubleClickZoom={false}
                  dragRotate={false}
                  pitchWithRotate={false}
                  maxZoom={focusZoom + 10}
                  mapStyle={mapStyle}
                  mapboxAccessToken={process.env.REACT_APP_APIKEY_MAPBOX}
                  onMoveEnd={(e) => {
                    const computedBounds = e
                      .target
                      .getBounds()
                      .toArray()
                      .flat() as never as BBox

                    const currentZoom = e
                      .target
                      .getZoom()

                    setBounds(computedBounds)
                    setZoom(currentZoom)
                  }}
                  interactiveLayerIds={interactiveLayerIds}
                  onClick={(e) => {
                    if (e.features?.length === 0 && selectedAgent) {
                      // clicked elsewhere in the map, so remove selected agent
                      removeSelected()
                    }
                  }}
                  attributionControl={false}
                  transformRequest={(url) => ({
                    url,
                    referrerPolicy: 'strict-origin-when-cross-origin'
                  })}
                >
                  {geoZones.map(({ geoZoneId, name, boundary }) => (
                    <Source
                      key={geoZoneId}
                      id={geoZoneId.toString()}
                      type="geojson"
                      data={{
                        type: 'Feature',
                        properties: {},
                        geometry: boundary
                      }}
                    >
                      <Layer
                        id={`${geoZoneId}-boundary`}
                        type="fill"
                        source={geoZoneId.toString()} // reference the data source
                        paint={{
                          'fill-color': FEATURE_FILL_COLOR,
                          'fill-opacity': 0.5
                        }}
                      />
                      <Layer
                        id={`${geoZoneId}-boundary-name`}
                        type="symbol"
                        source={geoZoneId.toString()}
                        layout={{
                          'text-field': name,
                          'text-size': 16,
                          'text-font': [
                            'DIN Pro Medium'
                          ]
                        }}
                        paint={{
                          'text-color': 'white',
                          'text-halo-color': 'black',
                          'text-halo-width': 1
                        }}
                      />
                    </Source>
                  ))}
                  <Devtools showAgentDetails={showAgentDetails} mapRef={mapRef}>
                    <>
                      <Markers
                        selectedAgent={selectedAgent}
                        handleAgentClick={handleAgentClick}
                        handleAgentDoubleClick={handleAgentDoubleClick}
                      />
                      <MarkerLayer
                        selectedAgent={selectedAgent}
                        mapRef={mapRef}
                        handleAgentClick={handleAgentClick}
                      />
                      {trips &&
                      selectedAgent &&
                      historyMode === 'trips' && (
                        <>
                          <Player />
                          <TripLayer mapRef={mapRef} />
                        </>
                      )}
                      {locations.length > 0 &&
                      selectedAgent &&
                      historyMode === 'locations' && (
                        <LocationLayer locations={locations} mapRef={mapRef} />
                      )}
                    </>
                  </Devtools>
                </ReactMapGL>
                <URLGeozoneLoader mapRef={mapRef} />
                <URLTripLoader mapRef={mapRef} focusRef={focusRef} />
              </>
              )}
        </div>
      </AnimationContext>
    </AgentAttendanceProvider>
  )
}
