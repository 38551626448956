import React from 'react'
import dayjs from 'dayjs'
import { uiDateFormatUniversal } from 'src/utils/dates'
import {
  Group,
  Stack,
  Title,
  Text,
  useMantineTheme
} from '@mantine/core'
import StackedLabelValue from 'src/Layout/StackedLabelValue'
import { IconAlertTriangleFilled } from '@tabler/icons-react'
import Pins, { type Pin } from '.'

export interface TheftData {
  x: Date | null
  theft: number
}

interface Props {
  show: boolean
  pins: Pin[]
}

function TheftPins ({
  show,
  pins
}: Props) {
  const { colors } = useMantineTheme()

  return show
    ? (
      <Pins
        pins={pins}
        content={(data) => {
          const { theft, x } = data as TheftData
          return (
            <Stack spacing="xs">
              <Group spacing="xs" display="flex">
                <IconAlertTriangleFilled size={32} color={colors.red[5]} />
                <Stack
                  spacing={0}
                  align="flex-end"
                  sx={{
                    flexGrow: 1
                  }}
                >
                  <Text color="dimmed" size="xs">Approx.</Text>
                  <Title order={3}>
                    {`~${theft.toFixed(0)} L`}
                  </Title>
                </Stack>
              </Group>
              <StackedLabelValue color="dimmed" value={dayjs(x).format(uiDateFormatUniversal)} />
            </Stack>
          )
        }}
      />
      )
    : null
}

export default TheftPins
