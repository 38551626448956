import {
  createStyles,
  Box,
  Group,
  Stack,
  Text
} from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import SensorHistorySparkLineChart from 'src/AssetTemplate/Components/Charts/SensorHistorySparkLineChart'
import AlarmIndicator from 'src/AssetTemplate/Components/AlarmIndicator'
import { mq } from 'src/utils/style'
import { getSensorValue } from 'src/AssetTemplate/shared'
import { getTextColor, getTextColorAsProp } from 'src/utils/theme'
import { type MultiSensors } from '..'
import VoltageBar from './VoltageBar'
import CurrentBar from './CurrentBar'

const useStyles = createStyles((theme) => ({
  container: {
    margin: '0 auto',
    ...getTextColor(theme),
    padding: '24px 28px',
    [mq(theme.breakpoints.md)]: {
      padding: '32px 44px'
    }
  },
  itemContainer: {
    position: 'relative'
  },
  groupContainer: {
    width: '100%'
  },
  chartContainer: {
    minWidth: 0,
    width: 64
  },
  alarmIndicatorContainer: {
    position: 'absolute',
    left: -28,
    bottom: 8
  },
  voltageGroupContainer: {
    width: '100%',
    display: 'inline-flex',
    flexFlow: 'column',
    flexWrap: 'wrap',
    flexGrow: 1,
    gap: 34,
    [mq('900px')]: {
      flexFlow: 'row',
      gap: 40
    }
  },
  voltageStackContainer: {
    flex: 1
  }
}))

interface Props {
  outputPower: Sensor | null
  powerFactor: Sensor | null
  powerFrequency: Sensor | null
  energyProduced: Sensor | null
  voltageLN: MultiSensors
  voltageLL: MultiSensors
  current: MultiSensors
}

export default function PowerDetails ({
  outputPower,
  powerFactor,
  powerFrequency,
  energyProduced,
  voltageLN,
  voltageLL,
  current
}: Props) {
  const { classes } = useStyles()
  const outputPowerHasAlarm = outputPower?.alarmStatus === 'ALARM'
  const powerFactorHasAlarm = powerFactor?.alarmStatus === 'ALARM'
  const powerFrequencyHasAlarm = powerFrequency?.alarmStatus === 'ALARM'
  const disabledOutputPower = outputPower === null
  const disabledPowerFactor = powerFactor === null
  const disabledPowerFrequency = powerFrequency === null
  const disabledEnergyProduced = energyProduced === null

  return (
    <Box className={classes.container}>
      <Stack
        align="center"
        justify="stretch"
        spacing={16}
      >
        <Group
          className={classes.groupContainer}
          position="apart"
          align="stretch"
        >
          <Stack className={classes.itemContainer} spacing={4}>
            <Text size={12} weight={500}>
              Total Output Power
            </Text>
            <Group>
              <Text
                size={24}
                weight={600}
                color={disabledOutputPower
                  ? 'dimmed'
                  : undefined}
              >
                {!disabledOutputPower
                  ? getSensorValue(outputPower, true, true)
                  : 'N/A'}
              </Text>
              <Box className={classes.chartContainer}>
                {outputPower?.sensorInstanceId && outputPower?.sensorInstanceId !== -1 && (
                  <SensorHistorySparkLineChart
                    sensorInstanceId={outputPower.sensorInstanceId}
                    height={50}
                    minValue={0}
                  />
                )}
              </Box>
            </Group>
            {outputPowerHasAlarm && (
              <Box className={classes.alarmIndicatorContainer}>
                <AlarmIndicator size="sm" />
              </Box>
            )}
          </Stack>
          <Stack className={classes.itemContainer} spacing={4} w={150}>
            <Text size={12} weight={500}>
              Power Factor
            </Text>
            <Group>
              <Text
                size={24}
                weight={600}
                color={disabledPowerFactor
                  ? 'dimmed'
                  : undefined}
              >
                {!disabledPowerFactor
                  ? getSensorValue(powerFactor, true, true)
                  : 'N/A'}
              </Text>
              <Box className={classes.chartContainer}>
                {powerFactor?.sensorInstanceId && powerFactor?.sensorInstanceId !== -1 && (
                  <SensorHistorySparkLineChart
                    sensorInstanceId={powerFactor.sensorInstanceId}
                    height={50}
                    minValue={0}
                  />
                )}
              </Box>
            </Group>
            {powerFactorHasAlarm && (
              <Box className={classes.alarmIndicatorContainer}>
                <AlarmIndicator size="sm" />
              </Box>
            )}
          </Stack>
        </Group>
        <Group
          className={classes.groupContainer}
          position="apart"
          align="stretch"
        >
          <Stack className={classes.itemContainer} spacing={4}>
            <Text size={12} weight={500}>
              Frequency
            </Text>
            <Group>
              <Text
                size={24}
                weight={600}
                color={disabledPowerFrequency
                  ? 'dimmed'
                  : undefined}
              >
                {!disabledPowerFrequency
                  ? getSensorValue(powerFrequency, true, true)
                  : 'N/A'}
              </Text>
              <Box className={classes.chartContainer}>
                {powerFrequency?.sensorInstanceId &&
                powerFrequency?.sensorInstanceId !== -1 && (
                  <SensorHistorySparkLineChart
                    sensorInstanceId={powerFrequency.sensorInstanceId}
                    height={50}
                    minValue={0}
                  />
                )}
              </Box>
            </Group>
            {powerFrequencyHasAlarm && (
              <Box className={classes.alarmIndicatorContainer}>
                <AlarmIndicator size="sm" />
              </Box>
            )}
          </Stack>
          <Stack className={classes.itemContainer} spacing={4} w={150}>
            <Text
              {...getTextColorAsProp()}
              size={12}
              weight={500}
            >
              Total Energy Produced
            </Text>
            <Text
              size={24}
              weight={600}
              color={disabledEnergyProduced
                ? 'dimmed'
                : undefined}
            >
              {!disabledEnergyProduced
                ? getSensorValue(energyProduced, true, true)
                : 'N/A'}
            </Text>
          </Stack>
        </Group>
      </Stack>
      <Box className={classes.voltageGroupContainer} mt={40}>
        <Stack
          className={classes.voltageStackContainer}
          align="stretch"
          justify="center"
          spacing={16}
        >
          <Text
            {...getTextColorAsProp()}
            size={14}
            weight={500}
            align="center"
          >
            Voltage L-N
          </Text>
          <Stack align="stretch" justify="center" spacing={8}>
            <VoltageBar
              label="A"
              type="LN"
              sensor={voltageLN.a}
            />
            <VoltageBar
              label="B"
              type="LN"
              sensor={voltageLN.b}
            />
            <VoltageBar
              label="C"
              type="LN"
              sensor={voltageLN.c}
            />
          </Stack>
        </Stack>
        <Stack
          className={classes.voltageStackContainer}
          align="stretch"
          justify="center"
          spacing={16}
        >
          <Text
            {...getTextColorAsProp()}
            size={14}
            weight={500}
            align="center"
          >
            Voltage L-L
          </Text>
          <Stack align="stretch" justify="center" spacing={8}>
            <VoltageBar
              label="A"
              type="LL"
              sensor={voltageLL.a}
            />
            <VoltageBar
              label="B"
              type="LL"
              sensor={voltageLL.b}
            />
            <VoltageBar
              label="C"
              type="LL"
              sensor={voltageLL.c}
            />
          </Stack>
        </Stack>
        <Stack
          className={classes.voltageStackContainer}
          align="stretch"
          justify="center"
          spacing={16}
        >
          <Text
            {...getTextColorAsProp()}
            size={14}
            weight={500}
            align="center"
          >
            Current
          </Text>
          <Stack align="stretch" justify="center" spacing={8}>
            <CurrentBar label="A" sensor={current.a} />
            <CurrentBar label="B" sensor={current.b} />
            <CurrentBar label="C" sensor={current.c} />
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}
