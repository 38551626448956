import { z } from 'zod'
import { createStatelessApi } from '../configure'
import {
  paginated,
  type AgentIdRequest,
  type PaginatedRequest,
  type TripIdRequest
} from '../shared'
import { user } from '../userManager/user'

const point = z.object({
  latitude: z.number(),
  longitude: z.number()
})

const tripSummary = z.object({
  tripId: z.number(),
  startTime: z.string(),
  endTime: z.string(),
  inProgress: z.boolean(),
  overSpeeding: z.optional(z.boolean()),
  distance: z.number(),
  startPoint: point,
  endPoint: point,
  rfidTag: z.optional(z.string()),
  user: z.optional(user.pick({
    orgUserId: true,
    firstName: true,
    lastName: true,
    email: true
  })),
  idlingDuration: z.optional(z.number()),
  drivingDuration: z.optional(z.number()),
  speedMin: z.optional(z.number()),
  speedMax: z.optional(z.number()),
  speedAvg: z.optional(z.number()),
  xAccelerationMin: z.optional(z.number()),
  xAccelerationMax: z.optional(z.number()),
  xAccelerationAvg: z.optional(z.number()),
  yAccelerationMin: z.optional(z.number()),
  yAccelerationMax: z.optional(z.number()),
  yAccelerationAvg: z.optional(z.number()),
  zAccelerationMin: z.optional(z.number()),
  zAccelerationMax: z.optional(z.number()),
  zAccelerationAvg: z.optional(z.number())
})

const tripsPagedResponse = paginated.extend({
  items: z.array(tripSummary)
})

export type TripSummary = z.infer<typeof tripSummary>
export interface AgentTripsRequest extends AgentIdRequest, PaginatedRequest {
  startTime: string
  endTime: string
}

export const getAgentTrips = createStatelessApi<AgentTripsRequest, typeof tripsPagedResponse>(
  'collector',
  '/trips/agents/:agentId',
  { method: 'GET', type: 'path' },
  tripsPagedResponse
)

const agentLocation = z.object({
  time: z.string(),
  longitude: z.number(),
  latitude: z.number(),
  altitude: z.optional(z.number()),
  direction: z.optional(z.number()),
  satellites: z.optional(z.number()),
  speed: z.optional(z.number()),
  xAcceleration: z.optional(z.number()),
  yAcceleration: z.optional(z.number()),
  zAcceleration: z.optional(z.number()),
  overSpeeding: z.optional(z.boolean()),
  speedLimit: z.optional(z.number()),
  idlingDuration: z.optional(z.number()),
  engineIgnitionStatus: z.optional(z.boolean())
})

const mapboxItem = z.array(z.number(), z.number())

const tripDetails = z.object({
  tripId: z.number(),
  rawPath: z.array(agentLocation),
  mapboxPath: z.array(mapboxItem),
  color: z.optional(z.string()),
  inProgress: z.boolean()
})

export type TripAgentLocation = z.infer<typeof agentLocation>

export type TripDetails = z.infer<typeof tripDetails>

export type MapboxPoint = z.infer<typeof mapboxItem>

export interface TripDetailsRequest extends TripIdRequest {
  startTime?: string
}

export const getTripDetails = createStatelessApi<TripDetailsRequest, typeof tripDetails>(
  'collector',
  '/trips/:tripId',
  { method: 'GET', type: 'path' },
  tripDetails
)

const idling = z.object({
  id: z.number(),
  time: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  idlingDuration: z.number(),
  geoZones: z.array(z.object({
    geoZoneId: z.number(),
    name: z.string(),
    hiddenOnMap: z.boolean()
  }))
})

const idlingTrips = z.array(idling)

export type IdlingTrip = z.infer<typeof idling>
export type IdlingTrips = z.infer<typeof idlingTrips>

export interface TripIdlingRequest extends TripDetailsRequest {
  endTime?: string
}

export const getTripIdlingLocations = createStatelessApi<TripIdlingRequest, typeof idlingTrips>(
  'collector',
  '/trips/:tripId/idling-locations',
  { method: 'GET', type: 'path' },
  idlingTrips
)
