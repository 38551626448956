import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getAgentDetails } from '@venturi-io/api/src/config/agent'
import { IconBatteryCharging2, IconBolt, IconDroplet, IconGauge } from '@tabler/icons-react'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import AssetTemplate from 'src/AssetTemplate'
import { convertStrToFloat, findSensor, getSensorValue } from 'src/AssetTemplate/shared'
import { secondsToHours } from 'src/utils/dates'
import NotFound from 'src/Router/NotFound'
import { type DiagnosticMetricItem } from 'src/AssetTemplate/Components/DiagnosticMetric'
import Loader from 'src/AssetTemplate/Components/Loader'
import Statuses from './Statuses'
import EngineStatus from './EngineStatus'
import FuelStatus from './FuelStatus'
import PowerStatus from './PowerStatus'
import Analytics from './Analytics'

interface RouteParams extends Record<string, string | undefined> {
  id: string
}

export default function Dashboard () {
  const { id } = useParams<RouteParams>()
  const { token } = useUser()
  const agentDetails = useApi(getAgentDetails)
  const agent = agentDetails.data.mapOrDefault(data => data, null)
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  useEffect(() => {
    const req = { agentId: parseInt(id) }

    void agentDetails
      .fetch(req, token)
      .finally(() => {
        agentDetails.stopPolling()
        agentDetails.startPolling(req, token, 30)
        setIsInitialLoad(false)
      })

    return () => {
      agentDetails.stopPolling()
      agentDetails.abort()
    }
  }, [id])

  if (agent === null || (isInitialLoad && agentDetails.loading)) {
    return <Loader />
  }

  const {
    agentId,
    agentName,
    assetType,
    metadata,
    geoLocation,
    sensors,
    lastSeenTime,
    connectionStatus
  } = agent

  // Engine sensors
  const engineRuntime = findSensor('ENGINE_RUN_TIME', sensors)
  const engineRuntimeValue = convertStrToFloat(engineRuntime?.currentValue)
  const totalRuntime = engineRuntimeValue > 0
    ? `${secondsToHours(convertStrToFloat(engineRuntime?.currentValue)).toFixed(2)} hr`
    : '0 hr'
  const engineOperateMode = findSensor('ENGINE_OPERATE_MODE', sensors)
  const controlMode = findSensor('CONTROL_MODE', sensors)
  const engineSpeed = findSensor('ENGINE_SPEED', sensors)
  const engineBatteryVoltage = findSensor('ENGINE_BATTERY_VOLTAGE', sensors)
  const coolantTemperature = findSensor('COOLANT_TEMPERATURE', sensors)
  const oilPressure = findSensor('OIL_PRESSURE', sensors)

  // Fuel sensors
  const fuelLevel = findSensor('FUEL_LEVEL', sensors)
  const fuelLevelPercentValue = Math.round(convertStrToFloat(fuelLevel?.currentValue))
  const fuelLevelLiter = findSensor('FUEL_LEVEL_L', sensors)
  const fuelLevelLiterValue = Math.round(convertStrToFloat(fuelLevelLiter?.currentValue))
  const fuelConsumption = findSensor('FUEL_CONSUMPTION', sensors)
  const fuelCapacity = metadata?.FUEL_CAPACITY?.value ?? '0'
  const fuelCapacityLiterValue = Math.round(convertStrToFloat(fuelCapacity))
  const fuelRemainingPercent = 100 - fuelLevelPercentValue
  const fuelRemainingLiter = (fuelRemainingPercent / 100) * fuelCapacityLiterValue
  const fuelConsumptionLiterValue = Math.round(convertStrToFloat(fuelConsumption?.currentValue))

  // Power sensors
  const powerLevel = findSensor('POWER_LEVEL_KVA', sensors)
  const powerLevelKvaValue = convertStrToFloat(powerLevel?.currentValue)
  const apparentPower = findSensor('APPARENT_POWER', sensors)
  const powerCapacity = metadata?.POWER_CAPACITY?.value ?? '0'
  const powerCapacityKvaValue = convertStrToFloat(powerCapacity)
  const powerLevelPercent = Math.round(powerCapacityKvaValue > 0
    ? powerLevelKvaValue / powerCapacityKvaValue
    : 0)
  const outputPower = findSensor('ACTIVE_POWER', sensors)
  const powerFactor = findSensor('POWER_FACTOR_THREE_PHASE', sensors)
  const powerFrequency = findSensor('FREQUENCY', sensors)
  const energyProduced = findSensor('ACTIVE_THREE_PHASE', sensors)
  const voltageLNA = findSensor('RMS_VOLTAGE_L1N', sensors)
  const voltageLNB = findSensor('RMS_VOLTAGE_L2N', sensors)
  const voltageLNC = findSensor('RMS_VOLTAGE_L3N', sensors)
  const voltageLLA = findSensor('RMS_LINE_VOLTAGE_L1_L2', sensors)
  const voltageLLB = findSensor('RMS_LINE_VOLTAGE_L2_L3', sensors)
  const voltageLLC = findSensor('RMS_LINE_VOLTAGE_L3_L1', sensors)
  const currentA = findSensor('RMS_LINE_CURRENT_L1', sensors)
  const currentB = findSensor('RMS_LINE_CURRENT_L2', sensors)
  const currentC = findSensor('RMS_LINE_CURRENT_L3', sensors)
  const statusConnectivity = connectionStatus === 'ONLINE'
    ? 'CONNECTED'
    : 'DISCONNECTED'

  const diagnosticMetrics: DiagnosticMetricItem[] = [
    {
      sensorInstanceId: fuelLevel?.sensorInstanceId ?? -1,
      name: 'Fuel Level',
      icon: <IconDroplet size={16} />,
      value: fuelLevelPercentValue,
      displayValue: fuelLevelPercentValue.toString(),
      displayValueUnit: '%',
      label: `${fuelLevelLiterValue} / ${fuelCapacityLiterValue} L`,
      disabled: fuelLevel === null
    },
    {
      sensorInstanceId: powerLevel?.sensorInstanceId ?? -1,
      name: 'Power Capacity',
      icon: <IconBolt size={16} />,
      value: powerLevelPercent,
      displayValue: powerLevelPercent.toString(),
      displayValueUnit: '%',
      label: `${powerLevelKvaValue} / ${powerCapacityKvaValue} kVa`,
      disabled: powerLevel === null
    },
    {
      sensorInstanceId: engineSpeed?.sensorInstanceId ?? -1,
      name: 'Engine Speed',
      icon: <IconGauge size={16} />,
      value: convertStrToFloat(engineSpeed?.currentValue),
      displayValue: getSensorValue(engineSpeed, true, false),
      displayValueUnit: engineSpeed?.unit ?? '',
      disabled: engineSpeed === null
    },
    {
      sensorInstanceId: engineBatteryVoltage?.sensorInstanceId ?? -1,
      name: 'Battery Voltage',
      icon: <IconBatteryCharging2 size={16} />,
      value: convertStrToFloat(engineBatteryVoltage?.currentValue),
      displayValue: getSensorValue(engineBatteryVoltage, true, false),
      displayValueUnit: engineBatteryVoltage?.unit ?? '',
      disabled: engineBatteryVoltage === null
    }
  ]

  return (
    <AssetTemplate
      agentId={agentId}
      agentName={agentName}
      assetType={assetType}
      metadata={metadata}
      geoLocation={geoLocation}
      diagnosticMetrics={diagnosticMetrics}
      statuses={(
        <Statuses
          alarms={sensors.filter(sensor => sensor.alarmStatus === 'ALARM').length}
          engineStatus={engineOperateMode}
          controlMode={controlMode}
        />
      )}
      connectivityStatus={statusConnectivity}
      lastDataTransfer={lastSeenTime}
      totalAssetRuntime={totalRuntime}
    >
      <EngineStatus
        engineSpeed={engineSpeed}
        engineBatteryVoltage={engineBatteryVoltage}
        coolantTemperature={coolantTemperature}
        oilPressure={oilPressure}
      />
      <FuelStatus
        fuelLevel={fuelLevel}
        fuelLevelPercent={fuelLevelPercentValue}
        fuelRemainingPercent={fuelRemainingPercent}
        fuelRemainingLiter={fuelRemainingLiter}
        fuelLevelLiter={fuelLevelLiterValue}
        fuelCapacityLiter={fuelCapacityLiterValue}
        fuelConsumption={fuelConsumption}
        fuelConsumptionLiter={fuelConsumptionLiterValue}
      />
      <PowerStatus
        apparentPower={apparentPower}
        outputPower={outputPower}
        powerFactor={powerFactor}
        powerFrequency={powerFrequency}
        energyProduced={energyProduced}
        voltageLN={{
          a: voltageLNA,
          b: voltageLNB,
          c: voltageLNC
        }}
        voltageLL={{
          a: voltageLLA,
          b: voltageLLB,
          c: voltageLLC
        }}
        current={{
          a: currentA,
          b: currentB,
          c: currentC
        }}
      />
      <Analytics agentId={agentId} sensors={sensors} />
    </AssetTemplate>
  )
}
