import {
  type Icon,
  IconAffiliate,
  IconBatteryVerticalCharging,
  IconBell,
  IconBellPlus,
  IconBinaryTree2,
  IconBolt,
  IconBox,
  IconBriefcase,
  IconCalendarClock,
  IconCar,
  IconCarouselHorizontal,
  IconChartDots,
  IconChartPie,
  IconChartTreemap,
  IconChecklist,
  IconClipboardList,
  IconComponents,
  IconCpu2,
  IconCylinder,
  IconDashboard,
  IconDevices,
  IconEngine,
  IconFileImport,
  IconGizmo,
  IconKeyframes,
  IconLogs,
  IconMap,
  IconMist,
  IconPalette,
  IconPhotoSensor2,
  IconPolygon,
  IconReport,
  IconReportSearch,
  IconScale,
  IconSettings,
  IconSettingsSearch,
  IconSparkles,
  IconSpeakerphone,
  IconTemplate,
  IconUser,
  IconUserCheck,
  IconUsersGroup
} from '@tabler/icons-react'
import type { Role } from 'src/NeedsRole'

export interface MenuItem {
  title: string
  path: string
  role?: Role | Role[]
  Icon: Icon
  selector?: string
  children: MenuItem[]
  isHidden?: boolean
}

// TODO: Make this partially dynamic

export const menuList: MenuItem[] = [
  {
    title: 'Map',
    path: '/',
    Icon: IconMap,
    selector: 'tour-map__menu',
    children: []
  },
  {
    title: 'Demo',
    path: '/demo',
    Icon: IconDashboard,
    children: [
      {
        title: 'Adaptive UI',
        path: '/demo/adaptive-ui',
        Icon: IconChartTreemap,
        children: []
      },
      {
        title: 'Asset Template',
        path: '/demo/asset-template',
        Icon: IconKeyframes,
        children: []
      },
      {
        title: 'Components',
        path: '/demo/components',
        Icon: IconChartPie,
        children: []
      }
    ],
    isHidden: true
  },
  {
    title: 'Dashboard',
    path: '/my-dashboard',
    Icon: IconChartDots,
    selector: 'tour-dashboard__menu',
    children: []
  },
  {
    title: 'Assets',
    path: '/assets',
    Icon: IconBox,
    children: [
      {
        title: 'ATS',
        path: '/assets/ats',
        Icon: IconGizmo,
        selector: 'tour-asset__menu',
        children: []
      },
      {
        title: 'Generator',
        path: '/assets/genset',
        Icon: IconEngine,
        children: []
      },
      {
        title: 'Generic',
        path: '/assets/generic',
        Icon: IconComponents,
        children: []
      },
      {
        title: 'PDD',
        path: '/assets/duress',
        Icon: IconSpeakerphone,
        children: []
      },
      {
        title: 'Power meter',
        path: '/assets/power-meter',
        Icon: IconBolt,
        children: []
      },
      {
        title: 'Tanks',
        path: '/assets/tank',
        Icon: IconCylinder,
        children: []
      },
      {
        title: 'UPS',
        path: '/assets/ups',
        Icon: IconBatteryVerticalCharging,
        children: []
      },
      {
        title: 'Vehicles',
        path: '/assets/vehicles',
        Icon: IconCar,
        children: []
      }
    ]
  },
  {
    title: 'Sites',
    path: '/sites',
    Icon: IconBinaryTree2,
    children: []
  },
  {
    title: 'Reports',
    path: '/reports',
    Icon: IconReport,
    selector: 'tour-report__menu',
    children: [
      {
        title: 'Viewer',
        path: '/reports/viewer',
        Icon: IconReportSearch,
        children: []
      },
      {
        title: 'Scheduled',
        path: '/reports/scheduled',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        Icon: IconCalendarClock,
        children: []
      },
      {
        title: 'Generated',
        path: '/reports/generated',
        Icon: IconFileImport,
        children: []
      }
    ]
  },
  {
    title: 'Alarm history',
    path: '/alarms',
    Icon: IconBell,
    children: []
  },
  {
    title: 'Tasks',
    path: '/tasks',
    Icon: IconChecklist,
    selector: 'tour-asset-task__menu',
    children: []
  },
  {
    title: 'Settings',
    path: '/settings',
    role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
    Icon: IconSettings,
    children: [
      {
        title: 'Agent config',
        path: '/settings/agents',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        Icon: IconSettingsSearch,
        children: []
      },
      {
        title: 'Agent groups',
        path: '/settings/agent-groups',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        Icon: IconCarouselHorizontal,
        children: []
      },
      {
        title: 'Alarm actions',
        path: '/settings/alarm-actions',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        Icon: IconSparkles,
        children: []
      },
      {
        title: 'Geozones',
        path: '/settings/geozones',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        Icon: IconPolygon,
        children: []
      },
      {
        title: 'Organisation',
        path: '/settings/org',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        Icon: IconAffiliate,
        children: []
      },
      {
        title: 'Sites',
        path: '/settings/sites',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        Icon: IconBinaryTree2,
        children: []
      },
      {
        title: 'Users',
        path: '/settings/users',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        Icon: IconUser,
        children: []
      },
      {
        title: 'User groups',
        path: '/settings/user-groups',
        role: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        Icon: IconUsersGroup,
        children: []
      }
    ]
  },
  {
    title: 'Admin',
    path: '/my-admin',
    role: ['ROLE_SUPERADMIN'],
    Icon: IconBriefcase,
    children: [
      {
        title: 'Agent logs',
        path: '/my-admin/agent/logs',
        role: ['ROLE_SUPERADMIN'],
        Icon: IconClipboardList,
        children: []
      },
      {
        title: 'Agent templates',
        path: '/my-admin/agent-templates',
        role: ['ROLE_SUPERADMIN'],
        Icon: IconTemplate,
        children: []
      },
      {
        title: 'Alarm webhooks',
        path: '/my-admin/alarm-webhooks',
        role: ['ROLE_SUPERADMIN'],
        Icon: IconBellPlus,
        children: []
      },
      {
        title: 'Camera Events',
        path: '/my-admin/camera-events',
        role: ['ROLE_SUPERADMIN'],
        Icon: IconPhotoSensor2,
        children: []
      },
      {
        title: 'Event logs',
        path: '/my-admin/event-logs',
        role: ['ROLE_SUPERADMIN'],
        Icon: IconLogs,
        children: []
      },
      {
        title: 'Inmarsat logs',
        path: '/my-admin/inmarsat-logs',
        role: ['ROLE_SUPERADMIN'],
        Icon: IconMist,
        children: []
      },
      {
        title: 'Modbus devices',
        path: '/my-admin/modbus-devices',
        role: ['ROLE_SUPERADMIN'],
        Icon: IconDevices,
        children: []
      },
      {
        title: 'Organisations',
        path: '/my-admin/organisations',
        role: ['ROLE_SUPERADMIN'],
        Icon: IconAffiliate,
        children: []
      },
      {
        title: 'Roles',
        path: '/my-admin/roles',
        role: ['ROLE_SUPERADMIN'],
        Icon: IconUserCheck,
        children: []
      },
      {
        title: 'Scale Factor',
        path: '/my-admin/scale-factor',
        role: ['ROLE_SUPERADMIN'],
        Icon: IconScale,
        children: []
      },
      {
        title: 'Sensors',
        path: '/my-admin/sensors',
        role: ['ROLE_SUPERADMIN'],
        Icon: IconCpu2,
        children: []
      },
      {
        title: 'Theme',
        path: '/my-admin/theme',
        role: ['ROLE_SUPERADMIN'],
        Icon: IconPalette,
        children: []
      }
    ]
  }
]

export const iconDirectory: Record<string, Icon> = menuList.reduce((acc, { children, title, Icon }) => {
  let icons = {
    [title]: Icon
  }
  if (children.length > 0) {
    children.forEach(({ title, Icon }) => {
      icons = {
        ...icons,
        [title]: Icon
      }
    })
  }
  return {
    ...acc,
    ...icons
  }
}, {})
