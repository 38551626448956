/* eslint-disable react/jsx-curly-newline */
import { Routes, Route } from 'react-router-dom'
import { adminRoles } from 'src/utils/role'
import Container from 'src/Layout/Page'

import AdaptiveUiDemo from 'src/Demo/AdaptiveUiDemo'
import AssetTemplateDemo from 'src/Demo/AssetTemplateDemo'
import ComponentsDemo from 'src/Demo/ComponentsDemo'

import UserProfile from 'src/UserProfile'

// import Home from 'src/Home'
import GeoZoneMap from 'src/Maps/GeoZoneMap'
import Dashboard from 'src/Dashboard'
import AgentDetails from 'src/AgentDetails'
// Reports
import ReportsViewer from 'src/Reports/Viewer'
import ScheduledReports from 'src/Reports/Scheduled'
import EditScheduledReports from 'src/Reports/Scheduled/Edit'
import GeneratedReports from 'src/Reports/Generated'
import GeneratedReportDetails from 'src/Reports/Generated/Details'

// Sites
import Sites from 'src/Sites'
import SiteDetails from 'src/Sites/Details'
// Alarm history
import AlarmHistory from 'src/AlarmHistory'
import IncidentReport from 'src/AlarmHistory/Incident'

// Assets
import AtsOverview from 'src/Assets/AtsOverview'
import AtsDashboard from 'src/Assets/AtsDashboard'
// import CameraOverview from 'src/Assets/CameraOverview'
import GensetOverview from 'src/Assets/GensetOverview'
import GensetDashboard from 'src/Assets/GensetOverview/Dashboard'
import GenericOverview from 'src/Assets/GenericOverview'
import GenericDashboard from 'src/Assets/GenericOverview/Dashboard'
import PddOverview from 'src/Assets/PddOverview'
import PddDashboard from 'src/Assets/PddDashboard'
import PddLocation from 'src/Assets/PddOverview/Location'
import PowerMeterOverview from 'src/Assets/PowerMeterOverview'
import PowerMeterDashboard from 'src/Assets/PowerMeterDashboard'
import TankOverview from 'src/Assets/TankOverview'
import TankDashboard from 'src/Assets/TankOverview/Dashboard'
import TankTransactions from 'src/Assets/TankOverview/Transactions'
import UpsOverview from 'src/Assets/UpsOverview'
import UpsDashboard from 'src/Assets/UpsDashboard'
import VehicleOverview from 'src/Assets/VehicleOverview'
import VehicleTransactions from 'src/Assets/VehicleOverview/Transactions'

// Users settings
import Users from 'src/Settings/Users'
import EditUser from 'src/Settings/Users/Edit'

// Assets settings
import Assets from 'src/Settings/Assets'
import EditAsset from 'src/Settings/Assets/Edit'

// Sites settings
import AdminSites from 'src/Settings/Sites'
import EditSite from 'src/Settings/Sites/Edit'

// Theme settings
import Theme from 'src/Admin/Theme'

// Agents settings
import Agents from 'src/Settings/Agents'
import ConfigureAgent from 'src/Settings/Agents/Configure'
import ConfigureSensors from 'src/Settings/Agents/Sensors'

// AgentGroups settings
import AgentGroups from 'src/Settings/AgentGroups'
import EditAgentGroup from 'src/Settings/AgentGroups/Edit'

// Alarm Actions settings
import AlarmActions from 'src/Settings/AlarmActions'
import EditAlarmAction from 'src/Settings/AlarmActions/Edit'

// GeoZones settings
import GeoZones from 'src/Settings/GeoZones'

// Agent Templates settings
import AgentTemplates from 'src/Admin/AgentTemplates'

// UserGroups settings
import UserGroups from 'src/Settings/UserGroups'
import EditUserGroup from 'src/Settings/UserGroups/Edit'

// Org settings
import OrgSettings from 'src/Settings/Organisation'

// Organisations admin
import Orgs from 'src/Admin/Orgs'
import EditOrg from 'src/Admin/Orgs/Edit'

// Agent Logs admin
import AgentLogs from 'src/Admin/AgentLogs'

// Field Gateway config admin
import FieldGatewayConfig from 'src/Admin/FieldGatewayConfig'

// Sensor admin
import Sensors from 'src/Admin/Sensors'
import EditSensor from 'src/Admin/Sensors/Edit'

// Sensor admin
import ScaleFactor from 'src/Admin/ScaleFactor'
import EditScaleFactor from 'src/Admin/ScaleFactor/Edit'

// Modbus admin
import Modbus from 'src/Admin/Modbus'
import EditModbus from 'src/Admin/Modbus/Edit'
import EditRegistersModbus from 'src/Admin/Modbus/Registers'

// Alarm Webhooks admin
import AlarmWebhooks from 'src/Admin/AlarmWebhooks'
import EditAlarmWebhook from 'src/Admin/AlarmWebhooks/Edit'

// Roles admin
import Roles from 'src/Admin/Roles'
import EditRole from 'src/Admin/Roles/Edit'

// Event Logs admin
import EventLogs from 'src/Admin/EventLogs'

// Inmarsat Logs admin
import InmarsatLogs from 'src/Admin/InmarsatLogs'

// Custom route with title support
import { agentLogsState, alarmHistoryState } from 'src/UIState'
import Tasks from 'src/Tasks'
import EditAgentIssue from 'src/Tasks/Edit'
import TasksDetails from 'src/Tasks/Details'
import { type ReactElement } from 'react'
import CameraEvents from 'src/Admin/CameraEvents'
import Event from 'src/Admin/CameraEvents/Event'
import VehicleDashboard from 'src/Assets/VehicleDashboard'
import Page from './Page'
import NotFound from './NotFound'

interface Props {
  expandBtn?: ReactElement
  fullView?: boolean
}

export default function Router ({ expandBtn, fullView }: Props) {
  return (
    <Routes>
      <Route
        path="/user-profile"
        element={(
          <Page title="User Profile">
            <Container>
              <UserProfile />
            </Container>
          </Page>
        )}
      />

      {/* Main pages */}
      <Route
        path="/"
        element={(
          <Page title="Map">
            <GeoZoneMap expandBtn={expandBtn} fullView={fullView} />
          </Page>
        )}
      />

      <Route
        path="/my-dashboard"
        element={(
          <Page title="Dashboard">
            <Container>
              <Dashboard />
            </Container>
          </Page>
        )}
      />

      <Route
        path="/agent/:agentId"
        element={(
          <Page>
            <Container>
              <AgentDetails />
            </Container>
          </Page>
        )}
      />

      {/* Assets */}
      <Route
        path="/assets/ats"
        element={(
          <Page title="ATS">
            <Container>
              <AtsOverview />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/ats/:assetId"
        element={(
          <Page title="ATS details">
            <Container breadcrumbs={[{ title: 'ATS', href: '/assets/ats' }, { title: 'ATS details' }]}>
              <AtsDashboard />
            </Container>
          </Page>
        )}
      />
      {/* <Route
        path="/assets/cameras"
        element={(
          <Page title="Cameras">
            <Container>
              <CameraOverview />
            </Container>
          </Page>
        )}
      /> */}
      <Route
        path="/assets/genset"
        element={(
          <Page title="Generators">
            <Container>
              <GensetOverview />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/genset/:id"
        element={(
          <Page title="Generator details">
            <Container noVerticalPadding>
              <GensetDashboard />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/generic"
        element={(
          <Page title="Generic">
            <Container>
              <GenericOverview />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/generic/:id"
        element={(
          <Page title="Generic asset details">
            <Container noVerticalPadding>
              <GenericDashboard />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/duress"
        element={(
          <Page title="Personal Duress Devices">
            <Container>
              <PddOverview />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/duress/:assetId"
        element={(
          <Page title="PDD details">
            <Container
              breadcrumbs={[
                { title: 'Personal Duress Devices', href: '/assets/duress' },
                { title: 'PDD details' }
              ]}
            >
              <PddDashboard />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/duress/:assetId/map"
        element={(
          <Page title="PDD Location">
            <PddLocation expandBtn={expandBtn} />
          </Page>
        )}
      />
      <Route
        path="/assets/power-meter"
        element={(
          <Page title="Power Meter">
            <Container>
              <PowerMeterOverview />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/power-meter/:assetId"
        element={(
          <Page title="Power meter details">
            <Container breadcrumbs={[
              { title: 'Power Meter', href: '/assets/power-meter' },
              { title: 'Power meter details' }
            ]}
            >
              <PowerMeterDashboard />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/tank"
        element={(
          <Page title="Tanks">
            <Container>
              <TankOverview />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/tank/:assetId"
        element={(
          <Page title="Tank details">
            <Container noVerticalPadding>
              <TankDashboard />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/tank/:assetId/transactions"
        element={(
          <Page title="Tank Transactions">
            <Container>
              <TankTransactions />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/ups"
        element={(
          <Page title="UPS">
            <Container>
              <UpsOverview />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/ups/:assetId"
        element={(
          <Page title="UPS details">
            <Container breadcrumbs={[{ title: 'UPS', href: '/assets/ups' }, { title: 'UPS details' }]}>
              <UpsDashboard />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/vehicles"
        element={(
          <Page title="Vehicles">
            <Container>
              <VehicleOverview />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/vehicle/:assetId"
        element={(
          <Page title="Vehicles">
            <Container breadcrumbs={[{ title: 'Vehicles', href: '/assets/vehicles' }, { title: 'Vehicle Details' }]}>
              <VehicleDashboard />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/vehicle/:assetId/transactions"
        element={(
          <Page title="Vehicle Transactions">
            <Container>
              <VehicleTransactions />
            </Container>
          </Page>
        )}
      />

      {/* Sites */}
      <Route
        path="/sites"
        element={(
          <Page title="Sites">
            <Container>
              <Sites />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/site/:siteId"
        element={(
          <Page>
            <Container breadcrumbs={[{ title: 'Sites', href: '/Sites' }, { title: 'Site details' }]}>
              <SiteDetails />
            </Container>
          </Page>
        )}
      />

      {/* Reports */}
      <Route
        path="/reports/viewer"
        element={(
          <Page title="Reports Viewer">
            <Container noVerticalPadding>
              <ReportsViewer />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/reports/scheduled"
        element={(
          <Page title="Scheduled Reports" role={['ROLE_SUPERADMIN', 'ROLE_ADMIN']}>
            <Container>
              <ScheduledReports />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/reports/scheduled/edit/:reportScheduleId"
        element={(
          <Page title="Edit Scheduled Report">
            <Container
              breadcrumbs={[
                { title: 'Scheduled Reports', href: '/reports/scheduled' },
                { title: 'Edit Report' }
              ]}
            >
              <EditScheduledReports />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/reports/generated"
        element={(
          <Page title="Generated Reports">
            <Container noVerticalPadding>
              <GeneratedReports />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/reports/generated/:generatedReportId"
        element={(
          <Page title="Reports">
            <Container noVerticalPadding>
              <GeneratedReportDetails />
            </Container>
          </Page>
        )}
      />

      {/* Alarms */}
      <Route
        path="/alarms"
        element={(
          <Page title="Alarm history">
            <Container>
              <AlarmHistory state={alarmHistoryState} />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/alarms/incident/:incidentId"
        element={(
          <Page>
            <Container breadcrumbs={[{ title: 'Alarm history', href: '/alarms' }, { title: 'Incident report' }]}>
              <IncidentReport />
            </Container>
          </Page>
        )}
      />

      {/* Tasks */}
      <Route
        path="/tasks"
        element={(
          <Page title="Tasks">
            <Container>
              <Tasks />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/tasks/edit/:taskId"
        element={(
          <Page title="Edit Task">
            <Container form
              breadcrumbs={[
                {
                  title: 'Tasks',
                  href: '/tasks'
                }, { title: 'Edit Task' }]}
            >
              <EditAgentIssue />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/tasks/details/:taskId"
        element={(
          <Page title="Details" role={adminRoles}>
            <Container
              breadcrumbs={[
                {
                  title: 'Tasks',
                  href: '/tasks'
                }, { title: 'Details' }]}
            >
              <TasksDetails />
            </Container>
          </Page>
        )}
      />

      {/* Assets settings */}
      <Route
        path="/settings/assets"
        element={(
          <Page title="Assets" role={adminRoles}>
            <Container>
              <Assets />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/settings/assets/edit/:assetId"
        element={(
          <Page title="Edit asset" role={adminRoles}>
            <Container form breadcrumbs={[{ title: 'Assets', href: '/settings/assets' }, { title: 'Edit asset' }]}>
              <EditAsset />
            </Container>
          </Page>
        )}
      />

      {/* Agents settings */}
      <Route
        path="/settings/agents"
        element={(
          <Page title="Agent config" role={adminRoles}>
            <Container>
              <Agents />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/settings/agents/:agentId"
        element={(
          <Page role={adminRoles}>
            <Container breadcrumbs={[{ title: 'Agent config', href: '/settings/agents' }, { title: 'Configure' }]}>
              <ConfigureAgent />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/settings/agents/:agentId/sensors"
        element={(
          <Page role={adminRoles}>
            <Container breadcrumbs={[{ title: 'Agent config', href: '/settings/agents' }, { title: 'Edit sensors' }]}>
              <ConfigureSensors />
            </Container>
          </Page>
        )}
      />

      {/* AgentGroups settings */}
      <Route
        path="/settings/agent-groups"
        element={(
          <Page title="Agent Groups" role={adminRoles}>
            <Container>
              <AgentGroups />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/settings/agent-groups/edit/:agentGroupId"
        element={(
          <Page title="Edit Agent Group" role={adminRoles}>
            <Container
              form
              breadcrumbs={[
                { title: 'Agent Groups', href: '/settings/agent-groups' },
                { title: 'Edit Agent Group' }
              ]}
            >
              <EditAgentGroup />
            </Container>
          </Page>
        )}
      />

      {/* Alarm Actions settings */}
      <Route
        path="/settings/alarm-actions"
        element={(
          <Page title="Alarm Actions" role={adminRoles}>
            <Container>
              <AlarmActions />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/settings/alarm-actions/edit/:alarmActionId"
        element={(
          <Page title="Edit Alarm Action" role={adminRoles}>
            <Container
              form
              breadcrumbs={[
                { title: 'Alarm actions', href: '/settings/alarm-actions' },
                { title: 'Edit alarm action' }
              ]}
            >
              <EditAlarmAction />
            </Container>
          </Page>
        )}
      />

      {/* GeoZones settings */}
      <Route
        path="/settings/geozones"
        element={(
          <Page title="Geozones" role={adminRoles}>
            <Container>
              <GeoZones />
            </Container>
          </Page>
        )}
      />

      {/* Sites settings */}
      <Route
        path="/settings/org"
        element={(
          <Page title="Org Settings" role={adminRoles}>
            <Container>
              <OrgSettings />
            </Container>
          </Page>
        )}
      />

      {/* Sites settings */}
      <Route
        path="/settings/sites"
        element={(
          <Page title="Sites" role={adminRoles}>
            <Container>
              <AdminSites />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/settings/sites/edit/:siteId"
        element={(
          <Page title="Edit site" role={adminRoles}>
            <Container form breadcrumbs={[{ title: 'Sites', href: '/settings/sites' }, { title: 'Edit site' }]}>
              <EditSite />
            </Container>
          </Page>
        )}
      />

      {/* Users settings */}
      <Route
        path="/settings/users"
        element={(
          <Page title="Users" role={adminRoles}>
            <Container>
              <Users />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/settings/users/edit/:userId"
        element={(
          <Page title="Edit user" role={adminRoles}>
            <Container form breadcrumbs={[{ title: 'Users', href: '/settings/users' }, { title: 'Edit user' }]}>
              <EditUser />
            </Container>
          </Page>
        )}
      />

      {/* UserGroups settings */}
      <Route
        path="/settings/user-groups"
        element={(
          <Page title="User Groups" role={adminRoles}>
            <Container>
              <UserGroups />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/settings/user-groups/edit/:userGroupId"
        element={(
          <Page title="Edit User Group" role={adminRoles}>
            <Container
              form
              breadcrumbs={[
                { title: 'User Groups', href: '/settings/user-groups' },
                { title: 'Edit User Group' }
              ]}
            >
              <EditUserGroup />
            </Container>
          </Page>
        )}
      />

      {/* Agent Logs admin */}
      <Route
        path="/my-admin/agent/logs"
        element={(
          <Page title="Agent Logs" role="ROLE_SUPERADMIN">
            <Container>
              <AgentLogs state={agentLogsState} />
            </Container>
          </Page>
        )}
      />

      {/* Agent Templates admin */}
      <Route
        path="/my-admin/agent-templates"
        element={(
          <Page title="Agent templates" role="ROLE_SUPERADMIN">
            <Container>
              <AgentTemplates />
            </Container>
          </Page>
        )}
      />

      {/* Alarm Webhooks admin */}
      <Route
        path="/my-admin/alarm-webhooks"
        element={(
          <Page title="Alarm Webhooks" role="ROLE_SUPERADMIN">
            <Container>
              <AlarmWebhooks />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/my-admin/alarm-webhooks/edit/:alarmWebhookId"
        element={(
          <Page title="Edit alarm webhook" role="ROLE_SUPERADMIN">
            <Container
              form
              breadcrumbs={[
                { title: 'Alarm Webhooks', href: '/my-admin/alarm-webhooks' },
                { title: 'Edit alarm webhook' }
              ]}
            >
              <EditAlarmWebhook />
            </Container>
          </Page>
        )}
      />

      {/* Camera events admin */}
      <Route
        path="/my-admin/camera-events"
        element={(
          <Page title="Camera Events" role="ROLE_SUPERADMIN">
            <Container>
              <CameraEvents />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/my-admin/camera-events/:cameraId"
        element={(
          <Page title="Camera Events" role="ROLE_SUPERADMIN">
            <Container>
              <Event />
            </Container>
          </Page>
        )}
      />

      {/* Field Gateway config admin */}
      <Route
        path="/my-admin/field-gateway-config"
        element={(
          <Page title="Field Gateway Config" role="ROLE_SUPERADMIN">
            <Container>
              <FieldGatewayConfig />
            </Container>
          </Page>
        )}
      />

      {/* Event logs admin */}
      <Route
        path="/my-admin/event-logs"
        element={(
          <Page title="Event Logs" role="ROLE_SUPERADMIN">
            <Container>
              <EventLogs />
            </Container>
          </Page>
        )}
      />

      {/* Inmarsat Logs admin */}
      <Route
        path="/my-admin/inmarsat-logs"
        element={(
          <Page title="Inmarsat Logs" role="ROLE_SUPERADMIN">
            <Container>
              <InmarsatLogs />
            </Container>
          </Page>
        )}
      />

      {/* Modbus admin */}
      <Route
        path="/my-admin/modbus-devices"
        element={(
          <Page title="Modbus Devices" role="ROLE_SUPERADMIN">
            <Container>
              <Modbus />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/my-admin/modbus-devices/edit/:deviceId"
        element={(
          <Page title="Edit sensor" role="ROLE_SUPERADMIN">
            <Container
              form
              breadcrumbs={[
                { title: 'Modbus Devices', href: '/my-admin/modbus-devices' },
                { title: 'Edit sensor' }
              ]}
            >
              <EditModbus />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/my-admin/modbus-devices/edit-registers/:deviceId"
        element={(
          <Page title="Edit registers" role="ROLE_SUPERADMIN">
            <Container
              form
              breadcrumbs={[{
                title: 'Modbus Device Register',
                href: '/my-admin/modbus-devices'
              }, { title: 'Edit registers' }]}
            >
              <EditRegistersModbus />
            </Container>
          </Page>
        )}
      />

      {/* Organisations admin */}
      <Route
        path="/my-admin/organisations"
        element={(
          <Page title="Organisations" role="ROLE_SUPERADMIN">
            <Container>
              <Orgs />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/my-admin/organisations/edit/:orgId"
        element={(
          <Page title="Edit organisation" role="ROLE_SUPERADMIN">
            <Container
              form
              breadcrumbs={[
                { title: 'Organisations', href: '/my-admin/organisations' },
                { title: 'Edit organisation' }
              ]}
            >
              <EditOrg />
            </Container>
          </Page>
        )}
      />

      {/* Roles admin */}
      <Route
        path="/my-admin/roles"
        element={(
          <Page title="Roles" role="ROLE_SUPERADMIN">
            <Container>
              <Roles />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/my-admin/roles/edit/:roleId"
        element={(
          <Page title="Edit role" role="ROLE_SUPERADMIN">
            <Container
              form
              breadcrumbs={[{ title: 'Roles', href: '/my-admin/roles' }, { title: 'Edit role' }]}
            >
              <EditRole />
            </Container>
          </Page>
        )}
      />

      {/* Scale factor admin */}
      <Route
        path="/my-admin/scale-factor"
        element={(
          <Page title="Scale Factor" role="ROLE_SUPERADMIN">
            <Container>
              <ScaleFactor />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/my-admin/scale-factor/edit/:id"
        element={(
          <Page title="Edit scale factor" role="ROLE_SUPERADMIN">
            <Container
              form
              breadcrumbs={[
                {
                  title: 'Scale Factor',
                  href: '/my-admin/scale-factor'
                },
                {
                  title: 'Edit scale factor'
                }
              ]}
            >
              <EditScaleFactor />
            </Container>
          </Page>
        )}
      />

      {/* Sensors admin */}
      <Route
        path="/my-admin/sensors"
        element={(
          <Page title="Sensors" role="ROLE_SUPERADMIN">
            <Container>
              <Sensors />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/my-admin/sensors/edit/:sensorId"
        element={(
          <Page title="Edit sensor" role="ROLE_SUPERADMIN">
            <Container form breadcrumbs={[{ title: 'Sensors', href: '/my-admin/sensors' }, { title: 'Edit sensor' }]}>
              <EditSensor />
            </Container>
          </Page>
        )}
      />

      {/* Theme admin */}
      <Route
        path="/my-admin/theme"
        element={(
          <Page title="Manage theme" role="ROLE_SUPERADMIN">
            <Container>
              <Theme />
            </Container>
          </Page>
        )}
      />

      {/* Pages for demo purposes only */}
      <Route
        path="/demo/adaptive-ui"
        element={(
          <Page title="Adaptive UI">
            <Container noVerticalPadding>
              <AdaptiveUiDemo />
            </Container>
          </Page>
        )}
      />

      <Route
        path="/demo/asset-template"
        element={(
          <Page title="Asset Template">
            <Container noVerticalPadding>
              <AssetTemplateDemo />
            </Container>
          </Page>
        )}
      />

      <Route
        path="/demo/components"
        element={(
          <Page title="Components Demo">
            <Container>
              <ComponentsDemo />
            </Container>
          </Page>
        )}
      />

      {/* 404 Route. Do not place any after this! */}
      <Route
        path="*"
        element={(
          <Page title="Page not found">
            <NotFound />
          </Page>
        )}
      />
    </Routes>
  )
}
