import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useMantineTheme, Group, Title } from '@mantine/core'
import { randomId } from '@mantine/hooks'
import { AreaStack, Bar, Line } from '@visx/shape'
import { GridRows, GridColumns } from '@visx/grid'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { scaleTime, scaleLinear } from '@visx/scale'
import { type SeriesPoint } from '@visx/shape/lib/types'
import { LinearGradient } from '@visx/gradient'
import { defaultStyles as tooltipStyles, TooltipWithBounds, withTooltip } from '@visx/tooltip'
import { type WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip'
import { localPoint } from '@visx/event'
import { Text } from '@visx/text'
import { bisector, max, min } from 'd3-array'
import { truncateWithEllipsis } from 'src/utils/strings'
import { round } from 'src/utils/math'
import { generateAreaColors } from 'src/utils/style'
import { formatNumber } from 'src/utils/numbers'
import { curves, type CurveType, type ColorGradient, type Margin } from './shared'
import { formatDateTime } from './utils'

type Data = Record<string, string>

type AreaColors = Record<string, ColorGradient>

interface AreaColorsMap {
  id: string
  color: ColorGradient
}

export interface Theme {
  titleColor: React.CSSProperties['color']
  textColor: React.CSSProperties['color']
  backgroundColor: ColorGradient
  areaColors?: AreaColors
  axisColor: React.CSSProperties['color']
}

interface Styles {
  width: number
  height: number
  margin: Margin
  theme?: Theme
  curveType?: CurveType
  withoutTitle?: boolean
  hideTotalStackedValue?: boolean
  hideRecentStackedValue?: boolean
  withoutXAxis?: boolean
  withoutYAxis?: boolean
  withoutGridRows?: boolean
  withoutGridColumns?: boolean
  withoutTooltip?: boolean
}

export const defaultStyles: Styles = {
  width: 500,
  height: 300,
  margin: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  curveType: 'linear',
  withoutTitle: false,
  hideTotalStackedValue: false,
  hideRecentStackedValue: false,
  withoutXAxis: false,
  withoutYAxis: false,
  withoutGridRows: false,
  withoutGridColumns: false,
  withoutTooltip: false
}

export interface Props {
  id: string
  title: string
  data: Data[]
  xProp?: string
  styles: Styles
}

export default memo(withTooltip<Props, Data>(
  ({
    title,
    data,
    xProp = 'date',
    styles = defaultStyles,
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0
  }: Props & WithTooltipProvidedProps<Data>) => {
    const chartId = randomId()
    const svgRef = useRef<SVGSVGElement>(null)
    const [tooltipTotalStackedValue, setTooltipTotalStackedValue] = useState(0)
    const { colors, colorScheme } = useMantineTheme()
    const isLight = colorScheme === 'light'
    const {
      width,
      height,
      margin,
      theme,
      curveType,
      withoutTitle,
      hideTotalStackedValue,
      hideRecentStackedValue,
      withoutXAxis,
      withoutYAxis,
      withoutGridRows,
      withoutGridColumns,
      withoutTooltip
    } = styles
    const hasData = data.length > 0
    const sortedData = hasData
      ? data.sort((a, b) => (a[xProp] > b[xProp] ? 1 : -1))
      : []

    const items = typeof data[0] !== 'undefined'
      ? Object.keys(data[0]).filter(k => k !== xProp)
      : []

    const containerSize = width + height
    const innerWidth = width - (margin.left + margin.right)
    const innerHeight = height - (margin.top + margin.bottom)
    const axisFontSize = '.8rem'

    // set default theme
    const titleColor = theme?.titleColor ?? isLight
      ? colors.dark[6]
      : colors.gray[4]
    const textColor = theme?.textColor ?? isLight
      ? colors.dark[5]
      : colors.gray[2]

    const defaultAreaColorId = `${chartId}-default-area-color`
    const defaultAreaColorUrl = `url(#${defaultAreaColorId})`
    const defaultAreaColor: ColorGradient = {
      primary: colors.primary[5],
      secondary: colors.primary[2]
    }

    const parseAreaColorId = useCallback((item: string) => (
      `${chartId}-${item.replace(/[^A-Za-z0-9]+/g, '')}`
    ), [chartId])

    const areaColors: AreaColors = theme?.areaColors ?? generateAreaColors(items)

    const areaColorsMap: Map<string, AreaColorsMap> = useMemo(() => {
      const areaColorsMap_ = new Map()

      for (const key of Object.keys(areaColors)) {
        areaColorsMap_.set(key, {
          id: parseAreaColorId(key),
          color: areaColors[key]
        })
      }

      return areaColorsMap_
    }, [areaColors, parseAreaColorId])

    const baseColor = isLight
      ? colors.white[0]
      : colors.dark[9]
    const backgroundGradientId = `${chartId}-background-gradient`
    const backgroundColor: ColorGradient = theme?.backgroundColor ?? {
      primary: baseColor,
      secondary: baseColor
    }

    const axisColor = theme?.axisColor ?? colors.dark[1]

    const getValueX = (d: Data) => d?.[xProp]
      ? new Date(formatDateTime(new Date(d[xProp]))).valueOf()
      : 0
    const bisectDate = bisector<Data, Date>(getValueX).left
    const getValueY0 = (d: SeriesPoint<Data>) => d[0]
    const getValueY1 = (d: SeriesPoint<Data>) => d[1]

    const computeTotalStackedValue = useCallback((data: Data) => {
      const total = items.reduce((total, key) => total + parseFloat(data[key]), 0)
      return round(total)
    }, [items])

    const getMinValue = useCallback((data: Data) => {
      const total = items.reduce<number[]>((acc, key) => {
        return [...acc, parseFloat(data[key])]
      }, [])

      return min(total) ?? 0
    }, [items])

    const maxTotalStackedValue = useMemo(() => {
      const stackedValues = sortedData.map(data => computeTotalStackedValue(data))
      return max(stackedValues) ?? 0
    }, [sortedData])

    const minTotalStackedValue = useMemo(() => {
      return Math.min(...sortedData.map(data => getMinValue(data))) ?? 0
    }, [sortedData])

    const recentTotalStackedValue = sortedData.length
      ? computeTotalStackedValue(sortedData[sortedData.length - 1])
      : 0

    const scaleX = scaleTime<number>({
      range: [margin.left, innerWidth + margin.left],
      domain: [
        Math.min(...sortedData.map(getValueX)),
        Math.max(...sortedData.map(getValueX))
      ]
    })
    const scaleY = scaleLinear<number>({
      range: [innerHeight + margin.top, margin.top],
      domain: [
        minTotalStackedValue < 0
          ? minTotalStackedValue
          : 0,
        maxTotalStackedValue > 0
          ? (maxTotalStackedValue + 1) + (maxTotalStackedValue / 5)
          : maxTotalStackedValue
      ],
      nice: true
    })

    const handleTooltip = useCallback(
      (
        event:
        | React.TouchEvent<SVGRectElement>
        | React.MouseEvent<SVGRectElement>
      ) => {
        if (!svgRef.current) return

        const { x } = localPoint(svgRef.current, event) ?? { x: 0 }
        const x0 = scaleX.invert(x)
        const index = bisectDate(sortedData, x0, 1)
        const d0 = sortedData[index - 1]
        const d1 = sortedData[index]
        let d = d0

        if (d1 && getValueX(d1)) {
          d =
            x0.valueOf() - getValueX(d0).valueOf() >
            getValueX(d1).valueOf() - x0.valueOf()
              ? d1
              : d0
        }

        showTooltip({
          tooltipData: d,
          tooltipLeft: scaleX(getValueX(d)),
          tooltipTop: 0
        })
      },
      [showTooltip, scaleY, scaleX]
    )

    useEffect(() => {
      if (tooltipData) {
        const totalStackedValue = computeTotalStackedValue(tooltipData)

        setTooltipTotalStackedValue(totalStackedValue)
      }
    }, [tooltipData])

    return (
      <div>
        <svg ref={svgRef} width={width} height={height}>
          {/* Background */}
          <LinearGradient
            id={backgroundGradientId}
            from={backgroundColor?.primary}
            to={backgroundColor?.secondary}
            rotate="-90"
            opacity={0.5}
          />
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            style={{
              fill: `url(#${backgroundGradientId})`
            }}
          />

          {/* Grid */}
          {!withoutGridRows && (
            <GridRows
              left={margin.left}
              scale={scaleY}
              width={innerWidth}
              strokeDasharray="1,3"
              stroke={axisColor}
              strokeOpacity={0.2}
              pointerEvents="none"
            />
          )}
          {!withoutGridColumns && (
            <GridColumns
              top={margin.top}
              scale={scaleX}
              height={innerHeight}
              strokeDasharray="1,3"
              stroke={axisColor}
              strokeOpacity={0.2}
              pointerEvents="none"
            />
          )}

          {/* Axis */}
          {!withoutYAxis && (
            <AxisLeft
              scale={scaleY}
              tickFormat={(value) => `${formatNumber(value.valueOf())}`}
              left={margin.left}
              stroke={axisColor}
              strokeWidth={1}
              tickStroke="transparent"
              tickLength={3}
              tickLabelProps={() => ({
                fill: axisColor,
                textAnchor: 'end',
                fontSize: axisFontSize,
                fontWeight: 200
              })}
            />
          )}
          {!withoutXAxis && (
            <AxisBottom
              scale={scaleX}
              top={innerHeight + margin.top}
              stroke={axisColor}
              strokeWidth={1}
              tickStroke={axisColor}
              tickLength={3}
              numTicks={width > 520 ? 10 : 5}
              tickLabelProps={() => ({
                fill: axisColor,
                textAnchor: 'middle',
                verticalAnchor: 'middle',
                fontSize: axisFontSize,
                fontWeight: 200
              })}
            />
          )}

          {/* Title */}
          {!withoutTitle && (
            <g>
              {title.length > 20 && <title>{title}</title>}
              <Text
                verticalAnchor="start"
                fontSize={`${containerSize / 38}px`}
                style={{
                  fill: titleColor
                }}
                x={margin.left + 20}
                y={25}
              >
                {truncateWithEllipsis(title, 20)}
              </Text>
            </g>
          )}

          {/* Recent Total Stacked Value */}
          {!hideRecentStackedValue && (
            <Text
              textAnchor="end"
              verticalAnchor="start"
              fontSize={`${containerSize / 35}px`}
              style={{
                fill: titleColor,
                opacity: 0.5
              }}
              x={(width - margin.left) - 20}
              y={24}
            >
              {recentTotalStackedValue}
            </Text>
          )}

          {/* Area Colors */}
          <LinearGradient
            id={defaultAreaColorId}
            from={defaultAreaColor.primary}
            to={defaultAreaColor.secondary}
            toOpacity={0.8}
          />
          {[...areaColorsMap.values()].map(({ id, color }) => (
            <LinearGradient
              id={id}
              key={id}
              from={color.primary}
              to={color.secondary}
              fromOpacity={0.9}
              toOpacity={0.4}
            />
          ))}

          {/* Stacked Areas */}
          {hasData && (
            <AreaStack
              keys={items}
              data={sortedData}
              x={d => scaleX(getValueX(d.data)) ?? 0}
              y0={d => scaleY(getValueY0(d)) ?? 0}
              y1={d => scaleY(getValueY1(d)) ?? 0}
              curve={curves[curveType ?? 'linear']}
            >
              {({ stacks, path }) =>
                stacks.map(stack => {
                  const areaColor = areaColorsMap.get(stack.key)
                  const areaColorUrl = areaColor
                    ? `url(#${areaColor.id})`
                    : defaultAreaColorUrl

                  return (
                    <path
                      key={`stack-${stack.key}`}
                      d={path(stack) ?? ''}
                      stroke={areaColorUrl}
                      strokeWidth={3}
                      fill={areaColorUrl}
                    />
                  )
                })}
            </AreaStack>
          )}

          {/* Tooltip area */}
          {hasData && !withoutTooltip && (
            <Bar
              x={margin.left}
              y={margin.top}
              width={innerWidth}
              height={innerHeight}
              fill="transparent"
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={hideTooltip}
            />
          )}

          {/* Tooltip vertical line */}
          {tooltipData && (
            <Line
              from={{
                x: tooltipLeft,
                y: tooltipTop + margin.top
              }}
              to={{
                x: tooltipLeft,
                y: innerHeight + margin.top
              }}
              stroke={axisColor}
              strokeWidth={2}
              pointerEvents="none"
              strokeDasharray="2,5"
            />
          )}
        </svg>

        {/* Tooltip data */}
        {hasData && !withoutTooltip && tooltipData && (
          <TooltipWithBounds
            key={Math.random()}
            top={tooltipTop + margin.top}
            left={tooltipLeft}
            style={{
              ...tooltipStyles,
              background: backgroundColor.primary,
              opacity: 0.85,
              border: `1px solid ${axisColor}`,
              color: textColor,
              padding: `${containerSize / 70}px`,
              borderRadius: '5px',
              zIndex: 5
            }}
          >
            <Title order={6}>
              {`${title} (${formatDateTime(new Date(getValueX(tooltipData)))})`}
            </Title>
            <ul
              style={{
                listStyle: 'none',
                display: 'flex',
                flexDirection: 'column',
                gap: containerSize / 100,
                padding: '0 5px'
              }}
            >
              {/* Limit displayed items in tooltip */}
              {items
                .filter(item => {
                  const value = tooltipData[item]
                  return (typeof value === 'number' && value > 0) || Number(value) > 0
                })
                .map(item => {
                  const areaColor = areaColorsMap.get(item)?.color
                  const background = areaColor
                    ? `linear-gradient(${areaColor.primary}, ${areaColor.secondary})`
                    : `linear-gradient(${defaultAreaColor.primary}, ${defaultAreaColor.secondary})`

                  return (
                    <li
                      key={item}
                      style={{
                        position: 'relative',
                        fontSize: `${containerSize / 85}px`
                      }}
                    >
                      <Group spacing={10}>
                        <div
                          style={{
                            background,
                            width: `${width / 25}px`,
                            height: '5px',
                            borderRadius: '2px'
                          }}
                        >
                        </div>
                        <Group position="apart">
                          <span>{item}</span>
                          -
                          <span>{tooltipData[item]}</span>
                        </Group>
                      </Group>
                    </li>
                  )
                })}
              {!hideTotalStackedValue && (
                <li>
                  <p
                    style={{
                      fontSize: `${containerSize / 60}px`,
                      fontWeight: 'bold'
                    }}
                  >
                    {`Total: ${tooltipTotalStackedValue}`}
                  </p>
                </li>
              )}
            </ul>
          </TooltipWithBounds>
        )}
      </div>
    )
  }
))
