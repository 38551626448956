import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import { Stack, Text, Paper } from '@mantine/core'
import { isEmpty } from 'src/utils/objects'
import { useState } from 'react'
import Attribute from './Attribute'
import Groups from './Groups'

interface Props {
  agentId: AgentDetails['agentId']
  metadata: AgentDetails['metadata']
}

export default function AssetDetails ({ agentId, metadata }: Props) {
  const [withGroups, setWithGroups] = useState(false)
  const withData = (typeof metadata !== 'undefined' && !isEmpty(metadata)) ||
    withGroups

  return (
    <Paper p={16} radius={4} shadow="xs">
      <Text size={14} weight={600}>Asset Details</Text>
      {!withData && (
        <Text
          mt={24}
          size={12}
          weight={400}
          color="silver"
          align="center"
        >
          No data available
        </Text>
      )}
      <Stack mt={16} spacing={16}>
        {metadata && (
          Object
            .keys(metadata)
            .map(prop => (
              <Attribute
                key={prop}
                label={prop}
                value={metadata[prop].value ?? 'N/A'}
              />
            ))
        )}
        <Groups agentId={agentId} setWithGroups={setWithGroups} />
      </Stack>
    </Paper>
  )
}
