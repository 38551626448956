import { getGeneratedReport, getReports } from '@venturi-io/api/src/config/report'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import Viewer from 'src/Reports/Viewer'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'

interface RouteParams extends Record<string, string | undefined> {
  generatedReportId: string
}

function Details () {
  const { token } = useUser()
  const { generatedReportId: id } = useParams<RouteParams>()
  const types = useApi(getReports)
  const rawReport = useApi(getGeneratedReport)

  useEffect(() => {
    void types.fetch({ page: 1, size: 20 }, token)
    void rawReport.fetch({ generatedReportId: Number(id) }, token)
  }, [id])

  const reportTypes = types.data.mapOrDefault(({ items }) => items, [])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const report = rawReport.data.mapOrDefault(({ data }) => {
    const keys = Object.keys(data)
    const key = keys[0]
    return reportTypes.find(({
      reportType: {
        reportQueryIds
      }
    }) => reportQueryIds.includes(Number(key)))
  }, undefined)

  return rawReport.data.caseOf({
    Nothing: () => null,
    Just: (rawData) => (
      <Viewer
        embeddedReportData={rawData}
        embeddedReport={report}
      />
    )
  })
}

export default Details
