import { type ReactElement, cloneElement, useState, useCallback } from 'react'
import { createStyles, ActionIcon, Tooltip } from '@mantine/core'
import { useHotkeys } from '@mantine/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMeta } from 'src/MetaContext'
import zIndex from 'src/utils/zIndex'
import { mq } from '../../utils/style'
import { type StyleParams, styleParams } from './shared'
import Header from './Header'
import Menu from './Menu'

const pagesWithFullView = ['Map', 'Advanced Routines', 'PDD Location']

const useStyles = createStyles(({
  colors,
  colorScheme,
  breakpoints
}, {
  headerHeight,
  sidebarCollapsedWidth,
  fullView
}: StyleParams) => ({
  app: {
    width: '100vw',
    maxWidth: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    [mq(breakpoints.sm)]: {
      flexDirection: 'row'
    }
  },
  content: {
    backgroundColor: colorScheme === 'light'
      ? colors.gray[0]
      : colors.dark[5],
    position: 'fixed',
    top: fullView
      ? 0
      : headerHeight,
    right: 0,
    left: 0,
    bottom: 0,
    overflowY: 'auto',
    zIndex: fullView
      ? zIndex.pageFullContent
      : 'initial',
    [mq(breakpoints.sm)]: {
      right: 0,
      left: fullView
        ? 0
        : sidebarCollapsedWidth,
      transition: '.2s ease-in-out'
    }
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  fullViewIcon: {
    position: 'absolute',
    bottom: 60,
    right: 27,
    zIndex: zIndex.pageFullViewIcon
  },
  disableScroll: {
    overflowY: 'hidden'
  }
}))

interface Props {
  orgId: number
  children: ReactElement
  signout: () => void
}

export default function AppFrame ({ orgId, children, signout }: Props) {
  const { meta } = useMeta()
  const [fullView, setFullView] = useState(false)
  const { classes, cx } = useStyles({ ...styleParams, fullView })
  const [show, setShow] = useState(false)
  const hasFullPageView = pagesWithFullView.includes(meta.pageTitle)

  const toggleFullView = useCallback(() => {
    setFullView(!fullView)
  }, [fullView])

  useHotkeys([
    ['ctrl + F11', toggleFullView]
  ])

  const expandBtn = hasFullPageView && (
    <Tooltip
      label={fullView ? 'Exit Full Screen' : 'Full Screen'}
      position="left"
      transitionProps={{
        transition: 'pop',
        duration: 200
      }}
      withArrow
    >
      <ActionIcon
        className={classes.fullViewIcon}
        size="md"
        variant="filled"
        color="primary"
        onClick={toggleFullView}
      >
        <FontAwesomeIcon
          icon={[
            'fas',
            fullView
              ? 'arrows-minimize'
              : 'arrows-maximize'
          ]}
        />
      </ActionIcon>
    </Tooltip>
  )

  return (
    <div className={classes.app}>
      <Header orgId={orgId} show={show} signout={signout} setShow={setShow} />
      <Menu orgId={orgId} show={show} signout={signout} setShow={setShow} />
      <div className={classes.wrapper}>
        <main className={cx(classes.content, { [classes.disableScroll]: show })}>
          {cloneElement(children, { expandBtn, fullView })}
        </main>
      </div>
    </div>
  )
}
