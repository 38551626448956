/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { type AllGeoJSON, type Position } from '@turf/helpers'
import { type AgentLocation } from '@venturi-io/api/src/collector/agent'
import { type SourceProps } from 'react-map-gl'
import {
  type MapboxPoint,
  type TripAgentLocation,
  type TripSummary
} from '@venturi-io/api/src/collector/trip'
import { type IncidentsSummary } from '@venturi-io/api/src/config/alarmHistory'
import { type TripProps } from '.'
export interface RawPointSourceProps extends Partial<TripAgentLocation> {
  color?: string
  tripId: string
  position: number
}

export interface SuggestedPlace {
  mapbox_id: string
  name: string
  name_preferred: string
  address: string
  full_address: string
  place_formatted: string
}

export interface Place {
  geometry: {
    type: string
    coordinates: Position
  }
  properties: {
    name: string
    mapbox_id: string
    full_address: string
    place_formatted: string
  }
}

export interface MapCoordinatePositions {
  coordinates: AllGeoJSON[]
  rawPos: AgentLocation[]
}

const accessToken = 'pk.eyJ1IjoiY29kZW5pZWwiLCJhIjoiY2w2YTM0emRuMTh6YjNjbWwwOGF3ZGx2ZyJ9.QqFz7rJOlIImh0aOh7olJA'
const sessionToken = '445a16f0-6c1b-43a7-8bda-71e8eb9ce237'

export const generateTripPoints = (
  points: TripAgentLocation[],
  tripId: number,
  color?: string
): SourceProps => {
  const features = points.map((tripPoint, position) => {
    const properties: RawPointSourceProps = {
      color,
      position,
      tripId: tripId.toString(),
      ...tripPoint
    }

    const { time, longitude, latitude } = tripPoint

    return ({
      id: time,
      type: 'Feature',
      properties,
      geometry: {
        coordinates: [
          longitude,
          latitude
        ],
        type: 'Point'
      }
    })
  })

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features
    }
  }
}

export const generateMapboxPoints = (
  points: MapboxPoint[],
  tripId: number,
  color?: string
): SourceProps => {
  const features = points
    .map((tripPoint) => {
      const origPos = points.indexOf(tripPoint)
      const properties: RawPointSourceProps = {
        color,
        position: origPos,
        tripId: tripId.toString(),
        ...tripPoint
      }

      const [longitude, latitude] = tripPoint

      return ({
        type: 'Feature',
        properties,
        geometry: {
          coordinates: [
            longitude,
            latitude
          ],
          type: 'Point'
        }
      })
    })

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features
    }
  }
}

export const generateLinePath = (
  points: MapboxPoint[],
  color?: string
): SourceProps => {
  return {
    type: 'geojson',
    lineMetrics: true,
    data: {
      type: 'Feature',
      properties: {
        color
      },
      geometry: {
        coordinates: points,
        type: 'LineString'
      }
    }
  }
}

export const generateRawLinePath = (
  points: TripAgentLocation[],
  color?: string
): SourceProps => {
  return {
    type: 'geojson',
    lineMetrics: true,
    data: {
      type: 'Feature',
      properties: {
        color
      },
      geometry: {
        coordinates: points.map(item => ([
          item.longitude,
          item.latitude
        ])),
        type: 'LineString'
      }
    }
  }
}

export const generateMapSource = (
  source: Geometry,
  color?: string
): SourceProps => {
  return {
    type: 'geojson',
    lineMetrics: true,
    data: {
      type: 'Feature',
      properties: {
        color
      },
      geometry: source
    }
  }
}

export const generateSpeedingPoints = (
  points: TripAgentLocation[],
  tripId: number,
  color?: string
): SourceProps => {
  const features = points
    .filter(({ overSpeeding }) => overSpeeding)
    .map((tripPoint, position) => {
      const properties: RawPointSourceProps = {
        color,
        position,
        tripId: tripId.toString(),
        ...tripPoint
      }

      const { time, longitude, latitude } = tripPoint

      return (
        {
          id: time,
          type: 'Feature',
          properties,
          geometry: {
            coordinates: [
              longitude,
              latitude
            ],
            type: 'Point'
          }
        }
      )
    })

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features
    }
  }
}

export const generateIdlingPoints = (
  points: TripAgentLocation[],
  tripId: number
): SourceProps => {
  const features = points
    .filter(({ idlingDuration }) => idlingDuration
      ? idlingDuration > 30
      : false
    )
    .map((tripPoint, position) => {
      const properties: RawPointSourceProps = {
        position,
        tripId: tripId.toString(),
        ...tripPoint
      }

      const { time, longitude, latitude, idlingDuration } = tripPoint
      let idlingInMins: string | undefined = ''
      if (idlingDuration) {
        const lessThan = idlingDuration / 60 < 1
          ? '~'
          : ''
        idlingInMins = idlingDuration
          ? `${lessThan}${Math.round(idlingDuration / 60)} min`
          : undefined
      }

      return (
        {
          id: time,
          type: 'Feature',
          properties: {
            ...properties,
            idlingInMins
          },
          geometry: {
            coordinates: [
              longitude,
              latitude
            ],
            type: 'Point'
          }
        }
      )
    })

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features
    }
  }
}

export const generateDuressPoints = (
  { items }: IncidentsSummary
): SourceProps => {
  const features = items
    .map((tripPoint, pos) => {
      const { agentLocationPoint, alarmId } = tripPoint

      return (
        {
          id: alarmId,
          type: 'Feature',
          properties: {
            color: '#AA4A44',
            position: pos + 1
          },
          geometry: {
            coordinates: [
              agentLocationPoint?.longitude,
              agentLocationPoint?.latitude
            ],
            type: 'Point'
          }
        }
      )
    })

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features
    }
  }
}

export const generateTrips = (items: TripSummary[], oldTrips: TripProps[]): TripProps[] => {
  return items.map((trip, index) => ({
    ...trip,
    color: oldTrips[index]
      ? oldTrips[index].color
      : randomHexColor()
  }))
}

// Locations generator

export const generateLocationPoints = (
  points: AgentLocation[]
): SourceProps => {
  const features = points.map((location) => {
    const {
      timestamp,
      geoLocation: {
        longitude,
        latitude
      }
    } = location

    return ({
      id: timestamp,
      type: 'Feature',
      properties: {
        ...location
      },
      geometry: {
        coordinates: [
          longitude,
          latitude
        ],
        type: 'Point'
      }
    })
  })

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features
    }
  }
}

export const generateRawLocationLinePath = (
  points: AgentLocation[],
  color?: string
): SourceProps => ({
  type: 'geojson',
  lineMetrics: true,
  data: {
    type: 'Feature',
    properties: {
      color
    },
    geometry: {
      coordinates: points.map(({
        geoLocation: {
          longitude,
          latitude
        }
      }) => ([
        longitude,
        latitude
      ])),
      type: 'LineString'
    }
  }
})

export const removeDuplicates = (coordinates: number[][]) => {
  const seen = new Set()
  const filtered = coordinates.filter(location => {
    const stringify = `${location[0]}|${location[1]}`
    const duplicate = seen.has(stringify)
    seen.add(stringify)
    return !duplicate
  })
  return filtered
}

export const findPlaces = async (searchText: string): Promise<SuggestedPlace[]> => {
  if (searchText.trim() === '') {
    return []
  }
  const url = `https://api.mapbox.com/search/searchbox/v1/suggest?q=${searchText}&language=en&access_token=${accessToken}&session_token=${sessionToken}&limit=10`
  const response = await fetch(url)
  const { suggestions } = await response.json()

  return suggestions ?? []
}

export interface Coordinate {
  coordinates: [number, number]
}
export interface PlaceGeometry {
  geometry: Coordinate
  type: string
}

export const retrievePlace = async (id: string): Promise<Place | null> => {
  const url = `https://api.mapbox.com/search/searchbox/v1/retrieve/${id}?access_token=${accessToken}&session_token=${sessionToken}`
  const response = await fetch(url)
  const { features } = await response.json()

  return features?.length ? features[0] : null
}

interface Feature {
  place_type: string[]
  place_name: string
}
interface FeatureCollection {
  features: Feature[]
}

const getFeatureByType = (features: Feature[], type: string) =>
  features.find(({ place_type }) => place_type.includes(type))

export const reverseGeocode = async (position: Position): Promise<string> => {
  const [lat, lng] = position

  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lat},${lng}.json?access_token=${accessToken}&type=address,locality,postcode,city,country`
  const response = await fetch(url)
  const { features } = await response.json() as FeatureCollection

  const address = getFeatureByType(features, 'address')
  const locality = getFeatureByType(features, 'locality')
  const postcode = getFeatureByType(features, 'postcode')
  const city = getFeatureByType(features, 'city')
  const country = getFeatureByType(features, 'country')

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { place_name } = address ??
    locality ??
    postcode ??
    city ??
    country ??
    { place_name: 'Unknown address' }

  return place_name
}

export const callMatchingGETApi = async (lngLats: MapboxPoint[]) => {
  const url = `https://api.mapbox.com/matching/v5/mapbox/driving/${lngLats.join(';')}?access_token=${accessToken}&geometries=geojson&overview=full&ignore=access,oneways,restrictions`
  const response = await fetch(url)
  return response
}

export const callMatchingPOSTApi = async (lngLats: MapboxPoint[]) => {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

  const urlencoded = new URLSearchParams()
  urlencoded.append('coordinates', lngLats.join(';'))
  urlencoded.append('radiuses', lngLats.map(() => '10').join(';'))
  urlencoded.append('geometries', 'geojson')
  urlencoded.append('overview', 'full')
  urlencoded.append('ignore', 'oneways,restrictions')

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded
  }

  const url = `https://api.mapbox.com/matching/v5/mapbox/driving?access_token=${accessToken}`
  const response = await fetch(url, requestOptions)
  return response
}

export interface LngLat {
  longitude: number
  latitude: number
}

export interface Geometry {
  coordinates: number[]
  type: string
}

interface Matching {
  confidence: number
  geometry: Geometry
}

export const getMatchings = async (positions: TripAgentLocation[] | LngLat[]) => {
  const lngLats = positions.map(item => ([item.longitude, item.latitude]))
  const chunkSize = 100
  const sources: Geometry[] = []
  for (let i = 0; i < lngLats.length; i += chunkSize) {
    const chunk = lngLats.slice(i, i + chunkSize)
    // do whatever
    const response = await callMatchingGETApi(chunk)
    const data = await response.json()
    const matchings = data.matchings as Matching[]
    const geometries = matchings.map(item => item.geometry)
    sources.push(...geometries ?? '')
  }
  // join all coordinates
  return sources.reduce<Geometry>((acc, geo) => {
    return {
      coordinates: [...acc.coordinates, ...geo.coordinates],
      type: 'LineString'
    }
  }, {
    coordinates: [],
    type: 'LineString'
  })
}

export const postMatchings = async (positions: TripAgentLocation[] | LngLat[]) => {
  const lngLats = positions.map(item => ([item.longitude, item.latitude]))
  const chunkSize = 100
  const sources: Geometry[] = []
  for (let i = 0; i < lngLats.length; i += chunkSize) {
    const chunk = lngLats.slice(i, i + chunkSize)
    // do whatever
    const response = await callMatchingPOSTApi(chunk)
    const data = await response.json()
    const matchings = data.matchings as Matching[]
    const geometries = matchings.map(item => item.geometry)
    sources.push(...geometries ?? '')
  }

  // join all coordinates
  return sources.reduce<Geometry>((acc, geo) => {
    return {
      coordinates: [...acc.coordinates, ...geo.coordinates],
      type: 'LineString'
    }
  }, {
    coordinates: [],
    type: 'LineString'
  })
}

export const randomHexColor = () => {
  const n = (Math.random() * 0xfffff * 1000000).toString(16)
  return '#' + n.slice(0, 6)
}
