import {
  createStyles,
  getStylesRef,
  Burger,
  Text,
  ActionIcon,
  Tooltip,
  useMantineTheme
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconHelp } from '@tabler/icons-react'
import { useZendesk } from 'src/ZendeskContext'
import { mq } from 'src/utils/style'
import {
  getPrimaryBgColor,
  getPrimaryTextColor,
  getTextColor,
  getTextColorAsProp,
  getWhiteBackgroundColor
} from 'src/utils/theme'

export interface CloseMenuProps {
  show: boolean
  showLabel?: boolean
  setShow: (input: boolean) => void
}

const closeItemStyles = createStyles(theme => {
  const menuItemLabel = getStylesRef('menuItemLabel')

  return {
    closeMenu: {
      ...getPrimaryBgColor(theme),
      cursor: 'pointer',
      display: 'flex',
      height: '48px',
      alignItems: 'center',
      padding: '5px 5px 5px 14px',
      width: '100%',
      '&:hover': {
        ...getPrimaryBgColor(theme)
      }
    },
    mobileCloseMenu: {
      position: 'absolute',
      ...getWhiteBackgroundColor(theme),
      width: 64,
      height: 64,
      top: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    closeMenuLabel: {
      ...getPrimaryTextColor(theme),
      [mq(theme.breakpoints.sm)]: {
        marginLeft: '40px'
      }
    },
    menuButton: {
      position: 'fixed',
      width: 34,
      zIndex: 199,
      transform: 'translate3d(0, 0, 0)',
      [mq(theme.breakpoints.sm)]: {
        position: 'initial',
        zIndex: 'initial'
      }
    },
    menuItemLabel: {
      ref: menuItemLabel,
      color: 'black',
      fontSize: '0.9rem',
      marginLeft: '20px',
      textAlign: 'left',
      [mq(theme.breakpoints.sm)]: {
        marginLeft: '36px'
      }
    }
  }
})

const useStyles = createStyles((theme) => ({
  chatbotIcon: {
    position: 'fixed',
    right: '75px',
    zIndex: 199,
    transform: 'translate3d(0, 0, 0)',
    ...getTextColor(theme),
    [mq(theme.breakpoints.sm)]: {
      position: 'initial',
      zIndex: 'initial',
      display: 'none'
    }
  }
}))

export function CloseMenu ({ show, setShow, showLabel }: CloseMenuProps) {
  const { classes, cx } = closeItemStyles()
  const { classes: chatbotClass } = useStyles()
  const { show: showChatbot, open } = useZendesk()
  const theme = useMantineTheme()
  const isDesktop = useMediaQuery(mq(theme.breakpoints.sm, false))

  return (
    <>
      <Tooltip label="Help">
        <ActionIcon
          variant="transparent"
          onClick={() => {
            showChatbot()
            open()
          }}
          sx={{
            marginRight: '-8px'
          }}
          className={chatbotClass.chatbotIcon}
        >
          <IconHelp size={24} />
        </ActionIcon>
      </Tooltip>
      <div
        className={isDesktop
          ? classes.closeMenu
          : classes.mobileCloseMenu}
        onClick={() => setShow(!show)}
      >
        <Burger
          color={isDesktop
            ? 'white'
            : getTextColorAsProp().color}
          className={classes.menuButton}
          opened={show}
        />
        {showLabel && (
          <Text className={cx(classes.menuItemLabel, classes.closeMenuLabel)}>
            Close
          </Text>
        )}
      </div>
    </>
  )
}
