import { type ReactElement } from 'react'
import {
  type MantineColor,
  createStyles,
  Badge,
  Stack,
  Text
} from '@mantine/core'
import {
  IconAlertOctagon,
  IconAssembly,
  IconCheck,
  IconCircleOff,
  IconClockEdit,
  IconManualGearbox,
  IconProgressX,
  IconRefresh,
  IconTool
} from '@tabler/icons-react'
import colors from 'src/AssetTemplate/colors'

export type Status =
  'Auto' |
  'Config' |
  'Manual' |
  'N/A' |
  'Off' |
  'Running' |
  'Stopped' |
  'Test' |
  'Other'

export const statusIcon: Record<Status, ReactElement> = {
  Auto: <IconRefresh size={20} />,
  Config: <IconTool size={20} />,
  Manual: <IconManualGearbox size={20} />,
  'N/A': <IconProgressX size={20} />,
  Off: <IconCircleOff size={20} />,
  Running: <IconCheck size={20} />,
  Stopped: <IconAlertOctagon size={20} />,
  Test: <IconClockEdit size={20} />,
  Other: <IconAssembly size={20} />
}

export const statusColor: Record<Status, string> = {
  Auto: colors.blue,
  Config: colors.darkGray,
  Manual: colors.darkGray,
  'N/A': colors.darkGray,
  Off: colors.darkGray,
  Running: colors.green,
  Stopped: colors.red,
  Test: colors.darkGray,
  Other: colors.darkGray
}

interface Props {
  title: string
  value: string
  color: MantineColor
  textColor: MantineColor
  icon: ReactElement | null
  expanded?: boolean
  showTitle?: boolean
  disabled?: boolean
}

interface StyleParams {
  color: MantineColor
  textColor: MantineColor
}

const useStyles = createStyles((_, { color, textColor }: StyleParams) => ({
  root: {
    position: 'relative',
    height: 32,
    padding: '0px 8px',
    fontSize: 16,
    fontWeight: 600,
    fontStyle: 'normal',
    textTransform: 'capitalize',
    color: textColor,
    letterSpacing: 0,
    background: color,
    userSelect: 'none'
  },
  leftSection: {
    display: 'flex'
  }
}))

export const getStatus = (input?: string): Status => {
  if (typeof input === 'undefined') {
    return 'N/A'
  }

  const input_ = input.toLowerCase()

  if (input_.includes('auto')) {
    return 'Auto'
  }

  if (input_.includes('config')) {
    return 'Config'
  }

  if (input_.includes('manual')) {
    return 'Manual'
  }

  if (input_.includes('off')) {
    return 'Off'
  }

  if (input_.includes('run')) {
    return 'Running'
  }

  if (input_.includes('stop')) {
    return 'Stopped'
  }

  return 'Other'
}

export default function StatusBadge ({
  title,
  value,
  color,
  textColor,
  icon,
  expanded = false,
  showTitle = false,
  disabled = false
}: Props) {
  const { classes } = useStyles({ color, textColor })

  return (
    <Stack spacing={0}>
      {showTitle && (
        <Text
          color="gray.6"
          size={10}
          weight={500}
          transform="capitalize"
        >
          {title}
        </Text>
      )}
      <Badge
        classNames={classes}
        color={disabled
          ? 'gray.4'
          : color}
        size="md"
        radius={4}
        variant="filled"
        leftSection={icon}
      >
        {expanded
          ? value
          : ''}
      </Badge>
    </Stack>
  )
}
