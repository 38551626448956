import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  createStyles,
  Divider,
  Group,
  Indicator,
  Stack,
  Text,
  TextInput,
  useMantineTheme
} from '@mantine/core'
import { useClickOutside, useDebouncedValue } from '@mantine/hooks'
import { IconSearch } from '@tabler/icons-react'
import React, { useCallback, useMemo, useState } from 'react'
import { AutoSizer, List, type ListRowProps } from 'react-virtualized'
import parseIconStyle from 'src/utils/parseIconStyle'
import { getAgentStatusColor } from 'src/utils/status'
import { truncateWithEllipsis } from 'src/utils/strings'
import { getTextColor } from 'src/utils/theme'
import { type AgentWithColorStatus, useViewTypes } from '../AgentAttendanceContext'

const useStyle = createStyles(theme => ({
  icon: {
    width: 24,
    height: 24
  },
  label: {
    cursor: 'pointer',
    padding: '3.5px 8px',
    fontSize: 13,
    lineHeight: '13px',
    fontWeight: 500,
    ...getTextColor(theme)
  },
  list: {
    scrollbarWidth: 'thin',
    overflow: 'scroll'
  }
}))

interface Props {
  agents: AgentWithColorStatus[]
  markerNode: HTMLDivElement | null
  onAgentSelect?: (agentId: AgentWithColorStatus['agentId']) => void
}

function AgentList ({
  agents,
  markerNode,
  onAgentSelect
}: Props) {
  const { colorScheme, colors } = useMantineTheme()
  const isLight = colorScheme === 'light'
  const [containerNode, setContainerNode] = useState<HTMLDivElement | null>(null)
  const { setPeekedClusterId } = useViewTypes()
  const [query, setQuery] = useState('')
  const [cursor, setCursor] = useState(0)
  const { classes } = useStyle()
  const [debouncedQuery] = useDebouncedValue(query, 500)
  const filteredAgents = useMemo(() => {
    setCursor(0)

    return agents.filter(({ agentName }) => agentName
      .toLowerCase()
      .includes(debouncedQuery.toLowerCase())
    )
  }, [debouncedQuery, agents])

  useClickOutside(
    () => {
      setPeekedClusterId(undefined)
    },
    null,
    [
      containerNode,
      markerNode
    ])

  const selectAgent = useCallback((agentId: number) => {
    if (onAgentSelect) {
      onAgentSelect(agentId)
      setPeekedClusterId(undefined)
      // delay for UX purposes
      setTimeout(() => {
        setCursor(0)
      }, 250)
    }
  }, [onAgentSelect, setCursor])

  const handleKeyDown = (key: string) => {
    // arrow up/down button should select next/previous list element
    if (key === 'ArrowUp' && cursor > 0) {
      setCursor(lastCursor => lastCursor - 1)
    }
    if (key === 'ArrowDown' && cursor < filteredAgents.length - 1) {
      setCursor(lastCursor => lastCursor + 1)
    }
    if (key === 'Enter') {
      const { agentId } = filteredAgents[cursor]
      selectAgent(agentId)
    }
  }

  return (
    <Stack ref={setContainerNode} spacing={0}>
      <TextInput
        pl={9}
        py={4}
        size="sm"
        autoFocus
        value={query}
        iconWidth={24}
        variant="unstyled"
        placeholder="Search assets"
        icon={<IconSearch size={16} />}
        onKeyDown={(e) => handleKeyDown(e.key)}
        onChange={(e) => setQuery(e.currentTarget.value)}
      />
      <Divider />
      <Box
        sx={{
          width: 240,
          height: 195
        }}
      >
        <AutoSizer>
          {({ width, height }) => (
            <List
              className={classes.list}
              style={{
                overflowY: 'scroll'
              }}
              height={height}
              width={width}
              rowCount={filteredAgents.length}
              rowHeight={44}
              itemData={filteredAgents}
              scrollToIndex={cursor}
              rowRenderer={({ index, key, style }: ListRowProps) => {
                const {
                  agentId,
                  agentName,
                  iconStyle,
                  alarmStatus,
                  agentStatus,
                  connectionStatus,
                  lastSeenTime
                } = filteredAgents[index]
                const agentStatusColor = getAgentStatusColor(
                  alarmStatus,
                  agentStatus,
                  connectionStatus,
                  lastSeenTime
                )
                const isAlarm = agentStatusColor === 'red'
                const icon = parseIconStyle(iconStyle ?? '')

                return (
                  <Box
                    key={`${agentName}-${key}`}
                    px="sm"
                    style={{
                      ...style,
                      cursor: 'pointer',
                      backgroundColor: cursor === index
                        ? isLight
                          ? colors.gray[2]
                          : colors.dark[6]
                        : 'unset'
                    }}
                    onMouseOver={() => {
                      setCursor(index)
                    }}
                    onClick={() => {
                      selectAgent(agentId)
                    }}
                  >
                    <Indicator
                      size={16}
                      color={agentStatusColor}
                      processing={isAlarm}
                      position="middle-end"
                      offset={12}
                      withBorder
                    >
                      <Group align="center" h={44} spacing={0}>
                        <FontAwesomeIcon
                          className={classes.icon}
                          icon={icon}
                          color={agentStatusColor}
                        />
                        <Text className={classes.label}>
                          {truncateWithEllipsis(agentName, 15)}
                        </Text>
                      </Group>
                    </Indicator>
                  </Box>
                )
              }}
            />
          )}
        </AutoSizer>
      </Box>
    </Stack>
  )
}

export default AgentList
