import { Box, createStyles } from '@mantine/core'
import { type AgentLocation } from '@venturi-io/api/src/collector/agent'
import React from 'react'
import { AutoSizer, List, type ListRowProps } from 'react-virtualized'
import Row from './Row'

interface Props {
  items: AgentLocation[]
}

const useStyle = createStyles(() => ({
  list: {
    scrollbarWidth: 'thin'
  }
}))

const LocationsTable = ({ items }: Props) => {
  const { classes } = useStyle()

  return (
    <Box
      sx={{
        height: '100%',
        scrollbarGutter: 'stable'
      }}
    >
      <AutoSizer>
        {({ width, height }) => (
          <List
            className={classes.list}
            height={height}
            width={width + 15}
            rowCount={items.length}
            rowHeight={94}
            itemData={items}
            rowRenderer={({ index, key, style }: ListRowProps) => {
              const row = items[index]

              return (
                <Row
                  key={`${row.timestamp}-${key}`}
                  data={row}
                  style={{
                    ...style,
                    paddingTop: 7,
                    width: width - 7
                  }}
                />
              )
            }}
          />
        )}
      </AutoSizer>
    </Box>
  )
}

export default LocationsTable
