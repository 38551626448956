/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/naming-convention */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {
  ActionIcon,
  Button,
  Collapse,
  Group,
  Table as MantineTable,
  Paper,
  Popover,
  Stack,
  Text,
  useMantineTheme
} from '@mantine/core'
import { type ReportData } from '@venturi-io/api/src/config/report'
import { dateFormat, uiDateFormatUniversal } from 'src/utils/dates'
import { useDisclosure } from '@mantine/hooks'
import NeedsRole from 'src/NeedsRole'
import { adminRoles } from 'src/utils/role'
import dayjs from 'dayjs'
import { type Range } from '@venturi-io/api'
import { usePaginatedApi } from 'src/utils/useApi'
import { getSpeedAlerts } from '@venturi-io/api/src/analytics/speedAlerts'
import { useUser } from 'src/UserContext'
import { reverseGeocode } from 'src/Maps/GeoZoneMap/mapbox'
import { downloadCSV } from 'src/utils/files'
import { useNotifications } from 'src/utils/notifications'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MapBuilderModal, { type BuilderProps } from 'src/Maps/MapToPdf/MapBuilderModal'
import { IconPrinter } from '@tabler/icons-react'
import { getLinkTextColor, getToggleableColorWBG, getWhiteBackgroundColor } from 'src/utils/theme'
import { type ReportFileType } from '..'
import SpeedAlerts from './SpeedAlerts'
import ExportToPdf from './ExportToPdf'
import { type ParsedSpeedAlert } from './ExportToPdf/AgentSpeedAlertHistory'

interface Props {
  data: ReportData
  queryId: number
  range: Range | null
  maxWidth: number
  setSpeedAlertsData: (data: ParsedSpeedEvent[]) => void
  selectedAgentIds: string[]
  selectedAgentGroupIds: string[]
}

export interface SpeedAlertItem {
  trip_id: string | null
  agent_id: string | null
  agent_name: string | null
  agent_groups_ids: string | string[]
  total_speed_alerts: string | null
  trip_start_time: string | null
  org_user_first_name: string | null
  org_user_last_name: string | null
}

export interface ParsedSpeedEvent {
  trip_id: string | null
  asset: string | null
  driver: string | null
  date: string | null
  count: string | null
}

interface RowsProps extends Omit<Props, 'data'> {
  data: SpeedAlertItem[]
  onDownloadFile?: (type: ReportFileType, tripId: number, dateRange: Range | null, mapUri?: string) => void
}

interface RowProps extends Pick<Props, 'range' | 'maxWidth'> {
  trip: SpeedAlertItem
  onDownloadFile?: (type: ReportFileType, tripId: number, dateRange: Range | null, mapUri?: string) => void
}

const Row = ({ trip, range, onDownloadFile }: RowProps) => {
  const ref = useRef<BuilderProps>(null)
  const {
    agent_id,
    trip_id,
    agent_name,
    total_speed_alerts,
    trip_start_time,
    org_user_first_name,
    org_user_last_name
  } = trip
  const theme = useMantineTheme()
  const [tableHeight, setTableHeight] = useState(200)
  const [open, { toggle }] = useDisclosure(false)

  const onClickDownloadFile = useCallback((type: ReportFileType, mapUri?: string) => {
    if (onDownloadFile) {
      onDownloadFile(type, Number(trip_id), range, mapUri)
    }
  }, [onDownloadFile, trip_id, range])

  const driver = org_user_first_name ?? org_user_last_name
    ? `${org_user_first_name ?? ''} ${org_user_last_name ?? ''}`
    : 'N/A'

  return (
    <>
      <tr
        onClick={toggle}
        style={{
          cursor: 'pointer',
          backgroundColor: getToggleableColorWBG(open),
          borderBottom: 1,
          position: open
            ? 'sticky'
            : 'initial',
          top: 34,
          zIndex: open
            ? 100
            : 'unset'
        }}
      >
        <td>
          <Text
            {...getLinkTextColor(theme)}
            weight={open
              ? 'bold'
              : 'normal'
              }
          >
            {agent_name}
          </Text>
        </td>
        <td>{dayjs(trip_start_time, dateFormat).format(uiDateFormatUniversal)}</td>
        <td>{total_speed_alerts}</td>
        <td>{driver}</td>
        <td align="right" onClick={(e) => e.stopPropagation()}>
          <Group
            spacing="lg"
            position="center"
            align="center"
          >
            <Link to={`/?agentId=${agent_id}&tripId=${trip_id}&startTime=${encodeURIComponent(trip_start_time ?? '')}`}>
              <Button
                size="xs"
                leftIcon={<FontAwesomeIcon icon={['far', 'route']} />}
                variant="outline"
                color="primary"
              >
                View trip
              </Button>
            </Link>
            <NeedsRole role={adminRoles}>
              <Popover
                position="bottom-end"
                withArrow
                arrowOffset={12}
              >
                <Popover.Target>
                  <ActionIcon
                    size="xs"
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <IconPrinter size={16} />
                  </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <Stack align="flex-start" spacing="xs">
                    <Text color="dimmed" size="xs">Export data</Text>
                    <Button
                      leftIcon={<FontAwesomeIcon icon={['far', 'file-csv']} />}
                      variant="subtle"
                      color="primary"
                      onClick={() => onClickDownloadFile('CSV')}
                    >
                      Download as CSV
                    </Button>
                    <Button
                      leftIcon={<FontAwesomeIcon icon={['far', 'file-pdf']} />}
                      variant="subtle"
                      color="primary"
                      onClick={() => ref.current?.open()}
                    >
                      Download as PDF
                    </Button>

                    {/* Map modal so it won't show until download pdf is clicked */}
                    <MapBuilderModal
                      ref={ref}
                      mode="speeding"
                      tripId={Number(trip_id)}
                      startTime={trip_start_time}
                      didFinishLoading={(uri) => onClickDownloadFile('PDF', uri)}
                    />
                  </Stack>
                </Popover.Dropdown>
              </Popover>
            </NeedsRole>
          </Group>
        </td>
      </tr>
      <tr>
        <td
          colSpan={6}
          style={{
            padding: 0,
            borderTop: 0,
            borderBottom: open
              ? 1
              : 0
          }}
        >
          <Collapse in={open}>
            {open && (
              <SpeedAlerts
                showTitle={false}
                tripId={parseInt(trip_id ?? '0')}
                style={{
                  width: '100%',
                  height: tableHeight,
                  margin: 0
                }}
                stickyTop={78}
                didChangeHeight={(height) => {
                  setTableHeight(height)
                }}
              />
            )}
          </Collapse>
        </td>
      </tr>
    </>
  )
}

const Rows = ({ data, range, maxWidth, onDownloadFile }: RowsProps) => {
  return data
    ? data.map(trip => (
      <Row
        key={trip.trip_id}
        trip={trip}
        range={range}
        maxWidth={maxWidth}
        onDownloadFile={onDownloadFile}
      />
    ))
    : null
}

export default function SpeedingReportView (props: Props) {
  const {
    data,
    queryId,
    setSpeedAlertsData,
    selectedAgentIds,
    selectedAgentGroupIds
  } = props

  const { orgId, token } = useUser()
  const { showError } = useNotifications()
  const theme = useMantineTheme()
  const agentSpeedAlerts = usePaginatedApi(getSpeedAlerts)
  const [showPdfViewer, { toggle }] = useDisclosure(false)
  const [selectedTripId, setSelectedTripId] = useState<string | null>()
  const [reportType, setReportType] = useState<ReportFileType | null>(null)
  const [exportableData, setExportableData] = useState<ParsedSpeedAlert[]>([])
  const speedAlertData = data[`${queryId}`] as unknown as SpeedAlertItem[] ?? []
  const [selectedSpeedAlertItem, setSelectedSpeedAlertItem] = useState<SpeedAlertItem | null>(null)
  const [exportableMapImage, setMapImage] = useState<string | null>(null)

  // Convert agent group ids into array of string
  const updatedSpeedAlertData = speedAlertData.map(({ agent_groups_ids, ...props }) => ({
    ...props,
    agent_groups_ids: (typeof agent_groups_ids === 'string') ? agent_groups_ids.split(',') : []
  }))

  // Convert selected agent group ids into string
  const agentGroupIds = selectedAgentGroupIds.join(',') ?? ''

  // Filtered updatedSpeedAlertData by selected agent group id/ids
  const filteredDataByGroups = useMemo(() =>
    agentGroupIds.length > 0
      ? updatedSpeedAlertData.filter(({ agent_groups_ids }) => agent_groups_ids.some(id => agentGroupIds.includes(id)))
      : updatedSpeedAlertData,
  [updatedSpeedAlertData, agentGroupIds])

  // Filtered updatedSpeedAlertData/filteredDataByGroups by selected agent id/ids
  const filteredDataById = useMemo(() =>
    selectedAgentIds.length > 0
      ? filteredDataByGroups.filter(({ agent_id }) => selectedAgentIds.includes(agent_id ?? ''))
      : filteredDataByGroups,
  [filteredDataByGroups, selectedAgentIds])

  const parsedSpeedEvents: ParsedSpeedEvent[] = filteredDataById.map(({
    trip_id,
    agent_name,
    total_speed_alerts,
    trip_start_time,
    org_user_first_name,
    org_user_last_name
  }) => ({
    trip_id,
    asset: agent_name,
    driver: org_user_first_name && org_user_last_name
      ? `${org_user_first_name} ${org_user_last_name}`
      : 'N/A',
    date: dayjs(trip_start_time).format(uiDateFormatUniversal),
    count: total_speed_alerts
  }))

  const handleDownloadFile = useCallback(async (
    type: ReportFileType,
    tripId: number,
    dateRange: Range | null,
    mapUri?: string
  ) => {
    setReportType(type)
    setSelectedTripId(tripId.toString())

    if (mapUri) {
      setMapImage(mapUri)
    }

    await agentSpeedAlerts.fetch({
      orgId,
      tripId,
      parentLog: true,
      page: 1,
      size: 999
    }, token)
  }, [])

  useEffect(() => {
    setSelectedSpeedAlertItem(filteredDataById.find(({ trip_id }) => trip_id === selectedTripId) ?? null)
  }, [selectedTripId])

  useEffect(() => {
    setSpeedAlertsData(parsedSpeedEvents)
  }, [queryId, props.range, selectedAgentIds, selectedAgentGroupIds])

  useEffect(() => {
    const agentName = selectedSpeedAlertItem?.agent_name ?? '-'

    // generate and download agent's trips report
    agentSpeedAlerts.data.ifJust(async ({ items }) => {
      try {
        const events = await Promise.all(items.map(async (event, idx) => {
          const {
            speed,
            speedLimit,
            speedDateTime,
            geoZoneName,
            agentLocationPoint
          } = event
          try {
            const speed_location = agentLocationPoint
              ? await reverseGeocode([agentLocationPoint.longitude, agentLocationPoint.latitude])
              : 'N/A'

            return reportType === 'CSV'
              ? {
                  'Event #': idx + 1,
                  'Event Time': dayjs(speedDateTime).format(uiDateFormatUniversal),
                  Location: speed_location,
                  Geozone: geoZoneName ?? 'N/A',
                  'Speed Limit': `${speedLimit} km/h`,
                  'Speed Violation': `${speed} km/h`
                }
              : {
                  ...event,
                  location: speed_location
                }
          } catch (err: any) {
            throw Error('Failed to generate speeding alert reports')
          }
        }))
        if (reportType === 'CSV') {
          downloadCSV(events as unknown as Array<Record<string, unknown>>, `${agentName} - Speed Event Report`)
        }

        if (reportType === 'PDF') {
          const data = events as unknown as ParsedSpeedAlert[]
          setExportableData(data)
          toggle()
        }
      } catch (error) {
        showError(error as Error)
      }
    })
  }, [agentSpeedAlerts.data])

  return (
    <>
      <Paper
        p="sm"
        shadow="xs"
        sx={{
          width: '100%',
          scrollbarWidth: 'thin'
        }}
      >
        <MantineTable
          sx={{
            position: 'relative'
          }}
        >
          <thead
            style={{
              ...getWhiteBackgroundColor(theme),
              position: 'sticky',
              top: 0,
              zIndex: 100
            }}
          >
            <tr style={{ borderBottom: 1 }}>
              <th>Asset Name</th>
              <th style={{ width: 180 }}>Date</th>
              <th style={{ width: 100 }}>Violations</th>
              <th>Driver</th>
              <th style={{ width: 180 }} />
            </tr>
          </thead>
          <tbody>
            <Rows
              {...props}
              data={filteredDataById}
              onDownloadFile={handleDownloadFile}
            />
          </tbody>
        </MantineTable>
      </Paper>
      {selectedSpeedAlertItem && (
        <ExportToPdf
          agentName={selectedSpeedAlertItem.agent_name ?? 'N/A'}
          data={exportableData}
          mapUri={exportableMapImage}
          showPdfViewer={showPdfViewer}
          toggleShowPdfViewer={toggle}
          {...selectedSpeedAlertItem}
        />
      )}
    </>
  )
}
