import { useCallback } from 'react'
import { Marker as MapboxMarker } from 'react-map-gl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Group,
  createStyles,
  Indicator,
  Box,
  Text
} from '@mantine/core'
import parseIconStyle from 'src/utils/parseIconStyle'
import { type Agent } from '@venturi-io/api/src/config/geoZone'
import { truncateWithEllipsis } from 'src/utils/strings'
import { useHover } from '@mantine/hooks'
import { getAgentStatusColor } from 'src/utils/status'

interface MarkerData {
  longitude: number
  latitude: number
  key: string
}

export const parseMarkerData = ({
  agentId,
  lastLocation: {
    geoLocation: {
      longitude,
      latitude
    }
  }
}: Agent): MarkerData => ({
  key: agentId.toString(),
  longitude,
  latitude
})

interface StyleOptions {
  selected: boolean
  isAlarm: boolean
}

const VENTURI_COLOR = '#274873'

const useStyles = createStyles(({ colorScheme, colors }, { selected, isAlarm }: StyleOptions) => {
  const isLight = colorScheme === 'light'
  const bgColor = selected
    ? VENTURI_COLOR
    : isLight
      ? colors.gray[0]
      : colors.gray[4]
  const fontColor = selected
    ? '#fff'
    : '#000'
  const fontWeight = selected
    ? 800
    : 500
  const border = selected
    ? '2px #274873 solid'
    : '0px'

  return {
    pin: {
      position: 'relative',
      background: '#fff',
      borderTopLeftRadius: 22,
      borderTopRightRadius: 22,
      borderBottomRightRadius: 22,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      boxSizing: 'border-box',
      border,
      width: 44,
      height: 44,
      '&:hover': {
        background: '#fff'
      },
      animation: isAlarm
        ? 'pulse-alarm 1s infinite'
        : ''
    },
    icon: {
      width: 28,
      height: 28
    },
    label: {
      cursor: 'pointer',
      backgroundColor: bgColor,
      color: fontColor,
      padding: '3.5px 18px',
      fontSize: 13,
      lineHeight: '13px',
      borderRadius: 10,
      fontWeight
    },
    details: {
      position: 'relative',
      overflow: 'auto'
    }
  }
})

export interface Props {
  agent: Agent
  selected?: boolean
  onClick?: (agentId: Agent['agentId']) => void
  onDoubleClick?: (
    agentId: Agent['agentId'], assetType: Agent['assetType']) => void
}

function MarkerV2 ({
  agent,
  selected = false,
  onClick,
  onDoubleClick
}: Props) {
  const { hovered, ref } = useHover()
  const id = selected
    ? 'tour-map__focused-asset'
    : 'asset-marker'

  const {
    agentName,
    iconStyle,
    alarmStatus,
    agentStatus,
    connectionStatus,
    lastSeenTime
  } = agent
  const agentStatusColor = getAgentStatusColor(
    alarmStatus,
    agentStatus,
    connectionStatus,
    lastSeenTime
  )
  const isAlarm = agentStatusColor === 'red'
  const icon = parseIconStyle(iconStyle ?? '')
  const { classes } = useStyles({ selected, isAlarm })

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(agent.agentId)
    }
  }, [agent, onClick])

  const handleDoubleClick = useCallback(() => {
    if (onDoubleClick) {
      const { agentId, assetType } = agent

      onDoubleClick(agentId, assetType)
    }
  }, [agent, onDoubleClick])

  return (
    <MapboxMarker
      {...parseMarkerData(agent)}
      anchor="bottom-left"
      onClick={handleClick}
      style={{
        zIndex: selected || hovered
          ? 90
          : 0
      }}
    >
      <Group spacing={5} onDoubleClick={handleDoubleClick}>
        <Indicator
          size={16}
          color={agentStatusColor}
          processing={isAlarm}
          offset={4}
          withBorder
        >
          <Box
            id={id}
            className={classes.pin}
            ref={ref}
          >
            <FontAwesomeIcon
              className={classes.icon}
              icon={icon}
              color={agentStatusColor}
            />
          </Box>
        </Indicator>
        <Text className={classes.label}>
          {truncateWithEllipsis(agentName, 20)}
        </Text>
      </Group>
    </MapboxMarker>
  )
}

export default MarkerV2
