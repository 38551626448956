import convert from 'convert-units'
import { Box, Group, Stack, Text } from '@mantine/core'
import { IconTopologyComplex } from '@tabler/icons-react'
import { formatNumber } from 'src/utils/numbers'

interface Props {
  featureArea: number
}

interface ItemProps {
  title: string
  value: string | number
}

const Item = ({ title, value }: ItemProps) => (
  <Stack spacing={2}>
    <Group spacing={2}>
      <IconTopologyComplex
        size={16}
        color="#2998F0"
        stroke={1.3}
      />
      <Text size={12} weight={500} color="dimmed">
        {title}
      </Text>
    </Group>
    <Text size={12} weight={400}>
      {value}
    </Text>
  </Stack>
)

export default function AreaDetails ({ featureArea }: Props) {
  return (
    <Box p={16}>
      <Text
        size={16}
        weight={500}
      >
        Area Details
      </Text>
      <Stack mt={12} spacing={12}>
        <Item
          title="Square Meters"
          value={formatNumber(featureArea) ?? '0'}
        />
        <Item
          title="Square Kilometers"
          value={formatNumber(
            convert(featureArea)
              .from('m2')
              .to('km2')
          ) ?? '0'}
        />
        <Item
          title="Square Feet"
          value={formatNumber(
            convert(featureArea)
              .from('m2')
              .to('ft2')
          ) ?? '0'}
        />
        <Item
          title="Acres"
          value={formatNumber(
            convert(featureArea)
              .from('m2')
              .to('ac')
          ) ?? '0'}
        />
        <Item
          title="Square Miles"
          value={formatNumber(
            convert(featureArea)
              .from('m2')
              .to('mi2')
          ) ?? '0'}
        />
      </Stack>
    </Box>
  )
}
