import React from 'react'
import {
  type ColorScheme,
  Divider,
  Group,
  Stack,
  Switch,
  Text,
  useMantineColorScheme,
  useMantineTheme
} from '@mantine/core'
import { IconMoon, IconSun } from '@tabler/icons-react'
import NeedsRole from 'src/NeedsRole'

interface Props {
  hideLabel?: boolean
}

function ThemeSelector ({ hideLabel }: Props) {
  const { colors } = useMantineTheme()
  const { toggleColorScheme, colorScheme } = useMantineColorScheme()

  const handleChangeTheme = (value: string) => {
    if (value === 'preview') {
      toggleColorScheme('light')
    } else {
      toggleColorScheme(value as never as ColorScheme)
    }
  }

  return (
    <NeedsRole role="ROLE_SUPERADMIN">
      <Stack px="xs" pb="sm" spacing="xs">
        {!hideLabel && <Divider />}
        <Group position="apart">
          {!hideLabel && <Text size="sm" weight="bold">Theme</Text>}
          <Switch
            size="md"
            checked={colorScheme === 'light'}
            color="primary"
            onLabel={<IconSun size="1rem" stroke={2.5} color={colors.white[0]} />}
            offLabel={<IconMoon size="1rem" stroke={2.5} color={colors.gray[2]} />}
            onChange={({ currentTarget: { checked } }) =>
              handleChangeTheme(checked
                ? 'light'
                : 'dark')}
          />
        </Group>
      </Stack>
    </NeedsRole>
  )
}

export default ThemeSelector
