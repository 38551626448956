import React, { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import SelectFromTo from 'src/Input/Select/SelectFromTo'
import { type Range } from '@venturi-io/api'
import { Button, Stack, Text } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  type AgentLocation,
  type AgentLocationHistoryRequest,
  getAgentLocationHistory
} from '@venturi-io/api/src/collector/agent'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import LocationsTable from './LocationsTable'

interface Props {
  agentId: number
  onFetch: Dispatch<SetStateAction<AgentLocation[]>>
}

const Locations = ({ agentId, onFetch }: Props) => {
  const { token } = useUser()
  const getHistory = useApi(getAgentLocationHistory)
  const [timeRange, setTimeRange] = useState<Range | null>(null)
  const [locationData, setLocationData] = useState<AgentLocation[]>([])

  useEffect(() => {
    if (timeRange) {
      const { from, to } = timeRange
      const req: AgentLocationHistoryRequest = {
        agentId,
        noOfRecords: 999,
        startTime: from,
        endTime: to
      }
      void getHistory.fetch(req, token)
    }
  }, [timeRange, agentId])

  useEffect(() => {
    getHistory.data.ifJust(({ items }) => {
      setLocationData(items)
    })
  }, [getHistory.data])

  useEffect(() => {
    if (locationData.length > 0) {
      onFetch(locationData)
    }
  }, [locationData])

  return (
    <Stack spacing="xs" h="100%">
      <SelectFromTo
        size="xs"
        withTime
        dateRange={timeRange}
        onChangeRange={date => {
          if (date && setTimeRange) {
            setTimeRange(date)
          }
        }}
        applyButton={(submit, disabled) => (
          <Button
            size="xs"
            disabled={disabled}
            onClick={submit}
            color="primary"
            leftIcon={<FontAwesomeIcon icon={['far', 'rotate-right']} />}
          >
            Apply range
          </Button>
        )}
      />
      {locationData.length
        ? <LocationsTable items={locationData} />
        : (
          <Text size="xs" color="dimmed">
            Please select a range
          </Text>
          )}

    </Stack>
  )
}

export default Locations
