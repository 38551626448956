import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getAgentDetails } from '@venturi-io/api/src/config/agent'
import { IconBell } from '@tabler/icons-react'
import { msToDhms } from 'src/utils/dates'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import AssetTemplate from 'src/AssetTemplate'
import { convertStrToFloat, findSensor } from 'src/AssetTemplate/shared'
import NotFound from 'src/Router/NotFound'
import { type SensorMetricItem } from 'src/AssetTemplate/Components/SensorMetric'
import Loader from 'src/AssetTemplate/Components/Loader'
import Statuses from './Statuses'
import Analytics from './Analytics'

interface RouteParams extends Record<string, string | undefined> {
  id: string
}

export default function Dashboard () {
  const { id } = useParams<RouteParams>()
  const { token } = useUser()
  const agentDetails = useApi(getAgentDetails)
  const agent = agentDetails.data.mapOrDefault(data => data, null)
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  useEffect(() => {
    const req = { agentId: parseInt(id) }

    void agentDetails
      .fetch(req, token)
      .finally(() => {
        agentDetails.stopPolling()
        agentDetails.startPolling(req, token, 30)
        setIsInitialLoad(false)
      })

    return () => {
      agentDetails.stopPolling()
      agentDetails.abort()
    }
  }, [id])

  if (agent === null || (isInitialLoad && agentDetails.loading)) {
    return <Loader />
  }

  const {
    agentId,
    agentName,
    assetType,
    metadata,
    geoLocation,
    sensors,
    lastSeenTime,
    connectionStatus
  } = agent

  // Engine sensors
  const engineRuntime = findSensor('ENGINE_RUN_TIME', sensors)
  const totalRuntimeMs = convertStrToFloat(engineRuntime?.currentValue) * 1000
  const totalRuntime = msToDhms(totalRuntimeMs)

  const statusConnectivity = connectionStatus === 'ONLINE'
    ? 'CONNECTED'
    : 'DISCONNECTED'

  const sensorMetrics: SensorMetricItem[] = sensors.map(({
    sensorInstanceId,
    name,
    currentValue,
    unit
  }) => ({
    sensorInstanceId,
    name,
    icon: <IconBell size={16} />,
    value: Number(currentValue ?? 0),
    displayValue: currentValue ?? '-',
    displayValueUnit: unit ?? ''
  }))

  return (
    <AssetTemplate
      disableMiniExpansion
      agentId={agentId}
      agentName={agentName}
      assetType={assetType}
      metadata={metadata}
      geoLocation={geoLocation}
      sensorMetrics={sensorMetrics}
      statuses={(
        <Statuses
          alarms={sensors.filter(sensor => sensor.alarmStatus === 'ALARM').length}
        />
      )}
      connectivityStatus={statusConnectivity}
      lastDataTransfer={lastSeenTime}
      totalAssetRuntime={totalRuntime}
    >
      <Analytics sensors={sensors} />
    </AssetTemplate>
  )
}
