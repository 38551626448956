import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type CSSProperties
} from 'react'
import ReactMapGL, { type MapRef } from 'react-map-gl'
import { type Editor } from 'react-map-gl-draw'
import { createStyles, Box } from '@mantine/core'
import {
  getGeoZone,
  getGeoZonesAndAgents as getGeoZones,
  createGeoZone,
  updateGeoZone,
  deleteGeoZone,
  assignAgentGroupsToGeoZone,
  removeAgentGroupsFromGeoZone,
  type GeoZone
} from '@venturi-io/api/src/config/geoZone'
import { createGeoZoneRuleAction } from '@venturi-io/api/src/config/geoZoneRuleAction'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import {
  type DrawCreateEvent,
  type DrawModeChangeEvent,
  type DrawUpdateEvent
} from '@mapbox/mapbox-gl-draw'
import { type LngLatLike } from 'mapbox-gl'
import { bbox } from '@turf/turf'
import { useNotifications } from 'src/utils/notifications'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { getBoundary } from 'src/utils/map'
import { MapType } from '../StylePicker'
import LocationMarker from './LocationMarker'
import ExistingGeoZoneWizard from './ExistingGeoZoneWizard'
import NewGeoZoneWizard from './NewGeoZoneWizard'
import { type FormProps, formInitialValues } from './NewGeoZoneWizard/Procedure'
import {
  type ActiveFeature,
  type FeatureWithId,
  type Feature,
  type LocationMark,
  type MapMode,
  featureToGeoZone,
  geoZoneToFeature
} from './shared'
import { MODES, modes } from './constants'
import DrawControl from './DrawControl'
import Toolbar from './Toolbar'
import ListControls from './ListControls'
import GeoZoneList from './GeoZoneList'
import RuleList from './RuleList'
import ActionList from './ActionList'
import type MapboxDraw from '@mapbox/mapbox-gl-draw'
import 'mapbox-gl/dist/mapbox-gl.css'

interface StyleParams {
  width: CSSProperties['width']
  height: CSSProperties['height']
}

const useStyles = createStyles((_, { width, height }: StyleParams) => ({
  container: {
    position: 'relative',
    width,
    height
  }
}))

export interface Props {
  height?: string
  width?: string
  mapStyle?: MapType
  longitude?: number
  latitude?: number
  zoom?: number
  showLocationMarker?: boolean
  showLocationMarkerInformation?: boolean
  onMarkerChange?: (value: LocationMark) => void
  canPan?: boolean
  canZoom?: boolean
  canRotate?: boolean
  canDraw?: boolean
  canViewList?: boolean
}

export default function CoreMap ({
  height = '100%',
  width = '100%',
  mapStyle = MapType.monochrome,
  longitude = 133.8807,
  latitude = 23.6980,
  zoom = 5,
  showLocationMarker = false,
  showLocationMarkerInformation = false,
  onMarkerChange,
  canPan = false,
  canZoom = false,
  canRotate = false,
  canDraw = false,
  canViewList = false
}: Props) {
  const { classes } = useStyles({ width, height })
  const { showError } = useNotifications()
  const { token, orgId } = useUser()
  const editorRef = useRef<Editor>(null)
  const mapRef = useRef<MapRef>(null)
  const drawRef = useRef<MapboxDraw>()
  const findGeoZone = useApi(getGeoZone)
  const allGeoZones = useApi(getGeoZones)
  const create = useApi(createGeoZone)
  const update = useApi(updateGeoZone)
  const remove = useApi(deleteGeoZone)
  const createGeoZoneRule = useApi(createGeoZoneRuleAction)
  const assignAgentGroups = useApi(assignAgentGroupsToGeoZone)
  const removeAgentGroups = useApi(removeAgentGroupsFromGeoZone)
  const [mode, setMode] = useState<MapMode>(undefined)
  const [openedGeoZoneList, setOpenedGeoZoneList] = useState(false)
  const [openedRuleList, setOpenedRuleList] = useState(false)
  const [openedActionList, setOpenedActionList] = useState(false)
  const [activeFeature, setActiveFeature] = useState<ActiveFeature | null>(null)
  const [existingFeatures, setExistingFeatures] = useState<Record<string, FeatureWithId>>({})
  const [uncommittedFeatures, setUncommittedFeatures] = useState<FeatureWithId[]>([])
  const [existingGeoZone, setExistingGeoZone] = useState<Omit<GeoZone, 'boundary'> | null>(null)
  const [geoZoneWizardForm, setGeoZoneWizardForm] = useState<FormProps>(formInitialValues)
  const [showWizard, setShowWizard] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [viewport, setViewport] = useState({
    longitude,
    latitude,
    zoom
  })
  const [marker, setMarker] = useState<LocationMark>({
    longitude,
    latitude
  })
  const settings = {
    dragPan: canPan,
    scrollZoom: canZoom,
    touchZoom: canZoom,
    doubleClickZoom: canZoom,
    dragRotate: canRotate,
    touchRotate: canRotate
  }
  const geoZones = allGeoZones.data.mapOrDefault(({ geoZones }) => geoZones, [])
  const isExistingGeoZone = !!activeFeature?.feature?.properties?.data?.geoZoneId

  const switchMode = (modeId: string) => {
    // TODO make it cleaner
    const newMode = modes.find(({ id }) => id === modeId) ?? modes[0]
    setMode(newMode)

    // Manually invokes drawRef to change mode
    if (newMode?.desc) {
      if (newMode.id === 'Editing') {
        if (activeFeature?.feature) {
          const { id } = activeFeature?.feature as FeatureWithId
          drawRef.current?.changeMode(newMode.desc, { featureId: id })
        }
      } else {
        drawRef.current?.changeMode(newMode.desc)
        setActiveFeature(null)
      }
    }
  }

  const fromDrawSwitchMode = (e: DrawModeChangeEvent) => {
    const newMode = modes.find(({ desc }) => desc === e.mode) ?? modes[0]
    setMode(newMode)
  }

  const removeExistingFeature = (feature: FeatureWithId) => {
    const updatedExistingFeatures = Object.keys(existingFeatures)
    setExistingFeatures(
      updatedExistingFeatures.reduce((acc, key) => (
        key !== feature.id
          ? { [key]: existingFeatures[key], ...acc }
          : acc
      ), {}))
  }

  // Draw controls' methods
  const onCreate = useCallback(({ features }: DrawCreateEvent) => {
    const createdFeature = features[0] as FeatureWithId
    setActiveFeature({ feature: createdFeature })
    setUncommittedFeatures(features => ([...features, createdFeature]))
    // delay??
    setTimeout(() => {
      switchMode(MODES.EDITING)
    }, 250)
  }, [])

  const onUpdate = useCallback(({ features }: DrawUpdateEvent) => {
    const updatedFeature = features[0] as FeatureWithId
    let features_ = uncommittedFeatures
    features_ = features_.filter(item => item.id !== updatedFeature.id)
    setUncommittedFeatures([...features_, updatedFeature])
    setActiveFeature({ feature: updatedFeature })
  }, [uncommittedFeatures])

  const onSelect = useCallback(({ features }: { features: object[] }) => {
    const feature = features[0] as Feature
    // avoid double focus
    if (feature && feature !== activeFeature?.feature) {
      // set's the active feature
      setActiveFeature({ feature })
      // calculate the bounding box of the feature
      const [minLng, minLat, maxLng, maxLat] = bbox(feature)

      if (mapRef.current) {
        mapRef.current.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat]
          ],
          {
            padding: 120,
            duration: 1000
          }
        )
      }
      setShowWizard(true)
    } else {
      switchMode(MODES.VIEWING)
      setActiveFeature(null)
      setShowWizard(false)
    }
  }, [mode, activeFeature?.feature])

  const handleSelectGeoZone = useCallback(({ boundary: { coordinates } }: GeoZone) => {
    const bounds = getBoundary(coordinates.flat() as never as LngLatLike[])
    const options = {
      padding: 40,
      speed: 5,
      maxZoom: 16
    }

    mapRef.current?.fitBounds(bounds, options)
    setShowWizard(true)
    setOpenedGeoZoneList(false)
  }, [mapRef.current])

  // Create geozone wizard's handlers
  const handleCreateGeoZone = useCallback(() => {
    if (activeFeature?.feature) {
      const {
        name,
        description,
        hiddenOnMap
      } = geoZoneWizardForm
      const { boundary } = featureToGeoZone(activeFeature.feature)

      void create
        .fetch({
          orgId,
          name,
          description,
          boundary,
          hiddenOnMap
        }, token, 'Successfully created geozone')
        .finally(() => {
          const { id } = activeFeature.feature as FeatureWithId
          if (id) {
            // remove raw feature then replace it with the created feature from backend via useEffect
            drawRef?.current?.delete(id)
            setUncommittedFeatures(features => features.filter(item => item.id !== id))
          }
          setActiveFeature(null)
          switchMode(MODES.VIEWING)
        })
    }
  }, [
    token,
    drawRef,
    activeFeature,
    geoZoneWizardForm,
    setUncommittedFeatures
  ])

  const assignGeoZoneToAgentGroups = useCallback((geoZoneId: number) => {
    if (typeof geoZoneWizardForm.agentGroupIds !== 'undefined' && geoZoneWizardForm.agentGroupIds.length > 0) {
      void assignAgentGroups.fetch({
        geoZoneId,
        agentGroupIds: geoZoneWizardForm.agentGroupIds
      }, token)
    }
  }, [token, geoZoneWizardForm.agentGroupIds])

  const assignGeoZoneToRuleAndActions = useCallback((geoZoneId: number) => {
    if (typeof geoZoneWizardForm.geoZoneRuleId !== 'undefined' && geoZoneWizardForm.geoZoneRuleId !== -1) {
      // create rule with action(s)
      if (typeof geoZoneWizardForm.geoZoneActionIds !== 'undefined' && geoZoneWizardForm.geoZoneActionIds.length > 0) {
        const queries = geoZoneWizardForm.geoZoneActionIds.map(async geoZoneActionId => {
          const query = new Promise<string>((resolve, reject) => {
            void createGeoZoneRuleAction({
              geoZoneId,
              geoZoneRuleId: geoZoneWizardForm.geoZoneRuleId ?? 0,
              geoZoneActionId,
              description: ''
            }, token)
              .caseOf({
                Left: err => {
                  reject(err)
                },
                Right: () => resolve('Successfully created geozone rule action')
              })
          })
          return await query
        })

        void Promise
          .all(queries)
          .catch(() => {
            showError(new Error('Failed to create rule and actions'))
          })
      } else {
        // create rule without action
        void createGeoZoneRule
          .fetch({
            geoZoneId,
            geoZoneRuleId: geoZoneWizardForm.geoZoneRuleId,
            description: ''
          }, token)
      }
    }
  }, [geoZoneWizardForm.geoZoneRuleId, geoZoneWizardForm.geoZoneActionIds])

  useEffect(() => {
    create.data.ifJust(({ geoZoneId }) => {
      // call methods for assigning geozone to agent groups and rule & actions
      assignGeoZoneToAgentGroups(geoZoneId)
      assignGeoZoneToRuleAndActions(geoZoneId)

      if (activeFeature?.feature) {
        const { id, properties } = activeFeature.feature as FeatureWithId
        if (id) {
          const { data } = properties
          // replace geozone data with the ones created via backend API
          setExistingFeatures(existing => ({
            ...existing,
            [id]: {
              ...activeFeature.feature,
              properties: {
                ...properties,
                data: {
                  ...data,
                  geoZoneId
                }
              }
            }
          }))
        }
      }
    })
  }, [create.data])

  useEffect(() => {
    create.data.map(geoZone => {
      const updatedFeature = geoZoneToFeature(geoZone)

      // this will draw the newly added geozone to the map
      drawRef.current?.add(updatedFeature)

      return geoZone
    })
  }, [create.data])

  const handleUpdateGeoZone = useCallback((
    geoZone: GeoZone,
    newAgentGroupIds: number[],
    removedAgentGroupIds: number[]
  ) => {
    const { geoZoneId } = geoZone

    if (newAgentGroupIds.length) {
      void assignAgentGroups.fetch({
        geoZoneId,
        agentGroupIds: newAgentGroupIds
      }, token)
    }

    if (removedAgentGroupIds.length) {
      void removeAgentGroups.fetch({
        geoZoneId,
        agentGroupIds: removedAgentGroupIds
      }, token)
    }

    void update
      .fetch(geoZone, token, 'Successfully updated geozone')
      .finally(() => {
        if (activeFeature?.feature) {
          const { id } = activeFeature.feature as FeatureWithId
          if (id) {
            const featuresToUpdate = {
              ...existingFeatures,
              [id]: activeFeature.feature
            }
            setExistingFeatures(featuresToUpdate)
            setUncommittedFeatures(features => features.filter(item => item.id !== id))
          }
        }
        setExistingGeoZone(null)
        switchMode(MODES.VIEWING)
      })
  }, [activeFeature])

  const showErrorUpdateAgentGroup = useCallback((error: Error) => {
    showError(error)
  }, [])

  const removeFeature = useCallback(() => {
    if (activeFeature?.feature) {
      const { id } = activeFeature.feature as FeatureWithId
      if (id) drawRef?.current?.delete(id)

      setUncommittedFeatures(features => features.filter(item => item.id !== id))
      setShowWizard(false)
      setActiveFeature(null)
      switchMode(MODES.VIEWING)
    }
  }, [drawRef, activeFeature])

  const removeGeoZone = useCallback((geoZoneId: number) => {
    if (activeFeature?.feature && geoZoneId) {
      void remove.fetch({ geoZoneId }, token, 'Successfully deleted geozone')
      setShowDeleteConfirmation(false)
      setExistingGeoZone(null)
      removeExistingFeature(activeFeature.feature)
    }
    setShowWizard(false)
  }, [editorRef, activeFeature])

  const handleDeleteGeoZone = useCallback(() => {
    if (!existingGeoZone) {
      removeFeature()
    } else {
      setShowDeleteConfirmation(true)
    }
  }, [existingGeoZone, removeFeature])

  useEffect(() => {
    remove.data.ifJust(() => {
      if (activeFeature?.feature) {
        const { id } = activeFeature.feature as FeatureWithId
        if (id) drawRef?.current?.delete(id)
      }
    })
  }, [remove.data])

  const onDiscard = useCallback(() => {
    //  for created features that was not saved
    const featureIds = uncommittedFeatures.reduce((acc: string[], item) => {
      return item.id
        ? [
            ...acc,
            item.id
          ]
        : acc
    }, [])
    drawRef?.current?.delete(featureIds)

    // redraw existing features
    drawRef?.current?.deleteAll()
    for (const f in existingFeatures) {
      drawRef?.current?.add(existingFeatures[f])
    }

    setUncommittedFeatures([])
    setShowWizard(false)
    setExistingGeoZone(null)
    setActiveFeature(null)
    switchMode(MODES.VIEWING)
  }, [existingFeatures])

  useEffect(() => {
    setMarker({
      longitude,
      latitude
    })
  }, [longitude, latitude])

  useEffect(() => {
    setViewport({
      ...viewport,
      ...marker
    })
  }, [marker])

  useEffect(() => {
    assignAgentGroups.error.ifJust(showErrorUpdateAgentGroup)
  }, [assignAgentGroups.error])

  useEffect(() => {
    removeAgentGroups.error.ifJust(showErrorUpdateAgentGroup)
  }, [removeAgentGroups.error])

  useEffect(() => {
    findGeoZone.data.ifJust((data) => setExistingGeoZone(data))
  }, [findGeoZone.data])

  useEffect(() => {
    if (activeFeature?.feature?.properties?.data) {
      void findGeoZone.fetch({
        geoZoneId: activeFeature?.feature?.properties?.data.geoZoneId
      }, token)
    } else {
      setExistingGeoZone(null)
    }
  }, [activeFeature])

  const onKeyDown = useCallback((e: { key: string }) => {
    if (e.key === 'Escape') {
      if (mapRef.current) {
        mapRef.current.flyTo({
          zoom: viewport.zoom - 1
        })
      }

      setShowWizard(false)
      setExistingGeoZone(null)
      setActiveFeature(null)
      switchMode(MODES.VIEWING)
    }
  }, [mapRef.current, viewport.zoom])

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [viewport.zoom])

  useEffect(() => {
    void allGeoZones.fetch({
      orgId,
      showHiddenGeoZones: true
    }, token)
    switchMode(MODES.VIEWING)
  }, [])

  useEffect(() => {
    if (mapRef.current &&
        drawRef.current &&
        geoZones.length > 0 &&
        mapRef.current.getMap()
    ) {
      // draw Geozones here
      const features: FeatureWithId[] = geoZones.map((geoZone) => geoZoneToFeature(geoZone))
      for (const f of features) {
        drawRef.current?.add(f)
      }
      const allFeatures = drawRef.current?.getAll()
      if (allFeatures?.features) {
        const featuresObj = allFeatures.features.reduce((acc, item) => (item?.id
          ? {
              ...acc,
              [item.id]: item
            }
          : acc), {})
        setExistingFeatures(featuresObj)
      }
    }
  }, [geoZones, mapRef, drawRef])

  return (
    <Box className={classes.container}>
      <ReactMapGL
        {...viewport}
        {...settings}
        ref={mapRef}
        style={{ width, height }}
        onMove={evt => setViewport(evt.viewState)}
        mapStyle={mapStyle}
        mapboxAccessToken={process.env.REACT_APP_APIKEY_MAPBOX}
        attributionControl={false}
        transformRequest={(url) => ({
          url,
          referrerPolicy: 'strict-origin-when-cross-origin'
        })}
      >
        {showLocationMarker && (
          <LocationMarker
            marker={marker}
            canPan={canPan}
            setMarker={setMarker}
            onMarkerChange={onMarkerChange}
            showInformation={showLocationMarkerInformation}
          />
        )}

        {/* Lists */}
        {canViewList && (
          <>
            <ListControls
              openedGeoZoneList={openedGeoZoneList}
              openedRuleList={openedRuleList}
              openedActionList={openedActionList}
              setOpenedGeoZoneList={setOpenedGeoZoneList}
              setOpenedRuleList={setOpenedRuleList}
              setOpenedActionList={setOpenedActionList}
            />
            <GeoZoneList
              opened={openedGeoZoneList}
              setOpened={setOpenedGeoZoneList}
              onSelectGeoZone={handleSelectGeoZone}
            />
            <RuleList
              opened={openedRuleList}
              setOpened={setOpenedRuleList}
            />
            <ActionList
              opened={openedActionList}
              setOpened={setOpenedActionList}
            />
          </>
        )}

        {canDraw && (
          <>
            <DrawControl
              ref={drawRef}
              boxSelect={false}
              keybindings={false}
              position="top-right"
              displayControlsDefault={false}
              defaultMode="simple_select"
              onCreate={onCreate}
              onUpdate={onUpdate}
              onSelect={onSelect}
              onModeChange={fromDrawSwitchMode}
            />
            <Toolbar
              mode={mode}
              switchMode={switchMode}
              activeFeature={activeFeature?.feature}
              showDiscard={mode?.id === MODES.VIEWING && !!uncommittedFeatures.length}
              onDiscard={onDiscard}
            />
          </>
        )}
      </ReactMapGL>

      {showWizard &&
        activeFeature &&
        isExistingGeoZone && (
          <ExistingGeoZoneWizard
            mode={mode}
            orgId={orgId}
            activeFeature={activeFeature.feature}
            selectedGeoZone={existingGeoZone}
            switchMode={switchMode}
            onUpdate={handleUpdateGeoZone}
            onCancel={() => switchMode(MODES.VIEWING)}
            onDelete={handleDeleteGeoZone}
            setShowWizard={setShowWizard}
          />
      )}

      {showWizard &&
        activeFeature &&
        !isExistingGeoZone && (
          <NewGeoZoneWizard
            formData={geoZoneWizardForm}
            setFormData={setGeoZoneWizardForm}
            onCreate={handleCreateGeoZone}
            onDelete={handleDeleteGeoZone}
          />
      )}

      {existingGeoZone && (
        <ConfirmModal
          type="delete"
          opened={showDeleteConfirmation}
          title={`Deleting "${existingGeoZone.name}"`}
          question="Are you sure you want to delete this geozone? This cannot be undone."
          onClose={() => setShowDeleteConfirmation(false)}
          onCancel={() => setShowDeleteConfirmation(false)}
          onConfirm={() => removeGeoZone(existingGeoZone.geoZoneId)}
          centered
        />
      )}
    </Box>
  )
}
