import {
  useMantineTheme,
  Table as MantineTable,
  createStyles,
  ScrollArea,
  Box
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import { type Props } from '..'
import DateTimeRow from './Rows/DateTime'
import Rows from './Rows/index'
import Head from './Head'

const useStyles = createStyles(() => ({
  head: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff'
  },
  th: {
    fontWeight: 400,
    fontSize: 10,
    color: '#5C5F66',
    paddingLeft: 0,
    border: 'none'
  },
  withBorder: {
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      height: '1rem',
      width: '2px',
      background: 'lightgray',
      right: '-1px',
      bottom: '-16px'
    }
  }
}))

export default function MobileTable ({ transactions }: Props) {
  const { breakpoints: { md } } = useMantineTheme()
  const isMediumScreen = useMediaQuery(mq(md, false))
  const { classes } = useStyles()

  return (
    <>
      <MantineTable
        w={isMediumScreen
          ? 160
          : 250}
        fontSize={10}
        withColumnBorders={false}
        withBorder={false}
        className={!isMediumScreen ? classes.withBorder : ''}
        style={!isMediumScreen
          ? {
              borderRight: '2px solid lightgray',
              marginRight: '1rem',
              position: 'relative'
            }
          : {}}
      >
        <thead className={classes.head}>
          <tr>
            <th className={classes.th}>Date/Time</th>
          </tr>
        </thead>
        <tbody
          style={{
            display: 'block'
          }}
        >
          <DateTimeRow transactions={transactions} />
        </tbody>
      </MantineTable>
      <ScrollArea
        w={isMediumScreen
          ? '100%'
          : 400}
        scrollbarSize={5}
      >
        <Box w={isMediumScreen ? 'auto' : 500}>
          <MantineTable
            fontSize={10}
            withColumnBorders={false}
            withBorder={false}
          >
            <Head isMobile />
            <tbody>
              <Rows transactions={transactions} isMobile />
            </tbody>
          </MantineTable>
        </Box>
      </ScrollArea>
    </>
  )
}
