import { type ReactNode, useState, useCallback } from 'react'
import { type SelectProps } from '@mantine/core'
import { reportScheduleType, type ScheduleType } from '@venturi-io/api/src/config/report'
import Select from '../Select'

type Value = ScheduleType | null

interface Props extends Omit<SelectProps, 'data'> {
  label?: string
  placeholder?: string
  value?: Value
  onChange?: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

export default function SelectScheduleType ({
  value,
  label,
  placeholder,
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const [scheduleType, setScheduleType] = useState<Value>()

  const handleChange = useCallback((val: Value) => {
    setScheduleType(val)

    if (onChange) {
      onChange(val)
    }
  }, [onChange])

  return (
    <Select
      label={label}
      placeholder={placeholder ?? 'Choose a schedule type'}
      data={reportScheduleType}
      value={value ?? scheduleType}
      onChange={handleChange}
      required={required}
      searchable={searchable}
      disabled={disabled}
      error={error}
      {...props}
    />
  )
}
