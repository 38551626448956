import { Box, Paper, Stack } from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import SensorHistoryLineChart from 'src/AssetTemplate/Components/Charts/SensorHistoryLineChart'
import { findSensor } from 'src/AssetTemplate/shared'

interface Props {
  sensors: Sensor[]
}

export default function CapacityHistory ({ sensors }: Props) {
  const fuelLevelLiter = findSensor('FUEL_LEVEL_L', sensors)

  return (
    <Paper p={16} radius={4} shadow="xs">
      <Box>
        <Stack spacing={28}>
          <SensorHistoryLineChart
            title="Capacity History"
            sensorInstanceId={fuelLevelLiter?.sensorInstanceId}
            unit={fuelLevelLiter?.unit}
            height={300}
            margin={{
              top: 20,
              right: 20,
              bottom: 50,
              left: 50
            }}
          />
        </Stack>
      </Box>
    </Paper>
  )
}
