import { Flex, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { type DefaultDataItem } from '@venturi-io/api/src/analytics/notification'
import { LabelValue, NotificationBody, NotificationHeading } from './shared'
import { type NotificationItemProps } from '.'

function Default ({
  onRead,
  showClear,
  closeDrawer,
  expanded = false,
  ...incident
}: NotificationItemProps) {
  const {
    timestamp,
    data
  } = incident
  const {
    name: alarmName,
    siteName,
    siteId,
    agentName,
    agentId,
    stringValue,
    sensorInstanceName
  } = data as DefaultDataItem

  const [show, { toggle }] = useDisclosure(expanded)
  const handleClear = () => {
    if (onRead) {
      onRead(incident)
    }
  }

  return (
    <Flex
      direction="column"
      justify="center"
    >
      <NotificationHeading
        show={show}
        link={`/?agentId=${agentId}`}
        showClear={showClear}
        toggle={toggle}
        onClear={handleClear}
        closeDrawer={closeDrawer}
        heading={(
          <Text
            size="xs"
            sx={{
              width: '240px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}
          >
            <strong>
              {agentName}
              :
              {' '}
            </strong>
            {alarmName}
          </Text>
        )}
      />
      <NotificationBody
        show={show}
        timestamp={timestamp}
      >
        <>
          {sensorInstanceName && (
            <LabelValue
              size="xs"
              link={`/?agentId=${agentId}`}
              label={sensorInstanceName}
              value={stringValue}
            />
          )}
          {alarmName && (
            <LabelValue
              size="xs"
              link={`/?agentId=${agentId}`}
              label="Alarm"
              value={alarmName}
            />
          )}
          {siteName && (
            <LabelValue
              push
              size="xs"
              label="Site Name"
              value={siteName}
              closeDrawer={closeDrawer}
              link={`/settings/sites/edit/${siteId}`}
            />
          )}
        </>
      </NotificationBody>
    </Flex>
  )
}

export default Default
