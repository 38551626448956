import { type GeoZone, type Agent } from '@venturi-io/api/src/config/geoZone'
import {
  forwardRef,
  type RefObject,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { type LngLatLike, type MapRef } from 'react-map-gl'
import mapboxgl from 'mapbox-gl'
import { ActionIcon, Tooltip, createStyles, useMantineTheme } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getTripContext } from './Trips/AnimationContext'
import { type Filters, type Options } from '.'

const useStyles = createStyles((theme, isEmbedded: boolean | undefined) => ({
  button: {
    position: 'absolute',
    bottom: isEmbedded ? 58 : 94,
    right: 27,
    padding: 10,
    zIndex: 1
  }
}))
interface Props {
  options?: Options
  filters?: Filters
  agent?: Agent
  agents?: Agent[]
  geozones?: GeoZone[]
  mapRef: RefObject<MapRef>
}

export interface FocusManagerRef {
  changeFocusState: (flag: boolean) => void
}

const agentZoom = 17

const FocusManager = forwardRef<FocusManagerRef, Props>(({
  agent,
  mapRef,
  agents,
  geozones,
  filters,
  options
}, ref) => {
  const { colors } = useMantineTheme()
  const { trip, cameraPosition } = useContext(getTripContext())
  const { classes } = useStyles(options?.embedded)
  const [lastAgent, setLastAgent] = useState<Agent | null>(null)
  const [shouldFocus, setShouldFocus] = useState(true)

  const handleChangeShouldFocusState = useCallback((flag: boolean) => {
    setShouldFocus(flag)
  }, [])

  useImperativeHandle(ref, () => ({
    changeFocusState: handleChangeShouldFocusState
  }), [handleChangeShouldFocusState])

  const focusOnPointerCoordinate = () => {
    if (mapRef.current && cameraPosition.length === 2) {
      mapRef.current.flyTo({
        center: [cameraPosition[0], cameraPosition[1]],
        duration: 0,
        animate: false,
        essential: true
      })
    }
  }

  const focusOnAgent = () => {
    if (mapRef.current && agent) {
      // should focus on agent
      const { lastLocation: { geoLocation: { longitude, latitude } } } = agent

      mapRef.current.flyTo({
        center: [longitude, latitude],
        zoom: Math.max(mapRef.current.getZoom(), agentZoom),
        duration: 1000
      })
    }
  }

  const focusOnAgentsAndGeozones = () => {
    if (mapRef.current && filters) {
      const { agentIds, geoZoneIds } = filters

      const hasAgents = agentIds
        ? agentIds.length > 0
        : false
      const hasGeozones = geoZoneIds
        ? geoZoneIds.length > 0
        : false

      let coordinates: LngLatLike[] = []
      if (hasAgents && agents) {
        coordinates = [
          ...coordinates,
          ...agents.map(({
            lastLocation: {
              geoLocation: {
                longitude,
                latitude
              }
            }
          }) => ({
            lng: longitude,
            lat: latitude
          }))
        ]
      }

      if (hasGeozones && geozones) {
        const points = geozones.map(({
          boundary: {
            coordinates
          }
        }) => {
          const bounds = coordinates[0]
          return bounds.map(([
            longitude,
            latitude
          ]) => ({
            lng: longitude,
            lat: latitude
          }))
        }).flat()
        coordinates = [
          ...coordinates,
          ...points
        ]
      }

      if (coordinates.length === 0) return

      // Create a 'LngLatBounds' with both corners at the first coordinate.
      const bounds = new mapboxgl.LngLatBounds(
        coordinates[0],
        coordinates[0]
      )

      // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
      for (const coord of coordinates) {
        bounds.extend(coord)
      }
      mapRef.current.fitBounds(bounds, {
        padding: 40,
        maxZoom: 16
      })
    }
  }

  const handleSingleFocus = () => {
    if (agent &&
      !trip &&
      mapRef.current &&
      shouldFocus) {
      focusOnAgent()
    }
  }

  const handleEmbeddedFocus = () => {
    if (!filters) return

    const inInteraction = mapRef.current
      ? mapRef.current.isMoving() || mapRef.current.isZooming()
      : false

    if (
      !inInteraction &&
      shouldFocus) {
      // should get max bounds from points
      focusOnAgentsAndGeozones()
    }
  }

  const handleOnMouseDown = () => {
    setShouldFocus(false)
  }

  useEffect(() => {
    // only trigger on true
    if (agent && shouldFocus) {
      focusOnAgent()
    }

    if (agents && geozones && shouldFocus) {
      focusOnAgentsAndGeozones()
    }
  }, [shouldFocus])

  useEffect(() => {
    void handleSingleFocus()
    if (agent && agent.agentId !== lastAgent?.agentId) {
      setShouldFocus(true)
      setLastAgent(agent)
    }
  }, [agent, trip])

  useEffect(() => {
    void handleEmbeddedFocus()
  }, [agents, geozones])

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.on('mousedown', handleOnMouseDown)

      return () => {
        mapRef.current?.off('mousedown', handleOnMouseDown)
      }
    }
  }, [mapRef.current])

  useEffect(() => {
    if (trip && shouldFocus) {
      focusOnPointerCoordinate()
    }
  }, [shouldFocus, trip, cameraPosition])

  const showTool = agent ?? options?.embedded

  return showTool && (
    <Tooltip
      label={shouldFocus
        ? 'Unfocus'
        : 'Focus'}
      position="left"
      withArrow
    >
      <ActionIcon
        className={classes.button}
        color={shouldFocus
          ? 'white'
          : 'primary'}
        variant="filled"
        onClick={() => setShouldFocus(!shouldFocus)}
      >
        <FontAwesomeIcon
          icon={[shouldFocus
            ? 'fas'
            : 'far',
          'location-crosshairs']}
          color={shouldFocus
            ? colors.primary[5]
            : 'white'}
          size="1x"
        />
      </ActionIcon>
    </Tooltip>
  )
})

export default FocusManager
