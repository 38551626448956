import dayjs from 'dayjs'
import { type SensorData } from './TankHistory'

const LEFT_MARGIN = 16

const millisecondsToMinutes = (millisecond: number) => {
  const second = 1000
  const minute = second * 60
  return millisecond / minute
}

export const computeSvgXCoordinates = ({
  time,
  sensorData,
  width
}: {
  sensorData: SensorData[]
  time: string | Date | null
  width: number
}) => {
  const timeArr = sensorData[0]?.data ?? []
  if (timeArr.length === 0) return 0
  const start = dayjs(timeArr[0].x)
  const end = dayjs(timeArr[timeArr.length - 1].x)
  const minutes = millisecondsToMinutes(end.diff(start))

  const targetTime = dayjs(time)
  const timeInMinutes = millisecondsToMinutes(targetTime.diff(start))

  const perItemWidth = width / minutes
  const x = perItemWidth * timeInMinutes

  return x - LEFT_MARGIN
}
