import {
  Box,
  Button,
  createStyles,
  Group,
  Paper,
  Text
} from '@mantine/core'
import { Link } from 'react-router-dom'
import { type TankTransaction } from '@venturi-io/api/src/config/fms'
import { type ReactNode, type ReactElement } from 'react'
import { useParams } from 'react-router'
import {
  IconArrowBigLeftLine,
  IconArrowBigRightLine,
  IconBatteryVerticalCharging,
  IconBolt,
  IconCar,
  IconClipboardText,
  IconCylinder,
  IconEngine,
  IconGizmo,
  IconSpeakerphone
} from '@tabler/icons-react'
import { type AssetType } from '@venturi-io/api/src/config/agent'
import { getWhiteBackgroundColor } from 'src/utils/theme'
import Table from './Table'

export interface Props {
  transactions: TankTransaction[]
}

interface RouteParams extends Record<string, string | undefined> {
  assetId: string
}

type Flow = 'IN' | 'OUT'

const useStyles = createStyles((theme) => ({
  titleContainer: {
    position: 'relative'
  },
  content: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'row'
  },
  container: {
    height: '90%',
    width: '100%',
    ...getWhiteBackgroundColor(theme)
  },
  button: {
    span: {
      marginRight: 5
    }
  }
}))

interface IconWrapperProps {
  children: ReactNode
}

const IconWrapper = ({ children }: IconWrapperProps) => (
  <Box mt={4}>
    {children}
  </Box>
)

export const assetTypeIcon: Record<AssetType, ReactElement | undefined> = {
  GENSET: (
    <IconWrapper>
      <IconEngine size={12} stroke={2} />
    </IconWrapper>
  ),
  VEHICLE: (
    <IconWrapper>
      <IconCar size={12} stroke={2} />
    </IconWrapper>
  ),
  TANK: (
    <IconWrapper>
      <IconCylinder size={12} stroke={2} />
    </IconWrapper>
  ),
  '3P-POWER': (
    <IconWrapper>
      <IconBolt size={12} stroke={2} />
    </IconWrapper>
  ),
  'POWER METER': (
    <IconWrapper>
      <IconBolt size={12} stroke={2} />
    </IconWrapper>
  ),
  ATS: (
    <IconWrapper>
      <IconGizmo size={12} stroke={2} />
    </IconWrapper>
  ),
  DURESS: (
    <IconWrapper>
      <IconSpeakerphone size={12} stroke={2} />
    </IconWrapper>
  ),
  UPS: (
    <IconWrapper>
      <IconBatteryVerticalCharging size={12} stroke={2} />
    </IconWrapper>
  ),
  OTHERS: undefined
}

export const flowTypeIcon: Record<Flow, ReactElement> = {
  IN: (
    <IconWrapper>
      <IconArrowBigLeftLine size={12} stroke={2} />
    </IconWrapper>
  ),
  OUT: (
    <IconWrapper>
      <IconArrowBigRightLine size={12} stroke={2} />
    </IconWrapper>
  )
}

export default function Transactions ({ transactions }: Props) {
  const { classes } = useStyles()
  const { assetId } = useParams<RouteParams>()

  if (!assetId) {
    throw new Error('No asset ID given, routing is broken')
  }

  return (
    <Paper p={16} radius={4} shadow="xs">
      <Box className={classes.titleContainer}>
        <Group mb="sm" position="apart" noWrap>
          <Text
            size={14}
            weight={600}
          >
            Recent Transactions
          </Text>
          <Button
            px={6}
            h={24}
            className={classes.button}
            type="button"
            component={Link}
            to={`/assets/tank/${assetId}/transactions`}
            variant="outline"
            radius={4}
            leftIcon={(
              <IconClipboardText height={12} width={12} />
            )}
          >
            <Text
              size={12}
              weight={400}
              color="#228BE6"
            >
              All Transactions
            </Text>
          </Button>
        </Group>
      </Box>
      <Box className={classes.content}>
        <Table transactions={transactions} />
      </Box>
    </Paper>
  )
}
