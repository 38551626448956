import { Group, Stack, Text } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextTooltip from 'src/Layout/TextTooltip'
import Battery from './Battery'
import type { Agent } from '@venturi-io/api/src/config/agent'
interface Props {
  agentId: Agent['agentId']
  agentName: Agent['agentName']
  assetType: Agent['assetType']
  lastSeen: string
  battery?: Agent['sensors'][0]
  isAlarm?: boolean
  isSmall?: boolean
}

export default function AgentDetails ({
  agentName,
  lastSeen,
  battery,
  isAlarm = false
}: Props) {
  return (
    <Group position="apart" noWrap>
      <Group position="apart" spacing="xs" noWrap>
        {isAlarm
          ? <FontAwesomeIcon icon={['fas', 'times-square']} color="red" size="2x" />
          : <FontAwesomeIcon icon={['fas', 'check-square']} color="green" size="2x" />}

        <Stack
          align="flex-start"
          justify="space-around"
          spacing={0}
          ml={0}
        >
          <TextTooltip
            value={agentName}
            size="md"
            weight={700}
            maxLength={25}
          />
          <Text
            color="dimmed"
            sx={{
              fontSize: '.5rem',
              fontWeight: 500,
              marginTop: '-3px'
            }}
          >
            {lastSeen}
          </Text>
        </Stack>
      </Group>
      <Group position="apart" pr="xs" noWrap>
        {battery && <Battery battery={battery} />}
      </Group>
    </Group>
  )
}
