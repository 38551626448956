import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import {
  createStyles,
  Anchor,
  Button,
  Center,
  Paper,
  Stack,
  TextInput,
  PasswordInput,
  Title
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useApi } from 'src/utils/useApi'
import {
  type User,
  getUserByResetId,
  resetPassword,
  loginUser
} from '@venturi-io/api/src/userManager/auth'
import MainLogo from 'src/MainLogo'
import { getHost } from 'src/utils/host'
import { giveOffset } from 'src/utils/dates'
import { getGradientBgColor } from 'src/utils/theme'

interface RouteParams extends Record<string, string | undefined> {
  resetId: string
}

interface Props {
  orgId: number
  signin: (user: User) => void
}

const useStyles = createStyles(theme => ({
  wrapper: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...getGradientBgColor(theme)
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8
  },
  form: {
    width: '30vw',
    minWidth: '400px'
  }
}))

export default function PasswordReset ({ orgId, signin }: Props) {
  const orgUrl = getHost()
  const timezone = giveOffset()
  const getUser = useApi(getUserByResetId, false)
  const resetUserPassword = useApi(resetPassword, false)
  const login = useApi(loginUser, false)
  const { resetId } = useParams<RouteParams>()
  const navigate = useNavigate()
  const { classes } = useStyles()

  if (!resetId) {
    return null
  }

  const user = getUser.data.mapOrDefault(data => data, null)

  const form = useForm({
    initialValues: {
      password: '',
      matchingPassword: ''
    },
    validate: {
      password: value => value.length < 1
        ? 'New password is required'
        : null,
      matchingPassword: (value, inputs) => value !== inputs.password
        ? 'Password do not match'
        : null
    }
  })

  const handleSubmit = useCallback((values: typeof form.values) => {
    if (user) {
      void resetUserPassword.fetch({
        ...user,
        ...values,
        orgId,
        orgUrl,
        timezone
      }, '')
    } else {
      showNotification({
        title: 'Failed',
        message: "Unable to reset unknown user's password",
        color: 'red'
      })
    }
  }, [form.values])

  useEffect(() => {
    login.error.mapOrDefault(error => {
      showNotification({
        title: 'Failed',
        message: `Login failed! ${error.message}`,
        color: 'red'
      })
    }, null)
  }, [login.error])

  useEffect(() => {
    login.data.ifJust(user => {
      // This will automatically sign in the updated user after successful password reset
      setTimeout(() => {
        signin(user)
        navigate('/')
      }, 100)
    })
  }, [login.data])

  useEffect(() => {
    resetUserPassword.data.ifJust(({ email, orgId }) => {
      showNotification({
        title: 'Success',
        message: 'Your password has been successfully changed',
        color: 'green'
      })

      void login.fetch({
        email,
        password: form.values.password,
        orgId,
        timezone,
        orgUrl
      }, '')
    })
  }, [resetUserPassword.data])

  useEffect(() => {
    getUser.error.ifJust(() => {
      // this will redirect the user to login page if the reset id is invalid
      navigate('/')
    })
  }, [getUser.error])

  useEffect(() => {
    void getUser.fetch({ resetId }, '')
  }, [])

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <form className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
          <Paper shadow="sm" radius="md" py={65} px={35}>
            <Stack align="stretch" justify="center" spacing="sm">
              <Center>
                <MainLogo orgId={orgId} />
              </Center>
              <Center>
                <Title order={2} mb="xs">PASSWORD RESET</Title>
              </Center>
              <TextInput
                label="Email"
                value={user?.email}
                disabled
              />
              <PasswordInput
                withAsterisk
                label="New Password"
                {...form.getInputProps('password')}
              />
              <PasswordInput
                withAsterisk
                label="Confirm New Password"
                {...form.getInputProps('matchingPassword')}
              />
              <Button
                type="submit"
                color="primary"
                leftIcon={<FontAwesomeIcon icon={['fas', 'rotate-left']} color="white" />}
                disabled={resetUserPassword.loading}
              >
                Reset Password
              </Button>
              <Center>
                <Anchor mt="lg" size="sm" href="/" target="_self">Go to Login</Anchor>
              </Center>
            </Stack>
          </Paper>
        </form>
      </div>
    </div>
  )
}
