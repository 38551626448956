import {
  createStyles,
  useMantineTheme,
  Group,
  Text,
  ThemeIcon,
  Tooltip
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import { type DiagnosticMetricItem } from './DiagnosticMetric'

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative'
  },
  icon: {
    height: 32,
    color: theme.colors.dark[2],
    background: '#E6E6E6',
    borderRadius: '4px 4px 0 0'
  }
}))

export default function DiagnosticMetricMini ({
  name,
  icon,
  displayValue,
  displayValueUnit,
  label,
  disabled = false
}: DiagnosticMetricItem) {
  const { classes } = useStyles()
  const { breakpoints: { md } } = useMantineTheme()
  const isMediumScreen = useMediaQuery(mq(md, false))

  return (
    <Group className={classes.container} spacing={8} align="center">
      <Group spacing={6}>
        <Tooltip
          label={name}
          position="bottom"
          withArrow
          withinPortal
        >
          <ThemeIcon className={classes.icon}>
            {icon}
          </ThemeIcon>
        </Tooltip>
        <Text
          size={16}
          weight={500}
          align="center"
          color={disabled
            ? 'dimmed'
            : undefined}
        >
          {!disabled
            ? `${displayValue} ${displayValueUnit}`
            : 'N/A'}
        </Text>
      </Group>
      {label &&
        isMediumScreen &&
        !disabled && (
          <Text
            size={12}
            weight={500}
            color="dark.3"
          >
            {label}
          </Text>
      )}
    </Group>
  )
}
