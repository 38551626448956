import { RENDER_STATE, SHAPE } from 'react-map-gl-draw'
import { type Feature } from './shared'
import { MODES } from './constants'

interface FeatureStyle {
  mode?: string
  state?: string
  shape?: string
  feature?: Feature
}

interface Style {
  r?: number
  fill?: string
  stroke?: string
  strokeDasharray?: string
}

const RECT_STYLE = {
  strokeWidth: 2,
  x: -6,
  y: -6,
  height: 12,
  width: 12
}

const CIRCLE_RADIUS = 8

const SELECTED_STYLE = {
  strokeWidth: 2,
  fillOpacity: 0.3
}

const HOVERED_STYLE = {
  strokeWidth: 2,
  fillOpacity: 0.3
}

const UNCOMMITTED_STYLE = {
  strokeDasharray: '4,2',
  strokeWidth: 2,
  fillOpacity: 0.2
}

const INACTIVE_STYLE = {
  strokeWidth: 2,
  fillOpacity: 0.2
}

const DEFAULT_STYLE = {
  stroke: '#000',
  strokeWidth: 2,
  fillOpacity: 0.1
}

export const FEATURE_STROKE_COLOR = '#F0E700'
export const FEATURE_FILL_COLOR = '#FFF500'

export function getFeatureStyle ({ feature, state, mode }: FeatureStyle) {
  const inEditingMode = mode === MODES.EDITING
  const strokeColor = inEditingMode
    ? FEATURE_STROKE_COLOR
    : FEATURE_FILL_COLOR
  const fillColor = inEditingMode
    ? FEATURE_STROKE_COLOR
    : FEATURE_FILL_COLOR
  const selectedFillColor = inEditingMode
    ? FEATURE_STROKE_COLOR
    : FEATURE_FILL_COLOR

  const type = feature?.properties.shape ?? feature?.geometry.type
  let style: Style = {}

  switch (state) {
    case RENDER_STATE.SELECTED:
      style = { ...SELECTED_STYLE, stroke: strokeColor, fill: selectedFillColor }
      break

    case RENDER_STATE.HOVERED:
      style = { ...HOVERED_STYLE, stroke: strokeColor, fill: fillColor }
      break

    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      style = { ...UNCOMMITTED_STYLE, stroke: strokeColor, fill: fillColor }
      break

    case RENDER_STATE.INACTIVE:
      style = { ...INACTIVE_STYLE, stroke: strokeColor, fill: fillColor }
      break

    default:
      style = { ...DEFAULT_STYLE, fill: fillColor }
  }

  switch (type) {
    case SHAPE.POINT:
      style.r = CIRCLE_RADIUS
      break
    case SHAPE.LINE_STRING:
      style.fill = 'none'
      break
    case SHAPE.POLYGON:
      if (state === RENDER_STATE.CLOSING) {
        style.strokeDasharray = '4,2'
      }

      break
    case SHAPE.RECTANGLE:
      if (state === RENDER_STATE.UNCOMMITTED) {
        style.strokeDasharray = '4,2'
      }

      break
    default:
  }

  return style
}

export function getEditHandleStyle ({ shape, state, mode }: FeatureStyle) {
  const inEditingMode = mode === MODES.EDITING
  const strokeColor = inEditingMode
    ? FEATURE_STROKE_COLOR
    : FEATURE_FILL_COLOR
  const fillColor = inEditingMode
    ? FEATURE_STROKE_COLOR
    : FEATURE_FILL_COLOR
  let style: Style = {}

  switch (state) {
    case RENDER_STATE.SELECTED:
      style = { ...SELECTED_STYLE, stroke: strokeColor, fill: fillColor }
      break

    case RENDER_STATE.HOVERED:
      style = { ...HOVERED_STYLE, stroke: strokeColor, fill: fillColor }
      break

    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      style = { ...UNCOMMITTED_STYLE, stroke: strokeColor, fill: fillColor }
      break

    case RENDER_STATE.INACTIVE:
      style = { ...INACTIVE_STYLE, stroke: strokeColor, fill: fillColor }
      break

    default:
      style = { ...DEFAULT_STYLE, fill: fillColor }
  }

  switch (shape) {
    case 'circle':
      style.r = CIRCLE_RADIUS
      break
    case 'rect':
      style = { ...style, ...RECT_STYLE, stroke: strokeColor }
      break
    default:
  }

  return style
}
