import { type CSSProperties } from 'react'
import {
  Box,
  Group,
  Paper,
  Text,
  createStyles
} from '@mantine/core'
import { type Sensor, type LiquidType } from '@venturi-io/api/src/config/agent'
import { mq } from 'src/utils/style'
import { type Alarm } from '@venturi-io/api/src/analytics/alarm'
import NotApplicableOverlay from 'src/AssetTemplate/Components/NotApplicableOverlay'
import { hasAlarm, hasWarning } from 'src/AssetTemplate/shared'
import colors from 'src/AssetTemplate/colors'
import FuelTank from './FuelTank'
import AlarmIndicator from './AlarmIndicator'

export const computeAlarmPosition = (setPoint: number, capacity: number) => (
  Math.round((setPoint / capacity) * 100)
)

const useStyles = createStyles(theme => ({
  container: {
    position: 'relative'
  },
  titleContainer: {
    position: 'relative',
    zIndex: 2
  },
  indicatorLine: {
    height: 32,
    width: 4
  },
  alarmIndicator: {
    position: 'absolute',
    top: -6,
    left: -16
  },
  content: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [mq(theme.breakpoints.md)]: {
      flexDirection: 'row'
    }
  }
}))

interface Props {
  fuelLevel: Sensor | null
  fuelConsumption: Sensor | null
  fuelLevelPercent: number
  fuelRemainingPercent: number
  fuelCapacityLiter: number
  fuelLevelLiter: Sensor | null
  safeFillLevelPercent: number | null
  safeFillLevelLiter: number | null
  currentFuelLevelLiter: number
  fuelType?: LiquidType
  fuelLevelPercentAlarms: Alarm[]
  fuelLevelLiterAlarms: Alarm[]
}

export default function TankStatus ({
  fuelLevel,
  fuelConsumption,
  fuelLevelPercent,
  fuelRemainingPercent,
  fuelLevelLiter,
  safeFillLevelPercent,
  safeFillLevelLiter,
  fuelCapacityLiter,
  fuelType,
  currentFuelLevelLiter,
  fuelLevelPercentAlarms,
  fuelLevelLiterAlarms
}: Props) {
  const { classes } = useStyles()
  const criticalAlarm = fuelLevelPercentAlarms[0] ??
    fuelLevelLiterAlarms[0] ??
    null
  const withWarning = hasWarning([
    fuelLevel,
    fuelConsumption,
    fuelLevelLiter
  ])
  const withAlarm = hasAlarm([
    fuelLevel,
    fuelConsumption,
    fuelLevelLiter
  ])
  const isCriticalAlarm = withAlarm &&
    criticalAlarm !== null &&
    ((typeof fuelLevelPercentAlarms[0] !== 'undefined' &&
      criticalAlarm.setPoint >= fuelLevelPercent) ||
    (typeof fuelLevelLiterAlarms[0] !== 'undefined' &&
      criticalAlarm.setPoint >= currentFuelLevelLiter))
  const statusColor: CSSProperties['color'] = withAlarm || isCriticalAlarm
    ? colors.red
    : withWarning
      ? colors.yellow
      : colors.blue
  const disabled = fuelLevel === null

  return (
    <Paper
      className={classes.container}
      p={16}
      radius={4}
      shadow="xs"
    >
      {disabled && <NotApplicableOverlay size="lg" />}
      <Box className={classes.titleContainer}>
        <Text
          size={14}
          weight={600}
        >
          Tank Status
        </Text>
        {withAlarm && (
          <Group
            className={classes.alarmIndicator}
            position="center"
            align="center"
            spacing={110}
          >
            <span
              className={classes.indicatorLine}
              style={{
                background: statusColor
              }}
            />
            <AlarmIndicator
              size={32}
              alarmColor={statusColor}
            />
          </Group>
        )}
      </Box>
      <Box className={classes.content}>
        <FuelTank
          fuelLevelPercent={fuelLevelPercent}
          fuelRemainingPercent={fuelRemainingPercent}
          fuelLevelPercentAlarms={fuelLevelPercentAlarms}
          fuelLevelLiterAlarms={fuelLevelLiterAlarms}
          fuelCapacityLiter={fuelCapacityLiter}
          safeFillLevelPercent={safeFillLevelPercent}
          safeFillLevelLiter={safeFillLevelLiter}
          currentFuelLevelLiter={currentFuelLevelLiter}
          fuelType={fuelType}
          withWarning={withWarning}
          withAlarm={withAlarm}
          isCriticalAlarm={isCriticalAlarm}
          statusColor={statusColor}
        />
      </Box>
    </Paper>
  )
}
