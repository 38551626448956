import { type Sensor } from '@venturi-io/api/src/config/agent'
import StatusBadge, {
  type Status,
  getStatus,
  statusIcon,
  statusColor
} from 'src/AssetTemplate/Components/StatusBadge'
import { truncateWithEllipsis } from 'src/utils/strings'

interface Props {
  sensor: Sensor | null
  expanded?: boolean
  showTitle?: boolean
}

export default function EngineState ({
  sensor,
  expanded,
  showTitle
}: Props) {
  const status: Status = getStatus(sensor?.currentValue)
  const statusLabel = status === 'Other'
    ? truncateWithEllipsis(status, 10)
    : status
  const disabled = sensor === null

  return (
    <StatusBadge
      title="Engine State"
      icon={statusIcon[status]}
      color={statusColor[status]}
      textColor="white"
      value={statusLabel}
      showTitle={showTitle}
      expanded={expanded}
      disabled={disabled}
    />
  )
}
