import { useEffect, useState } from 'react'
import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import { getAgentDetails } from '@venturi-io/api/src/config/agent'
import {
  createStyles,
  Box,
  Chip,
  Group,
  Stack,
  Text,
  Tooltip
} from '@mantine/core'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'

const useStyles = createStyles(() => ({
  chip: {
    fontSize: 12,
    fontWeight: 500
  }
}))

interface Props {
  agentId: AgentDetails['agentId']
  setWithGroups: (value: boolean) => void
}

export default function Groups ({ agentId, setWithGroups }: Props) {
  const { classes } = useStyles()
  const { token } = useUser()
  const agent = useApi(getAgentDetails)
  const agentGroups = agent.data.mapOrDefault(({ agentGroups }) => agentGroups, [])
  const displayItemsLimit = 2
  const excessItemsCount = agentGroups.length - displayItemsLimit
  const [showMore, setShowMore] = useState(false)

  useEffect(() => {
    setWithGroups(agentGroups.length > 0)
  }, [agentGroups])

  useEffect(() => {
    void agent.fetch({ agentId }, token)
  }, [agentId])

  return (
    !agent.loading && agentGroups.length > 0 && (
      <Stack spacing={4}>
        <Text size={12} weight={500}>Groups</Text>
        <Group spacing={6}>
          {agentGroups
            .slice(0, displayItemsLimit)
            .map(({ agentGroupId, name }) => (
              <Chip
                key={agentGroupId}
                className={classes.chip}
                checked={false}
                color="gray"
                size="xs"
                radius="sm"
                variant="outline"
              >
                {name}
              </Chip>
            ))}
          {!showMore && agentGroups.length > displayItemsLimit && (
            <Tooltip
              label="Show more"
              position="right"
              withArrow
              withinPortal
            >
              <Box>
                <Chip
                  className={classes.chip}
                  checked={false}
                  color="gray"
                  size="xs"
                  radius="sm"
                  variant="outline"
                  onClick={() => setShowMore(true)}
                >
                  {`+${excessItemsCount}`}
                </Chip>
              </Box>
            </Tooltip>
          )}
          {/* Display the rest of the items if showMore === true */}
          {showMore && (
            agentGroups
              .slice(displayItemsLimit, agentGroups.length)
              .map(({ agentGroupId, name }) => (
                <Chip
                  key={agentGroupId}
                  className={classes.chip}
                  checked={false}
                  color="gray"
                  size="xs"
                  radius="sm"
                  variant="outline"
                >
                  {name}
                </Chip>
              ))
          )}
        </Group>
      </Stack>
    )
  )
}
