import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { Col, Grid, Stack } from '@mantine/core'
import Paper from 'src/Layout/Paper'
import SelectAgent from 'src/Input/Select/SelectAgent'
import DateRangePicker from 'src/Input/DateRangePicker'
import dayjs from 'dayjs'
import { type AgentLogsState } from 'src/UIState'
import { type DateRange } from 'src/utils/dates'
import { observer } from 'mobx-react-lite'
import Logs from './Logs'

interface Props {
  state: AgentLogsState
}

function AgentLogsPage ({ state }: Props) {
  const { dateRange } = state
  const { search } = useLocation()
  const navigate = useNavigate()
  const [showLogs, setShowLogs] = useState<boolean>(false)
  const [agentId, setAgentId] = useState<string | null>(null)
  const [period, setPeriod] = useState<DateRange>([
    dayjs(new Date())
      .subtract(7, 'days')
      .toDate(),
    dayjs(new Date()).toDate()
  ])

  useEffect(() => {
    const params = new URLSearchParams()

    if (agentId) {
      params.append('agentId', agentId)
    }

    navigate(`?${params.toString()}`, { replace: true })
  }, [agentId])

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search)
      const agentId_ = params.get('agentId')

      if (agentId_) {
        setAgentId(agentId_)
      }
    }
    setShowLogs(true)
  }, [search])

  useEffect(() => {
    const isSameFirstDate = dayjs(period[0]).diff(dateRange[0], 'days') === 0
    const isSameSecondDate = dayjs(period[1]).diff(dateRange[1], 'days') === 0

    if (isSameFirstDate && isSameSecondDate) return

    state.dateRange = period
  }, [period])

  const childProps = {
    agentId: agentId
      ? Number(agentId)
      : undefined,
    dateRange
  }

  return (
    <Stack
      spacing={0}
      sx={{
        position: 'absolute',
        width: '97%',
        height: '95%'
      }}
    >
      <Paper mt={0}>
        <Grid mt={2}>
          <Col span={12} sm={8}>
            <SelectAgent
              defaultValue={agentId}
              setExternalAgentId={setAgentId}
              searchable
            />
          </Col>
          <Col span={12} sm={4}>
            <DateRangePicker
              placeholder="Choose date range"
              value={period}
              onChange={setPeriod}
              clearable={false}
              disabled={!agentId}
              maxDate={dayjs().toDate()}
            />
          </Col>
        </Grid>
      </Paper>
      {showLogs && (
        <Logs {...childProps} />
      )}
    </Stack>
  )
}

const AgentLogs = observer<Props>(AgentLogsPage)

export default AgentLogs
