import { IconBolt, IconDroplet, IconGauge } from '@tabler/icons-react'
import { createStyles, Accordion } from '@mantine/core'
import { type Agent, type Sensor } from '@venturi-io/api/src/config/agent'
import { getWhiteBackgroundColor } from 'src/utils/theme'
import Engine from './Engine'
import Fuel from './Fuel'
import Power from './Power'

const useStyles = createStyles((theme) => ({
  item: {
    ...getWhiteBackgroundColor(theme),
    '&[data-active]': {
      ...getWhiteBackgroundColor(theme)
    }
  }
}))

interface Props {
  agentId: Agent['agentId']
  sensors: Sensor[]
}

export default function Analytics ({ agentId, sensors }: Props) {
  const { classes } = useStyles()

  return (
    <Accordion
      classNames={classes}
      variant="contained"
      radius="xs"
      multiple
    >
      <Accordion.Item value="engine">
        <Accordion.Control icon={<IconGauge size={16} color="#228BE6" />}>
          Engine
        </Accordion.Control>
        <Accordion.Panel>
          <Engine sensors={sensors} />
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value="fuel">
        <Accordion.Control icon={<IconDroplet size={16} color="#228BE6" />}>
          Fuel
        </Accordion.Control>
        <Accordion.Panel>
          <Fuel sensors={sensors} />
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value="power">
        <Accordion.Control icon={<IconBolt size={16} color="#228BE6" />}>
          Power
        </Accordion.Control>
        <Accordion.Panel>
          <Power agentId={agentId} sensors={sensors} />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  )
}
