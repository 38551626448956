import React from 'react'
import { ActionIcon, Popover } from '@mantine/core'
import { type TripData } from './TripPins'
import { type TransactionData } from './FillPins'
import { type TheftData } from './TheftPins'

export type PinType = 'trips' | 'transactions' | 'theft'

export type Data = TripData | TransactionData | TheftData

export interface Pin {
  x: number
  icon: JSX.Element
  data: Data
}

export interface PinProps {
  pins: Pin[]
  content: (data: Data) => JSX.Element
}

function Pins ({
  pins,
  content
}: PinProps) {
  return pins.map(({ x, icon, data }) => (
    <Popover
      position="right-start"
      withArrow
      withinPortal
      arrowOffset={10}
      offset={-5}
      key={x}
    >
      <Popover.Target>
        <ActionIcon
          size={32}
          radius="xl"
          sx={{
            position: 'absolute',
            top: 0,
            left: x
          }}
        >
          {icon}
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        {content(data)}
      </Popover.Dropdown>
    </Popover>
  ))
}

export default Pins
