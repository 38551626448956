import { Drawer, Title } from '@mantine/core'
import { createReportSchedule, type ReportSchedule } from '@venturi-io/api/src/config/report'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { useEffect } from 'react'
import Form from './Form'

interface Props {
  show: boolean
  close: () => void
  onCreate: () => Promise<void>
}

export default function Create ({ show, close, onCreate }: Props) {
  const { token } = useUser()
  const createSchedule = useApi(createReportSchedule)

  const handleCreate = (values: Partial<ReportSchedule>) => {
    void createSchedule
      .fetch(values, token, 'Successfuly created report schedule')
      .then(close)
  }

  useEffect(() => {
    createSchedule.data.ifJust(() => {
      if (onCreate) {
        void onCreate()
      }
    })
  }, [createSchedule.data])

  return (
    <Drawer
      opened={show}
      onClose={close}
      title={<Title order={3}>Create New Schedule</Title>}
      padding="xl"
      size={500}
      position="right"
    >
      <Form
        mode="CREATE"
        isLoading={createSchedule.loading}
        onCreate={handleCreate}
        onClose={close}
      />
    </Drawer>
  )
}
