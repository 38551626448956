import { type AgentDetails, type AssetType } from '@venturi-io/api/src/config/agent'
import { createStyles, ActionIcon, Box, Image } from '@mantine/core'
import { IconBox } from '@tabler/icons-react'
import { getBackgroundColor } from 'src/utils/theme'

const defaultPhoto = '/assets/AP-Generic-100.svg'

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 224,
    ...getBackgroundColor(theme),
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.05)'
  },
  symbol: {
    position: 'absolute',
    top: 4,
    left: 4,
    pointerEvents: 'none'
  }
}))

const assetTypePhotos: Record<AssetType, string> = {
  GENSET: '/assets/AP-GenSet-128.svg',
  ATS: '/',
  UPS: '/',
  TANK: '/assets/AP-Tank-128.svg',
  VEHICLE: '/',
  DURESS: '/',
  'POWER METER': '/',
  '3P-POWER': '/',
  OTHERS: ''
}

interface Props {
  assetType: AgentDetails['assetType']
}

export default function Pictogram ({ assetType }: Props) {
  const { classes } = useStyles()
  return (
    <Box className={classes.container}>
      <ActionIcon
        className={classes.symbol}
        variant="transparent"
        size="xs"
        color="dark.0"
      >
        <IconBox size={16} stroke={2} />
      </ActionIcon>
      <Image
        src={assetTypePhotos[assetType as AssetType] ?? defaultPhoto}
        alt={`${assetType} photo`}
        width={128}
        height={128}
        fit="contain"
      />
    </Box>
  )
}
