import { useCallback } from 'react'
import { Box, createStyles, useMantineTheme } from '@mantine/core'
import { useClickOutside, useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import zIndex from 'src/utils/zIndex'
import { getTextColor, getWhiteBackgroundColor } from 'src/utils/theme'
import { type StyleParams, styleParams } from '../shared'
import Logo from './Logo'
import { CloseMenu } from './CloseMenu'
import MenuList from './MenuList'
import MobileExtraMenuItems from './MobileExtraMenuItems'

const useStyles = createStyles((theme, params: StyleParams) => ({
  root: {
    zIndex: zIndex.menu,
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'fixed',
    top: params.headerHeight,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    [mq(theme.breakpoints.sm)]: {
      top: 0,
      width: '260px',
      left: 'initial',
      right: 'initial'
    },
    transition: 'all ease-in-out .2s',
    boxShadow: params.show
      ? theme.shadows.xs
      : 'unset',
    scrollbarWidth: 'auto',
    '&::-webkit-scrollbar': {
      width: '3px'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.colors.gray[4]
    },
    ...getWhiteBackgroundColor(theme),
    ...getTextColor(theme)
  },
  collapsed: {
    bottom: `calc(100vh - ${params.headerHeight})`,
    [mq(theme.breakpoints.sm)]: {
      width: params.sidebarCollapsedWidth,
      bottom: 0
    }
  },
  container: {
    minWidth: '100vw',
    [mq(theme.breakpoints.sm)]: {
      minWidth: '260px'
    }
  }
}))

interface Props {
  orgId: number
  show: boolean
  signout: () => void
  setShow: (input: boolean) => void
}

export default function Menu ({ orgId, show, signout, setShow }: Props) {
  const { classes, cx } = useStyles({ ...styleParams, show })
  const theme = useMantineTheme()
  const isDesktop = useMediaQuery(mq(theme.breakpoints.sm, false))

  const closeMenu = useCallback(() => {
    if (isDesktop) setShow(false)
  }, [isDesktop])

  const ref = useClickOutside(closeMenu)

  const menuProps = {
    show,
    setShow,
    isDesktop,
    signout
  }

  return (
    <aside
      className={cx(classes.root, { [classes.collapsed]: !show })}
      ref={ref}
    >
      <Box className={classes.container}>
        {isDesktop && <Logo orgId={orgId} minimised={!show} isDesktop={isDesktop} />}
        {isDesktop && <CloseMenu {...menuProps} showLabel={isDesktop} />}
        <MenuList {...menuProps} />
        {!isDesktop && <MobileExtraMenuItems {...menuProps} />}
      </Box>
    </aside>
  )
}
