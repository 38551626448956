import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from '@mantine/form'
import {
  Box,
  Col,
  Divider,
  Grid,
  Group,
  Stack,
  Switch,
  Text
} from '@mantine/core'
import { type Metadata, type MetadataProp } from '@venturi-io/api/src/config/agent'
import AutocompleteInput from 'src/Input/AutocompleteInput'
import TextInput from 'src/Input/TextInput'
import ActionButton from 'src/ActionButton'

interface Item extends MetadataProp {
  name: string
}

interface FormProps {
  data: Item[]
}

interface Props {
  initialValue?: Metadata
  onChange: (value: Metadata) => void
  setErrorMessage: (value: string | null) => void
}

export const defaultItem: Item = {
  name: '',
  value: '',
  userEditable: true
}

const supportedNames = ['SAFE_FILL', 'POWER_CAPACITY']

export default function MetadataInput ({
  initialValue,
  onChange,
  setErrorMessage
}: Props) {
  const form = useForm<FormProps>({
    initialValues: {
      data: []
    },
    validate: {
      data: {
        name: (value) => value.includes(' ')
          ? 'Name should not contain a space, please use underscore instead'
          : null
      }
    }
  })

  const hasDuplicatedInputs = useMemo(() => {
    const inputKeys = form.values.data
      .filter(({ name }) => name.length > 0)
      .map(({ name }) => name)
    const uniqueInputKeys = new Set(inputKeys)

    return inputKeys.length !== uniqueInputKeys.size
  }, [form.values.data])

  const handleAddItem = () => {
    form.insertListItem('data', { ...defaultItem })
  }

  const handleValueChange = useCallback(() => {
    const result = form.validate()

    if (!result.hasErrors) {
      if (hasDuplicatedInputs) {
        setErrorMessage('** Duplicated inputs are not allowed **')
      } else {
        const metadata = form.values.data.reduce((data: Metadata, {
          name,
          value,
          userEditable
        }) => {
          data[name] = {
            value,
            userEditable
          }

          return data
        }, {})

        setErrorMessage(null)
        onChange(metadata)
      }
    }
  }, [form.values.data, onChange])

  useEffect(() => {
    handleValueChange()
  }, [form.values.data])

  useEffect(() => {
    if (initialValue) {
      const transformedData: Item[] = Object
        .entries(initialValue)
        .map(([name, { value, userEditable }]) => ({
          name,
          value,
          userEditable
        }))

      form.setFieldValue('data', transformedData)
    }
  }, [initialValue])

  const items = form.values.data.map((item, index) => {
    const disabled = initialValue?.[item.name]?.userEditable === false
    const withError = typeof form.errors[`data.${index}.name`] !== 'undefined'

    return (
      <Grid key={index} gutter={12} gutterSm={20}>
        <Col span={12} sm={5}>
          <Group align="flex-end" spacing={4}>
            <AutocompleteInput
              required
              sx={{
                flexGrow: 1
              }}
              label="Name"
              data={supportedNames}
              value={form.values.data[index].name}
              onChange={value => {
                form.setFieldValue(`data.${index}.name`, value.toLocaleUpperCase())
              }}
              onKeyUp={() => {
                handleValueChange()
              }}
              disabled={disabled}
              error={form.errors[`data.${index}.name`]}
            />
            {!withError && (
              <Box mb={1}>
                <ActionButton
                  label="Remove item"
                  actionVariant="default"
                  actionSize="lg"
                  icon="remove"
                  iconType="fas"
                  iconColor="gray"
                  iconSize="sm"
                  onClick={() => {
                    form.removeListItem('data', index)
                    handleValueChange()
                  }}
                  actionDisabled={disabled}
                />
              </Box>
            )}
          </Group>
        </Col>
        <Col span={12} sm={5}>
          <TextInput
            required
            label="Value"
            onKeyUp={() => {
              handleValueChange()
            }}
            {...form.getInputProps(`data.${index}.value`)}
            disabled={disabled}
          />
        </Col>
        <Col span={12} sm={2}>
          <Stack mb={12} spacing={12}>
            <Text size={12}>Editable</Text>
            <Switch
              color="primary"
              size="sm"
              checked={form.values.data[index].userEditable}
              onChange={({ currentTarget }) => {
                form.setFieldValue(`data.${index}.userEditable`, currentTarget.checked)
              }}
              disabled={disabled}
            />
          </Stack>
        </Col>
        <Col span={12}>
          <Divider variant="dashed" size="xs" />
        </Col>
      </Grid>
    )
  })

  return (
    <Grid grow>
      <Col span={12}>
        <Group spacing={8}>
          <Text size={12}>Metadata</Text>
          <ActionButton
            label="Add item"
            icon="plus"
            iconType="fas"
            onClick={handleAddItem}
            actionColor="blue"
            actionVariant="filled"
            actionSize="xs"
            iconColor="white"
            iconSize="xs"
          />
        </Group>
        <Col span={12}>
          <Stack spacing={16}>
            {items}
          </Stack>
        </Col>
      </Col>
    </Grid>
  )
}
