import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Group, Paper, SimpleGrid, Stack } from '@mantine/core'
import dayjs from 'dayjs'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import { deleteTask, type Task } from '@venturi-io/api/src/config/task'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import ActionButton from 'src/ActionButton'
import NeedsRole from 'src/NeedsRole'
import { adminRoles, checkIfHasRole } from 'src/utils/role'
import { dateFormat, uiDateFormatRegular } from 'src/utils/dates'
import { parseEmpty } from 'src/utils/parseEmpty'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'
import { type Actions } from '.'

export default function Card ({
  id,
  agent,
  taskType,
  priority,
  assignedTo,
  dueDate,
  status,
  actions
}: Task & ListRow<Actions>) {
  const navigate = useNavigate()
  const [showDelete, setShowDelete] = useState(false)
  const removeTask = useApi(deleteTask)
  const { token } = useUser()
  const hasRole = checkIfHasRole(adminRoles)
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  const handleDelete = useCallback(() => {
    void removeTask
      .fetch({ taskId: id }, token, 'Succesfully deleted task')
      .finally(() => {
        void actions.loadTasks()
      })
  }, [id])

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group
          position={hasRole
            ? 'apart'
            : 'right'}
          align="start"
        >
          <NeedsRole role={adminRoles}>
            <TextGroup label="ID" value={id.toString()} isKey />
          </NeedsRole>
          <Group position="right">
            <ActionButton
              label="Details"
              icon="file-text"
              iconType="fas"
              onClick={() => navigate(`/tasks/details/${id}`)}
              actionColor="blue"
              actionVariant="filled"
              iconColor="white"
              iconSize="sm"
            />
            <ActionButton
              label="Edit"
              icon="pencil"
              iconType="fas"
              onClick={() => navigate(`/tasks/edit/${id}`)}
              actionColor="green"
              actionVariant="filled"
              iconColor="white"
              iconSize="sm"
            />
            <ActionButton
              label="Delete"
              icon="trash-can"
              iconType="fas"
              onClick={() => setShowDelete(true)}
              actionColor="red"
              actionVariant="filled"
              iconColor="white"
              iconSize="sm"
              actionDisabled={false}
            />
          </Group>
        </Group>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup
            label="Asset"
            value={agent.agentName}
            maxLength={textMaxLength}
          />
          <TextGroup label="Type" value={agent.assetType ?? ''} />
          <TextGroup label="Task Type" value={taskType} />
          <TextGroup label="Priority" value={priority} />
          <TextGroup
            label="Assignee"
            value={assignedTo
              ? `${assignedTo.firstName} ${assignedTo.lastName}`
              : 'N/A'}
            maxLength={textMaxLength}
          />
          <TextGroup
            label="Due Date"
            value={dayjs(dueDate, dateFormat).format(uiDateFormatRegular)}
          />
          <TextGroup label="Status" value={parseEmpty(status)} />
        </SimpleGrid>
      </Stack>
      <ConfirmModal
        type="delete"
        opened={showDelete}
        title={`Deleting task for "${agent.agentName}"`}
        loading={removeTask.loading}
        question="Are you sure you want to delete this task? This cannot be undone."
        onClose={() => setShowDelete(false)}
        onCancel={() => setShowDelete(false)}
        onConfirm={handleDelete}
      />
    </Paper>
  )
}
