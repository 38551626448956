import { Loader } from '@mantine/core'
import LoadingOverlay from 'src/Layout/LoadingOverlay'

interface Props {
  isLoading: boolean
}

export default function ({ isLoading }: Props) {
  return (
    <LoadingOverlay
      visible={isLoading}
      overlayBlur={1}
      loader={(
        <Loader
          color="primary"
          size="sm"
          variant="dots"
        />
      )}
    />
  )
}
