import { useMantineTheme, Group } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import { mq } from 'src/utils/style'
import Alarms from './Alarms'
import EngineState from './EngineState'
import ControlMode from './ControlMode'

interface Props {
  alarms: number
  engineStatus: Sensor | null
  controlMode: Sensor | null
}

export default function Statuses ({
  alarms,
  engineStatus,
  controlMode
}: Props) {
  const { breakpoints: { xs } } = useMantineTheme()
  const isSmallerScreen = useMediaQuery(mq(xs, false))

  return (
    <Group
      position="center"
      align="center"
      spacing={isSmallerScreen
        ? 16
        : 8}
    >
      {alarms > 0 && (
        <Alarms count={alarms} showTitle={isSmallerScreen} />
      )}
      <EngineState
        sensor={engineStatus}
        showTitle={isSmallerScreen}
        expanded={isSmallerScreen}
      />
      <ControlMode
        sensor={controlMode}
        showTitle={isSmallerScreen}
        expanded={isSmallerScreen}
      />
    </Group>
  )
}
