import {
  Center,
  Paper,
  SimpleGrid,
  Text,
  useMantineColorScheme
} from '@mantine/core'

const statuses = [
  'Off',
  'Manual',
  'Auto',
  'Test'
] as const

export type AtsStatus = typeof statuses[number]

export default function Status ({ value }: { value: AtsStatus }) {
  const { colorScheme } = useMantineColorScheme()

  return (
    <SimpleGrid cols={4} px={15} spacing="xl">
      {statuses.map(status => (
        <Paper
          key={status}
          radius="md"
          p="md"
          sx={theme => ({
            color: status === value
              ? 'white'
              : colorScheme === 'light'
                ? theme.colors.dark[4]
                : theme.colors.gray[2],
            background: status === value
              ? theme.colors.green[4]
              : colorScheme === 'light'
                ? theme.colors.gray[2]
                : theme.colors.dark[4]
          })}
        >
          <Center>
            <Text>{status}</Text>
          </Center>
        </Paper>
      ))}
    </SimpleGrid>
  )
}
