import { type ReactNode, useEffect, useState, useCallback } from 'react'
import { type SelectProps, type SelectItem } from '@mantine/core'
import { type User } from '@venturi-io/api/src/userManager/user'
import { getUsers } from '@venturi-io/api/src/userManager/ui'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import Select from '../Select'

type Value = string | null

interface Props extends Omit<SelectProps, 'data'> {
  label?: string
  placeholder?: string
  defaultValue?: Value
  value?: Value
  onChange?: (value: Value) => void
  setExternalUserId?: (value: Value) => void
  required?: boolean
  searchable?: boolean
  disabled?: boolean
  error?: ReactNode
}

const userToSelect = ({
  orgUserId,
  firstName,
  lastName
}: User): SelectItem => ({
  value: orgUserId.toString(),
  label: `${firstName} ${lastName}`
})

export default function SelectUser ({
  defaultValue,
  value,
  label,
  placeholder,
  setExternalUserId,
  onChange,
  required = false,
  searchable = false,
  disabled = false,
  error,
  ...props
}: Props) {
  const { token, orgId } = useUser()
  const users = useApi(getUsers)
  const [userId, setUserId] = useState<Value>(null)
  const selectUsers = users.data.mapOrDefault(data => data.map(userToSelect), [])

  const loadUsers = () => {
    void users.fetch({ orgId }, token)
  }

  const handleChange = useCallback((val: Value) => {
    setUserId(val)

    if (setExternalUserId) {
      setExternalUserId(val)
    }

    if (onChange) {
      onChange(val)
    }
  }, [setExternalUserId, onChange])

  useEffect(() => {
    if (defaultValue) {
      setUserId(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    void loadUsers()
  }, [])

  return (
    <Select
      label={label}
      placeholder={placeholder ?? 'Choose a user'}
      data={selectUsers}
      defaultValue={defaultValue}
      value={value ?? userId}
      onChange={handleChange}
      required={required}
      searchable={searchable}
      disabled={disabled || users.loading}
      error={error}
      {...props}
    />
  )
}
