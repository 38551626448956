import React, { useEffect, useState } from 'react'
import { dateFormat } from 'src/utils/dates'
import dayjs from 'dayjs'
import { type DateTimePickerProps, type DatesRangeValue } from '@mantine/dates'
import { type InputSharedProps, Text, type SelectItem, Stack } from '@mantine/core'
import { type Range } from '@venturi-io/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from '../Select'
import DateRangePicker from '../DateRangePicker'
import DateTimeRangePicker from '../DateTimeRangePicker'

interface Props extends Pick<InputSharedProps, 'size' > {
  withTime?: boolean
  dateRange: Range | null
  defaultValue?: string
  onChangeRange?: (range: Range | null) => void
  applyButton?: (submit: () => void, disabled: boolean) => React.ReactNode | JSX.Element | null
}

const format = `${dateFormat}`

const options: SelectItem[] = [
  { value: '8hours', label: 'Last 8 hours' },
  { value: '16hours', label: 'Last 16 hours' },
  { value: '24hours', label: 'Last 24 hours' },
  { value: '3days', label: 'Last 3 days' },
  { value: '7days', label: 'Last 7 days' },
  { value: 'custom', label: 'Absolute date' }
]

const inputWrapperOrder: Array<'label' | 'error' | 'input' | 'description'> = ['label', 'error', 'input', 'description']
const sharedProps: Partial<DateTimePickerProps> = {
  inputWrapperOrder,
  descriptionProps: {
    style: {
      fontStyle: 'italic'
    }
  },
  maxDate: dayjs().toDate()
}

function SelectFromTo ({
  size = 'sm',
  dateRange,
  defaultValue,
  onChangeRange,
  withTime,
  applyButton
}: Props) {
  const [delayedRange, setDelayedRange] = useState<Range | null>(dateRange)
  const [selectedRangeValue, setSelectedRangeValue] = useState<string | null>(defaultValue ?? null)

  const useRange = applyButton
    ? delayedRange
    : dateRange

  const { from, to } = useRange
    ? {
        from: dayjs(useRange.from, dateFormat),
        to: dayjs(useRange.to, dateFormat)
      }
    : {
        from: null,
        to: null
      }

  const range: DatesRangeValue = [
    from?.toDate() ?? null,
    to?.toDate() ?? null
  ]

  const isValidRange = from && to
    ? from.isBefore(to)
    : true

  const handleChangeRange = (range: Range | null, passThru?: boolean) => {
    if (applyButton && !passThru) {
      setDelayedRange(range)
      return
    }
    if (range && onChangeRange) {
      onChangeRange(range)
    }
  }

  const handleDelayedChangeRange = () => {
    if (delayedRange && onChangeRange) {
      // only change to utc here
      onChangeRange(delayedRange)
    }
  }

  useEffect(() => {
    const now = dayjs()
    switch (selectedRangeValue) {
      case '8hours': {
        handleChangeRange({
          from: now
            .subtract(8, 'hour')
            .format(format),
          to: now.format(format)
        }, true)
        break
      }
      case '16hours': {
        handleChangeRange({
          from: now
            .subtract(16, 'hour')
            .format(format),
          to: now.format(format)
        }, true)
        break
      }
      case '24hours': {
        handleChangeRange({
          from: now
            .subtract(24, 'hour')
            .format(format),
          to: now.format(format)
        }, true)
        break
      }
      case '3days': {
        handleChangeRange({
          from: now
            .subtract(3, 'day')
            .format(format),
          to: now.format(format)
        }, true)
        break
      }
      case '7days': {
        handleChangeRange({
          from: now
            .subtract(7, 'day')
            .format(format),
          to: now.format(format)
        }, true)
        break
      }
      default:
        break
    }
  }, [selectedRangeValue])

  return (
    <>
      <Select
        icon={<FontAwesomeIcon icon={['far', 'calendar-day']} />}
        size={size}
        placeholder="Select range"
        value={selectedRangeValue}
        onChange={value => setSelectedRangeValue(value)}
        data={options}
      />
      {selectedRangeValue === 'custom' && (
        !withTime
          ? (
            <DateRangePicker
              size={size}
              label="Date"
              valueFormat="MMM DD YYYY hh:mm A"
              inputWrapperOrder={inputWrapperOrder}
              descriptionProps={{
                style: {
                  fontStyle: 'italic'
                }
              }}
              allowSingleDateInRange
              defaultValue={range}
              onChange={date => {
                if (!date) {
                  return handleChangeRange(null)
                }
                const [from, to] = date
                handleChangeRange({
                  from: dayjs(from)
                    .set('hour', 0)
                    .set('minute', 0)
                    .set('seconds', 0)
                    .format(format),
                  to: dayjs(to)
                    .set('hour', 23)
                    .set('minute', 59)
                    .set('seconds', 59)
                    .format(format)
                })
              }}
            />
            )
          : (
            <Stack>
              <DateTimeRangePicker
                fromProps={{
                  ...sharedProps,
                  size,
                  valueFormat: 'dddd, DD MMMM  HH:mm',
                  error: !isValidRange,
                  placeholder: 'From',
                  defaultValue: range[0],
                  onChange: date => {
                    if (!date) {
                      return handleChangeRange(null)
                    }
                    handleChangeRange({
                      from: dayjs(date)
                        .set('seconds', 0)
                        .format(format),
                      to: dayjs(range[1])
                        .set('seconds', 0)
                        .format(format)
                    })
                  }
                }}
                toProps={{
                  ...sharedProps,
                  size,
                  valueFormat: 'HH:mm  dddd, DD MMMM',
                  error: !isValidRange,
                  placeholder: 'To',
                  defaultValue: range[1],
                  onChange: date => {
                    if (!date) {
                      return handleChangeRange(null)
                    }
                    handleChangeRange({
                      from: dayjs(range[0])
                        .set('seconds', 0)
                        .format(format),
                      to: dayjs(date)
                        .set('seconds', 0)
                        .format(format)
                    })
                  }
                }}
                error={!isValidRange && (
                  <Text
                    italic
                    size="xs"
                    color="red"
                  >
                    Please provide a valid date range
                  </Text>
                )}
              />
              {applyButton && applyButton(handleDelayedChangeRange, !isValidRange)}
            </Stack>
            )
      )}
    </>
  )
}

export default SelectFromTo
