/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Container, Table as MantineTable, Text, createStyles } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import dayjs from 'dayjs'
import { uiDateFormatUniversal, uiDateOnly, uiTimeOnly } from 'src/utils/dates'
import NoData from 'src/Dashboard/Items/shared/NoData'
import Nothing from 'src/Nothing'
import { reverseGeocode } from 'src/Maps/GeoZoneMap/mapbox'
import { secondsToDhms } from 'src/Assets/shared'
import {
  getGeozoneEventsForTrip,
  type GeozoneEvent as GeoEvent
} from '@venturi-io/api/src/analytics/geozone'
import { getBackgroundColor } from 'src/utils/theme'

const useStyles = createStyles((theme, { stickyTop }: { stickyTop: number }) => ({
  container: {
    background: 'white'
  },
  head: {
    ...getBackgroundColor(theme, -1),
    position: 'sticky',
    top: stickyTop,
    zIndex: 3,
    borderTop: 0
  },
  table: {
    ...getBackgroundColor(theme, -1)
  },
  row: {
    zIndex: 2
  },
  center: {
    margin: '0 auto'
  },
  dock: {
    width: '100%',
    height: '100%',
    padding: '0 .5px',
    ...getBackgroundColor(theme, -1)
  },
  contentTitle: {
    margin: '0px 0px 15px 0px'
  },
  select: {
    input: {
      fontSize: '.7rem'
    },
    width: '100px'
  },
  statusBarContainer: {
    marginTop: '-10px'
  },
  statusGaugeContainer: {
    height: '50%',
    width: '20%',
    marginTop: '0'
  },
  gaugeSubLabel: {
    position: 'absolute',
    bottom: 10,
    left: 70
  }
}))

interface Props {
  tripId: number
  startTime: string
  endTime: string
  showTitle?: boolean
  style?: React.CSSProperties
  stickyTop?: number
  didChangeHeight?: (height: number) => void
}

export interface TableProps extends Pick<Props, 'didChangeHeight' | 'stickyTop'> {
  tripId: number
  data: GeoEvent[]
}

interface LocationCellProps {
  longitude?: number
  latitude?: number
}

function LocationCell ({ longitude, latitude }: LocationCellProps) {
  const [address, setAddress] = useState('...')
  const loadAddress = async () => {
    if (longitude && latitude) {
      const result = await reverseGeocode([longitude, latitude])
      setAddress(result)
    }
  }

  useEffect(() => {
    if (longitude && latitude) {
      void loadAddress()
    }
  }, [longitude, latitude])

  return (
    <td>{address}</td>
  )
}

function Rows ({ data }: TableProps) {
  return data.length > 0
    ? (
      <>
        {data.map(({
          geoZoneId,
          geoZoneName,
          timesVisited,
          enteredTime,
          leftTime,
          totalParkedTime,
          totalDurationInsideZone
        }, idx) => {
          return (
            <tr key={`${enteredTime}-${geoZoneId}`}>
              <td>{idx + 1}</td>
              <td>{geoZoneName}</td>
              <td>{dayjs(enteredTime).format(uiDateOnly)}</td>
              <td>{dayjs(enteredTime).format(uiTimeOnly)}</td>
              <td>{dayjs(leftTime).format(uiTimeOnly)}</td>
              <td>{secondsToDhms(totalDurationInsideZone)}</td>
              <td>{secondsToDhms(totalParkedTime ?? 0)}</td>
              <td>{timesVisited}</td>
            </tr>
          )
        })}
      </>
      )
    : (
      <tr>
        <td colSpan={15}><NoData message="No geozone events available" /></td>
      </tr>
      )
}

const barHeight = 12
const Table = ({
  data,
  tripId,
  stickyTop = 0,
  didChangeHeight
}: TableProps) => {
  const { classes } = useStyles({ stickyTop: stickyTop ?? 0 })
  const { ref, height } = useElementSize()

  useEffect(() => {
    if (didChangeHeight) {
      didChangeHeight(height + barHeight)
    }
  }, [height])

  return (
    <MantineTable
      className={classes.table}
      ref={ref}
      fontSize="xs"
    >
      <thead className={classes.head}>
        <tr>
          <th>Event #</th>
          <th>Geozone</th>
          <th>Event Date</th>
          <th>Time In</th>
          <th>Time Out</th>
          <th>Duration In</th>
          <th>Parked Duration</th>
          <th>Times Visited</th>
        </tr>
      </thead>
      <tbody>
        <Rows data={data} tripId={tripId} />
      </tbody>
    </MantineTable>
  )
}

export default function GeozoneEvent ({
  tripId,
  showTitle = true,
  style,
  stickyTop = 0,
  didChangeHeight
}: Props) {
  const { token } = useUser()
  const geozoneEvents = useApi(getGeozoneEventsForTrip)

  const { classes: sharedClasses } = useStyles({ stickyTop })

  const loadTrips = () => {
    void geozoneEvents.fetch({
      tripId
    }, token)
  }

  useEffect(() => {
    void loadTrips()
  }, [tripId])

  return (
    <Container className={sharedClasses.dock} fluid style={style}>
      {showTitle && (
        <Text
          transform="uppercase"
          size="sm"
          weight={500}
        >
          Geozone events
        </Text>
      )}

      {geozoneEvents.data.caseOf({
        Nothing: () => (
          <Nothing
            isLoading={geozoneEvents.loading}
            nothing={geozoneEvents.data.isNothing()}
          />
        ),
        Just: (data) => (
          <Table
            didChangeHeight={didChangeHeight}
            tripId={tripId}
            data={data}
            stickyTop={stickyTop}
          />
        )
      })}
    </Container>
  )
}
