import {
  Divider,
  Group,
  Paper,
  Skeleton,
  Stack
} from '@mantine/core'

export default function Tasks () {
  return (
    <Paper
      h={248}
      radius={8}
      shadow="xs"
    >
      <Group p={16} position="apart">
        <Skeleton
          height={20}
          width={100}
          radius="md"
        />
        <Group spacing={8}>
          <Skeleton
            height={20}
            width={20}
            radius="sm"
          />
          <Skeleton
            height={20}
            width={20}
            radius="sm"
          />
        </Group>
      </Group>
      <Divider size="xs" />
      <Stack p={16} spacing={18}>
        <Group position="apart">
          <Skeleton
            height={24}
            width={100}
            radius="md"
          />
          <Skeleton
            height={24}
            width={60}
            radius="xl"
          />
        </Group>
        <Group position="apart">
          <Skeleton
            height={24}
            width={110}
            radius="md"
          />
          <Skeleton
            height={24}
            width={60}
            radius="xl"
          />
        </Group>
        <Group position="apart">
          <Skeleton
            height={24}
            width={90}
            radius="md"
          />
          <Skeleton
            height={24}
            width={60}
            radius="xl"
          />
        </Group>
        <Group position="apart">
          <Skeleton
            height={24}
            width={130}
            radius="md"
          />
          <Skeleton
            height={24}
            width={60}
            radius="xl"
          />
        </Group>
      </Stack>
    </Paper>
  )
}
