import { PDFViewer } from '@react-pdf/renderer'
import { type ComplianceSummary } from '@venturi-io/api/src/config/alarmHistory'
import { Modal } from '@mantine/core'
import { type DateRange } from 'src/utils/dates'
import { type AlarmItem } from '..'
import AlarmHistoryReport from './AlarmHistoryReport'

export interface Props {
  agentName: string
  sensorName: string
  complianceSummary: ComplianceSummary
  data: AlarmItem[]
  dateRange: DateRange
  showPdfViewer: boolean
  toggleShowPdfViewer: () => void
}

export default function ExportToPdf ({
  agentName,
  sensorName,
  complianceSummary,
  data,
  dateRange,
  showPdfViewer,
  toggleShowPdfViewer
}: Props) {
  return (
    <Modal
      title="Exporting to pdf..."
      size="auto"
      opened={showPdfViewer}
      onClose={toggleShowPdfViewer}
    >
      <PDFViewer width="1000" height="650">
        <AlarmHistoryReport
          agentName={agentName}
          sensorName={sensorName}
          complianceSummary={complianceSummary}
          data={data}
          dateRange={dateRange}
        />
      </PDFViewer>
    </Modal>
  )
}
