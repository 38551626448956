import { createStyles } from '@mantine/core'
import { type LinkProps, Link as RRDLink } from 'react-router-dom'
import { getLinkTextColor } from 'src/utils/theme'

const useStyles = createStyles(theme => ({
  link: {
    ...getLinkTextColor(theme)
  }
}))

export default function Link (props: LinkProps) {
  const { classes } = useStyles()

  return <RRDLink className={classes.link} {...props} />
}
