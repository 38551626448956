import { useState, useEffect, type ReactNode } from 'react'
import { getReports, type Report } from '@venturi-io/api/src/config/report'
import Select from 'src/Input/Select'
import { toProperCase } from 'src/utils/strings'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { type SelectProps } from '@mantine/core'

interface Props extends Pick<SelectProps, 'withAsterisk'> {
  label?: string
  required?: boolean
  disabled?: boolean
  value?: number
  error?: ReactNode
  isMain?: boolean
  onChange: (value?: Report) => void
}

export default function SelectReport ({
  required = false,
  disabled = false,
  label,
  error,
  isMain,
  ...props
}: Props) {
  const { token } = useUser()
  const initialValue = props.value?.toString() ?? ''
  const [value, setValue] = useState(initialValue)

  const allReports = useApi(getReports)
  const reports = allReports.data.mapOrDefault(({ items }) => items, [])

  useEffect(() => {
    const type = reports.find(({
      id: mainId,
      reportType: {
        id
      }
    }) => isMain
      ? mainId.toString() === value
      : id.toString() === value)

    if (type) {
      props.onChange(type)
    } else {
      props.onChange()
    }
  }, [value, isMain])

  useEffect(() => {
    void allReports.fetch({ page: 1, size: 999 }, token)
  }, [])

  return (
    <Select
      {...props}
      label={label ?? 'Report type'}
      withAsterisk={required}
      disabled={disabled}
      placeholder="Choose a Report Type"
      value={initialValue}
      data={reports.map(({
        id: mainId,
        reportType: {
          id,
          name
        }
      }) => ({
        label: toProperCase(name),
        value: isMain
          ? mainId.toString()
          : id.toString()
      }))}
      onChange={val => setValue(val ?? '')}
      error={error}
    />
  )
}
