import { type CSSProperties } from 'react'
import { Image, Indicator, Stack, Text } from '@mantine/core'
import { getTextColorAsProp } from 'src/utils/theme'

interface Props {
  state?: boolean
  src: string
  label?: string
  style?: CSSProperties
}

export default function Sensor ({ state, src, label = '', style }: Props) {
  const statusColor = typeof state === 'undefined'
    ? getTextColorAsProp().color
    : state
      ? 'green'
      : 'red'

  return (
    <Stack
      mt={5}
      align="center"
      spacing="lg"
      style={{
        position: 'absolute',
        ...style
      }}
    >
      <Indicator size={10} color={statusColor} processing={statusColor === 'red'}>
        <span />
      </Indicator>
      <Image
        src={src}
        alt={label}
        sx={{
          width: '60%'
        }}
      />
      {label ?? <Text>{label}</Text>}
    </Stack>
  )
}
