import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import {
  type PaginatedRequest,
  type GeoZoneActionIdRequest,
  paginated,
  type OrgIdRequest
} from '../shared'

// Geo Zone Action Types
const actionTypes = z.record(z.string(), z.unknown())

export const getGeoZoneActionTypes = createStatelessApi<Record<string, unknown>, typeof actionTypes>(
  'config',
  '/geo/zone-action-types',
  { method: 'GET', type: 'path' },
  actionTypes
)

// Geo Zone Actions
const geoZoneAction = z.object({
  geoZoneActionId: z.number(),
  name: z.string(),
  action: z.string(),
  description: z.optional(z.string()),
  userGroup: z.object({
    userGroupId: z.number(),
    name: z.optional(z.string()),
    description: z.optional(z.string())
  })
})

export type GeoZoneAction = z.infer<typeof geoZoneAction>

export const createGeoZoneAction = createStatefulApi<Omit<GeoZoneAction, 'geoZoneActionId'>, typeof geoZoneAction>(
  'config',
  '/geo/zone-action',
  { method: 'POST' },
  geoZoneAction
)

const allGeoZoneActions = paginated.extend({
  items: z.array(geoZoneAction)
})

interface AllGeoZoneActionsRequest extends PaginatedRequest, OrgIdRequest {
  sensorType?: string
  search?: string
}
export const getGeoZoneActions = createStatelessApi<AllGeoZoneActionsRequest, typeof allGeoZoneActions>(
  'config',
  '/geo/zone-actions',
  { method: 'GET', type: 'path' },
  allGeoZoneActions
)

export const getGeoZoneAction = createStatelessApi<GeoZoneActionIdRequest, typeof geoZoneAction>(
  'config',
  '/geo/zone-action/:geoZoneActionId',
  { method: 'GET', type: 'path' },
  geoZoneAction
)

export const updateGeoZoneAction = createStatefulApi<GeoZoneAction, typeof geoZoneAction>(
  'config',
  '/geo/zone-action/:geoZoneActionId',
  { method: 'PUT' },
  geoZoneAction
)

export const deleteGeoZoneAction = createStatefulApi<GeoZoneActionIdRequest, z.ZodBoolean>(
  'config',
  '/geo/zone-action/:geoZoneActionId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)
