import { type Roles } from '@venturi-io/api/src/userManager/auth'
import React from 'react'
import { checkIfHasRole } from 'src/utils/role'

export type Role = Roles[0]

interface Props {
  role: Role | Role[]
  children: React.ReactNode
}

export default function NeedsRole ({ role, children }: Props) {
  const hasRole = checkIfHasRole(role)
  if (hasRole) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return null
}

export function wrapWithNeedsRole (role: Role | Roles | undefined, item: React.ReactNode) {
  if (!React.isValidElement(item)) return

  const key = item.key

  return role
    ? <NeedsRole key={key} role={role}>{item}</NeedsRole>
    : item
}
