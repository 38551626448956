import { Text } from '@mantine/core'
import { useStyles } from 'src/Layout/ActionList'

const Header = () => {
  const { classes, cx } = useStyles()

  return (
    <th className={cx(classes.row, classes.header)}>
      <td className={classes.col}>
        <Text weight={600} size="sm">
          Report Name
        </Text>
      </td>
      <td className={classes.minCol}>
        <Text weight={600} size="sm">
          Schedule
        </Text>
      </td>
      <td className={classes.periodCol}>
        <Text weight={600} size="sm">
          Period
        </Text>
      </td>
      <td className={classes.dateCol}>
        <Text weight={600} size="sm">
          Started
        </Text>
      </td>
      <td className={classes.dateCol}>
        <Text weight={600} size="sm">
          Finished
        </Text>
      </td>
      <td className={classes.dateCol}>
        <Text weight={600} size="sm">
          Sent
        </Text>
      </td>
      <td className={classes.minCol}>
        <Text weight={600} size="sm">
          Action
        </Text>
      </td>
    </th>
  )
}

export default Header
