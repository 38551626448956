import { type Sensor } from '@venturi-io/api/src/config/agent'
import { Box, Stack } from '@mantine/core'
import { findSensor } from 'src/AssetTemplate/shared'
import SensorHistoryLineChart from 'src/AssetTemplate/Components/Charts/SensorHistoryLineChart'
import SensorHistoryBarChart from 'src/AssetTemplate/Components/Charts/SensorHistoryBarChart'

interface Props {
  sensors: Sensor[]
}

export default function Engine ({ sensors }: Props) {
  const engineSpeed = findSensor('ENGINE_SPEED', sensors)
  const engineBatteryVoltage = findSensor('ENGINE_BATTERY_VOLTAGE', sensors)
  const coolantTemperature = findSensor('COOLANT_TEMPERATURE', sensors)
  const oilPressure = findSensor('OIL_PRESSURE', sensors)

  return (
    <Box>
      <Stack spacing={28}>
        <SensorHistoryLineChart
          title="Engine Speed"
          sensorInstanceId={engineSpeed?.sensorInstanceId}
          unit={engineSpeed?.unit}
          height={300}
          margin={{
            top: 30,
            right: 20,
            bottom: 50,
            left: 40
          }}
          roundedValues
        />
        <SensorHistoryLineChart
          title="Engine Battery Voltage"
          sensorInstanceId={engineBatteryVoltage?.sensorInstanceId}
          unit={engineBatteryVoltage?.unit}
          height={300}
          margin={{
            top: 30,
            right: 20,
            bottom: 50,
            left: 40
          }}
        />
        <SensorHistoryBarChart
          title="Coolant Temperature"
          sensorInstanceId={coolantTemperature?.sensorInstanceId}
          unit={coolantTemperature?.unit}
          height={300}
          margin={{
            top: 30,
            right: 20,
            bottom: 50,
            left: 40
          }}
          roundedValues
        />
        <SensorHistoryBarChart
          title="Oil Pressure"
          sensorInstanceId={oilPressure?.sensorInstanceId}
          unit={oilPressure?.unit}
          height={300}
          margin={{
            top: 30,
            right: 20,
            bottom: 50,
            left: 40
          }}
          roundedValues
        />
      </Stack>
    </Box>
  )
}
