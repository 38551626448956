import { Text } from '@mantine/core'

interface Props {
  value: string
}

export default function ItemDescription ({ value }: Props) {
  return (
    <Text color="dimmed" size="xs">
      {value}
    </Text>
  )
}
