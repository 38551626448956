import { useNavigate } from 'react-router-dom'
import { Badge } from '@mantine/core'
import TextTooltip from 'src/Layout/TextTooltip'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import ActionButton from 'src/ActionButton'
import dayjs from 'dayjs'
import { dateFormat, uiDateFormatUniversal, uiDateOnlyFormatUniversal } from 'src/utils/dates'
import { type ReportSchedule } from '@venturi-io/api/src/config/report'

export default function Row ({
  reportScheduleId,
  reportName,
  reportScheduleType,
  scheduleDay,
  scheduleStartDate,
  lastScheduleRun,
  scheduleEnabled
}: ReportSchedule & ListRow) {
  const navigate = useNavigate()
  const { classes, cx } = useStyles()

  return (
    <tr className={`${classes.row} ${classes.onHover}`}>
      <td className={classes.col}>
        <TextTooltip
          value={parseEmpty(reportName).toString()}
          maxLength={30}
          size="sm"
        />
      </td>
      <td className={classes.col}>
        <TextTooltip
          value={parseEmpty(reportScheduleType).toString()}
          maxLength={30}
          size="sm"
        />
      </td>
      <td className={classes.col}>
        <TextTooltip
          value={parseEmpty(scheduleDay).toString()}
          maxLength={30}
          size="sm"
        />
      </td>
      <td className={classes.col}>
        <TextTooltip
          value={dayjs(scheduleStartDate, dateFormat).format(uiDateOnlyFormatUniversal)}
          maxLength={30}
          size="sm"
        />
      </td>
      <td className={classes.col}>
        <TextTooltip
          value={lastScheduleRun
            ? dayjs(lastScheduleRun, dateFormat).format(uiDateFormatUniversal)
            : '-'}
          maxLength={30}
          size="sm"
        />
      </td>
      <td className={classes.col}>
        <Badge
          color={scheduleEnabled
            ? 'green'
            : 'red'}
          size="sm"
        >
          {scheduleEnabled ? 'ON' : 'OFF'}
        </Badge>
      </td>
      <td className={cx(classes.col, classes.actionsCol)}>
        <ActionButton
          label="Edit"
          icon="pencil"
          iconType="fas"
          onClick={() => navigate(`/reports/scheduled/edit/${reportScheduleId}`)}
          actionColor="green"
          actionVariant="filled"
          iconColor="white"
          iconSize="sm"
        />
      </td>
    </tr>
  )
}
