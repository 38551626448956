import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { paginated, type AlarmIdRequest, type PaginatedRequest } from '../shared'

export const alarmType = [
  'OK',
  'WARNING',
  'ALARM'
] as const

export type AlarmType = typeof alarmType[number]

export interface AlarmRequest extends Record<string, unknown> {
  sensorInstanceId: number
  name: string
  operator: string
  setPoint: number
  description?: string
  alarmType: Omit<AlarmType, 'OK'>
  enabled: boolean
  manualClear: boolean
  quietMode: boolean
}

const alarmResponse = z.object({
  sensorInstanceId: z.number(),
  name: z.string(),
  operator: z.string(),
  setPoint: z.number(),
  description: z.optional(z.string()),
  enabled: z.boolean(),
  alarmStatus: z.string(),
  alarmType: z.enum(alarmType),
  manualClear: z.boolean(),
  quietMode: z.boolean()
})

const alarmResponseWithId = alarmResponse.extend({
  alarmId: z.number()
})

export const createAlarm = createStatefulApi<AlarmRequest, typeof alarmResponse>(
  'analytics',
  '/alarm',
  { method: 'POST' },
  alarmResponse
)

export const updateAlarm = createStatefulApi<AlarmRequest & AlarmIdRequest, typeof alarmResponseWithId>(
  'analytics',
  '/alarm/:alarmId',
  { method: 'PUT' },
  alarmResponseWithId
)

export const deleteAlarm = createStatefulApi<AlarmIdRequest, typeof alarmResponseWithId>(
  'analytics',
  '/alarm/:alarmId',
  { method: 'DELETE', type: 'path' },
  alarmResponseWithId
)

// Sensor Instance Alarm
const alarm = z.object({
  alarmId: z.number(),
  name: z.string(),
  operator: z.string(),
  setPoint: z.number(),
  enabled: z.boolean(),
  description: z.nullable(z.optional(z.string())),
  alarmType: z.enum(alarmType),
  manualClear: z.boolean(),
  quietMode: z.boolean(),
  taskCreationEnabled: z.boolean()
})
export type Alarm = z.infer<typeof alarm>

interface GetAlarmsRequest extends PaginatedRequest {
  sensorInstanceId: number
}

const sensorInstanceAlarms = paginated.extend({
  attributes: z.object({
    sensorInstanceId: z.number(),
    alarmStatus: z.string()
  }),
  items: z.array(alarm)
})

export const getAlarmsForSensorInstance = createStatelessApi<GetAlarmsRequest, typeof sensorInstanceAlarms>(
  'analytics',
  '/sensorInstance/:sensorInstanceId/alarms',
  { method: 'GET', type: 'path' },
  sensorInstanceAlarms
)
