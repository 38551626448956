import {
  ActionIcon,
  createStyles,
  Group,
  Menu,
  Text,
  Tooltip
} from '@mantine/core'
import { Link } from 'react-router-dom'
import { useUser } from 'src/UserContext'
import { useZendesk } from 'src/ZendeskContext'
import { mq } from 'src/utils/style'
import zIndex from 'src/utils/zIndex'
import { IconHelp, IconLogout2, IconUserCircle } from '@tabler/icons-react'
import { getTextColor, getTextColorAsProp } from 'src/utils/theme'
import Alarms from '../Alarms'
import ThemeSelector from '../ThemeSelector'

const useStyles = createStyles(theme => ({
  menu: {
    zIndex: zIndex.menu,
    float: 'right',
    display: 'none !important', // TODO: Fix this !important
    [mq(theme.breakpoints.sm)]: {
      display: 'initial !important', // TODO: Fix this !important
      marginRight: '25px'
    }
  },
  menuIconWrapper: {
    width: '125px'
  },
  chatbotIcon: {
    padding: '8px',
    borderRadius: '50%'
  },
  menuIconText: {
    fontSize: 16,
    marginLeft: '10px',
    ...getTextColor(theme)
  },
  menuItemIcon: {
    marginRight: '10px'
  }
}))

interface Props {
  signout: () => void
}

export default function ProfileMenu ({ signout }: Props) {
  const { classes } = useStyles()
  const { firstName, lastName } = useUser()
  const { open, show } = useZendesk()

  return (
    <Group mr="md" position="right">
      <Alarms showDrawer />
      <Tooltip label="Help">
        <ActionIcon
          variant="transparent"
          onClick={() => {
            show()
            open()
          }}
          sx={{
            marginRight: '-8px'
          }}
        >
          <IconHelp size={24} color={getTextColorAsProp().color} />
        </ActionIcon>
      </Tooltip>
      <Menu
        withArrow
        zIndex={zIndex.menu}
        position="bottom-end"
        transitionProps={{
          transition: 'pop-bottom-left'
        }}
        width={250}
        shadow="lg"
      >
        <Menu.Target>
          <ActionIcon className={classes.menuIconWrapper} variant="transparent">
            <IconUserCircle size={24} color={getTextColorAsProp().color} />
            <Text className={classes.menuIconText} lineClamp={1}>
              {`${firstName} ${lastName}`}
            </Text>
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item component={Link} to="/user-profile">
            <Group align="center">
              <IconUserCircle size={24} color={getTextColorAsProp().color} />
              User Profile
            </Group>
          </Menu.Item>
          <Menu.Item component={Link} to="/" onClick={signout}>
            <Group align="center">
              <IconLogout2 size={24} color={getTextColorAsProp().color} />
              Sign Out
            </Group>
          </Menu.Item>
          <ThemeSelector />
        </Menu.Dropdown>
      </Menu>
    </Group>
  )
}
