// TODO: Set up color configuration once ready
const colors = {
  blue: '#4E79A6',
  green: '#12B886',
  yellow: '#FFE066',
  red: '#F03E3E',
  darkGray: '#868E96',
  gray: '#CED4DA',
  lightGray: '#E9ECEF'
}

export default colors
