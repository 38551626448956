import { makeAutoObservable } from 'mobx'
import dayjs from 'dayjs'
import { type DateRange } from './utils/dates'

export class AlarmHistoryState {
  sensorInstanceId: null | string = null

  dateRange: DateRange = [
    dayjs(new Date()).subtract(7, 'days').toDate(),
    dayjs(new Date()).toDate()
  ]

  constructor () {
    makeAutoObservable(this)
  }
}

// Singleton shared state for Alarm History
export const alarmHistoryState = new AlarmHistoryState()

// Agent Logs State
export class AgentLogsState {
  dateRange: DateRange = [
    dayjs(new Date()).subtract(7, 'days').toDate(),
    dayjs(new Date()).toDate()
  ]

  constructor () {
    makeAutoObservable(this)
  }
}

// Singleton shared state for Agent Logs
export const agentLogsState = new AgentLogsState()
