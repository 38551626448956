import { Container, ScrollArea, Stack, useMantineTheme } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import {
  type CellStyle,
  ReactGrid,
  type Column,
  type Row,
  type BorderProps
} from '@silevis/reactgrid'
import { type ReportData } from '@venturi-io/api/src/config/report'
import dayjs from 'dayjs'
import Paper from 'src/Layout/Paper'
import { dateFormat, uiDateFormatUniversal } from 'src/utils/dates'
import { toProperCase } from 'src/utils/strings'
import { getBackgroundColor, getTextColor } from 'src/utils/theme'

interface ReportViewProps {
  data: ReportData
}

export default function ReportView ({ data }: ReportViewProps) {
  const theme = useMantineTheme()
  const { ref, width } = useElementSize()

  function getRowsAndColumns (items: ReportData[0]) {
    const { colors, colorScheme } = theme
    const isLight = colorScheme === 'light'
    const raw = items[0]
    const rawKeys = Object.keys(raw)
    const columns: Column[] = rawKeys.map(columnId => ({
      columnId,
      width: (width - 8) / rawKeys.length
    }))
    const cellStyle: CellStyle = {
      ...getTextColor(theme)
    }
    const borderStyle: BorderProps = {
      width: '1px',
      color: isLight
        ? colors.gray[4]
        : colors.dark[4]
    }
    const border = {
      top: borderStyle,
      right: borderStyle,
      bottom: borderStyle,
      left: borderStyle
    }
    const headerRow: Row = {
      rowId: 'header',
      cells: rawKeys.map(columnId => ({
        type: 'header',
        text: toProperCase(columnId),
        style: {
          border,
          ...cellStyle,
          ...getBackgroundColor(theme, -2)
        }
      }))
    }
    const rows: Row[] = [
      headerRow,
      ...items.map<Row>((item, idx) => ({
        rowId: idx,
        cells: Object
          .keys(item)
          .map(key => ({
            type: 'text',
            text: key === 'date_collect'
              ? dayjs(item[key], dateFormat).format(uiDateFormatUniversal)
              : item[key] ?? '',
            style: {
              border,
              ...cellStyle
            }
          }))
      }))
    ]

    return {
      columns,
      rows
    }
  }

  return (
    <Paper p="lg">
      <Container ref={ref} fluid px={0}>
        <ScrollArea
          type="always"
          scrollbarSize={4}
          h="calc(100vh - 246px)"
          offsetScrollbars
        >
          <Stack spacing="md">
            {Object
              .keys(data)
              .map(queryId => (
                <ReactGrid
                  key={queryId}
                  stickyTopRows={1}
                  {...getRowsAndColumns(data[queryId])}
                />
              ))}
          </Stack>
        </ScrollArea>
      </Container>
    </Paper>
  )
}
