import TextTooltip from 'src/Layout/TextTooltip'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import dayjs from 'dayjs'
import { dateFormat, uiDateFormatUniversal } from 'src/utils/dates'
import { type GeneratedReport } from '@venturi-io/api/src/config/report'
import ActionButton from 'src/ActionButton'
import { useNavigate } from 'react-router'

export default function Row ({
  generatedReportId,
  reportName,
  schedule,
  fromTime,
  toTime,
  finishedAt,
  sentAt
}: GeneratedReport & ListRow) {
  const navigate = useNavigate()
  const { classes } = useStyles()

  return (
    <tr className={`${classes.row} ${classes.onHover}`}>
      <td className={classes.col}>
        <TextTooltip
          value={parseEmpty(reportName).toString()}
          maxLength={30}
          size="sm"
        />
      </td>
      <td className={classes.minCol}>
        <TextTooltip
          value={parseEmpty(schedule).toString()}
          maxLength={30}
          size="sm"
        />
      </td>
      <td className={classes.periodCol}>
        <TextTooltip
          value={`${dayjs(fromTime, `${dateFormat}Z`).format(uiDateFormatUniversal)}
          -
          ${dayjs(toTime, `${dateFormat}Z`).format(uiDateFormatUniversal)}`}
          maxLength={70}
          size="sm"
        />
      </td>
      <td className={classes.dateCol}>
        <TextTooltip
          value={dayjs(toTime, dateFormat).format(uiDateFormatUniversal)}
          maxLength={30}
          size="sm"
        />
      </td>
      <td className={classes.dateCol}>
        <TextTooltip
          value={dayjs(finishedAt, dateFormat).format(uiDateFormatUniversal)}
          maxLength={30}
          size="sm"
        />
      </td>
      <td className={classes.dateCol}>
        <TextTooltip
          value={dayjs(sentAt, dateFormat).format(uiDateFormatUniversal)}
          maxLength={30}
          size="sm"
        />
      </td>
      <td className={classes.minCol}>
        <ActionButton
          label="View"
          icon="eye"
          iconType="fas"
          onClick={() => navigate(`/reports/generated/${generatedReportId}`)}
          actionColor="primary"
          actionVariant="filled"
          iconColor="white"
          iconSize="sm"
        />
      </td>
    </tr>
  )
}
