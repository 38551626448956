import { z } from 'zod'
import { createStatefulApi, createStatelessApi } from '../configure'
import { type PaginatedRequest, type GeoZoneRuleActionIdRequest, paginated } from '../shared'

const geoZoneRuleAction = z.object({
  geoZoneRuleActionId: z.number(),
  geoZoneId: z.number(),
  geoZoneRuleId: z.number(),
  geoZoneActionId: z.optional(z.number()),
  description: z.string()
})

export type GeoZoneRuleAction = z.infer<typeof geoZoneRuleAction>

interface GeoZoneRuleActionRequest extends Record<string, unknown> {
  geoZoneId: number
  geoZoneRuleId: number
  geoZoneActionId?: number
  description: string
}
export const createGeoZoneRuleAction = createStatefulApi<GeoZoneRuleActionRequest, typeof geoZoneRuleAction>(
  'config',
  '/geo/zone-rule-action',
  { method: 'POST' },
  geoZoneRuleAction
)

const allGeoZoneRuleActions = paginated.extend({
  items: z.array(geoZoneRuleAction)
})

export const getGeoZoneRuleActions = createStatelessApi<PaginatedRequest, typeof allGeoZoneRuleActions>(
  'config',
  '/geo/zone-rule-actions',
  { method: 'GET', type: 'path' },
  allGeoZoneRuleActions
)

export const getGeoZoneRuleAction = createStatelessApi<GeoZoneRuleActionIdRequest, typeof geoZoneRuleAction>(
  'config',
  '/geo/zone-rule-action/:geoZoneRuleActionId',
  { method: 'GET', type: 'path' },
  geoZoneRuleAction
)

export const updateGeoZoneRuleAction = createStatefulApi<GeoZoneRuleActionRequest & GeoZoneRuleActionIdRequest, typeof geoZoneRuleAction>(
  'config',
  '/geo/zone-rule-action/:geoZoneRuleActionId',
  { method: 'PUT' },
  geoZoneRuleAction
)

export const deleteGeoZoneRuleAction = createStatefulApi<GeoZoneRuleActionIdRequest, z.ZodBoolean>(
  'config',
  '/geo/zone-rule-action/:geoZoneRuleActionId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)
