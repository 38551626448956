import { useCallback, useEffect } from 'react'
import {
  type SelectItem,
  Button,
  Col,
  Drawer,
  Grid,
  Group,
  Title
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconDeviceFloppy } from '@tabler/icons-react'
import {
  type GeoZoneAction,
  createGeoZoneAction,
  getGeoZoneActionTypes,
  getGeoZoneAction,
  updateGeoZoneAction
} from '@venturi-io/api/src/config/geoZoneAction'
import TextInput from 'src/Input/TextInput'
import Select from 'src/Input/Select'
import SelectUserGroup from 'src/Input/Select/SelectUserGroup'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { type FormMode } from 'src/utils'
import { capitalise } from 'src/utils/strings'

interface Props {
  mode: FormMode
  actionId?: GeoZoneAction['geoZoneActionId']
  opened: boolean
  setOpened: (status: boolean) => void
  onSuccessCreate?: (action: GeoZoneAction) => void
  onSuccessEdit?: (action: GeoZoneAction) => void
  onClose?: () => void
}

export default function Form ({
  mode,
  actionId,
  opened,
  setOpened,
  onSuccessCreate,
  onSuccessEdit,
  onClose
}: Props) {
  const { token } = useUser()
  const geoZoneActionTypes = useApi(getGeoZoneActionTypes)
  const action = useApi(getGeoZoneAction)
  const createAction = useApi(createGeoZoneAction)
  const editAction = useApi(updateGeoZoneAction)
  const inCreateMode = mode === 'CREATE'

  const actionTypes: SelectItem[] = geoZoneActionTypes.data.mapOrDefault((types) => (
    Object.entries(types).map(([key, val]) => ({
      label: val as string,
      value: key
    }))
  ), [])

  const form = useForm<Omit<GeoZoneAction, 'geoZoneActionId'>>({
    initialValues: {
      name: '',
      action: '',
      description: '',
      userGroup: {
        userGroupId: -1
      }
    },
    validate: {
      name: value => value.length < 1
        ? 'Please specify Name'
        : null,
      action: value => value.length < 1
        ? 'Please specify Action'
        : null,
      userGroup: {
        userGroupId: value => value === -1
          ? 'Please specify User Group'
          : null
      }
    }
  })

  type FormValues = typeof form.values

  const handleCreate = useCallback((values: FormValues) => {
    void createAction.fetch(values, token, 'Successfully created new action')
  }, [token, form.values])

  const handleUpdate = useCallback((values: FormValues) => {
    if (typeof actionId !== 'undefined') {
      void editAction.fetch({
        ...values,
        geoZoneActionId: actionId
      }, token, 'Successfully updated action')
    }
  }, [token, actionId, form.values])

  const handleSubmit = useCallback((values: FormValues) => {
    switch (mode) {
      case 'CREATE':
        handleCreate(values)
        break

      case 'EDIT':
        handleUpdate(values)
        break
    }
  }, [mode])

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }

    setOpened(false)
  }, [onClose])

  useEffect(() => {
    createAction.data.ifJust(data => {
      if (onSuccessCreate) {
        onSuccessCreate(data)
      }

      form.reset()
      handleClose()
    })
  }, [createAction.data])

  useEffect(() => {
    editAction.data.ifJust(data => {
      if (onSuccessEdit) {
        onSuccessEdit(data)
      }

      handleClose()
    })
  }, [editAction.data])

  useEffect(() => {
    if (mode === 'CREATE') {
      form.reset()
    }

    if (mode === 'EDIT' && typeof actionId !== 'undefined') {
      void action.fetch({ geoZoneActionId: actionId }, token)
    }
  }, [mode, actionId])

  useEffect(() => {
    action.data.ifJust(data => {
      form.setValues(data)
    })
  }, [action.data])

  useEffect(() => {
    void geoZoneActionTypes.fetch({}, token)
  }, [])

  return (
    <Drawer
      title={(
        <Title order={3}>
          {`${capitalise(mode)} Action`}
        </Title>
      )}
      size="md"
      padding="xl"
      position="right"
      opened={opened}
      onClose={handleClose}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          <Col span={12}>
            <TextInput
              label="Name"
              {...form.getInputProps('name')}
              withAsterisk
            />
          </Col>
          <Col span={12}>
            <Select
              label="Action"
              data={actionTypes}
              value={form.values.action}
              onChange={action => (
                form.setFieldValue('action', action ?? '')
              )}
              withAsterisk
              error={form.errors.action}
            />
          </Col>
          <Col span={12}>
            <TextInput
              label="Description"
              {...form.getInputProps('description')}
            />
          </Col>
          <Col span={12}>
            <SelectUserGroup
              required
              label="User Group"
              placeholder="Select user group"
              {...form.getInputProps('userGroup.userGroupId')}
              value={form.values.userGroup.userGroupId.toString()}
              onSelect={e => {
                form.setFieldValue('userGroup.name', e.currentTarget.value)
              }}
            />
          </Col>
        </Grid>
        <Group mt={36} position="right" >
          <Button
            variant="default"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            leftIcon={<IconDeviceFloppy size={20} />}
            disabled={inCreateMode
              ? createAction.loading
              : action.loading || editAction.loading}
          >
            Save
          </Button>
        </Group>
      </form>
    </Drawer>
  )
}
