import { type ReactNode } from 'react'
import { createStyles, Paper, Tooltip } from '@mantine/core'
import { getWhiteBackgroundColor } from 'src/utils/theme'

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 40,
    padding: 8,
    borderRadius: '8px 0 0 8px',
    cursor: 'pointer',
    ...getWhiteBackgroundColor(theme)
  }
}))

interface Props {
  title: string
  icon: ReactNode
  opened: boolean
  setOpened: (status: boolean) => void
}

export default function Toggle ({ title, icon, setOpened }: Props) {
  const { classes } = useStyles()

  return (
    <Tooltip
      label={title}
      position="left"
      withArrow
    >
      <Paper
        className={classes.container}
        shadow="lg"
        onClick={() => setOpened(true)}
      >
        {icon}
      </Paper>
    </Tooltip>
  )
}
