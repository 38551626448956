import { useCallback, useState } from 'react'
import {
  createStyles,
  Button,
  Divider,
  Group,
  Stack,
  Text
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createGeoZoneRuleAction } from '@venturi-io/api/src/config/geoZoneRuleAction'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { useNotifications } from 'src/utils/notifications'
import SelectRule from 'src/Input/Select/SelectRule'
import MultiSelectAction from 'src/Input/MultiSelect/MultiSelectAction'

const useStyles = createStyles((theme) => ({
  id: {
    display: 'inline',
    backgroundColor: theme.colors.teal[6],
    padding: '4px 10px',
    borderRadius: '100%',
    textAlign: 'center'
  },
  selectRule: {
    flex: 1
  }
}))

interface Props {
  geoZoneId: number
  existingRules: number[]
  onCreate?: () => void
}

interface FormProps {
  geoZoneRuleId: number
  geoZoneActionIds: number[]
}

const FormAddRule = ({ geoZoneId, existingRules, onCreate }: Props) => {
  const { token } = useUser()
  const { classes } = useStyles()
  const { showSuccess, showError } = useNotifications()
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const createGeoZoneRule = useApi(createGeoZoneRuleAction)

  const form = useForm<FormProps>({
    initialValues: {
      geoZoneRuleId: -1,
      geoZoneActionIds: []
    },
    validate: {
      geoZoneRuleId: value => (
        value === -1
          ? 'Please select rule'
          : null
      )
    }
  })

  const handleSubmit = useCallback(({ geoZoneRuleId, geoZoneActionIds }: typeof form.values) => {
    setIsSaving(true)

    // create rule with action(s)
    if (geoZoneActionIds.length > 0) {
      const queries = geoZoneActionIds.map(async geoZoneActionId => {
        const query = new Promise<string>((resolve, reject) => {
          void createGeoZoneRuleAction({
            geoZoneId,
            geoZoneRuleId,
            geoZoneActionId,
            description: ''
          }, token)
            .caseOf({
              Left: err => {
                reject(err)
              },
              Right: () => resolve('Successfully created geozone rule action')
            })
        })
        return await query
      })

      void Promise.all(queries)
        .then(() => {
          showSuccess('Successfully created rule and actions')
          if (onCreate) void onCreate()
          form.reset()
        })
        .catch(() => {
          showError(new Error('Failed to create rule and actions'))
        })
        .finally(() => {
          setIsSaving(false)
        })
    } else {
      // create rule without action
      void createGeoZoneRule
        .fetch({
          geoZoneId,
          geoZoneRuleId,
          description: ''
        }, token)
        .finally(() => {
          showSuccess('Successfully created rule and actions')
          setIsSaving(false)
          form.reset()
          if (onCreate) void onCreate()
        })
    }
  }, [form.values])

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack spacing={15}>
        <Group position="left" spacing={8}>
          <Text className={classes.id} size="xs" weight="bold" color="white">{existingRules.length + 1}</Text>
          <SelectRule
            className={classes.selectRule}
            existingRules={existingRules}
            setExternalRule={(ruleId) => {
              if (ruleId) {
                form.setFieldValue('geoZoneRuleId', Number(ruleId))
              }
            }}
            searchable
            error={form.errors.geoZoneRuleId}
          />
        </Group>
        <Stack mx={6} spacing={5}>
          <Divider label="Actions" />
          <MultiSelectAction
            value={form.values.geoZoneActionIds.map(id => id.toString())}
            onChange={actionIds => {
              form.setFieldValue('geoZoneActionIds', actionIds.map(id => Number(id)))
            }}
            searchable
            disabled={form.values.geoZoneRuleId === -1}
            error={form.errors.geoZoneActionIds}
          />
        </Stack>
        <Button
          type="submit"
          leftIcon={<FontAwesomeIcon icon={['far', 'floppy-disk']} color="white" />}
          disabled={isSaving}
        >
          Save
        </Button>
      </Stack>
    </form>
  )
}

export default FormAddRule
