import {
  createStyles,
  Center,
  Group,
  Paper,
  Skeleton,
  Stack
} from '@mantine/core'

const useStyles = createStyles(() => ({
  container: {
    flex: '1 1 150px',
    background: 'white',
    height: 200,
    minWidth: 0,
    padding: '8px 0px',
    overflow: 'clip'
  }
}))

export default function DiagnosticMetric () {
  const { classes } = useStyles()

  return (
    <Paper className={classes.container}
      radius={8}
      shadow="xs"
    >
      <Group p={12} position="apart">
        <Skeleton
          height={20}
          width={85}
          radius="md"
        />
        <Skeleton
          height={20}
          width={20}
          circle
        />
      </Group>
      <Stack
        mt={12}
        mb={24}
        spacing={8}
        align="center"
        justify="center"
      >
        <Skeleton
          height={60}
          width={60}
          radius="sm"
        />
        <Skeleton
          height={20}
          width={80}
          radius="md"
        />
      </Stack>
      <Center>
        <Skeleton
          height={12}
          width={120}
          radius="md"
        />
      </Center>
    </Paper>
  )
}
