import { Image, Paper, createStyles, useMantineColorScheme } from '@mantine/core'
import { findSensorByType, type Sensors as AgentSensors } from '../../../shared'
import Sensor from './Sensor'

const useStyles = createStyles(() => ({
  container: {
    position: 'relative',
    width: '80%',
    margin: '25px auto 0 auto'
  }
}))

interface Props {
  instances: AgentSensors['sensors']
}

export default function Sensors ({ instances }: Props) {
  const { colorScheme } = useMantineColorScheme()
  const isLight = colorScheme === 'light'
  const s1Available = findSensorByType('S1_AVAILABLE', instances)
  const s1Switch = findSensorByType('S1_SWITCH', instances)
  const s2Available = findSensorByType('S2_AVAILABLE', instances)
  const s2Switch = findSensorByType('S2_SWITCH', instances)

  const { classes } = useStyles()

  return (
    <Paper px={15} className={classes.container}>
      <Image
        src="/line-connector.svg"
        alt="Sensors Line Connector"
        style={{
          height: '100px'
        }}
      />
      <Sensor
        state={s1Available
          ? s1Available.currentValue === 'true'
          : undefined}
        src={`/icon-mains${isLight
          ? ''
          : '-dark'}.svg`}
        label="MAINS"
        style={{
          top: '80px',
          left: '-11px',
          width: '54px'
        }}
      />
      <Sensor
        state={s1Switch
          ? s1Switch.currentValue === 'true'
          : undefined}
        src={`/connection-indicator${isLight
          ? ''
          : '-dark'}.svg`}
        style={{
          top: '-3px',
          left: '20%',
          width: '60px'
        }}
      />
      <Sensor
        src={`/icon-load${isLight
          ? ''
          : '-dark'}.svg`}
        label="LOAD"
        style={{
          top: '150px',
          left: '50%',
          transform: 'translate(-50%, 0)',
          width: '78px'
        }}
      />
      <Sensor
        state={s2Switch
          ? s2Switch.currentValue === 'true'
          : undefined}
        src={`/connection-indicator${isLight
          ? ''
          : '-dark'}.svg`}
        style={{
          top: '-3px',
          right: '20%',
          width: '60px'
        }}
      />
      <Sensor
        state={s2Available
          ? s2Available.currentValue === 'true'
          : undefined}
        src={`/icon-genset${isLight
          ? ''
          : '-dark'}.svg`}
        label="GENSET"
        style={{
          top: '80px',
          right: '-20px',
          width: '74px'
        }}
      />
    </Paper>
  )
}
