import { Text } from '@mantine/core'

interface Props {
  message?: string
}

export default function TableRowNoData ({ message = 'No data' }: Props) {
  return (
    <tr>
      <td colSpan={20}>
        <Text align="center" color="dimmed">{message}</Text>
      </td>
    </tr>
  )
}
