import { createStyles } from '@mantine/core'
import { getWhiteBackgroundColor } from 'src/utils/theme'

const useStyles = createStyles((theme) => ({
  head: {
    position: 'sticky',
    top: 0,
    ...getWhiteBackgroundColor(theme)
  },
  th: {
    fontWeight: 400,
    fontSize: 10,
    color: '#5C5F66',
    paddingLeft: 0,
    border: 'none'
  }
}))

interface Props {
  isMobile?: boolean
}

export default function Head ({ isMobile }: Props) {
  const { classes } = useStyles()

  return (
    <thead className={classes.head}>
      <tr>
        {!isMobile && <th className={classes.th}>Date/Time</th>}
        <th className={classes.th}>Agent Name</th>
        <th className={classes.th}>Asset Type</th>
        <th className={classes.th}>Vol. (L)</th>
        <th className={classes.th}>Flow</th>
        <th
          className={classes.th}
          style={{
            textAlign: 'right'
          }}
        >
          LPM (Litres/Minute)
        </th>
      </tr>
    </thead>
  )
}
