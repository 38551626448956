export const MODES = {
  READ_ONLY: 'Reading',
  VIEWING: 'Viewing',
  EDITING: 'Editing',
  MODIFYING: 'Modifying',
  DRAW_POLYGON: 'Drawing Polygon',
  DRAW_RECTANGLE: 'Drawing Rectangle',
  DRAW_SQUARE: 'Drawing Square',
  DRAW_CIRCLE: 'Drawing Circle'
}

export const modes = [
  {
    id: MODES.READ_ONLY,
    desc: null
  },
  {
    id: MODES.VIEWING,
    desc: 'simple_select'
  },
  {
    id: MODES.EDITING,
    desc: 'direct_select'
  },
  {
    id: MODES.DRAW_POLYGON,
    desc: 'draw_polygon'
  },
  {
    id: MODES.DRAW_RECTANGLE,
    desc: 'draw_rectangle'
  },
  {
    id: MODES.DRAW_SQUARE,
    desc: 'draw_square'
  },
  {
    id: MODES.DRAW_CIRCLE,
    desc: 'draw_circle'
  }
]
