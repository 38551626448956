import { useNavigate } from 'react-router-dom'
import { Group, Paper, Stack } from '@mantine/core'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import ActionButton from 'src/ActionButton'
import { type GeneratedReport } from '@venturi-io/api/src/config/report'
import { dateFormat, uiDateFormatUniversal } from 'src/utils/dates'
import dayjs from 'dayjs'

export default function Card ({
  generatedReportId,
  reportName,
  schedule,
  fromTime,
  toTime,
  finishedAt,
  sentAt
}: GeneratedReport & ListRow) {
  const navigate = useNavigate()

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <Group position="apart" align="start">
          <TextGroup label="ID" value={generatedReportId.toString()} isKey />
          <ActionButton
            label="Edit"
            icon="pencil"
            iconType="fas"
            onClick={() => navigate(`/reports/generated/${generatedReportId}`)}
            actionColor="green"
            actionVariant="filled"
            iconColor="white"
            iconSize="sm"
          />
        </Group>
        <TextGroup label="Name" value={reportName} />
        <TextGroup label="Schedule" value={schedule} />
        <TextGroup label="Period"
          value={`${dayjs(fromTime, dateFormat).format(uiDateFormatUniversal)}
          -
          ${dayjs(toTime, dateFormat).format(uiDateFormatUniversal)}`}
        />
        <TextGroup label="Started" value={dayjs(finishedAt, dateFormat).format(uiDateFormatUniversal)} />
        <TextGroup label="Finished" value={dayjs(finishedAt, dateFormat).format(uiDateFormatUniversal)} />
        <TextGroup label="Sent" value={dayjs(sentAt, dateFormat).format(uiDateFormatUniversal)} />
      </Stack>
    </Paper>
  )
}
