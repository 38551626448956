import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import TextTooltip from 'src/Layout/TextTooltip'
import { type VehicleTransaction } from '@venturi-io/api/src/config/fms'
import { uiDateFormat } from 'src/utils/dates'
import dayjs from 'dayjs'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'

export default function Row ({ ...transactions }: VehicleTransaction & ListRow) {
  const { classes } = useStyles()
  const {
    started,
    rfidTag,
    person,
    registrationNumber,
    volume,
    odometerKilometres,
    kmTravelled,
    transactionId,
    agent
  } = transactions
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint, true)

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={dayjs(started).format(uiDateFormat).toString()}
          maxLength={25}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(rfidTag)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(agent?.agentId)}
          maxLength={25}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(agent?.agentName)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(agent?.assetType)}
          maxLength={25}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(person)}
          maxLength={textMaxLength}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(registrationNumber)}
          maxLength={25}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(volume)}
          maxLength={25}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(odometerKilometres)}
          maxLength={25}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(kmTravelled)}
          maxLength={25}
        />
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(transactionId)}
          maxLength={25}
        />
      </div>
    </div>
  )
}
