import { type AssetType } from '@venturi-io/api/src/config/agent'
import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'
import Link from 'src/Layout/Link'
import NoData from 'src/Layout/TableRowNoData'
import { getAgentDashboardPath } from 'src/AssetTemplate/shared'
import { Badge, createStyles } from '@mantine/core'
import { assetTypeIcon, flowTypeIcon } from '../..'
import { type Props } from '../../../Transactions'

const useStyles = createStyles(() => ({
  text: {
    border: 'none',
    paddingLeft: 0
  }
}))
interface RowProps extends Props {
  isMobile?: boolean
}

export default function Rows ({ transactions, isMobile = false }: RowProps) {
  const { classes } = useStyles()

  return transactions.length > 0
    ? (
      <>
        {transactions.map(({
          started,
          volume,
          lpm,
          type,
          transactionId,
          agent
        }) => {
          const assetType = agent?.assetType ?? 'OTHERS'
          const assetDashboardPath = typeof agent !== 'undefined'
            ? getAgentDashboardPath(agent.agentId, agent.assetType)
            : null

          return (
            <tr
              key={transactionId}
              style={{
                border: 'none'
              }}
            >
              {!isMobile && (
                <td className={classes.text}>
                  {dayjs(started).format(uiDateFormat)}
                </td>
              )}
              <td className={classes.text}>
                {assetDashboardPath !== null
                  ? (
                    <Link to={assetDashboardPath}>
                      {agent?.agentName}
                    </Link>
                    )
                  : '-'}
              </td>
              <td>
                <Badge
                  color="gray"
                  size="md"
                  sx={{
                    minWidth: 40
                  }}
                  leftSection={assetTypeIcon[assetType as AssetType]}
                >
                  {agent?.assetType ?? '-'}
                </Badge>
              </td>
              <td
                className={classes.text}
                style={{
                  fontFeatureSettings: 'tnum'
                }}
              >
                {volume.toFixed(2)}
              </td>
              <td>
                <Badge
                  color="gray"
                  size="md"
                  rightSection={flowTypeIcon[type]}
                >
                  {type}
                </Badge>
              </td>
              <td
                style={{
                  textAlign: 'right'
                }}
              >
                {lpm.toFixed(2)}
              </td>
            </tr>
          )
        })}
      </>
      )
    : <NoData message="No transaction available" />
}
