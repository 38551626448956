import { Box, Group, Paper, Skeleton } from '@mantine/core'

export default function Location () {
  return (
    <Paper
      radius={8}
      shadow="xs"
    >
      <Group p={16} position="apart">
        <Skeleton
          height={20}
          width={120}
          radius="md"
        />
        <Skeleton
          height={20}
          width={20}
          radius="sm"
        />
      </Group>
      <Skeleton
        radius={0}
        height={160}
        width="100%"
      />
      <Box p={16}>
        <Skeleton
          height={12}
          width={170}
          radius="md"
        />
      </Box>
    </Paper>
  )
}
