import { type Alarm } from '@venturi-io/api/src/analytics/alarm'
import { createStyles, Box } from '@mantine/core'
import { computeAlarmPosition } from '..'
import MarkerAnnotationDetails from './MarkerAnnotationDetails'

interface Props {
  fuelLevelLiterAlarms: Alarm[]
  fuelCapacityLiter: number
}

const useStyles = createStyles(() => ({
  container: {
    position: 'absolute',
    top: 0,
    left: '8px',
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1
  }
}))

export default function LiterMarkerAnnotations ({
  fuelLevelLiterAlarms,
  fuelCapacityLiter
}: Props) {
  const { classes } = useStyles()
  const alarmsPositions = fuelLevelLiterAlarms.map(({ setPoint }) => (
    computeAlarmPosition(setPoint, fuelCapacityLiter)
  ))

  return (
    <Box h="100%" className={classes.container}>
      {fuelLevelLiterAlarms
        .map(({
          alarmId,
          name,
          setPoint
        }) => {
          const alarmPosition = computeAlarmPosition(setPoint, fuelCapacityLiter)
          const isCriticalAlarm = Math.min(...alarmsPositions) === alarmPosition

          return (
            <MarkerAnnotationDetails
              key={alarmId}
              alarmId={alarmId}
              alarmName={name}
              percentValue={alarmPosition}
              literValue={setPoint}
              isCriticalAlarm={isCriticalAlarm}
            />
          )
        })
        .sort((a, b) => Number(b.key) - Number(a.key))}
    </Box>
  )
}
