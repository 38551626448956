import { type MantineColor, Stack, type StackProps, Text } from '@mantine/core'
import React from 'react'
import { getTextColorAsProp } from 'src/utils/theme'

interface Props extends StackProps {
  label?: string
  value?: string | number
  color?: MantineColor
}

const StackedLabelValue = ({
  label,
  value,
  color = getTextColorAsProp().color,
  ...props
}: Props) => {
  return (
    <Stack spacing={0} {...props}>
      {label && <Text weight="bold" size="xs">{label}</Text>}
      <Text size="xs" color={color}>{value ?? 'N/A'}</Text>
    </Stack>
  )
}

export default StackedLabelValue
