import { type CSSProperties, useCallback, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type IconName } from '@fortawesome/fontawesome-svg-core'
import {
  ActionIcon,
  Box,
  Menu,
  Tooltip,
  createStyles
} from '@mantine/core'
import { getTripContext } from './GeoZoneMap/Trips/AnimationContext'

export enum MapType {
  satellite = 'mapbox://styles/venturidev/clntzsuec00xk01pn7uxg7844',
  simplified = 'mapbox://styles/venturidev/clhoq5ktd01u701p6gc841esq',
  monochrome = 'mapbox://styles/venturidev/cm1q21wv700rx01rb6i7v4dwy'
}

const mapTypeIcons: Record<MapType, IconName> = {
  [MapType.satellite]: 'earth-oceania',
  [MapType.simplified]: 'globe',
  [MapType.monochrome]: 'earth-americas'
}

interface StyleProps {
  width?: CSSProperties['width']
  offsetTop?: CSSProperties['top']
  offsetBottom?: CSSProperties['bottom']
  offsetRight?: CSSProperties['right']
  offsetLeft?: CSSProperties['left']
}

const useStyles = createStyles((_, {
  width,
  offsetTop,
  offsetBottom,
  offsetRight,
  offsetLeft
}: StyleProps) => ({
  container: {
    position: 'absolute',
    top: offsetTop,
    bottom: offsetBottom,
    right: offsetRight,
    left: offsetLeft,
    width,
    zIndex: 2
  }
}))

interface Props extends StyleProps {
  type: MapType
  onChangeStyle: (mode: MapType) => void
  enabledTripContext?: boolean
}

export default function StylePicker ({
  type,
  onChangeStyle,
  width = 'auto',
  offsetTop,
  offsetBottom,
  offsetRight,
  offsetLeft,
  enabledTripContext = false
}: Props) {
  const { classes } = useStyles({
    width,
    offsetTop,
    offsetBottom,
    offsetRight,
    offsetLeft
  })
  const tripContext = enabledTripContext
    ? useContext(getTripContext())
    : null

  const handleChangeStyle = useCallback((mapStyle: MapType) => () => {
    onChangeStyle(mapStyle)

    if (tripContext !== null) {
      tripContext.onMapReloadStyle()
    }
  }, [tripContext])

  return (
    <Box className={classes.container} p="sm">
      <Menu withArrow>
        <Menu.Target>
          <Tooltip
            label="Map Style"
            position="left"
            withArrow
          >
            <ActionIcon color="primary" variant="filled">
              <FontAwesomeIcon
                icon={[
                  'far',
                  mapTypeIcons[type]
                ]}
              />
            </ActionIcon>
          </Tooltip>
        </Menu.Target>
        <Menu.Dropdown>
          <Tooltip
            label="Satellite"
            position="left"
            withArrow
          >
            <ActionIcon
              color={type === MapType.satellite
                ? 'primary'
                : 'gray.5'}
              onClick={handleChangeStyle(MapType.satellite)}
            >
              <FontAwesomeIcon
                icon={[
                  'fas',
                  mapTypeIcons[MapType.satellite]
                ]}
              />
            </ActionIcon>
          </Tooltip>
          <Tooltip
            label="Simplified"
            position="left"
            withArrow
          >
            <ActionIcon
              color={type === MapType.simplified
                ? 'primary'
                : 'gray.5'}
              onClick={handleChangeStyle(MapType.simplified)}
            >
              <FontAwesomeIcon
                icon={[
                  'fas',
                  mapTypeIcons[MapType.simplified]
                ]}
              />
            </ActionIcon>
          </Tooltip>
          <Tooltip
            label="Monochrome"
            position="left"
            withArrow
          >
            <ActionIcon
              color={type === MapType.monochrome
                ? 'primary'
                : 'gray.5'}
              onClick={handleChangeStyle(MapType.monochrome)}
            >
              <FontAwesomeIcon
                icon={[
                  'fas',
                  mapTypeIcons[MapType.monochrome]
                ]}
              />
            </ActionIcon>
          </Tooltip>
        </Menu.Dropdown>
      </Menu>
    </Box>
  )
}
