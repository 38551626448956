import { type Agent } from '@venturi-io/api/src/config/geoZone'
import { useContext, useEffect } from 'react'
import { getToolContext } from '../Trips/Layer/Devtools'
import { getTripContext } from '../Trips/AnimationContext'
import { useViewTypes } from '../AgentAttendanceContext'
import ClusterMarker from './ClusterMarker'
import MarkerV2 from './MarkerV2'

interface MarkerProps {
  selectedAgent?: Agent
  handleAgentClick: (id: Agent['agentId']) => void
  handleAgentDoubleClick: (id: Agent['agentId'], assetType: Agent['assetType']) => void
}

function Markers ({
  selectedAgent,
  handleAgentClick,
  handleAgentDoubleClick
}: MarkerProps) {
  const { trip } = useContext(getTripContext())
  const { agentPoints, agentClusters, agentCheckReference } = useViewTypes()
  const { showMarkerLayer, showMarkerElements, setShowMarkerElements } = useContext(getToolContext())

  useEffect(() => {
    // lets hide markers by default when a trip is present
    setShowMarkerElements(trip === null)
  }, [trip])

  return !showMarkerLayer && showMarkerElements && (
    <>
      {selectedAgent && ( // Render selected exclusively
        <MarkerV2
          selected
          agent={selectedAgent}
          onClick={handleAgentClick}
          onDoubleClick={handleAgentDoubleClick}
        />
      )}
      {agentClusters.map((cluster, i) => {
        const [longitude, latitude] = cluster.geometry.coordinates
        const {
          cluster: isCluster,
          point_count: pointCount
        } = cluster.properties

        const isSelectedMarker = !isCluster
          ? cluster.properties.agent.agentId === selectedAgent?.agentId
          : false

        return isCluster
          ? (
            <ClusterMarker
              key={`${cluster.id}-${i}`}
              clusterId={cluster.id}
              latitude={latitude}
              longitude={longitude}
              pointCount={pointCount ?? 0}
              totalPoints={agentPoints.length}
              onAgentSelect={handleAgentClick}
            />
            )
            // Render or not
          : isSelectedMarker
            ? undefined // let's not rerender selected marker
            : agentCheckReference[cluster.properties.agent.agentId] && (
              <MarkerV2
                key={cluster.properties.agent.agentId}
                agent={cluster.properties.agent}
                onClick={handleAgentClick}
                onDoubleClick={handleAgentDoubleClick}
              />
            )
      })}
    </>
  )
}

export default Markers
